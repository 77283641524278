import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { editorConfig } from 'common/formControls/textInputs/RichTextEdit2'
import TreeViewPlugin from 'common/formControls/textInputs/RichTextEdit2/plugins/TreeViewPlugin'
import type { SerializedEditorState } from 'lexical'
import { useEffect } from 'react'

type LexicalRendererInternalProps = {
  displayDebugger?: boolean
  editorState: SerializedEditorState
}

const LexicalRendererInternal = (props: LexicalRendererInternalProps) => {
  const { displayDebugger, editorState } = props

  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    if (editorState) {
      // Use a timeout to avoid synchronous updates within react lifecycle method.
      // The ideal solution is to use useLayoutEffect, but that didn't seem to work here.
      // We receive warnings like:
      //    Warning: flushSync was called from inside a lifecycle method.
      //    React cannot flush when React is already rendering.
      //    Consider moving this call to a scheduler task or micro task

      setTimeout(() => {
        editor.update(() => {
          editor.setEditorState(editor.parseEditorState(editorState))
        })
      }, 0)
    }
  }, [editor, editorState])

  return (
    <div className="lexile-renderer">
      <RichTextPlugin
        contentEditable={<ContentEditable className="editor-input" />}
        ErrorBoundary={LexicalErrorBoundary}
      />
      {Boolean(displayDebugger) && <TreeViewPlugin />}
    </div>
  )
}

const LexicalRenderer = (props: LexicalRendererInternalProps) => (
  <LexicalComposer
    initialConfig={{
      ...editorConfig,
      editable: false,
    }}>
    <LexicalRendererInternal {...props} />
  </LexicalComposer>
)

export default LexicalRenderer
