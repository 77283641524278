import FormControlLabel from '@mui/material/FormControlLabel'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import { styled } from '@mui/material/styles'
import cl from 'classnames'
import Popper from 'common/indicators/Popper'
import ThemedPortal from 'common/wrappers/ThemedPortal'
import { get } from 'fp/objects'
import { not } from 'fp/utils'
/* istanbul ignore file */
import PropTypes from 'prop-types'
import { useCallback, useEffect, useState } from 'react'
import { createRoot } from 'react-dom/client'
import { compose } from 'redux'
import videojs from 'video.js'
import RateControl from './RateControl'
import SettingsIcon from './SettingsIcon'

const StyledFormControlLabel = styled(FormControlLabel, {
  name: 'SettingsButton-FormControlLabel',
})(
  ({
    theme: {
      mixins: { important },
      palette,
    },
  }) => ({
    '& .MuiFormControlLabel-label': { color: important(palette.common.white) },
    width: '100%',
  }),
)

const StyledSwitch = styled(Switch, { name: 'SettingsButton-Switch' })(
  ({
    theme: {
      mixins: { important },
      palette,
    },
  }) => ({
    '& .Mui-checked': { color: important(palette.common.white) },
  }),
)

const SettingsToggle = ({ checkedValue, label, onChange }) => (
  <div>
    <StyledFormControlLabel
      control={
        <StyledSwitch
          checked={checkedValue}
          onChange={onChange}
        />
      }
      label={label}
    />
  </div>
)

SettingsToggle.propTypes = {
  checkedValue: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

const Content = props => {
  const {
    captionsOn,
    player,
    setTranscriptOpen,
    toggleCaptionsOn,
    toggleSettingsOpen,
    transcriptOpen,
    transcriptsAvailable,
  } = props

  const handleTranscriptChange = compose(
    setTranscriptOpen,
    get('target.checked'),
  )

  useEffect(() => {
    toggleSettingsOpen()
    return () => toggleSettingsOpen()
  })

  return (
    <Stack
      spacing={2}
      sx={{
        '& .MuiTypography-root, .MuiStack-root, .MuiInputBase-root, .MuiMenuItem-root':
          {
            fontSize: 16,
            color: 'common.white',
          },
      }}>
      {Boolean(transcriptsAvailable) && (
        <>
          <SettingsToggle
            checkedValue={transcriptOpen}
            label="Transcript"
            onChange={handleTranscriptChange}
          />
          <SettingsToggle
            checkedValue={captionsOn}
            label="Captions"
            onChange={toggleCaptionsOn}
          />
        </>
      )}
      <RateControl player={player} />
    </Stack>
  )
}

Content.propTypes = {
  captionsOn: PropTypes.bool.isRequired,
  player: PropTypes.object.isRequired,
  setTranscriptOpen: PropTypes.func.isRequired,
  toggleCaptionsOn: PropTypes.func.isRequired,
  toggleSettingsOpen: PropTypes.func.isRequired,
  transcriptOpen: PropTypes.bool.isRequired,
  transcriptsAvailable: PropTypes.bool.isRequired,
}

const SettingsButton = props => {
  const {
    captionsOn: extCaptionsOn,
    setTranscriptOpen: extSetTranscriptOpen,
    toggleCaptionsOn: extToggleCaptionsOn,
    vjsComponent: { player_ },
  } = props

  const [captionsOn, setCaptionsOn] = useState(extCaptionsOn)
  const [transcriptOpen, setTranscriptOpen] = useState(false)
  const [transcriptsAvailable, setTranscriptsAvailable] = useState(false)
  const [settingsOpen, setSettingsOpen] = useState(false)

  useEffect(() => {
    extToggleCaptionsOn(captionsOn)
  }, [extToggleCaptionsOn, captionsOn])

  useEffect(() => {
    extSetTranscriptOpen(transcriptOpen)
  }, [extSetTranscriptOpen, transcriptOpen])

  const updateTextTracks = useCallback(() => {
    const textTracks = player_?.textTracks() || []

    // While textTracks looks like an iterable, it's actually a custom class
    // It does provide a getter to access text tracks like an array though.
    //
    // https://docs.videojs.com/texttracklist
    // biome-ignore lint/style/useForOf: it's not iterable
    for (let i = 0; i < textTracks.length; i++) {
      if (textTracks[i].kind === 'subtitles') {
        textTracks[i].mode = captionsOn ? 'showing' : 'hidden'
      }
    }
  }, [player_, captionsOn])

  // biome-ignore lint/correctness/useExhaustiveDependencies(captionsOn): right??
  useEffect(() => {
    updateTextTracks()
  }, [captionsOn, updateTextTracks])

  player_?.on('play', () => updateTextTracks())
  player_?.on('texttrackchange', () => {
    const textTracks = player_?.textTracks() || []

    const available = Array.from(textTracks).reduce(
      (acc, { kind }) => kind === 'subtitles' || acc,
      false,
    )

    if (available) {
      setTranscriptsAvailable(true)
    }
  })

  return (
    <ThemedPortal>
      <Popper
        disableCloseButton
        disablePortal
        placement="top">
        <Popper.Control>
          <IconButton title="Settings">
            <SettingsIcon
              color="white"
              height="24"
              settingsOpen={settingsOpen}
              width="24"
            />
          </IconButton>
        </Popper.Control>
        <Popper.Content
          sx={{
            '& .MuiPaper-root': { backgroundColor: 'rgba(0,0,0,.5)' },
            '& .arrow::before': { backgroundColor: 'rgba(0,0,0,.8)' },
            '& .MuiPaper-root .popoverRoot': { padding: '2rem' },
          }}>
          <Content
            captionsOn={captionsOn}
            player={player_}
            setTranscriptOpen={setTranscriptOpen}
            toggleCaptionsOn={() => setCaptionsOn(not)}
            toggleSettingsOpen={() => setSettingsOpen(not)}
            transcriptOpen={transcriptOpen}
            transcriptsAvailable={transcriptsAvailable}
          />
        </Popper.Content>
      </Popper>
    </ThemedPortal>
  )
}

SettingsButton.propTypes = {
  captionsOn: PropTypes.bool.isRequired,
  setTranscriptOpen: PropTypes.func.isRequired,
  toggleCaptionsOn: PropTypes.func.isRequired,
  vjsComponent: PropTypes.object.isRequired,
}

const vjsComponent = videojs.getComponent('Component')

class ssSettingsButton extends vjsComponent {
  constructor(player, options = {}) {
    super(player, options)

    this.options = options
    this.mount = this.mount.bind(this)

    this.el().className = cl('vjs-control', 'ss-settings-button')

    this.root = createRoot(this.el())

    player.ready(() => {
      this.mount()
    })
  }

  mount() {
    this.root.render(
      <SettingsButton
        vjsComponent={this}
        {...this.options}
      />,
    )
  }
}

vjsComponent.registerComponent('ssSettingsButton', ssSettingsButton)

export default SettingsButton
