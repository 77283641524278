import { border } from 'polished'
import type { Theme } from 'styling/theming/@types/custom'

const plugin = ({
  mixins: {
    borderS,
    em,
    // important,
    px,
  },
  palette,
  // typography,
}: Theme) => ({
  '.lexile-editor, .lexile-renderer': {
    position: 'relative',
    height: 'calc(100vh - 275px)',

    '.ltr': {
      textAlign: 'left',
    },

    '.rtl': {
      textAlign: 'right',
    },

    '.editor-container': {
      margin: px(20, 'auto'),
      height: '100%',
      maxWidth: 780,
      color: palette.text.primary,
      position: 'relative',
      lineHeight: px(20),
      fontWeight: 400,
      textAlign: 'left',
    },

    '.editor-inner': {
      height: '100%',
      background: palette.background.paper,
      position: 'relative',
      ...borderS(palette.border[0]),
    },

    '.editor-input': {
      minHeight: 150,
      height: '50%',
      resize: 'none',
      fontSize: 15,
      position: 'relative',
      tabSize: 1,
      outline: 0,
      padding: px(15, 10),
      caretColor: palette.text.primary,
    },

    '.editor-placeholder': {
      color: palette.text.disabled,
      overflow: 'hidden',
      position: 'absolute',
      textOverflow: 'ellipsis',
      top: 15,
      left: 10,
      // fontSize: 15,
      fontStyle: 'italic',
      userSelect: 'none',
      display: 'inline-block',
      pointerEvents: 'none',
    },

    '.editor-text-bold': {
      fontWeight: 'bold',
    },

    '.editor-text-italic': {
      fontStyle: 'italic',
    },

    '.editor-text-underline': {
      textDecoration: 'underline',
    },

    '.editor-text-strikethrough': {
      textDecoration: 'line-through',
      fontWeight: 'bold',
    },

    '.editor-text-underlineStrikethrough': {
      textDecoration: 'underline line-through',
    },

    '.editor-text-code': {
      backgroundColor: palette.background.default,
      padding: '1px 0.25rem',
      fontFamily: 'Menlo, Consolas, Monaco, monospace',
      fontSize: '94%',
    },

    '.editor-link': {
      color: palette.selection,
      textDecoration: 'none',
    },

    '.tree-view-output': {
      display: 'block',
      background: palette.background.inverted,
      color: palette.grey.contrastText,
      padding: 5,
      // fontSize: 12,
      whiteSpace: 'pre-wrap',
      margin: px(1, 'auto', 10),
      maxHeight: 250,
      position: 'relative',
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      overflow: 'auto',
      lineHeight: px(14),

      button: {
        color: palette.grey.contrastText,
      },

      pre: {
        backgroundColor: palette.grey[1],
        color: palette.grey.contrastText,
      },
    },

    '.editor-code': {
      backgroundColor: palette.background.default,
      fontFamily: 'Menlo, Consolas, Monaco, monospace',
      display: 'block',
      padding: px(8, 8, 8, 52),
      lineHeight: 1.53,
      // fontSize: 13,
      margin: 0,
      marginTop: 8,
      marginBottom: 8,
      tabSize: 2,
      /* white-space: pre; */
      overflowX: 'auto',
      position: 'relative',
    },

    '.editor-code:before': {
      content: 'attr(data-gutter)',
      position: 'absolute',
      backgroundColor: palette.border[0],
      left: 0,
      top: 0,
      ...border('right', 1, 'solid', palette.border[1]),
      padding: 8,
      color: '#777',
      whiteSpace: 'pre-wrap',
      textAlign: 'right',
      minWidth: 25,
    },
    '.editor-code:after': {
      content: 'attr(data-highlight-language)',
      top: 0,
      right: 3,
      padding: 3,
      fontSize: 10,
      textTransform: 'uppercase',
      position: 'absolute',
      color: palette.text.secondary,
    },

    '.editor-tokenComment': {
      color: 'slategray',
    },

    '.editor-tokenPunctuation': {
      color: '#999',
    },

    '.editor-tokenProperty': {
      color: '#905',
    },

    '.editor-tokenSelector': {
      color: '#690',
    },

    '.editor-tokenOperator': {
      color: '#9a6e3a',
    },

    '.editor-tokenAttr': {
      color: '#07a',
    },

    '.editor-tokenVariable': {
      color: '#e90',
    },

    '.editor-tokenFunction': {
      color: '#dd4a68',
    },

    '.editor-paragraph': {
      margin: 0,
      marginBottom: 8,
      position: 'relative',
    },

    '.editor-paragraph:last-child': {
      marginBottom: 0,
    },

    '.editor-heading-h1': {
      fontSize: 24,
      // color: 'rgb(5, 5, 5)',
      fontWeight: 400,
      margin: 0,
      marginBottom: 12,
      padding: 0,
    },

    '.editor-heading-h2': {
      fontSize: 15,
      // color: 'rgb(101, 103, 107)',
      fontWeight: 700,
      margin: 0,
      marginTop: 10,
      padding: 0,
      textTransform: 'uppercase',
    },

    '.editor-quote': {
      margin: 0,
      marginLeft: 20,
      fontSize: 15,
      color: 'rgb(101, 103, 107)',
      borderLeftColor: 'rgb(206, 208, 212)',
      borderLeftWidth: 4,
      borderLeftStyle: 'solid',
      paddingLeft: 16,
    },

    '.editor-list-ol': {
      padding: 0,
      margin: 0,
      marginLeft: 16,
    },

    '.editor-list-ul': {
      padding: 0,
      margin: 0,
      marginLeft: 16,
    },

    '.editor-listitem': {
      margin: px(8, 32),
    },

    '.editor-nested-listitem': {
      listStyleType: 'none',
    },

    '.editor-table': {
      borderCollapse: 'collapse',
      borderSpacing: 0,
      overflowY: 'scroll',
      overflowX: 'scroll',
      tableLayout: 'fixed',
      width: 'max-content',
      margin: '30px 0',
    },
    '.editor-tableSelection *::selection': {
      backgroundColor: 'transparent',
    },
    '.editor-tableSelected': {
      outline: '2px solid rgb(60, 132, 244)',
    },
    '.editor-tableCell': {
      border: '1px solid #bbb',
      width: 75,
      minWidth: 75,
      verticalAlign: 'top',
      textAlign: 'start',
      padding: '6px 8px',
      position: 'relative',
      outline: 'none',
    },
    '.editor-tableCellSortedIndicator': {
      display: 'block',
      opacity: 0.5,
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: 4,
      backgroundColor: '#999',
    },
    '.editor-tableCellResizer': {
      position: 'absolute',
      right: '-4px',
      height: '100%',
      width: 8,
      cursor: 'ew-resize',
      zIndex: 10,
      top: 0,
    },
    '.editor-tableCellHeader': {
      backgroundColor: '#f2f3f5',
      textAlign: 'start',
    },
    '.editor-tableCellSelected': {
      backgroundColor: '#c9dbf0',
    },
    '.editor-tableCellPrimarySelected': {
      border: '2px solid rgb(60, 132, 244)',
      display: 'block',
      height: 'calc(100% - 2px)',
      position: 'absolute',
      width: 'calc(100% - 2px)',
      left: '-1px',
      top: '-1px',
      zIndex: 2,
    },
    '.editor-tableCellEditing': {
      boxShadow: '0 0 5px rgba(0, 0, 0, 0.4)',
      borderRadius: 3,
    },
    '.editor-tableAddColumns': {
      position: 'absolute',
      top: 0,
      width: 20,
      backgroundColor: '#eee',
      height: '100%',
      right: '-25px',
      animation: 'table-controls 0.2s ease',
      border: 0,
      cursor: 'pointer',
    },
    '.editor-tableAddColumns:after': {
      backgroundImage: 'url(../images/icons/plus.svg)',
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      display: 'block',
      content: "' '",
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      opacity: 0.4,
    },
    '.editor-tableAddColumns:hover': {
      backgroundColor: '#c9dbf0',
    },
    '.editor-tableAddRows': {
      position: 'absolute',
      bottom: '-25px',
      width: 'calc(100% - 25px)',
      backgroundColor: '#eee',
      height: 20,
      left: 0,
      animation: 'table-controls 0.2s ease',
      border: 0,
      cursor: 'pointer',
    },
    '.editor-tableAddRows:after': {
      backgroundImage: 'url(../images/icons/plus.svg)',
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      display: 'block',
      content: "' '",
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      opacity: 0.4,
    },
    '.editor-tableAddRows:hover': {
      backgroundColor: '#c9dbf0',
    },
    '@keyframes table-controls': {
      '0%': {
        opacity: 0,
      },
      '100%': {
        opacity: 1,
      },
    },
    '.editor-tableCellResizeRuler': {
      display: 'block',
      position: 'absolute',
      width: 1,
      backgroundColor: 'rgb(60, 132, 244)',
      height: '100%',
      top: 0,
    },
    '.editor-tableCellActionButtonContainer': {
      display: 'block',
      right: 5,
      top: 6,
      position: 'absolute',
      zIndex: 4,
      width: 20,
      height: 20,
    },
    '.editor-tableCellActionButton': {
      backgroundColor: '#eee',
      display: 'block',
      border: 0,
      borderRadius: 20,
      width: 20,
      height: 20,
      color: '#222',
      cursor: 'pointer',
    },
    '.editor-tableCellActionButton:hover': {
      backgroundColor: '#ddd',
    },

    'pre::-webkit-scrollbar': {
      background: 'transparent',
      width: 10,
    },

    'pre::-webkit-scrollbar-thumb': {
      background: '#999',
    },

    '.debug-timetravel-panel': {
      overflow: 'hidden',
      padding: px(0, 0, 10),
      margin: 'auto',
      display: 'flex',
    },

    '.debug-timetravel-panel-slider': {
      padding: 0,
      flex: 8,
    },

    '.debug-timetravel-panel-button': {
      padding: 0,
      border: 0,
      background: 'none',
      flex: 1,
      color: palette.grey.contrastText,
      fontSize: 12,
    },

    '.debug-timetravel-panel-button:hover': {
      textDecoration: 'underline',
    },

    '.debug-timetravel-button': {
      border: 0,
      padding: 0,
      fontSize: 12,
      top: 10,
      right: 15,
      position: 'absolute',
      background: 'none',
      color: palette.text.secondary,
    },

    '.debug-timetravel-button:hover': {
      textDecoration: 'underline',
    },

    '.emoji': {
      color: 'transparent',
      backgroundSize: px(16),
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      verticalAlign: 'middle',
      margin: px(0, -1),
    },

    '.emoji-inner': {
      padding: em(0, 0.15),
    },

    '.emoji-inner::selection': {
      color: 'transparent',
      backgroundColor: 'rgba(150, 150, 150, 0.4)',
    },

    '.emoji-inner::moz-selection': {
      color: 'transparent',
      backgroundColor: 'rgba(150, 150, 150, 0.4)',
    },

    '.emoji.happysmile': {
      // backgroundImage: `url(${Happy})`,
    },

    '.dropdown': {
      zIndex: 5,
      display: 'block',
      position: 'absolute',
      boxShadow:
        '0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1)',
      inset: '0 0 0 1px rgba(255, 255, 255, 0.5)',
      borderRadius: 8,
      minWidth: 100,
      minHeight: 40,
      backgroundColor: '#fff',
    },

    '.dropdown .item': {
      margin: '0 8px 0 8px',
      padding: 8,
      color: '#050505',
      cursor: 'pointer',
      lineHeight: px(16),
      fontSize: 15,
      display: 'flex',
      alignContent: 'center',
      flexDirection: 'row',
      flexShrink: 0,
      justifyContent: 'space-between',
      backgroundColor: '#fff',
      borderRadius: 8,
      border: 0,
      minWidth: 268,
    },

    '.dropdown .item .active': {
      display: 'flex',
      width: 20,
      height: 20,
      backgroundSize: 'contain',
    },

    '.dropdown .item:first-child': {
      marginTop: 8,
    },

    '.dropdown .item:last-child': {
      marginBottom: 8,
    },

    '.dropdown .item:hover': {
      backgroundColor: '#eee',
    },

    '.dropdown .item .text': {
      display: 'flex',
      lineHeight: px(20),
      flexGrow: 1,
      width: 200,
    },

    '.dropdown .item .icon': {
      display: 'flex',
      width: 20,
      height: 20,
      userSelect: 'none',
      marginRight: 12,
      lineHeight: px(16),
      backgroundSize: 'contain',
    },

    '.link-editor': {
      position: 'absolute',
      zIndex: 100,
      top: '-10000px',
      left: '-10000px',
      marginTop: '-6px',
      maxWidth: 300,
      width: '100%',
      opacity: 0,
      backgroundColor: '#fff',
      boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.3)',
      borderRadius: 8,
      transition: 'opacity 0.5s',
    },

    '.link-editor .link-input': {
      display: 'block',
      width: 'calc(100% - 24px)',
      boxSizing: 'border-box',
      margin: '8px 12px',
      padding: '8px 12px',
      borderRadius: 15,
      backgroundColor: '#eee',
      fontSize: 15,
      color: 'rgb(5, 5, 5)',
      border: 0,
      outline: 0,
      position: 'relative',
      fontFamily: 'inherit',
    },

    // '.link-editor div.link-edit': {
    //   backgroundImage: `url(${Pencil})`,
    //   backgroundSize: 16,
    //   backgroundPosition: 'center',
    //   backgroundRepeat: 'no-repeat',
    //   width: 35,
    //   verticalAlign: '-0.25em',
    //   position: 'absolute',
    //   right: 0,
    //   top: 0,
    //   bottom: 0,
    //   cursor: 'pointer',
    // },

    '.link-editor .link-input a': {
      color: 'rgb(33, 111, 219)',
      textDecoration: 'none',
      display: 'block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      marginRight: 30,
      textOverflow: 'ellipsis',
    },

    '.link-editor .link-input a:hover': {
      textDecoration: 'underline',
    },

    '.link-editor .button': {
      width: 20,
      height: 20,
      display: 'inline-block',
      padding: 6,
      borderRadius: 8,
      cursor: 'pointer',
      margin: '0 2px',
    },

    '.link-editor .button.hovered': {
      width: 20,
      height: 20,
      display: 'inline-block',
      backgroundColor: '#eee',
    },

    '.link-editor .button i, .actions i': {
      backgroundSize: 'contain',
      display: 'inline-block',
      height: 20,
      width: 20,
      verticalAlign: '-0.25em',
    },
  },
})

export default plugin
