import { annotationShape } from 'core/shapes'
import PropTypes from 'prop-types'
import { forwardRef, useState } from 'react'
import AnnotationHeader from './AnnotationHeader'
import AnnotationTextField from './AnnotationTextField'

const VideoAnnotation = forwardRef((props, ref) => {
  const { annotation, handleRemove, handleUpdate, id, idx, postAnnotation } =
    props
  const [value, setValue] = useState(annotation.text)

  const handleChange = ({ target: { value: newValue } }) => {
    setValue(newValue)
    handleUpdate(id, annotation, newValue)
  }

  return (
    <>
      <AnnotationHeader
        annotation={annotation}
        handleRemove={() => handleRemove(id)}
        id={id}
        update={postAnnotation}
      />
      <AnnotationTextField
        aria-label={`Annotation ${idx + 1}`}
        label=""
        multiline
        name={`annotations.${idx}.text`}
        onChange={handleChange}
        ref={ref}
        value={value}
      />
    </>
  )
})

VideoAnnotation.propTypes = {
  annotation: annotationShape.isRequired,
  handleRemove: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  idx: PropTypes.number.isRequired,
  postAnnotation: PropTypes.func.isRequired,
}

export default VideoAnnotation
