import Fab from '@mui/material/Fab'
import { useContext } from 'react'
import CollapseButtonNav from 'routing/shells/NavShell/NavSidebar/CollapseButton'
import { sidebarContext } from 'routing/shells/SidebarProvider'
import { rem } from 'styling/theming/base/mixins'

const CollapseButton = props => {
  const context = useContext(sidebarContext)
  const { isSidebarShrunk, setSidebarShrunk, shrinkButtonRef } = context

  return (
    <>
      <Fab
        aria-expanded={!isSidebarShrunk}
        aria-label="Toggle navigation size"
        onClick={() => setSidebarShrunk(!isSidebarShrunk)}
        ref={shrinkButtonRef}
        size="large"
        sx={{ color: isSidebarShrunk ? 'common.white' : 'currentColor' }}
        variant="toc-menu"
        {...props}
      />
      <div>
        <CollapseButtonNav sx={{ left: rem(0) }} />
      </div>
    </>
  )
}

export default CollapseButton
