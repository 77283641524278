import { useTheme } from '@mui/material/styles'
import ColorSwatchPicker from 'common/formControls/selects/ColorSwatchPicker'

const ColorPicker = props => {
  const {
    palette: { annotations },
  } = useTheme()

  return (
    <ColorSwatchPicker
      colors={annotations}
      {...props}
    />
  )
}

export default ColorPicker
