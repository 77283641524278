import copy from 'copy-to-clipboard'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'

const withCopyToClipboard = WrappedComponent => {
  const Enhanced = forwardRef(({ text, disabled = false, ...rest }, ref) => {
    const handleOnClick = () => {
      copy(text)
    }
    return (
      <WrappedComponent
        style={{ pointerEvents: 'auto' }}
        {...rest}
        disabled={disabled}
        onClick={disabled ? null : handleOnClick}
        ref={ref}
      />
    )
  })
  Enhanced.propTypes = {
    text: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    disabled: PropTypes.bool,
  }

  return Enhanced
}

export default withCopyToClipboard
