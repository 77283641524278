import AppBusy from 'common/indicators/AppBusy'
import BusySpinner from 'common/indicators/BusySpinner'
import useEffectOnce from 'hooks/useEffectOnce'
import useReduxPromise from 'hooks/useReduxPromise'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import actionTypes from 'reducers/actionTypes'
import { getListedContent } from 'selectors/content'

const withContentList = (WrappedComponent, { contentType }) => {
  const Enhanced = ({ busy = 'local', queryParams, ...rest }) => {
    const [isLoaded, setIsLoaded] = useState(false)

    const call = useReduxPromise(actionTypes.CONTENT_FETCH_LIST)

    useEffectOnce(() => {
      setIsLoaded(false)
      call({ contentType, queryParams })
        .then(() => {
          setIsLoaded(true)
        })
        .catch(() => {
          setIsLoaded(true)
        })
    })

    const items = useSelector(getListedContent({ contentType }))

    if (isLoaded)
      return <WrappedComponent {...{ items, contentType, ...rest }} />

    if (busy === 'app') return <AppBusy />

    if (busy === 'local') {
      return (
        <BusySpinner
          mt={10}
          segments={11}
          size={64}
        />
      )
    }

    return null
  }

  Enhanced.propTypes = {
    busy: PropTypes.oneOf(['app', 'local', 'silent']),
    queryParams: PropTypes.object,
  }

  return Enhanced
}

export default withContentList
