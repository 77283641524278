/* istanbul ignore file */
import videojs from 'video.js'

export default class GradientOverlay extends videojs.getComponent('Component') {
  constructor(player, options = {}) {
    super(player, options)
    this.addClass('ss-gradient-overlay')
  }

  createEl(tag = 'div', props = {}, attributes = {}) {
    return super.createEl(tag, props, attributes)
  }
}
