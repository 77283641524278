/* istanbul ignore file */

import { isEmpty } from 'fp/arrays'
import { checkPropTypes } from 'prop-types'

const ANONYMOUS = '<<anonymous>>'

/** Adapted from prop-types source code */
const createChainableTypeChecker = validate => {
  function checkType(
    isRequired,
    props,
    propName,
    componentName,
    location,
    propFullName,
  ) {
    const cName = componentName || ANONYMOUS
    const pFullName = propFullName || propName

    if (props[propName] == null) {
      if (isRequired) {
        if (props[propName] === null) {
          return new Error(
            `The ${location} \`${pFullName}\` is marked as required in \`${cName}\`, but its value is \`null\`.`,
          )
        }
        return new Error(
          `The ${location} \`${pFullName}\` is marked as required in \`${cName}\`, but its value is \`undefined\`.`,
        )
      }
      return null
    }
    return validate(props, propName, cName, location, propFullName)
  }

  const chainedCheckType = checkType.bind(null, false)
  chainedCheckType.isRequired = checkType.bind(null, true)

  return chainedCheckType
}

export const arrayOfTypeAndLength = (expectedLen, expectedType) => {
  function validate(props, propName, componentName) {
    const actualLen = props[propName].length

    if (actualLen !== expectedLen) {
      return new Error(
        `Invalid array length ${actualLen} (expected ${expectedLen}) for prop ${propName} supplied to ${componentName}. Validation failed.`,
      )
    }

    /**
     * There's probably a cleaner way to do this, but it'll do for now. This all gets
     * stubbed out in production so I'm not terribly concerned with efficiency.
     *
     * What this does is turn the array of items into a keyed object and also an
     * in-place shape, with the expected propType assigned to each item in the
     * object. Quick and dirty way to check that each element has the correct type.
     */
    const { builtPropTypes, builtProps } = props[propName].reduce(
      (acc, item, i) => ({
        builtPropTypes: {
          ...acc.builtPropTypes,
          [`index${i}`]: expectedType,
        },
        builtProps: {
          ...acc.builtProps,
          [`index${i}`]: item,
        },
      }),
      { builtPropTypes: {}, builtProps: {} },
    )

    checkPropTypes(builtPropTypes, builtProps, 'prop', componentName)

    return null
  }
  return createChainableTypeChecker(validate)
}

// export const isoDateTime = createChainableTypeChecker((props, propName, componentName) => {
//   if (!/\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+/.test(props[propName])) {
//     return new Error(`Invalid date time \`${ propName }\` supplied to \`${ componentName }\`. Validation failed.`)
//   }
//   return null
// })

export const emptyObjectOrShape = shape => (props, propName, componentName) => {
  const { [propName]: propValue } = props

  if (!isEmpty(Object.keys(propValue || {}))) {
    checkPropTypes(shape, propValue, 'prop', componentName)
  }

  return null
}
