import { colorShape, componentShape, refShape, sizeShape } from 'core/shapes'
import { difference } from 'fp/arrays'
import { omit, pick } from 'fp/objects'
import PropTypes from 'prop-types'
import withHookForm from './withHookForm'

const commonPropNames = [
  'checked',
  'color',
  'disabled',
  'error',
  'helperText',
  'indeterminate',
  'inputProps',
  'inputRef',
  'label',
  'name',
  'onBlur',
  'onChange',
  'onFocus',
  'options',
  'placeholder',
  'required',
  'row',
  'size',
  'value',
]

const commonPropTypes = {
  color: colorShape,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  helperText: componentShape,
  indeterminate: PropTypes.bool,
  inputProps: PropTypes.object,
  inputRef: refShape.isRequired,
  label: componentShape,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  size: sizeShape,
  value: PropTypes.any,
}

const splitIntoPropBags = controlPropNames => props => {
  const pool = difference(commonPropNames)(controlPropNames)
  return {
    componentProps: pick(pool)(props),
    rest: omit([...pool, ...controlPropNames])(props),
    controlProps: pick(controlPropNames)(props),
  }
}

export const withPropBags =
  (controlPropNames, injectUpfront) => WrappedComponent => {
    const Enhanced = props => {
      const assertedProps = {
        color: 'primary',
        disabled: false,
        error: false,
        helperText: undefined,
        indeterminate: false,
        inputProps: {},
        label: undefined,
        onBlur: undefined,
        onChange: undefined,
        onFocus: undefined,
        placeholder: undefined,
        required: false,
        size: 'medium',
        value: false,
        ...props,
      }
      const { componentProps, controlProps, rest } =
        splitIntoPropBags(controlPropNames)(assertedProps)
      return (
        <WrappedComponent
          componentProps={componentProps}
          controlProps={controlProps}
          rest={rest}
        />
      )
    }

    Enhanced.propTypes = commonPropTypes

    return withHookForm(Enhanced, injectUpfront)
  }

export const propBagsShape = PropTypes.shape({
  componentProps: PropTypes.object.isRequired,
  rest: PropTypes.object.isRequired,
  controlProps: PropTypes.object.isRequired,
})
