import { useContext } from 'react'
import SignInButton from './SignInButton'
import { context } from './context'

const SignIn = () => {
  const { signIn, status } = useContext(context)

  return status === 'SIGNIN' ? (
    <SignInButton
      onClick={() => signIn()}
      style={{ marginTop: '4rem' }}
    />
  ) : null
}

export default SignIn
