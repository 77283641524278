import cl from 'classnames'
import { videoPlayerContext } from 'common/avclub/video/context'
import { numberOrString } from 'core/shapes'
import PropTypes from 'prop-types'
import { forwardRef, useContext, useEffect, useRef } from 'react'
import videojs from 'video.js'

/**
 * Based on a gist to deal with mount/unmount concerns:
 * https://codesandbox.io/s/react-videojs-strictmode-forked-gkk5eh?file=/src/VideoJS.js
 */

const PlayerWrapper = forwardRef((props, playerRef) => {
  const placeholderRef = useRef(null)
  const { captionId } = useContext(videoPlayerContext)

  const {
    //
    className,
    id,
    onMounted,
    options,
    poster,
    src,
  } = props
  const { autoplay } = options

  // biome-ignore lint/correctness/useExhaustiveDependencies: seems we need autoplay?  TODO: check that
  useEffect(() => {
    let player

    if (!playerRef.current) {
      const placeholderEl = placeholderRef.current

      const videoElement = placeholderEl.appendChild(
        document.createElement('video-js'),
      )

      videoElement.setAttribute(
        'class',
        cl(className, 'video-js', 'vjs-big-play-centered'),
      )
      videoElement.setAttribute('poster', poster)
      videoElement.setAttribute('x-webkit-airplay', 'allow')
      videoElement.setAttribute('id', id)
      if (captionId) videoElement.setAttribute('aria-describedby', captionId)

      playerRef.current = videojs(videoElement, options)
      player = playerRef.current
      player.src(src)
      onMounted(player)
    }
  }, [
    autoplay,
    captionId,
    className,
    id,
    onMounted,
    options,
    playerRef,
    poster,
    src,
  ])

  /**
   * Keep this as a separate useEffect, even though it's tempting to add this as
   * the return of the useEffect up top.
   *
   * We only want to unmount when the playerRef changes, and not the other props
   * otherwise there will be flicker.
   */
  useEffect(() => {
    const player = playerRef.current

    return () => {
      if (player) {
        player.dispose()
        playerRef.current = null
      }
    }
  }, [playerRef])

  return <div ref={placeholderRef} />
})

PlayerWrapper.propTypes = {
  className: PropTypes.string,
  id: numberOrString.isRequired,
  onMounted: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
  poster: PropTypes.string,
  src: PropTypes.array.isRequired,
}

export default PlayerWrapper
