import MenuItem from '@mui/material/MenuItem'
import Tooltip from '@mui/material/Tooltip'
import { componentShape } from 'core/shapes'
import PropTypes from 'prop-types'

const MenuItemWithTooltip = ({ children, title = '', ...rest }) => (
  <MenuItem
    {...rest}
    variant="with-tooltip">
    <Tooltip
      placement="right"
      title={title}>
      <div className="content">{children}</div>
    </Tooltip>
  </MenuItem>
)

MenuItemWithTooltip.propTypes = {
  children: componentShape.isRequired,
  title: PropTypes.string,
}

export default MenuItemWithTooltip
