import Box from '@mui/material/Box'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Fade from '@mui/material/Fade'
import Paper from '@mui/material/Paper'
import MuiPopper from '@mui/material/Popper'
import { styled } from '@mui/material/styles'
import CloseButton from 'common/dialogs/CloseButton'
import ThemedPortal from 'common/wrappers/ThemedPortal'
import { componentShape } from 'core/shapes'
import { arraySequence } from 'fp/arrays'
import { increment } from 'fp/numbers'
import { wrap } from 'fp/strings'
import { when } from 'fp/utils'
import withTabModeSupport from 'hoc/withTabModeSupport'
import {
  bindPopper,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks'
import PropTypes from 'prop-types'
import {
  cloneElement,
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from 'react'
import KeyboardEventHandler from 'react-keyboard-event-handler'
import { useFocusTrap } from 'react-use-focus-trap'

const arrowW = 32
const arrowH = 22.6 // width / sqrt(2) = (length of the hypotenuse)

const Context = createContext()

const usePopupTooltipContext = () => {
  const context = useContext(Context)
  if (!context) {
    throw new Error(
      'Control and Content compound components cannot be rendered outside the Popper component',
    )
  }
  return context
}

const Popper = props => {
  const {
    children,
    container,
    disableCloseButton = false,
    /**
     * NOTE:
     * Turning this off (e.g. allowing the Popper to be portalled) will cause the
     * component to be wrapped with StyledPopper, which is known to have a significant
     * effect on performance.
     *
     * Turn it off if you absolutely need access to the theme within your popper
     * content, but be aware of the performance implications; and please do test
     * the carp out of your changes. 🐟
     */
    disablePortal = true,
    paperStyle,
    placement = 'bottom',
  } = props
  const id = useId()
  const popupState = usePopupState({
    variant: 'popover',
    popupId: id,
  })
  const value = useMemo(
    () => ({
      container,
      disableCloseButton,
      disablePortal,
      id,
      paperStyle,
      placement,
      popupState,
    }),
    [
      container,
      disableCloseButton,
      disablePortal,
      id,
      paperStyle,
      placement,
      popupState,
    ],
  )
  return <Context.Provider value={value}>{children}</Context.Provider>
}

const Control = ({ children }) => {
  const { popupState } = usePopupTooltipContext()
  return cloneElement(children, bindTrigger(popupState))
}
const TabSupportedPopper = withTabModeSupport(MuiPopper)
const ThemedPopper = /* istanbul ignore next */ props => (
  <ThemedPortal>
    <TabSupportedPopper {...props} />
  </ThemedPortal>
)

const Content = ({
  children,
  className,
  hideArrow = false,
  onClose,
  onOpen,
  ...rest
}) => {
  const [arrowRef, setArrowRef] = useState(null)
  const closeButtonRef = useRef()
  const [focusTrapRef] = useFocusTrap()
  const {
    container,
    disableCloseButton,
    disablePortal,
    paperStyle,
    placement,
    popupState,
  } = usePopupTooltipContext()
  const { isOpen } = popupState

  useEffect(() => {
    when(isOpen, onOpen)
  }, [isOpen, onOpen])

  // biome-ignore lint/correctness/useExhaustiveDependencies: ??
  useEffect(() => {
    if (isOpen && closeButtonRef.current && !disableCloseButton) {
      closeButtonRef.current.focus()
    }
  }, [closeButtonRef.current, isOpen])

  const close = useCallback(() => {
    popupState.close()
    onClose?.()
  }, [onClose, popupState])

  const ActualPopper = useMemo(
    () => (disablePortal ? MuiPopper : ThemedPopper),
    [disablePortal],
  )
  const mainViewPort = document.getElementsByTagName('main')[0]

  return (
    <ActualPopper
      {...bindPopper(popupState)}
      {...rest}
      className={className}
      container={container}
      disablePortal={disablePortal}
      modifiers={[
        {
          name: 'arrow',
          options: {
            element: arrowRef,
          },
        },
        {
          name: 'offset',
          options: {
            offset: [0, hideArrow ? arrowH / 2 : arrowH],
          },
        },
        {
          name: 'preventOverflow',
          options: {
            boundary: disablePortal ? mainViewPort : window,
          },
        },
      ]}
      placement={placement}
      transition>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps}>
          <Paper
            className="popoverContainer"
            ref={focusTrapRef}>
            {!hideArrow && (
              <span
                className="arrow"
                ref={setArrowRef}
              />
            )}

            {!disableCloseButton && (
              <>
                <CloseButton
                  className="closeButton"
                  onClick={close}
                  ref={closeButtonRef}
                />

                <KeyboardEventHandler
                  handleEventType="keyup"
                  handleFocusableElements
                  handleKeys={['esc']}
                  onKeyEvent={close}
                />
              </>
            )}

            <ClickAwayListener onClickAway={close}>
              <Paper
                className="popoverRoot"
                style={paperStyle}>
                <Box className="content">{children}</Box>
              </Paper>
            </ClickAwayListener>
          </Paper>
        </Fade>
      )}
    </ActualPopper>
  )
}

Content.propTypes = {
  children: componentShape.isRequired,
  hideArrow: PropTypes.bool,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
}

const PopperStyledContent = styled(Content, { name: 'indicators-Popper' })(
  ({ theme: { palette, shadows, typography, zIndex } }) => {
    const paperColor = palette.common.white
    const boxShadow = shadows[3]

    return {
      zIndex: zIndex.tooltip,

      '&[data-popper-placement*="bottom"] .arrow': {
        top: 0,
        left: 0,
        marginTop: -arrowH,
        marginLeft: 4,
        marginRight: 4,
        '&::before': {
          transformOrigin: '0 100%',
        },
      },
      '&[data-popper-placement*="top"] .arrow': {
        bottom: 0,
        left: 0,
        marginBottom: -arrowH,
        '&::before': {
          transformOrigin: '100% 0',
        },
      },
      '&[data-popper-placement*="right"] .arrow': {
        left: 0,
        marginLeft: -arrowH,
        height: arrowW,
        width: arrowH,
        marginTop: 4,
        marginBottom: 4,
        '&::before': {
          transformOrigin: '100% 100%',
        },
      },
      '&[data-popper-placement*="left"] .arrow': {
        right: 0,
        marginRight: -arrowH,
        height: arrowW,
        width: arrowH,
        marginTop: 4,
        marginBottom: 4,
        '&::before': {
          transformOrigin: '0 0',
        },
      },

      '.arrow': {
        overflow: 'hidden',
        position: 'absolute',
        width: arrowW,
        height: arrowH,
        boxSizing: 'border-box',
        color: paperColor,
        '&::before': {
          content: '""',
          margin: 'auto',
          display: 'block',
          width: '100%',
          height: '100%',
          boxShadow,
          backgroundColor: 'currentColor',
          transform: 'rotate(45deg)',
        },
      },

      '.closeButton': {
        right: 15,
        top: 15,
      },

      '.popoverRoot': {
        backgroundColor: paperColor,
        boxShadow,
        maxWidth: 495,
        padding: 32,
        borderRadius: 0,
      },

      '.content': {
        color: palette.text.primary,
        ...typography.variants.body1,
      },

      '.popoverContainer': {
        boxShadow: 'none',
      },

      ...arraySequence(6)
        .map(increment)
        .map(wrap('h', ':first-of-type'))
        .map(key => ({
          [key]: { ...typography.h5 },
        }))
        .reduce((acc, obj) => ({ ...acc, ...obj }), {}),
    }
  },
)

Popper.Control = memo(Control)
Popper.Content = memo(PopperStyledContent)

Popper.propTypes = {
  children: componentShape.isRequired,
  container: PropTypes.any,
  disableCloseButton: PropTypes.bool,
  disablePortal: PropTypes.bool,
  paperStyle: PropTypes.object,
  placement: PropTypes.oneOf([
    'auto-end',
    'auto-start',
    'auto',
    'bottom-end',
    'bottom-start',
    'bottom',
    'left-end',
    'left-start',
    'left',
    'right-end',
    'right-start',
    'right',
    'top-end',
    'top-start',
    'top',
  ]),
}
export default Popper
