import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import cl from 'classnames'
import { componentShape } from 'core/shapes'
import { border } from 'polished'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'

const StyledFormActions = styled(Box, { name: 'Form-FormActions' })(
  ({
    theme: {
      mixins: { rem },
      palette,
    },
  }) => ({
    margin: rem(3, 0, 2),
    justifyContent: 'flex-end',
    display: 'flex',
    flexWrap: 'wrap-reverse',
    gap: 8,

    button: {
      marginTop: 6,
    },

    '&.divider': {
      ...border('top', 1, 'solid', palette.border[0]),
      margin: rem(3, 0, 2),
      padding: rem(2.5, 0),
    },
  }),
)

const FormActions = forwardRef(({ children, divider = true, ...rest }, ref) => (
  <StyledFormActions
    className={cl({ divider })}
    {...rest}
    ref={ref}>
    {children}
  </StyledFormActions>
))

FormActions.propTypes = {
  children: componentShape,
  divider: PropTypes.bool,
}

export default FormActions
