import List from '@mui/material/List'
import { styled } from '@mui/material/styles'
import CollapsibleSection from 'common/layout/CollapsibleSection'
import { contentShape } from 'core/shapes'
import { orderBy } from 'fp/arrays'
import { labels } from 'hss/ContentBuilder/consts'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { getAggregatedVocabFromContentChildren } from 'selectors/vocabulary'

const StyledTable = styled('table', { name: 'VocabularyList-Table' })({
  width: '100%',
  th: {
    verticalAlign: 'top',
  },
})

const VocabularyList = ({ vocabPhrases }) =>
  vocabPhrases?.length ? (
    <List variant="column-2">
      {orderBy('name')(vocabPhrases).map(({ id, name }) => (
        <li key={id}>{name}</li>
      ))}
    </List>
  ) : (
    '[none]'
  )

VocabularyList.propTypes = {
  vocabPhrases: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

const DisplayChildVocabulary = ({ content }) => {
  const { contentType } = content || {}
  const aggregateVocab = useSelector(
    getAggregatedVocabFromContentChildren({ content }),
  )
  const { academicVocabPhrases, contentVocabPhrases, footnoteVocabPhrases } =
    aggregateVocab
  const isEmpty = !(
    academicVocabPhrases.length ||
    contentVocabPhrases.length ||
    footnoteVocabPhrases.length
  )

  return (
    <CollapsibleSection
      empty={Boolean(isEmpty)}
      label="Vocabulary">
      <p>
        The following vocabulary terms are used in this{' '}
        {labels[contentType].toLowerCase()}.
      </p>
      <StyledTable>
        <tbody>
          <tr>
            <th>Content:</th>
            <td>
              <VocabularyList vocabPhrases={contentVocabPhrases} />
            </td>
          </tr>
          <tr>
            <th>Academic:</th>
            <td>
              <VocabularyList vocabPhrases={academicVocabPhrases} />
            </td>
          </tr>
          <tr>
            <th>Footnote:</th>
            <td>
              <VocabularyList vocabPhrases={footnoteVocabPhrases} />
            </td>
          </tr>
        </tbody>
      </StyledTable>
    </CollapsibleSection>
  )
}

DisplayChildVocabulary.propTypes = {
  content: contentShape.isRequired,
}

export default DisplayChildVocabulary
