import Box from '@mui/material/Box'
import { maxFormWidth } from './utils'

const ContainedFormContent = props => (
  <Box
    maxWidth={maxFormWidth}
    {...props}
  />
)

export default ContainedFormContent
