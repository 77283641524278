import { get } from 'fp/objects'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import ConnectedMultiSelect from '.'

const Wrapper = ({
  placeholder,
  reduceOptions,
  style,
  subTitle,
  title,
  value = [],
  ...rest
}) => {
  const { name, valueField } = rest

  /**
   * `name` is the data key for the "built out" values (e.g. "instructionStandards").
   * `valueField` is the key for the ids (e.g. instructionStandardIds).
   *
   * The picker only passes up changes through `onChange`.  Here we watch that for updates
   * (addition, removals) and then update both the form's `name` field and `valueField`
   * so that they stay in sync.
   *
   * Later when the form is submitted, we only pass `valueField` (e.g. the ids)
   */

  const { setValue } = useFormContext()

  const handleChange = newValue => {
    setValue(name, newValue, { shouldDirty: true })
    setValue(valueField, (newValue || []).map(get('value')), {
      shouldDirty: true,
    })
  }

  return (
    <ConnectedMultiSelect
      noOptionsText="No items found"
      {...rest}
      onChange={handleChange}
      value={value === '' ? [] : value}
    />
  )
}

Wrapper.propTypes = {
  placeholder: PropTypes.string,
  reduceOptions: PropTypes.func,
  style: PropTypes.object,
  subTitle: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
}

const withConnectedMultiSelect = options => props => (
  <Wrapper
    {...options}
    {...props}
  />
)

export default withConnectedMultiSelect
