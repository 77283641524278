import { renderOption } from 'common/formControls/selects/ConnectedMultiSelect/utils'
import withConnectedMultiSelect from 'common/formControls/selects/ConnectedMultiSelect/withConnectedMultiSelect'
import { METADATA_TYPE_STANDARD } from 'core/consts'
import { restEndpoint } from 'reducers/utils'

const ConnectedStandardsPicker = withConnectedMultiSelect({
  renderOption: renderOption('label', 'statement'),
  label: 'Add standard',
  orderBy: 'shortCode',
  placeholder: 'Search for standards...',
  uri: `${restEndpoint.metadata}/${METADATA_TYPE_STANDARD}`,
  valueField: 'standardIds',
})

export default ConnectedStandardsPicker
