import { when, whenPresent } from 'fp/utils'
import { useRef, useState } from 'react'

const requestRecorder = async () => {
  const stream = await navigator.mediaDevices.getUserMedia({
    audio: true,
    video: false,
  })
  const rec = new MediaRecorder(stream)
  return { rec, stream }
}

const useAudioRecorder = ({ initialAudioData, onStop } = {}) => {
  const [visualizerAudioData, setVisualizerAudioData] =
    useState(initialAudioData)
  const [visualizerAudio, setVisualizerAudio] = useState()
  const [isRecording, setIsRecording] = useState(false)
  const [visualizerAudioUrl, setVisualizerAudioUrl] = useState('')
  const mediaRecorder = useRef(null)
  const recordedChunks = useRef([])
  const audioContext = useRef()
  const animationFrame = useRef()
  const analyzer = useRef()

  // Obtain the audio when ready.
  /* istanbul ignore next */
  const handleData = ({ data }) => {
    setVisualizerAudioUrl(URL.createObjectURL(data))
    recordedChunks.current.push(data)
  }

  const handleStop = () => {
    whenPresent(onStop, new Blob(recordedChunks.current, { type: 'audio/wav' }))
  }

  // get new audio data for displaying
  const tick = () => {
    const dataArray = new Uint8Array(analyzer.current.frequencyBinCount)
    analyzer.current.getByteTimeDomainData(dataArray)
    setVisualizerAudioData(dataArray)
    animationFrame.current = requestAnimationFrame(tick)
  }

  // get the initial audio context
  // this should only be called once
  const getAudioContext = localAudio => {
    audioContext.current = new (
      window.AudioContext ||
      /* istanbul ignore next */ window.webkitAudioContext
    )()
    analyzer.current = audioContext.current.createAnalyser()

    const source = audioContext.current.createMediaStreamSource(localAudio)
    source.connect(analyzer.current)
    animationFrame.current = requestAnimationFrame(tick)
  }

  const startRecording = async () => {
    const { rec, stream } = await requestRecorder()
    const localAudio = stream

    recordedChunks.current = []
    rec.ondataavailable = handleData
    rec.onstop = handleStop
    rec.start()

    mediaRecorder.current = rec

    setVisualizerAudio(stream)

    getAudioContext(localAudio)

    /* istanbul ignore else */
    if (audioContext.current && audioContext.current.state !== 'running') {
      await audioContext.current.resume()
    }
    setIsRecording(true)
  }

  const stopRecording = async () => {
    if (mediaRecorder.current?.state === 'recording') {
      mediaRecorder.current.stop()
      const stop = visualizerAudio?.getAudioTracks?.()[0].stop || handleStop
      try {
        stop()
      } catch (_) {}

      visualizerAudio?.getAudioTracks?.()[0].stop()
      when(animationFrame.current, cancelAnimationFrame, animationFrame.current)
      /* istanbul ignore else */
      if (audioContext.current?.state === 'running') {
        await audioContext.current.suspend()
      }
    }
    setIsRecording(false)
  }

  const deleteRecording = () => {
    setVisualizerAudioData(null)
    setVisualizerAudioUrl('')
  }

  return {
    deleteRecording,
    isRecording,
    mediaRecorderState: mediaRecorder.current?.state,
    startRecording,
    stopRecording,
    visualizerAudioData,
    visualizerAudioUrl,
  }
}

export default useAudioRecorder
