import { withOptions } from '@comfy/redux-selectors'
import { INTERACTION_STATE_COMPLETED } from 'core/consts'
// import { pullListed } from 'projections/index'
import { dedupe, filter, find, map } from 'fp/arrays'
import { callWith } from 'fp/call'
import { get, hasProperty, pick, set } from 'fp/objects'
import { fallbackTo, identity } from 'fp/utils'
import { compose } from 'redux'
import { createSelector, stateAssignments, stateInteractions } from '.'
import { getContentViewerParams } from './contentViewerParams'
import {
  getAssignmentBehindUserAssignment,
  getUserAssignmentsByAssignmentId,
  getUserAssignmentsById,
} from './userAssignments'

// export const getListedAssignments = createSelector('getListedAssignments')(
//   stateAssignments,
//   pullListed,
// )

const getAssignment = createSelector('getAssignment')(
  stateAssignments,
  getContentViewerParams(),
  (assignments, { assignmentId } = {}) => get(assignmentId)(assignments),
)

export const getAssignmentById = withOptions(({ assignmentId }) =>
  createSelector('getAssignmentById')(
    stateAssignments,
    get(String(assignmentId)),
  ),
)

const createCompletionStatusSetter = (
  interactions,
  contentId,
  contextContentId,
) => {
  const isCompleted = compose(
    Boolean,
    find(
      i =>
        i.contentId === contentId &&
        i.contextContentId === contextContentId &&
        i.state === INTERACTION_STATE_COMPLETED,
    ),
    filter(Boolean),
    Object.values,
    callWith(interactions),
    pick,
    fallbackTo([]),
    get('interactionIds'),
  )

  return contentId && contextContentId
    ? userAssignment =>
        set('completed', isCompleted(userAssignment))(userAssignment)
    : identity
}

export const getAssignmentStudents = withOptions(
  ({ assignmentId, interaction }) =>
    createSelector('getAssignmentStudents')(
      getUserAssignmentsByAssignmentId({ assignmentId }),
      stateInteractions,
      (userAssignments, interactions) => {
        const { contentId, contextContentId } = interaction || {}
        const setCompleted = createCompletionStatusSetter(
          interactions,
          contentId,
          contextContentId,
        )
        return map(setCompleted)(userAssignments)
      },
    ),
)

export const getContextualAssignment = createSelector(
  'getContextualAssignment',
)(
  getAssignment,
  getAssignmentBehindUserAssignment,
  (standaloneAssignment, assignmentFromUserAssignment) =>
    standaloneAssignment || assignmentFromUserAssignment,
)

export const isAssignmentLoaded = withOptions(({ assignmentId }) =>
  createSelector('isAssignmentLoaded')(
    stateAssignments,
    compose(hasProperty(String(assignmentId)), get('loaded')),
  ),
)

export const getAssignmentsFromUserAssignments = withOptions(
  ({ userAssignmentIds }) =>
    createSelector('getAssignmentsFromUserAssignments')(
      getUserAssignmentsById({ userAssignmentIds }),
      stateAssignments,
      (userAssignments, assignments) =>
        compose(
          filter(Boolean),
          map(assignmentId => assignments[assignmentId]),
          dedupe,
          map(get('assignmentId')),
        )(userAssignments),
    ),
)
