import IconButton from '@mui/material/IconButton'
// import { useState } from 'react'
import PlayIcon from 'hss/images/controls/audio/regular-play.svg'
/* istanbul ignore file */
import PropTypes from 'prop-types'
import { createRoot } from 'react-dom/client'
import videojs from 'video.js'
// import ReplayIcon from 'hss/images/controls/audio/replay.svg'

const BigPlayButton = props => {
  const {
    vjsComponent: { player_ },
  } = props
  const label = 'Play Video'
  // const [videoEnded, setVideoEnded] = useState(false)

  const handleClick = () => {
    player_.play()
  }

  // player_.on('ended', () => {
  //   setVideoEnded(true)
  // })

  return (
    <IconButton
      onClick={handleClick}
      title={label}
      type="button">
      {/* TODO: Put this back when we get a good replay button */}
      {/* {videoEnded ? (
        <ReplayIcon
          color="white"
          width="2em"
        />
      ) : (
        <PlayIcon
          color="white"
          width="2em"
        />
      )} */}
      <PlayIcon
        color="white"
        width="2em"
      />
    </IconButton>
  )
}

BigPlayButton.propTypes = {
  vjsComponent: PropTypes.object.isRequired,
}

const vjsComponent = videojs.getComponent('Component')

class ssBigPlayButton extends vjsComponent {
  constructor(player, options = {}) {
    super(player, options)

    this.options = options
    this.mount = this.mount.bind(this)

    this.el().className = 'vjs-big-play-button'

    this.root = createRoot(this.el())

    player.ready(() => {
      this.mount()
    })
  }

  mount() {
    this.root.render(
      <BigPlayButton
        vjsComponent={this}
        {...this.options}
      />,
    )
  }
}

vjsComponent.registerComponent('ssBigPlayButton', ssBigPlayButton)

export default BigPlayButton
