import DragIcon from '@mui/icons-material/DragIndicator'
import Box from '@mui/material/Box'
import FormHelperText from '@mui/material/FormHelperText'
import FormLabel from '@mui/material/FormLabel'
import List from '@mui/material/List'
import cl from 'classnames'
import { componentShape } from 'core/shapes'
import { last } from 'fp/arrays'
import { omit } from 'fp/objects'
import { isDefined, noop, when } from 'fp/utils'
import useHookFormError from 'hooks/useHookFormError'
import PropTypes from 'prop-types'
import {
  createElement,
  createRef,
  forwardRef,
  useEffect,
  useId,
  useRef,
} from 'react'
import { List as SortableList } from 'react-movable'
import { importantRem } from 'styling/theming/base/mixins'
import withList from './withList'

const NonSortableList = props => {
  const { renderItem, sx, values } = props

  return (
    <List sx={sx}>
      {values.map((value, index) =>
        renderItem({
          index,
          value,
          props: { key: index },
        }),
      )}
    </List>
  )
}

NonSortableList.propTypes = {
  renderItem: PropTypes.func.isRequired,
  values: PropTypes.array.isRequired,
  sx: PropTypes.object,
}

const DragHandle = props => (
  <button
    data-movable-handle={1}
    style={{ cursor: 'grab', marginTop: 7 }}
    type="button"
    {...props}>
    <DragIcon />
  </button>
)

const Item = forwardRef((props, ref) => {
  const {
    ItemRenderer,
    allowReordering = true,
    className,
    disabled = false,
    getter,
    index,
    item,
    itemProps,
    onClick,
    readOnly = false,
    replaceItem,
    setter,
    utilizeDragHandle = false,
    ...rest
  } = props

  const onChange = value => {
    when(!readOnly, replaceItem, index, setter(item, value))
  }

  return (
    <Box
      alignItems="flex-start"
      component="li"
      display="flex"
      {...{ className, onClick, ...itemProps }}
      sx={{
        'fieldset legend': {
          fontSize: importantRem(2),
          fontWeight: 'bold',
        },
        ...itemProps.sx,
      }}>
      {createElement(ItemRenderer, {
        disabled,
        index,
        item,
        name: `item-${item.id}`,
        onChange,
        readOnly,
        ref,
        replaceItem,
        value: getter(item),
        ...rest,
      })}

      {Boolean(allowReordering && utilizeDragHandle && !disabled) && (
        <DragHandle aria-label={`Move item at position ${index + 1}`} />
      )}
    </Box>
  )
})
Item.propTypes = {
  allowReordering: PropTypes.bool,
  disabled: PropTypes.bool,
  getter: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  itemProps: PropTypes.object.isRequired,
  ItemRenderer: componentShape.isRequired,
  onClick: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  replaceItem: PropTypes.func.isRequired,
  setter: PropTypes.func,
  utilizeDragHandle: PropTypes.bool,
}

const useNoop = noop

export const AdvancedListPrime = props => {
  const {
    addItem,
    allowDeletion = true,
    allowReordering = false,
    children,
    disabled = false,
    hideLabel = false,
    itemContainerProps = {},
    itemRenderFilterer,
    items,
    label,
    listProps,
    maximumItems,
    minimumItems,
    moveItem,
    nonHookForm = false,
    readOnly = false,
    selectedIndex,
    setSelectedIndex,
    userHasAddedItems = false,
    utilizeDragHandle = false,
    ...rest
  } = props

  const { name } = rest

  const hook = nonHookForm ? useNoop : useHookFormError

  const itemRefs = useRef([])

  const baseItemId = useId()

  const { error } = hook(name) || {}

  if (itemRefs.current.length !== items.length) {
    itemRefs.current = new Array(items.length)
      .fill()
      .map((_, i) => itemRefs.current[i] || createRef())
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies(itemRefs.current.length): ???
  useEffect(() => {
    if (
      userHasAddedItems &&
      itemRefs.current.length &&
      last(itemRefs.current).current
    ) {
      last(itemRefs.current).current.focus?.()
    }
  }, [userHasAddedItems, itemRefs.current.length])

  const handleDragEnd = ({ oldIndex, newIndex }) => {
    moveItem(oldIndex, newIndex)
  }

  const ListComponent = allowReordering ? SortableList : NonSortableList

  return (
    <>
      {Boolean(label && !hideLabel) && <FormLabel>{label}</FormLabel>}

      <ListComponent
        lockVertically
        onChange={handleDragEnd}
        renderItem={({ index, value: item, props: itemProps, isDragged }) =>
          !itemRenderFilterer || itemRenderFilterer(index, item) ? (
            <Item
              key={itemProps.key}
              {...{
                allowDeletion:
                  allowDeletion &&
                  (isDefined(minimumItems)
                    ? items.length > minimumItems
                    : true),
                allowReordering,
                className: cl({
                  selected: index === selectedIndex,
                  dragging: isDragged,
                }),
                disabled,
                index,
                item,
                onClick: () => {
                  setSelectedIndex(index)
                },
                readOnly,
                ref: itemRefs.current[index],
                itemProps: {
                  ...omit('key')(itemProps),
                  ...itemContainerProps,
                },
                utilizeDragHandle,
                ...rest,
              }}
            />
          ) : (
            <li key={`${baseItemId}-${index}`} />
          )
        } // must render something or the indices for the list will not work with the react-moveable library
        renderList={({ children: c, props: p }) => createElement(List, p, c)}
        values={items}
        {...listProps}
      />

      {children?.({
        addItem,
        allowReordering,
        disabled,
        items,
        readOnly,
        utilizeDragHandle,
        ...rest,
      })}

      {!!error && (
        <FormHelperText
          data-variant="block"
          error>
          {error}
        </FormHelperText>
      )}

      {Boolean(isDefined(maximumItems) && items.length >= maximumItems) && (
        <FormHelperText>
          There is a maximum of {maximumItems} items.
        </FormHelperText>
      )}
    </>
  )
}

AdvancedListPrime.propTypes = {
  addItem: PropTypes.func.isRequired,
  allowDeletion: PropTypes.bool,
  allowReordering: PropTypes.bool,
  children: PropTypes.func,
  disabled: PropTypes.bool,
  getter: PropTypes.func.isRequired,
  ItemRenderer: componentShape.isRequired,
  items: PropTypes.array.isRequired,
  hideLabel: PropTypes.bool,
  itemContainerProps: PropTypes.object,
  itemRenderFilterer: PropTypes.func,
  label: PropTypes.string,
  listProps: PropTypes.object,
  maximumItems: PropTypes.number,
  minimumItems: PropTypes.number,
  moveItem: PropTypes.func.isRequired,
  nonHookForm: PropTypes.bool,
  readOnly: PropTypes.bool,
  selectedIndex: PropTypes.number.isRequired,
  setSelectedIndex: PropTypes.func.isRequired,
  setter: PropTypes.func,
  userHasAddedItems: PropTypes.bool,
  utilizeDragHandle: PropTypes.bool,
}

export const itemRendererProps = {
  disabled: PropTypes.bool,
  index: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  removeItem: PropTypes.func.isRequired,
  replaceItem: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
}

const HFAdvancedList = withList(AdvancedListPrime)

export const AdvancedList = withList(AdvancedListPrime, true)

export default HFAdvancedList
