import Dialog from 'common/dialogs/Dialog'
import { CONTENT_TYPE_INTERACTIVE } from 'core/consts'
import { assert, alter } from 'core/store/search/squery'
import useDataset from 'hooks/useDataset'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { restEndpoint } from 'reducers/utils'
import { compose } from 'redux'
import DialogPagination from './DialogPagination'
import InteractivesFilter from './InteractivesFilter'
import InteractivesTable from './InteractivesTable'

const initialSquery = compose(
  alter.set.limit(10),
  alter.set.orderBy('name', 'asc'),
  alter.set.where('contentType').is(CONTENT_TYPE_INTERACTIVE),
)(assert())

const ChooseInteractiveDialog = ({
  excludeIds,
  onClose,
  onComplete,
  subContentTypes,
}) => {
  const tableRef = useRef()
  const [squery, setSquery] = useState(initialSquery)
  const dataset = useDataset({ restEndpoint: restEndpoint.content, squery })

  useEffect(() => {
    setSquery(alter.set.where('id').notIn(excludeIds))
  }, [excludeIds])

  useEffect(() => {
    if (subContentTypes.length) {
      setSquery(alter.set.where('contentSubType').in(subContentTypes))
    }
  }, [subContentTypes])

  return (
    <Dialog
      disableAutoFocus
      maxWidth="sm"
      onClose={onClose}
      open
      showCloseButton
      swatch
      title="Insert Interactive"
      variant="maximized-vertically">
      <InteractivesFilter {...{ squery, setSquery, subContentTypes }} />

      <InteractivesTable {...{ dataset, onComplete, ref: tableRef }} />

      <DialogPagination
        {...{ dataset, squery, setSquery, scrollToResultsRef: tableRef }}
      />
    </Dialog>
  )
}

ChooseInteractiveDialog.propTypes = {
  excludeIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  subContentTypes: PropTypes.array.isRequired,
}

export default ChooseInteractiveDialog
