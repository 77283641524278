import DialogContent from '@mui/material/DialogContent'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import Dialog from 'common/dialogs/Dialog'
import { capitalize } from 'fp/strings'
import { not } from 'fp/utils'
import useBrowser from 'hooks/useBrowser'
import PropTypes from 'prop-types'
import { useId } from 'react'
import bindingsList from './utils/bindings'

const KeyBindingsDialog = ({ excludeToolButtons = [], ...rest }) => {
  const browser = useBrowser()
  const metaKey = browser.getPlatform().vendor === 'Apple' ? '⌘' : 'ctrl'

  const id = useId()
  const titleId = `title-${id}`

  return (
    <Dialog
      showCloseButton
      title="Keyboard Shortcuts"
      TitleProps={{ id: titleId }}
      {...rest}>
      <DialogContent>
        <List
          aria-labelledby={titleId}
          sx={{ pb: 2 }}>
          {bindingsList
            .filter(({ label, name }) =>
              not(excludeToolButtons.includes(label || name)),
            )
            .map(({ key, modifier, name }) => (
              <Grid
                component="li"
                container
                justifyContent="space-between"
                key={key}
                sx={{ pb: 2 }}>
                <span id={`${id}-${key}`}>{name}</span>
                <span
                  aria-labelledby={`${id}-${key}`}>{`${metaKey} ${modifier ? capitalize(modifier) : ''} ${key.toUpperCase()}`}</span>
              </Grid>
            ))}
        </List>
      </DialogContent>
    </Dialog>
  )
}

KeyBindingsDialog.propTypes = {
  excludeToolButtons: PropTypes.arrayOf(PropTypes.string),
}

export default KeyBindingsDialog
