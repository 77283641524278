import { TOGGLE_STATE_PRESENTER_MODE } from 'core/consts'
import useLocalSetting from 'hooks/useLocalSetting'
import useToggleState from 'hooks/useToggleState'
import PropTypes from 'prop-types'
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { isTestEnv } from 'selectors/index'
import {
  echoMaxPaneWidth,
  echoTabSize,
  scrollbarSize,
} from 'styling/theming/base'

const variants = {
  echo: {
    normalWidth: echoMaxPaneWidth - scrollbarSize,
    shrunkWidth: echoTabSize,
  },
  nav: {
    normalWidth: 251,
    shrunkWidth: 60,
  },
  toc: {
    normalWidth: 337,
    shrunkWidth: 60,
  },
}

const sidebarContext = createContext()

const SidebarProvider = ({ children, testingProps, variant }) => {
  const [isSidebarShrunk, setSidebarShrunk] = useLocalSetting(
    `${variant}-nav-shrunk`,
    false,
  )
  const [presenterModeEnabled, togglePresenterMode] = useToggleState(
    false,
    TOGGLE_STATE_PRESENTER_MODE,
  )
  const [isEchoSidebarShrunk, setEchoSidebarShrunk] = useState(true)
  const [isEchoActive, setIsEchoActive] = useState(false)
  const [isEchoScaffoldOpen, setIsEchoScaffoldOpen] = useState(false)
  const sidebarRef = useRef()
  const shrinkButtonRef = useRef()
  const [sidebarWidth, setSidebarWidth] = useState()
  const [echoSidebarWidth, setEchoSidebarWidth] = useState()
  const [viewportWidth, setViewportWidth] = useState()

  useEffect(() => {
    const newSidebarWidth =
      variants[variant][isSidebarShrunk ? 'shrunkWidth' : 'normalWidth']
    setSidebarWidth(newSidebarWidth)
  }, [isSidebarShrunk, variant])

  useEffect(() => {
    const newEchoSidebarWidth =
      variants.echo[isEchoSidebarShrunk ? 'shrunkWidth' : 'normalWidth']
    setEchoSidebarWidth(isEchoActive ? newEchoSidebarWidth : 0)
  }, [isEchoActive, isEchoSidebarShrunk])

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    setViewportWidth(`calc(100vw - ${sidebarWidth}px)`)
  }, [echoSidebarWidth, isEchoSidebarShrunk, sidebarWidth])

  // Only one sidebar can be open at a time (but both can be closed)
  const handleSetSidebarShrunk = useCallback(
    newValue => {
      if (isEchoActive && !newValue && !isEchoSidebarShrunk) {
        setEchoSidebarShrunk(true)
      }
      setSidebarShrunk(newValue)
    },
    [isEchoActive, isEchoSidebarShrunk, setSidebarShrunk],
  )

  // Only one sidebar can be open at a time (but both can be closed)
  const handleSetEchoSidebarShrunk = useCallback(
    newValue => {
      if (!(newValue || isSidebarShrunk)) {
        setSidebarShrunk(true)
      }
      setEchoSidebarShrunk(newValue)
    },
    [isSidebarShrunk, setSidebarShrunk],
  )

  const value = useMemo(
    () => ({
      echoSidebarWidth,
      isEchoScaffoldOpen,
      isEchoSidebarShrunk,
      isSidebarShrunk,
      presenterModeEnabled,
      setIsEchoScaffoldOpen,
      setIsEchoSidebarShrunk: handleSetEchoSidebarShrunk,
      setIsEchoActive,
      setSidebarShrunk: handleSetSidebarShrunk,
      shrinkButtonRef,
      sidebarRef,
      sidebarWidth,
      togglePresenterMode,
      viewportWidth,
      ...(isTestEnv() ? testingProps : null),
    }),
    [
      echoSidebarWidth,
      isEchoScaffoldOpen,
      isEchoSidebarShrunk,
      isSidebarShrunk,
      handleSetEchoSidebarShrunk,
      handleSetSidebarShrunk,
      presenterModeEnabled,
      sidebarWidth,
      testingProps,
      togglePresenterMode,
      viewportWidth,
    ],
  )

  return (
    <sidebarContext.Provider value={value}>{children}</sidebarContext.Provider>
  )
}

SidebarProvider.propTypes = {
  children: PropTypes.node.isRequired,
  testingProps: PropTypes.object,
  variant: PropTypes.oneOf(['echo', 'toc', 'nav']).isRequired,
}

export { sidebarContext, SidebarProvider }
