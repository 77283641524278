import {
  ABILITY_TEACHER_INTERFACE,
  INTERACTION_STATE_COMPLETED,
} from 'core/consts'
import { get, set } from 'fp/objects'
import { unwrap } from 'fp/strings'
import actionTypes from 'reducers/actionTypes'
import { compose } from 'redux'
import { put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { abilityCheck } from 'selectors/userAbility'
import { getCurrentUser } from 'selectors/users'
import { success } from './utils'

export function* handleMessageReceived({ payload }) {
  if (payload.type === 'data') {
    const ranked = payload.data.filter(data =>
      String(data.id).startsWith('ranked-'),
    )
    const interacted = payload.data.filter(
      data => data.interactionType === 'interactive',
    )

    /**
     * The socket could send us many items at once, depending on the class size,
     * the number of interactives on a page, etc.
     *
     * We need to split these up into two groups:
     *  - ranked: interactions that represent a peer rating
     *  - interacted: pure interactions (needs a better name)
     *
     * To reduce the amount of work that redux has to do (as well as avoiding
     * unnecessary re-renders), we batch these are dispatch ONE message for each
     * type
     */

    if (ranked.length) {
      yield put({
        type: actionTypes.SOCKET_INTERACTION_RANKING_RECEIVED,
        payload: ranked.map(compose(set('id', unwrap('ranked-')), get('id'))),
      })
    }

    if (interacted.length) {
      yield put({
        type: actionTypes.SOCKET_INTERACTION_RECEIVED,
        payload: interacted,
      })
    }
  }
}

export function* handleInteractionReceived({ payload = [] }) {
  const { id: userId } = yield select(getCurrentUser)
  const hasContentViewTeach = yield select(
    abilityCheck(ABILITY_TEACHER_INTERFACE),
  )

  const { myItemsWithReactions, otherUsersItems } = payload.reduce(
    (acc, item) => {
      if ((hasContentViewTeach || item.userId === userId) && item.reactions) {
        acc.myItemsWithReactions.push(item)
      } else if (
        hasContentViewTeach ||
        item.state === INTERACTION_STATE_COMPLETED
      ) {
        acc.otherUsersItems.push(item)
      }
      return acc
    },
    { myItemsWithReactions: [], otherUsersItems: [] },
  )

  if (myItemsWithReactions.length) {
    yield put({
      type: actionTypes.SOCKET_REACTIONS_RECEIVED,
      payload: myItemsWithReactions,
    })
  }

  if (otherUsersItems.length) {
    yield put({
      type: success(actionTypes.INTERACTION_POST_MULTIPLE),
      passThrough: { suppressAlert: true },
      responses: otherUsersItems,
    })
  }
}

export function* handleReactionsReceived({ payload }) {
  yield put({
    type: success(actionTypes.REACTIONS_RECEIVED),
    response: {
      data: payload.map(({ id, reactions }) => ({ ...reactions, id })),
      metadata: {},
    },
  })
}

/* istanbul ignore next line */
function* backendSocketSaga() {
  yield takeEvery(actionTypes.SOCKET_MESSAGE_RECEIVED, handleMessageReceived)

  yield takeEvery(
    actionTypes.SOCKET_INTERACTION_RECEIVED,
    handleInteractionReceived,
  )
  yield takeLatest(
    actionTypes.SOCKET_REACTIONS_RECEIVED,
    handleReactionsReceived,
  )
}

export default backendSocketSaga
