import { CONTENT_RESTRICTION_TYPE_NONE } from 'core/consts'
import { buildUrl } from 'fp/internet'
import actionTypes from 'reducers/actionTypes'
import { restEndpoint } from 'reducers/utils'
import { call, select, takeLatest } from 'redux-saga/effects'
import { getDistrictId } from 'selectors/users'
import { dangerouslyCallApi } from './api'

const endpoint = id => buildUrl(`${restEndpoint.contentRestrictions}/${id}`)

function* handleDelete(action) {
  const {
    payload: { contentRestrictionId },
  } = action

  yield call(dangerouslyCallApi, {
    action,
    options: {
      method: 'DELETE',
      body: {
        contentRestrictionId,
      },
    },
    url: endpoint(contentRestrictionId),
  })
}

function* handleCreate(action) {
  const {
    payload: { contentId, type },
  } = action

  const districtId = yield select(getDistrictId)

  yield call(dangerouslyCallApi, {
    action,
    options: {
      method: 'POST',
      body: {
        contentId,
        districtId,
        type,
      },
    },
    url: restEndpoint.contentRestrictions,
  })
}

function* handlePatch(action) {
  const {
    payload: { contentRestrictionId, type },
  } = action

  yield call(dangerouslyCallApi, {
    action,
    options: {
      method: 'PATCH',
      body: {
        type,
      },
    },
    url: endpoint(contentRestrictionId),
  })
}

export function* handleAlteration(action) {
  const { contentRestrictionId, type } = action.payload

  if (contentRestrictionId) {
    if (type === CONTENT_RESTRICTION_TYPE_NONE) {
      yield call(handleDelete, action)
    } else {
      yield call(handlePatch, action)
    }
  } else {
    yield call(handleCreate, action)
  }
}

/* istanbul ignore next line */
function* contentRestrictionsSaga() {
  yield takeLatest(actionTypes.CONTENT_RESTRICTION_ALTERATION, handleAlteration)
}

export default contentRestrictionsSaga
