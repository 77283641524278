import { withOptions } from '@comfy/redux-selectors'
import {
  CONTENT_SUB_TYPE_STANDALONE,
  CONTENT_TYPE_ECHO,
  CONTENT_TYPE_INTERACTIVE,
} from 'core/consts'
import { filter, map } from 'fp/arrays'
import { get } from 'fp/objects'
import { fallbackTo } from 'fp/utils'
import { echoResponse, echoRubric, isSubmitted } from 'projections/echo'
import { compose } from 'redux'
import { createSelector, stateEchoRanking, stateInteractions } from '.'
import { getContentForType } from './collapsedContent'
import { getContentById, getListedContent } from './content'
import { getInteractionsForUser } from './interactions'
import { omitReduxMetadata } from './utils'

export const getStandaloneEchoes = createSelector('getStandaloneEchoes')(
  getListedContent({ contentType: CONTENT_TYPE_ECHO }),
  filter(
    ({ contentSubType }) => contentSubType === CONTENT_SUB_TYPE_STANDALONE,
  ),
)

export const getEchoResponse = withOptions(({ contentType, id: contentId }) =>
  createSelector('getEchoResponse')(
    getContentById({ contentType, contentId }),
    getContentForType(CONTENT_TYPE_INTERACTIVE),
    getInteractionsForUser,
    echoResponse,
  ),
)

export const getEchoRubric = withOptions(({ contentType, id: contentId }) =>
  createSelector('getEchoRubric')(
    getContentById({ contentType, contentId }),
    getContentForType(CONTENT_TYPE_INTERACTIVE),
    echoRubric,
  ),
)

export const isEchoSubmitted = withOptions(({ contentType, id }) =>
  createSelector('isEchoSubmitted')(
    getEchoResponse({ contentType, id }),
    isSubmitted,
  ),
)

const getEchoResponseRankings = createSelector('getEchoResponseRankings')(
  stateEchoRanking,
  omitReduxMetadata,
)

const getEchoResponseRanking = withOptions(({ id }) =>
  createSelector('getEchoResponseRanking')(
    getEchoResponseRankings,
    get(String(id)),
  ),
)

export const getRankedEchoResponses = withOptions(({ id }) =>
  createSelector('getRankedEchoResponses')(
    getEchoResponseRanking({ id }),
    stateInteractions,
    (ranking, interactions) =>
      compose(
        map(({ interactionId, peerReactionAverage, peerReactionCount }) => ({
          peerReaction: interactions[interactionId],
          peerReactionAverage,
          peerReactionCount,
        })),
        fallbackTo([]),
        get('interactionData.ranking'),
      )(ranking),
  ),
)
