import Checkbox from 'common/formControls/switches/Checkbox'
import Centered from 'common/layout/Centered'
import { prefix } from 'fp/strings'
import PropTypes from 'prop-types'
import { Fragment } from 'react'
import { extractActionLabelFromRow } from '../RowActions'

const RowSelectCell = props => {
  const {
    checked,
    indeterminate,
    isCardView = false,
    isHeader,
    onChange,
    pluralLabel,
    row,
    singleLabel,
  } = props

  const ariaLabel = prefix('Select ')(
    row ? extractActionLabelFromRow(row) : singleLabel,
  )

  const Wrapper = isCardView ? Fragment : Centered

  return (
    <Wrapper>
      <Checkbox
        {...{
          checked,
          indeterminate,
          onChange,
        }}
        inputProps={{
          'aria-label': isHeader ? pluralLabel : ariaLabel,
        }}
        label={isCardView ? pluralLabel : ''}
      />
    </Wrapper>
  )
}

RowSelectCell.propTypes = {
  checked: PropTypes.bool.isRequired,
  indeterminate: PropTypes.bool.isRequired,
  isCardView: PropTypes.bool,
  isHeader: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  pluralLabel: PropTypes.string.isRequired,
  row: PropTypes.object,
  singleLabel: PropTypes.string.isRequired,
}

export default RowSelectCell
