import IconButton from '@mui/material/IconButton'
import MuiMenu from '@mui/material/Menu'
import Tooltip from '@mui/material/Tooltip'
import { componentShape } from 'core/shapes'
import PropTypes from 'prop-types'
import { useId, useState } from 'react'
import { MoreVertical } from 'react-feather'

const MenuButton = ({
  ButtonControl = IconButton,
  children,
  disabled = false,
  icon,
  label,
  menuProps,
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const menuId = useId()

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Tooltip title={disabled ? '' : label}>
        <ButtonControl
          aria-controls={menuId}
          aria-haspopup="true"
          aria-label={label}
          color="secondary"
          data-bodyvariant="body1"
          disabled={disabled}
          onClick={handleClick}
          size="large"
          {...rest}>
          {icon || <MoreVertical />}
        </ButtonControl>
      </Tooltip>

      <MuiMenu
        anchorEl={anchorEl}
        id={menuId}
        keepMounted
        {...menuProps}
        onClick={handleClose}
        onClose={handleClose}
        open={Boolean(anchorEl)}>
        {children}
      </MuiMenu>
    </>
  )
}

MenuButton.propTypes = {
  ButtonControl: PropTypes.elementType,
  children: componentShape,
  disabled: PropTypes.bool,
  icon: componentShape,
  label: PropTypes.string.isRequired,
  menuProps: PropTypes.object,
}

export default MenuButton
