import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import cl from 'classnames'
import MaybeDraft from 'common/indicators/Draft'
import BaseTE from 'common/indicators/TE'
import ScreenReaderText from 'common/text/ScreenReaderText'
import { isSubsectionLike } from 'core/consts'
import { contentShape } from 'core/shapes'
import { COMPLETION_DONE, COMPLETION_INDETERMINATE } from 'projections/content'
import { useMatch } from 'react-router-dom'

const TE = styled(BaseTE, { name: 'TocNavItemLabel-TE' })(
  ({
    theme: {
      mixins: { px },
      palette,
      typography,
    },
  }) => ({
    marginLeft: px(8),
    ...typography.variants['nav-item-uppercase-semibold'],
    color: palette.navigation.tocLight,
    display: 'inline-block',
  }),
)

const ItemLabel = ({ className, item }) => {
  const { calculatedCompletion, contentType, data, name, teacherEdition, to } =
    item
  const match = useMatch({ path: to || '' })
  const isTE = teacherEdition

  const leaf = data?.tabbed || isSubsectionLike(contentType)

  return (
    <Typography
      className={cl(className, { leaf })}
      component="div"
      variant={match ? 'nav-item-semibold' : 'nav-item'}>
      {name}
      {calculatedCompletion === COMPLETION_DONE && (
        <ScreenReaderText>(complete)</ScreenReaderText>
      )}
      {calculatedCompletion === COMPLETION_INDETERMINATE && (
        <ScreenReaderText>(partially complete)</ScreenReaderText>
      )}{' '}
      {Boolean(isTE) && <TE />}
      <MaybeDraft
        content={item}
        small
      />
    </Typography>
  )
}

ItemLabel.propTypes = {
  item: contentShape.isRequired,
}

export default ItemLabel
