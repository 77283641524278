import type { Content } from 'common/@types/custom'
import { findObj } from 'fp/arrays'
import { useImageSelectionContext } from '../imagePlugin/ImageSelectionProvider'
import UploadsPreview from './UploadsPreview'

interface TableImageCardRendererProps {
  row: {
    original: {
      name: string
      data: {
        imageAltText: string
      }
    }
    getVisibleCells: () => object[]
  }
}

const TableImageCardRenderer: React.FC<TableImageCardRendererProps> = props => {
  const { row } = props
  const {
    original: { data },
  } = row

  const { imageAltText } = data

  const { setSelectedSearchResult } = useImageSelectionContext()

  const cells = row.getVisibleCells()

  const previewCell = findObj('column.columnDef.header', 'Preview')(cells)

  const handleClick = () => {
    setSelectedSearchResult(row.original as unknown as Content)
  }

  return (
    <>
      <UploadsPreview
        onClick={handleClick}
        altText={imageAltText}
        uploadsMap={previewCell.getValue()}
      />
    </>
  )
}

export default TableImageCardRenderer
