import ContainerQuery from 'common/layout/ContainerQuery'
import { Outlet } from 'react-router-dom'
import Banner from 'routing/shells/NavShell/Banner'

const CurriculumNavShell = () => (
  <>
    <Banner />

    <ContainerQuery>
      <Outlet />
    </ContainerQuery>
  </>
)

export default CurriculumNavShell
