import { insertBlock } from '@studysync/draft-js-modifiers'
import { join } from 'fp/arrays'
import { hasProperty, mapValues, set } from 'fp/objects'
import { insert, wrap } from 'fp/strings'
import PropTypes from 'prop-types'
import { cloneElement } from 'react'
import { compose } from 'redux'

export const INTERACTIVE_WHITESPACE = 'q' // abusing the <q> tag :-/

export const customBlockPropsShape = PropTypes.shape({
  getEditorState: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  setPluginHasFocus: PropTypes.func.isRequired,
})

const isTable = (contentState, block, checkingBefore) => {
  const key = block.getKey()
  const blockBefore = contentState.getBlockBefore(key)
  const blockAfter = contentState.getBlockAfter(key)

  return (
    block.getType() === 'table' &&
    ((checkingBefore && blockBefore?.getType() !== 'table') ||
      (!checkingBefore && blockAfter?.getType() !== 'table'))
  )
}

const isInteractive = (contentState, block, checkingBefore) =>
  block &&
  (['advanced-heading', 'interactive', 'number-crunch'].includes(
    block.getData().get('type'),
  ) ||
    isTable(contentState, block, checkingBefore))

const isWhitespace = block => block?.type === INTERACTIVE_WHITESPACE

const assertWhitespaceBeforePlugins = editorState => {
  if (!editorState?.getCurrentContent) {
    return editorState
  }
  const contentState = editorState.getCurrentContent()
  const blockMap = contentState.getBlockMap()
  const blocks = blockMap.toArray().reverse()

  let newState = editorState

  for (const block of blocks) {
    if (isInteractive(contentState, block, true)) {
      const blockKey = block.getKey()
      const previousBlock = contentState.getBlockBefore(blockKey)

      if (!isWhitespace(previousBlock)) {
        newState = insertBlock(
          'before',
          newState,
          block,
          INTERACTIVE_WHITESPACE,
        )
      }
    }
  }

  return newState
}

const assertWhitespaceAfterPlugins = editorState => {
  if (!editorState?.getCurrentContent) {
    return editorState
  }
  const contentState = editorState.getCurrentContent()
  const blockMap = contentState.getBlockMap()
  const blocks = blockMap.toArray().reverse()

  let newState = editorState

  for (const block of blocks) {
    if (isInteractive(contentState, block, false)) {
      const blockKey = block.getKey()
      const nextBlock = contentState.getBlockAfter(blockKey)

      if (!isWhitespace(nextBlock)) {
        newState = insertBlock('after', newState, block, INTERACTIVE_WHITESPACE)
      }
    }
  }

  return newState
}

export const assertWhitespaceAroundPlugins = compose(
  assertWhitespaceBeforePlugins,
  assertWhitespaceAfterPlugins,
)

export const mergeProps = (target, source) => {
  const props = mapValues(String)(source)

  if (hasProperty('$$typeof')(target)) {
    // Is a react element so we can just extend it with the new props
    return cloneElement(target, props)
  }

  // otherwise it must have the form { start: '', end: '', ... } so we'll
  // need to rebuild 'start'

  const { start } = target

  const startWithProps = insert(
    start.length - 1,
    ' '.concat(
      Object.entries(props)
        .map(([k, v]) => [k, wrap('"')(v)])
        .map(join('='))
        .join(' '),
    ),
  )(start)

  return set('start', startWithProps)(target)
}
