/**
 * TODO:
 *
 * Let's ditch react-shimmer for https://www.npmjs.com/package/react-image
 *
 * It's much more mature and configurable via hooks
 */

import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import { keyframes } from '@mui/styled-engine'
import { animation, darken } from 'polished'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { Image as ShimmerImage } from 'react-shimmer'

const shimmer = keyframes`
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
`

const Loader = styled(
  ({ className, percentHeight }) => (
    <Box
      className={className}
      sx={{
        '&::before': {
          paddingTop: `${percentHeight}% !important`,
        },
      }}
    />
  ),
  {
    name: 'indicators-Image',
  },
)(({ theme: { palette } }) => ({
  ...animation([shimmer, '2s', 'linear', 'infinite']),
  background: `linear-gradient(
      to right,
      ${palette.border[5]} 4%,
      ${darken(0.1, palette.border[3])} 25%,
      ${palette.border[3]} 36%
    )`,
  backgroundSize: '1000px 100%',
  width: '100% !important',
  height: 'unset !important',
  '&::before': {
    content: '""',
    display: 'block',
  },
}))

const Image = forwardRef(
  ({ percentHeight = 66, src = 'use fallback', ...rest }, ref) => (
    <ShimmerImage
      fallback={<Loader percentHeight={percentHeight} />}
      NativeImgProps={rest}
      ref={ref}
      src={src}
    />
  ),
)

Image.propTypes = {
  percentHeight: PropTypes.number,
  src: PropTypes.string,
}

export default Image
