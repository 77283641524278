import MuiTextField from '@mui/material/TextField'
import { componentShape } from 'core/shapes'
import formatIt from 'date-fns/fp/format'
import set from 'date-fns/fp/set'
import isValid from 'date-fns/isValid'
import { get } from 'fp/objects'
import { prefix } from 'fp/strings'
import { now } from 'locale/dates'
import { tzAbbr } from 'locale/timezones'
import { Maybe } from 'monet'
import PropTypes from 'prop-types'
import { createElement, useMemo } from 'react'
import { compose } from 'redux'

const Base = ({ Component, helperText, format, value, ...rest }) => {
  const exampleInput = useMemo(
    () => compose(formatIt(format), set({ minutes: 0, seconds: 0 }), now)(),
    [format],
  )

  const valueTimeZoneText = Maybe.fromFalsy(tzAbbr(value))
    .map(prefix('Time zone: '))
    .orNull()
  const exampleInputText = Maybe.fromFalsy(exampleInput)
    .map(prefix('Example: '))
    .orNull()

  return createElement(Component, {
    fullWidth: false,
    disableMaskedInput: true,

    textField: props => (
      <MuiTextField
        {...props}
        helperText={
          helperText || (isValid(value) ? valueTimeZoneText : exampleInputText)
        }
      />
    ),
    ...{ format, value },
    ...rest,
    inputProps: {
      ...get('inputProps')(rest),
      placeholder: '',
    },
  })
}

Base.propTypes = {
  Component: componentShape.isRequired,
  format: PropTypes.string.isRequired,
  helperText: componentShape,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
}

export default Base
