// NOTE: TreeView is managing these a11y concerns, one level above us

import cl from 'classnames'
import PropTypes from 'prop-types'
import { forwardRef, useContext } from 'react'

import { useTreeItem } from '@mui/x-tree-view'
import {
  ABILITY_CONTENT_RESTRICTION,
  CONTENT_SUBTYPE_CHAPTER_SUMMARY,
  CONTENT_TYPE_BLOCK,
  CONTENT_TYPE_ECHO,
  CONTENT_TYPE_SOURCE,
  CONTENT_TYPE_SUBSECTION,
} from 'core/consts'
import { contentShape } from 'core/shapes'
import { first } from 'fp/arrays'
import { noop } from 'fp/utils'
import useAbilityCheck from 'hooks/useAbilityCheck'
import useNavigation from 'hooks/useNavigation'
import { assignmentEditContext } from 'hss/AssignmentEditor/assignmentEditContext'
import ItemExclusionIndicator from './ItemExclusionIndicator'
import ItemIcon from './ItemIcon'
import ItemLabel from './ItemLabel'

const ItemContent = forwardRef((props, ref) => {
  const { className, classes, expansionIcon, isNestedSection, item, nodeId } =
    props

  const leaf =
    !item.children?.length ||
    first(item.children).contentType === CONTENT_TYPE_BLOCK

  const {
    disabled,
    expanded,
    focused,
    handleExpansion,
    handleSelection,
    preventSelection,
    selected,
  } = useTreeItem(nodeId)

  const { navigate } = useNavigation()

  const canAlterContent = useAbilityCheck(ABILITY_CONTENT_RESTRICTION)

  const handleMouseDown = event => {
    preventSelection(event)
  }

  const handleLabelClick = event => {
    handleSelection(event)
    if (isNestedSection && item.data?.tabbed) {
      navigate(`${item.children[0].contentType}/${item.children[0].id}`)
    } else if (isNestedSection) {
      navigate(`${CONTENT_TYPE_SUBSECTION}/${item.children[0].id}`)
    } else if (item.contentType === CONTENT_TYPE_ECHO) {
      navigate(`${CONTENT_TYPE_ECHO}/${item.id}`)
    } else if (leaf) {
      if (item.parent?.contentType === CONTENT_TYPE_SOURCE) {
        navigate(
          `${CONTENT_TYPE_SOURCE}/${item.parent.id}/${CONTENT_TYPE_SUBSECTION}/${item.id}`,
        )
      } else {
        navigate(`${item.contentType}/${item.id}`)
      }
    } else if (
      (item.teacherEdition || item.data?.tabbed) &&
      item.children.length
    ) {
      navigate(`${CONTENT_TYPE_SUBSECTION}/${item.children[0].id}`)
    } else {
      handleExpansion(event)
    }
  }

  const handleExpansionClick = event => {
    event.stopPropagation()
    handleExpansion(event)
    handleSelection(event)
  }

  const context = useContext(assignmentEditContext)
  const isViewingAssignment = Boolean(context)
  const { includeChapterSummary, isContentIdExcluded = noop } = context || {}
  const isMyContentIdExcluded =
    isContentIdExcluded(item.id) && !item.teacherEdition

  const hideSummaryForPresenterMode =
    isViewingAssignment &&
    item.contentSubType === CONTENT_SUBTYPE_CHAPTER_SUMMARY &&
    !includeChapterSummary

  return hideSummaryForPresenterMode ? null : (
    <div
      className={cl(className, classes.root, {
        'Mui-expanded': expanded,
        'Mui-selected': selected,
        'Mui-focused': focused,
        'Mui-disabled': disabled,
        leaf: leaf || isNestedSection,
        'customer-insertion': canAlterContent && !!item.insertionData,
      })}
      onClick={handleLabelClick}
      onMouseDown={handleMouseDown}
      ref={ref}
      role="link">
      <div>
        <ItemIcon item={item} />

        <ItemLabel
          className={cl(classes.label, { excluded: isMyContentIdExcluded })}
          item={item}
        />
      </div>

      <div>
        <ItemExclusionIndicator item={item} />

        {!leaf && (
          <button
            className={classes.iconContainer}
            onClick={handleExpansionClick}
            style={{ display: 'block' }}
            tabIndex={-1}
            type="button">
            {expansionIcon}
          </button>
        )}
      </div>
    </div>
  )
})

ItemContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  expansionIcon: PropTypes.node,
  isNestedSection: PropTypes.bool.isRequired,
  item: contentShape.isRequired,
  nodeId: PropTypes.string.isRequired,
}

export default ItemContent
