import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import cl from 'classnames'
import { whenPresent } from 'fp/utils'
import AnnotateIcon from 'hss/images/controls/video/annotate.svg'
/* istanbul ignore file */
import PropTypes from 'prop-types'
import { useState } from 'react'
import { createRoot } from 'react-dom/client'
import videojs from 'video.js'

const AnnotationsToggle = props => {
  const {
    setAnnotationsOn: setAnnotationsOnInContext,
    setTheaterMode,
    setVideosInTheaterMode,
  } = props
  const [annotationsOn, setAnnotationsOn] = useState(false)

  const label = 'Annotations'
  const iconColor = annotationsOn ? 'white' : 'transparent'

  const handleClick = () => {
    if (annotationsOn) {
      setAnnotationsOnInContext(!annotationsOn)
      setTimeout(() => {
        whenPresent(setVideosInTheaterMode, false)
        setTheaterMode(false)
      }, 500)
    } else {
      whenPresent(setVideosInTheaterMode, true)
      setTheaterMode(true)
      setTimeout(() => {
        setAnnotationsOnInContext(!annotationsOn)
      }, 500)
    }

    setAnnotationsOn(!annotationsOn)
  }

  return (
    <Tooltip
      arrow
      title={<Typography fontSize="1.4rem">{label}</Typography>}>
      <IconButton
        aria-label={label}
        onClick={handleClick}
        type="button">
        <AnnotateIcon color={iconColor} />
      </IconButton>
    </Tooltip>
  )
}

AnnotationsToggle.propTypes = {
  setAnnotationsOn: PropTypes.func.isRequired,
  setTheaterMode: PropTypes.func.isRequired,
  setVideosInTheaterMode: PropTypes.func,
}

const vjsComponent = videojs.getComponent('Component')

class ssAnnotationsToggle extends vjsComponent {
  constructor(player, options = {}) {
    super(player, options)

    this.options = options
    this.annotationsOn = false
    this.mount = this.mount.bind(this)

    this.el().className = cl('vjs-control', 'ss-annotations-toggle')

    this.root = createRoot(this.el())

    player.ready(() => {
      this.mount()
    })
  }

  handleClick(e) {
    e.preventDefault()
    e.stopImmediatePropagation()

    this.annotationsOn = !this.annotationsOn
  }

  mount() {
    this.root.render(
      <AnnotationsToggle
        vjsComponent={this}
        {...this.options}
      />,
    )
  }
}

vjsComponent.registerComponent('ssAnnotationsToggle', ssAnnotationsToggle)

export default AnnotationsToggle
