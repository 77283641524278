import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import cl from 'classnames'
import { ABILITY_RECEIVE_USER_ALERTS } from 'core/consts'
import withAbilityCheck from 'hoc/withAbilityCheck'
import ProfileUserAlertsDialog from 'hss/sections/Home/userAlertDialogs/ProfileUserAlertsDialog'
import { useContext } from 'react'
import { Bell } from 'react-feather'
import { useSelector } from 'react-redux'
import { getNewProfileUserAlertsCount } from 'selectors/userAlerts'
import { navDialogsContext } from '../NavDialogsProvider'

const ProfileAlertsContainer = styled(Box, { name: 'profileAlerts-Container' })(
  ({
    theme: {
      mixins: { rem, transition },
      palette,
    },
  }) => ({
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    margin: rem(0, 2),

    '&> button': {
      color: palette.grey[0],
      fontSize: rem(1.4),
      fontWeight: 500,
      height: rem(5),
      minWidth: rem(5),
      padding: rem(0, 0.5),

      svg: {
        fill: palette.cobalt[0],
        ...transition('fill'),
        marginRight: rem(0.25),
        stroke: palette.cobalt[0],
      },
    },

    '&.profile-alerts--empty': {
      cursor: 'default',

      '&> button svg': {
        fill: 'transparent',
        stroke: palette.grey[2],
      },
    },

    '&.profile-alerts--high-count > button': {
      fontSize: rem(1.2),
      padding: rem(0, 0.25),
    },
  }),
)

const hasAlertsLabel = 'New Notifications'
const noAlertsLabel = 'No New Notifications'

const ProfileUserAlertsMenu = withAbilityCheck(() => {
  const { toggleProfileUserAlertsIsOpen } = useContext(navDialogsContext)

  const profileAlertsCount = useSelector(getNewProfileUserAlertsCount) || 0
  const label = profileAlertsCount ? hasAlertsLabel : noAlertsLabel

  return (
    <>
      <Tooltip title={label}>
        <ProfileAlertsContainer
          className={cl({
            'profile-alerts--empty': !profileAlertsCount,
            'profile-alerts--high-count': profileAlertsCount > 99,
          })}>
          <IconButton
            aria-label={label}
            data-bodyvariant="body1"
            disabled={!profileAlertsCount}
            onClick={() => {
              toggleProfileUserAlertsIsOpen()
            }}>
            <Bell size={20} />
            {profileAlertsCount || null}
          </IconButton>
        </ProfileAlertsContainer>
      </Tooltip>

      <ProfileUserAlertsDialog />
    </>
  )
}, ABILITY_RECEIVE_USER_ALERTS)

export default ProfileUserAlertsMenu
