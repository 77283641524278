import { success } from 'sagas/utils'
import actionTypes from './actionTypes'
import {
  createReducer,
  handleFetchListSuccess,
  listReducerInitialState,
  updateLoaded,
} from './utils'

export const actions = {
  fetchGroups: props => ({
    type: actionTypes.GROUP_FETCH_LIST,
    queryParams: props?.queryParams,
  }),
  fetchGroupById: ({ groupId }) => ({
    groupId,
    type: actionTypes.GROUP_FETCH,
  }),
  saveGroup: ({ payload }) => ({
    type: actionTypes.GROUP_SAVE,
    payload,
  }),
}

const handleFetchItemSuccess = (state, { response }) =>
  updateLoaded(state, response)

const groups = createReducer(listReducerInitialState(), {
  [success(actionTypes.GROUP_FETCH_LIST)]: handleFetchListSuccess,
  [success(actionTypes.GROUP_FETCH)]: handleFetchItemSuccess,
  [success(actionTypes.GROUP_SAVE)]: handleFetchItemSuccess,
})

export default groups
