import actionTypes from './actionTypes'
import { createReducer, listReducerInitialState, updateLoaded } from './utils'

const handleRankingReceived = (state, { payload }) =>
  updateLoaded(state, payload)

const echoRanking = createReducer(listReducerInitialState(), {
  [actionTypes.SOCKET_INTERACTION_RANKING_RECEIVED]: handleRankingReceived,
})

export default echoRanking
