import { DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import withProps from 'hoc/withProps'
import { dateShortFormatString, timeFormatString } from 'locale/i18n'
import Base from './_Base'

const DateTimePicker = withProps(Base, {
  Component: MuiDateTimePicker,
  format: `${dateShortFormatString} ${timeFormatString}`,
})

export default DateTimePicker
