import InlineImage from 'common/indicators/InlineImage'
import type { ContentState } from 'draft-js'

interface DraftDecoratorProps extends React.ComponentPropsWithoutRef<'img'> {
  contentState: ContentState
  entityKey: string
}

const DraftDecorator: React.FC<DraftDecoratorProps> = ({
  contentState,
  entityKey,
}) => {
  const entity = contentState.getEntity(entityKey)

  return (
    <InlineImage
      contentId={entity.getData().contentId}
      size={entity.getData().size}
      valign={entity.getData().valign}
    />
  )
}

export default DraftDecorator
