import Icon from '@mui/material/Icon'
import IconButton, { type IconButtonProps } from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import { position } from 'polished'
import { forwardRef } from 'react'
import { X as Close } from 'react-feather'
import type { Theme } from 'styling/theming/@types/custom'

const Button = styled(IconButton, {
  name: 'dialogs-CloseButton',
})(({ theme }: { theme: Theme }) => ({
  color: 'currentColor',
  cursor: 'pointer',
  fontSize: theme.mixins.rem(3),
  lineHeight: 0,
  margin: 0,
  ...position(
    'absolute',
    theme.mixins.rem(1.5),
    theme.mixins.rem(1.2),
    null,
    null,
  ),
  ...theme.mixins.transition('all', 150),
  zIndex: 1,
  '&::before': {
    fontSize: theme.mixins.rem(2),
    margin: 0,
    position: 'relative',
    lineHeight: theme.mixins.px(1),
  },
})) as unknown as typeof IconButton

interface ICloseButtonProps extends IconButtonProps {
  dense?: boolean
}

const CloseButton = forwardRef<HTMLButtonElement, ICloseButtonProps>(
  ({ color, dense = false, ...rest }, ref) => (
    <Button
      ref={ref}
      tabIndex={0}
      title="Close"
      {...rest}
      size="large"
      sx={
        dense
          ? {
              top: '0.5rem',
              right: '0.2rem',
            }
          : null
      }>
      <Icon>
        <Close color={color ? (color as unknown as string) : undefined} />
      </Icon>
    </Button>
  ),
)

export default CloseButton
