import { padding } from 'polished'
import type { Theme } from 'styling/theming/@types/custom'

const overrides = ({ mixins: { px, rem }, typography }: Theme) => ({
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        ...typography.h3,
        ...padding(rem(6), rem(3.2), 0),
        marginBottom: rem(3),
        fontWeight: 400,
        lineHeight: px(37.4),
        textAlign: 'center',
      },
    },

    variants: [
      {
        props: { variant: 'swatch' },
        style: {
          ...padding(0, rem(4.5), 0),
          marginTop: rem(3),
          textAlign: 'left',
        },
      },
    ],
  },
})

export default overrides
