import { ChevronDown, ChevronUp } from 'react-feather'

const overrides = ({ mixins: { px }, palette }) => ({
  MuiTreeView: {
    defaultProps: {
      className: 'tree-nav',
      defaultCollapseIcon: <ChevronUp />,
      defaultExpandIcon: <ChevronDown />,
    },

    variants: [
      {
        props: { variant: 'nav-shell' },
        style: {
          marginTop: 15,
          marginLeft: -10,

          '.MuiCollapse-wrapperInner li:first-of-type  .MuiTreeItem-content': {
            paddingTop: 0,
            marginTop: 18,
          },

          '.MuiCollapse-wrapperInner li:last-of-type  .MuiTreeItem-content': {
            paddingBottom: 0,
            marginBottom: 18,
          },
        },
      },

      {
        props: { variant: 'content-selections' },
        style: {
          li: {
            '&[aria-expanded="true"], &[aria-expanded="false"]': {
              ul: {
                marginLeft: 36,
              },
            },
          },

          '.MuiTreeItem-content': {
            padding: px(12, 0),
            backgroundColor: 'transparent',

            '.MuiTreeItem-iconContainer': {
              width: 18,
              marginRight: 0,
            },

            '.MuiTreeItem-label': {
              textDecoration: 'line-through',
            },

            '&.Mui-selected .MuiTreeItem-label': {
              textDecoration: 'none',
            },

            '&.Mui-expanded, &.Mui-focused, &.Mui-selected:hover': {
              backgroundColor: 'transparent',
            },

            'div:first-of-type': {
              width: '100%',
            },
          },
        },
      },

      {
        props: { 'data-subvariant': 'content-viewer' },
        style: {
          marginTop: 5,
          marginLeft: 18,
          marginRight: 2,

          '&::-webkit-scrollbar': {
            width: 8,
          },

          '&::-webkit-scrollbar-track': {
            backgroundColor: 'inherit',
            borderRadius: 4,
          },

          '&::-webkit-scrollbar-thumb': {
            borderRadius: 10,
            backgroundColor: palette.primary.contrastText,
          },

          '.MuiCollapse-wrapperInner li:first-of-type  .MuiTreeItem-content': {
            paddingTop: 0,
            marginTop: 0,
          },

          '.MuiCollapse-wrapperInner li:last-of-type  .MuiTreeItem-content': {
            paddingBottom: 0,
            marginBottom: 0,
          },
        },
      },
    ],
  },
})

export default overrides
