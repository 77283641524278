import { deepMerge, mapKeys, pick } from 'fp/objects'
import { prefix, toInt } from 'fp/strings'
import { compose } from 'redux'
import { mergeProps } from './helpers/utils'

const blockToHTML =
  (/* features */) =>
  (current, { data }) => {
    const props = compose(
      mapKeys(prefix('data-')),
      pick('indent', 'indentfirstline'),
    )(data)

    return Object.keys(props)?.length && current.start
      ? mergeProps(current, props)
      : current
  }

const htmlToBlock = (/* features */) => (current, _, node) => {
  if (node.getAttribute('data-indentfirstline') === 'true') {
    return deepMerge(current, {
      type: 'unstyled',
      data: {
        indentfirstline: true,
      },
    })
  }

  const depth = toInt(node.getAttribute('data-indent'))

  if (depth) {
    return deepMerge(current, {
      type: 'unstyled',
      data: {
        indent: depth,
      },
    })
  }

  return current
}

const indentationPlugin = {
  blockToHTML,
  htmlToBlock,
}

export default indentationPlugin
