import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { useTheme } from '@mui/material/styles'
import { filterAndSortVideoAnnotations } from 'common/avclub/utils'
import VideoAnnotation from 'common/avclub/video/lib/controls/VideoAnnotation'
import {
  INTERACTION_SUBTYPE_VIDEO,
  INTERACTION_TYPE_ANNOTATION,
} from 'core/consts'
import { last } from 'fp/arrays'
import { get } from 'fp/objects'
import { matches } from 'fp/utils'
import { useDeepCompareEffect } from 'hooks/useDeepCompare'
import { contentViewerContext } from 'hss/ContentViewer/ContentViewerProvider'
import { interactiveContext } from 'hss/sections/contentBlocks/Interactive/InteractiveProvider'
import { createRef, useContext, useEffect, useRef, useState } from 'react'
import { Plus } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { actions as interactionActions } from 'reducers/interactions'
import { getUserAssignment } from 'selectors/userAssignments'
import { videoPlayerContext } from '../context'

const VideoAnnotations = () => {
  const dispatch = useDispatch()
  const { contentId } = useContext(interactiveContext) || {}
  const { contextContentId, currentTime } = useContext(videoPlayerContext)
  const { id: userAssignmentId } = useSelector(getUserAssignment) || {}
  const [sortedAnnotations, setSortedAnnotations] = useState([])
  const {
    palette: { annotations: colors },
  } = useTheme()
  const { annotations } = useContext(contentViewerContext)
  const [userHasAddedItems, setUserHasAddedItems] = useState(false)

  const itemRefs = useRef([])

  const annotationColors = annotations.map(get('interactionData.colorId'))

  useDeepCompareEffect(() => {
    setSortedAnnotations(filterAndSortVideoAnnotations(annotations))
    // We only want this to run when the length of the annotations array changes,
    // or when they change a color.
    // It shouldn't run if they only update the text, as that will cause jumpiness.
  }, [annotations.length, annotationColors])

  if (itemRefs.current.length !== sortedAnnotations.length) {
    itemRefs.current = new Array(sortedAnnotations.length) //
      .fill()
      .map((_, i) => itemRefs.current[i] || createRef())
  }

  const postAnnotation = (annotation, id) => {
    dispatch(
      interactionActions.postInteraction({
        id,
        contentId,
        contextContentId,
        interactionData: annotation,
        interactionSubType: INTERACTION_SUBTYPE_VIDEO,
        interactionType: INTERACTION_TYPE_ANNOTATION,
        suppressAlert: true,
        userAssignmentId,
      }),
    )
  }

  const addAnnotation = () => {
    const newAnnotation = {
      time: currentTime,
      colorId: colors[0].colorId,
      text: '',
    }

    setUserHasAddedItems(true)
    postAnnotation(newAnnotation)
  }

  const handleRemove = interactionId => {
    dispatch(interactionActions.deleteInteraction({ interactionId }))
  }

  const handleUpdate = (id, annotation, value) => {
    postAnnotation({ ...annotation, text: value }, id)
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: TODO: take a look
  useEffect(() => {
    if (
      userHasAddedItems &&
      itemRefs.current.length &&
      annotations.length &&
      sortedAnnotations.length
    ) {
      const index = sortedAnnotations.findIndex(
        matches('id', last(annotations).id),
      )
      if (index > -1) {
        itemRefs.current[index].current.focus?.()
      }
    }
    // we only want this to run when the length of the annotations array changes
  }, [annotations.length, sortedAnnotations.length])

  return (
    <Box
      backgroundColor="common.white"
      display="flex"
      flexDirection="column"
      height="100%"
      maxHeight="85vh"
      px={2.5}
      py={3}
      textAlign="center"
      width={300}>
      <Box
        flexGrow="1"
        mb={2}
        sx={{ overflowY: 'auto' }}
        textAlign="left">
        {sortedAnnotations.map(({ id, interactionData: annotation }, idx) => (
          <VideoAnnotation
            annotation={annotation}
            handleRemove={handleRemove}
            handleUpdate={handleUpdate}
            id={id}
            idx={idx}
            key={id}
            postAnnotation={postAnnotation}
            ref={itemRefs.current[idx]}
          />
        ))}
      </Box>
      <Button
        color="secondary"
        onClick={addAnnotation}
        variant="primary">
        <Plus />
        Add Annotation
      </Button>
    </Box>
  )
}

export default VideoAnnotations
