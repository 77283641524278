import { styled } from '@mui/material/styles'
import { hotspotCalloutShape, imageTitleDragCalloutShape } from 'core/shapes'
import { isDefined } from 'fp/utils'
import dashedLine from 'hss/images/controls/image-title-drag/dashed-line.svg?url'
import Point from 'hss/images/controls/image-title-drag/square.svg'
import LineP from 'hss/sections/contentBlocks/interactives/LineP'
import PropTypes from 'prop-types'
import DraggableItem from './DraggableItem'
import { CalloutRenderer, TextRenderer } from './DraggableRenderer'

const StyledLineP = styled(LineP, { name: 'Common-CalloutLine' })(
  ({ theme: { palette } }) => {
    const defaultColor = palette.grey[0]
    return {
      maskImage: `url(${dashedLine})`,
      backgroundColor: defaultColor,
      border: 'none',
      height: 2,
    }
  },
)

const StyledPoint = styled(Point, { name: 'Common-Point' })(
  ({
    theme: {
      palette,
      mixins: { sizeR, transition },
    },
  }) => ({
    ...sizeR(3.2),
    ...transition(),
    path: {
      ...transition(),
      fill: palette.cobalt[0],
      fillOpacity: 0.6,
      stroke: palette.background.paper,
    },
    '&:hover': {
      ...transition(),
      ...sizeR(4.8),
      path: {
        ...transition(),
        fillOpacity: 1,
      },
    },
  }),
)

const isNumeric = value => isDefined(value) && !Number.isNaN(value)

const Callout = ({ item, ...rest }) => {
  const { calloutCoord, id, label, labelCoord } = item

  const [labelX, labelY] = labelCoord.split(',').map(Number.parseFloat)
  const [calloutX, calloutY] = calloutCoord.split(',').map(Number.parseFloat)

  const includeLabel = [isNumeric(labelX), isNumeric(labelY)].every(Boolean)

  const includeCallout = [isNumeric(calloutX), isNumeric(calloutY)].every(
    Boolean,
  )

  const includeLine = includeLabel && includeCallout

  return (
    <>
      {!!includeLine && (
        <StyledLineP
          from={{ x: labelX, y: labelY }}
          to={{ x: calloutX, y: calloutY }}
          {...rest}
        />
      )}

      {!!includeCallout && (
        <DraggableItem
          coordinates={calloutCoord}
          itemId={id}
          Renderer={CalloutRenderer}
          type="CALLOUT-ARROW">
          <StyledPoint />
        </DraggableItem>
      )}

      {!!includeLabel && (
        <DraggableItem
          coordinates={labelCoord}
          itemId={id}
          Renderer={TextRenderer}
          type="CALLOUT-LABEL">
          {label}
        </DraggableItem>
      )}
    </>
  )
}

Callout.propTypes = {
  item: PropTypes.oneOfType([hotspotCalloutShape, imageTitleDragCalloutShape])
    .isRequired,
  parentHeight: PropTypes.number.isRequired,
  parentWidth: PropTypes.number.isRequired,
}

export default Callout
