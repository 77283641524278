import PropTypes from 'prop-types'
/* istanbul ignore file */
import { createElement } from 'react'

const ScreenReaderText = ({ component = 'span', children, ...rest }) =>
  createElement(component, { className: 'hidden', ...rest }, children)

ScreenReaderText.propTypes = {
  component: PropTypes.elementType,
  children: PropTypes.node.isRequired,
}

export default ScreenReaderText
