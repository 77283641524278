import DialogContent from '@mui/material/DialogContent'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import Select from 'common/formControls/selects/Select'
import Checkbox from 'common/formControls/switches/Checkbox'
import SearchInput from 'common/formControls/textInputs/SearchInput'
import { contentTypeShape, squeryShape } from 'core/shapes'
import { alter, inspect } from 'core/store/search/squery'
import { isEmptyString } from 'fp/strings'
import { labels } from 'hss/ContentBuilder/consts'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { compose } from 'redux'

const ContentFilter = props => {
  const {
    className,
    closestParent,
    constrainToNearest,
    contentTypes,
    mode,
    setSquery,
    squery,
    toggleConstrainToNearest,
  } = props

  const [contentType, setContentType] = useState('')

  const searchText = inspect(squery).get.modifier('keywordSearch').is('')

  const handleSearch = value => {
    setSquery(alter.set.modifier('keywordSearch').is(value)(squery))
  }

  const handleContentTypeChange = ({ target }) => {
    const { value } = target

    compose(
      setSquery,
      sq =>
        isEmptyString(value)
          ? alter.set.where('contentType').in(contentTypes)(sq)
          : alter.set.where('contentType').is(value)(sq),
      alter.remove.where('contentType'),
    )(squery)

    setContentType(value)
  }

  return (
    <DialogContent className={className}>
      <SearchInput
        autoFocus
        containerSx={{ mt: 2 }}
        label="Search Content"
        onChange={handleSearch}
        value={searchText}
        variant="search-form"
      />

      <Stack
        direction="row"
        justifyContent="space-between"
        pb={2}
        pt={3}
        spacing={2}>
        <Select
          controlProps={{ sx: { maxWidth: '50%' } }}
          fullWidth={false}
          label="Filter by Type"
          name="contentSubType"
          onChange={handleContentTypeChange}
          value={contentType}
          variant="filled">
          <MenuItem value="">Any</MenuItem>

          {contentTypes.map(value => (
            <MenuItem
              key={value}
              value={value}>
              {labels[value]}
            </MenuItem>
          ))}
        </Select>

        {mode === 'internal-link' && (
          <Checkbox
            checked={constrainToNearest}
            label={`Constrain to current ${closestParent.contentType}`}
            onChange={toggleConstrainToNearest}
          />
        )}
      </Stack>
    </DialogContent>
  )
}

ContentFilter.propTypes = {
  closestParent: PropTypes.object.isRequired,
  constrainToNearest: PropTypes.bool.isRequired,
  contentTypes: PropTypes.arrayOf(contentTypeShape).isRequired,
  mode: PropTypes.oneOf(['content-insertion', 'internal-link']).isRequired,
  toggleConstrainToNearest: PropTypes.func.isRequired,
  setSquery: PropTypes.func.isRequired,
  squery: squeryShape.isRequired,
}

export default ContentFilter
