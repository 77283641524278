import MenuItem from '@mui/material/MenuItem'
import { styled } from '@mui/material/styles'
import { border } from 'polished'
import type { Theme } from 'styling/theming/@types/custom'
import { acuminTextSizeR } from 'styling/theming/base/mixins'

const ToolMenuItem = styled(MenuItem, { name: 'toolbar-DraftMenuButton' })(
  ({
    theme: {
      mixins: { important, rem, transition },
      palette,
      typography,
    },
  }: { theme: Theme }) => ({
    ...transition('color', 100),
    '&:not(:last-child)': {
      ...border('bottom', 1, 'solid', palette.border[1]),
    },
    position: 'relative',
    color: palette.grey[1],
    fontWeight: 500,
    'blockquote,h3,h4,h5': { lineHeight: 1 },
    '&:hover, &:focus-within': {
      backgroundColor: important(palette.grey[1]),
      color: palette.primary.contrastText,
      blockquote: { color: important(palette.primary.contrastText) },
    },
    blockquote: {
      margin: 0,
      paddingLeft: 10,
      '&[data-variant="pull"]': {
        paddingLeft: 20,
        ...typography.variants['pull-quote'],
        fontSize: rem(4),
        textTransform: 'none',
        '&::before': {
          content: '"“"',
          position: 'absolute',
          left: 28,
          top: 13,
          color: palette.accent.main,
          ...acuminTextSizeR(3, '95%', 900),
        },
      },
    },
    '&[data-key="header-one"]': { paddingTop: 10 },
    '&[data-key="header-two"]': { paddingTop: 10 },
    '&[data-key="P"]': { paddingTop: 10 },
    '.MuiTypography-drop-cap:first-letter': {
      fontSize: rem(4),
      marginTop: 0,
    },
  }),
) as unknown as typeof MenuItem

export default ToolMenuItem
