import { useEffect, useMemo } from 'react'
import useCurrentUser from './useCurrentUser'
import useLocalSetting from './useLocalSetting'

const fallback = { name: 'hss' }

/**
 * NOTE: This is only meant to be used by the ThemeProvider when setting up the
 * initial environment.
 *
 * Use @mui/material/styles/useTheme to get the theme name, it's much lighter.
 */

const useThemeName = override => {
  const [theme, setTheme] = useLocalSetting('theme', fallback)
  const { user } = useCurrentUser()

  useEffect(() => {
    if (user?.theme && theme?.name !== user.theme) {
      setTheme({ name: user.theme })
    }
  }, [setTheme, theme?.name, user?.theme])

  return useMemo(
    () => override || user?.theme || theme?.name,
    // biome-ignore lint/correctness/useExhaustiveDependencies(override): TODO:
    [override, user?.theme, theme?.name],
  )
}

export default useThemeName
