import { DefaultDraftInlineStyle, EditorState } from 'draft-js'
import { isEmpty } from 'fp/arrays'
import { toCamelCase, toKebabCase } from 'fp/strings'
import { OrderedSet, Map as immutableMap } from 'immutable'
import { compose } from 'redux'

const convertStyleStringToObject = (style = '', data = {}) => {
  if (!style) {
    return null
  }
  return style
    .split(';')
    .filter(s => s.includes(':'))
    .map(s => s.split(':'))
    .reduce((map, s) => {
      const key = s.shift().trim()
      const val = s.join(':').trim()
      map[key] = val
      return map
    }, data)
}

export const tableBlocksInSelection = editorState => {
  const content = editorState.getCurrentContent()
  const selection = editorState.getSelection()
  const startKey = selection.getStartKey()
  const endKey = selection.getEndKey()
  const nextKey = content.getKeyAfter(endKey)
  const blockMap = content.getBlockMap()
  const blocks = blockMap
    .toSeq()
    .skipUntil((_, k) => k === startKey)
    .takeUntil((_, k) => k === nextKey)
    .filter(block => block.getType() === 'table')
    .toOrderedMap()
  return blocks.size ? blocks : null
}

const customStyleFn = style => {
  // "style" is an Immutable.js OrderedSet of inline styles for a given range of characters that share the same styling

  // handle draftjs default styles
  const defaultStyles = style
    .intersect(['BOLD', 'CODE', 'ITALIC', 'UNDERLINE'])
    .reduce((map, v) => map.merge(DefaultDraftInlineStyle[v]), immutableMap())

  style = style.subtract(['BOLD', 'CODE', 'ITALIC', 'UNDERLINE'])

  // separate out any entries that are a string of multiple styles
  let groupedStyles = style.filter(v => v.includes(':'))

  style = style.subtract(groupedStyles)

  // convert string containing multiple styles to a CSS styles object
  groupedStyles = groupedStyles.reduce((map, v) => {
    // biome-ignore lint/style/noParameterAssign:
    v = convertStyleStringToObject(v)
    // biome-ignore lint/style/noParameterAssign:
    v = immutableMap(v).mapKeys(k => toCamelCase(k))
    return map.merge(v)
  }, immutableMap())

  // convert style strings with single style to CSS styles objects and merge with groupedStyles

  style = style
    .map(v => v.split('.'))
    .filter(v => v.every(vv => vv.length))
    .reduce((map, v) => {
      const key = v.shift().trim()
      const val = v.join('.').trim()
      return map.merge({ [key]: val })
    }, groupedStyles.merge(defaultStyles))
    .toJS()

  if (isEmpty(style)) {
    return null
  }
  return style
}

export const buildHtmlForBlockText = (result, block, contentState) => {
  if (!block) {
    return '<span> </span>'
  }
  // now build the html for all inline styles for each "styleRange" in the block. A styleRange is
  // any sequence in the block where the characters share the same inline styling.
  block.findStyleRanges(
    () => true,
    (s, e) => {
      let close = ''
      let styles = block.getInlineStyleAt(s)
      styles = immutableMap(customStyleFn(styles))
        .reduce((styleSet, v, k) => {
          // biome-ignore lint/style/noParameterAssign:
          k = toKebabCase(k)
          // biome-ignore lint/style/noParameterAssign:
          if (k === 'font-size' && /^\d*$/.test(v)) v += 'pt'
          return styleSet.add(`${k}: ${v}`)
        }, OrderedSet())
        .toArray()
        .join('; ')

      styles = styles ? ` style="${styles}"` : ''
      // If a styleRange overlaps with an "entity" that starts and ends at the same points in the block
      // the entity represents an embedded link
      const startKey = block.getEntityAt(s)
      const endKey = block.getEntityAt(e - 1)
      const entity =
        startKey && startKey === endKey
          ? contentState.getEntity(startKey)
          : null

      if (styles) {
        // biome-ignore lint/style/noParameterAssign:
        result += `<span${styles}>`
        close = `</span>${close}`
      }
      // Now add the text content of the block for the current styleRange. If a "link" entity exists for this range
      // then wrap the text content in an anchor tag and add the href.
      // The multiple "replace" calls prevent empty paragraphs and extra spaces from collapsing and failing to render.
      const textContent = block
        .getText()
        .slice(s, e)
        .replace(/\n/g, '<br>')
        .replace(/\s{2,}?/g, '  ')
        .replace(/^\s$/g, ' ')
      if (entity && entity.get('type') === 'LINK') {
        const { target, url } = entity.getData()
        // biome-ignore lint/style/noParameterAssign:
        result += `<a href="${url}" ${
          target ? `target="${target}" rel="noreferrer"` : ''
        }>${textContent}</a>`
      } else {
        // biome-ignore lint/style/noParameterAssign:
        result += textContent
      }
      // biome-ignore lint/style/noParameterAssign:
      result += close
    },
  )
  return result
}

export const tableBlocksAreInSelection = compose(
  Boolean,
  tableBlocksInSelection,
)

export const setAlignmentInTable = (editorState, alignment) => {
  /**
   * Because cell alignment is stored in the tableShape of the first block in the
   * table, we have to find and update that block from here.
   * */
  const tableBlocks = tableBlocksInSelection(editorState)
  if (!tableBlocks) return editorState

  const content = editorState.getCurrentContent()
  let blockMap = content.getBlockMap()
  const tableKey = tableBlocks.first().getData().get('tableKey')
  let firstTableBlock = blockMap.find(
    block => block.getData().get('tablePosition') === `${tableKey}-0-0`,
  )
  const tableShape = firstTableBlock.getData().get('tableShape')
  for (const block of tableBlocks.values()) {
    const [, row, col] = block.getData().get('tablePosition').split('-')
    tableShape[row][col].alignment = alignment
  }
  let data = firstTableBlock.getData()

  data = data.set('tableShape', tableShape)
  firstTableBlock = firstTableBlock.merge({ data })
  blockMap = blockMap.merge([[firstTableBlock.getKey(), firstTableBlock]])

  const newContentState = content.merge({ blockMap })

  return EditorState.push(editorState, newContentState, 'change-block-data')
}
