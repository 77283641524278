import { compose } from 'redux'
import { fallsWithin, increment } from './numbers'
import { get, set } from './objects'
import { isString, maybeParseJSON } from './strings'
import { identity } from './utils'

export const arraySequence = numberOfElements =>
  Array.from(new Array(numberOfElements).keys())

export const chunk = size => arr =>
  arr.reduce(
    (_arr, item, idx) =>
      idx % size === 0 //
        ? [..._arr, [item]]
        : [..._arr.slice(0, -1), [..._arr.slice(-1)[0], item]],
    [],
  )

/**
 * dedupe() is slightly faster than dedupeById()
 * only use the latter for arrays of objects
 */
export const dedupe = arr => [...new Set(arr)]

export const dedupeById = (arr, id = 'id') =>
  [...new Set(arr.map(a => a[id]))].map(i => arr.find(a => a[id] === i))

export const difference =
  (arr1 = [], areObjects = false) =>
  (arr2 = []) => {
    if (!areObjects) return arr1?.filter(x => !arr2?.includes(x))

    const a1 = arr1.map(JSON.stringify)
    const a2 = arr2.map(JSON.stringify)
    return a1?.filter(x => !a2?.includes(x)).map(maybeParseJSON)
  }

export const filter = f => arr => arr?.filter(f || identity)

export const find = f => arr => arr?.find(f || identity)

// TODO: Would it be more useful to compare *anything*?  Sticking to numbers for now.
export const findMaxValue =
  (id = 'id') =>
  arr =>
    arr.reduce((acc, obj) => Math.max(acc, get(id)(obj)), 0)

export const findObj = (key, value) => arr =>
  arr?.find?.(item => get(key)(item) === value)

export const first = arr => arr?.[0]

export const flatten = (arr = []) => arr.reduce((a, b) => a.concat(b), [])

/**
 * Can also use this on objects.
 * Use with curryRight to chain.
 */
export const getAt = (arr, idx) => arr?.[idx]

export const includes = item => array => array?.includes(item)

export const incrementByKey =
  (key, valueName = 'value', keyName = 'id') =>
  arr =>
    arr.map(item =>
      item[keyName] === key
        ? set(valueName, compose(increment, get(valueName))(item))(item)
        : item,
    )

export const intersection =
  (arr1 = []) =>
  (arr2 = []) =>
    arr1?.filter(x => arr2?.includes(x))

export const isEmpty = obj =>
  !obj ||
  ([Object, Array].includes(obj.constructor) && !Object.entries(obj).length)

export const join =
  separator =>
  (arr = []) =>
    arr.join(separator)

export const last = arr => arr?.slice(-1)[0]

export const map = fn => arr => arr?.map(fn)

export const nextValue = id => compose(increment, findMaxValue(id))

export const orderBy = key => arr => arr.concat().sort(sortBy(key))

export const push =
  obj =>
  (arr = []) => [...arr, obj]

export const reduce = (r, init) => arr =>
  arr && r ? arr.reduce(r, init) : init

export const replaceById =
  (obj, id = 'id') =>
  arr =>
    arr.map(elm => (elm[id] === obj[id] ? obj : elm))

export const removeByIndex = idx => arr => {
  const result = [...arr]
  result.splice(idx, 1)
  return result
}

export const reverse = arr => arr.slice().reverse()

// export const rollup = arr => arr.reduce((acc, curr) => ({
//   ...acc,
//   [curr]: acc[curr] ? acc[curr] + 1 : 1,
// }), {})

export const second = arr => arr?.[1]

/* istanbul ignore next */
export const shuffle = arr => [...arr].sort(() => 0.5 - Math.random())

export const sort = compare => A =>
  Array.isArray(A) ? [...A].sort(compare) : A

export const sortBy =
  (key, dir = 'asc', comparisonType = 'string', sortFn = () => 0) =>
  // biome-ignore lint/complexity/noExcessiveCognitiveComplexity: meh
  (a, b) => {
    let aVal = get(key)(a)?.toString()
    let bVal = get(key)(b)?.toString()

    if (comparisonType === 'function') {
      return sortFn(aVal, bVal)
    }

    if (comparisonType === 'numeric') {
      aVal = Number.parseFloat(aVal)
      bVal = Number.parseFloat(bVal)
    }

    let result
    if (
      comparisonType === 'numeric' &&
      !Number.isNaN(aVal) &&
      !Number.isNaN(bVal)
    ) {
      result = aVal < bVal ? -1 : bVal < aVal ? 1 : 0
    } else if (
      comparisonType === 'string' &&
      isString(aVal) &&
      isString(bVal)
    ) {
      result = aVal.localeCompare(bVal, 'en', { sensitivity: 'base' })
    } else if (aVal) {
      result = -1
    } else if (bVal) {
      result = 1
    } else {
      /* istanbul ignore next */
      result = 0
    }
    return dir === 'asc' ? result : result * -1
  }

// export const spread = keys => value => keys.reduce((acc, key) => set(key, value)(acc), {})

export const swapByIndex = (i, j) => arr => {
  if (!(fallsWithin(i, 0, arr.length) && fallsWithin(j, 0, arr.length)))
    return arr
  const result = [...arr]
  const temp = result[i]
  result[i] = result[j]
  result[j] = temp
  return result
}

export const toKeyedObject = (arr, id = 'id') =>
  (arr || [])
    .filter(x => Object.prototype.hasOwnProperty.call(x, id))
    .reduce((acc, item) => ({ ...acc, [item[id]]: item }), {})

export const toggleItem = arr => item =>
  arr.includes(item) ? arr.filter(key => key !== item) : [...arr, item]

export const xDifference =
  (arr1 = []) =>
  (arr2 = []) =>
    arr1
      ?.filter(x => !arr2?.includes(x))
      .concat(arr2?.filter(x => !arr1?.includes(x)))
