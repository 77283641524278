import { toggleItem } from 'fp/arrays'
import { deepMerge, get, omit, set } from 'fp/objects'
import { isEmptyString } from 'fp/strings'
import { curryRight, isDefined, toggleBetween } from 'fp/utils'
import { produce } from 'immer'

const defaults = {
  limit: 50,
  modifiers: {},
  offset: 0,
  orderBy: [],
  where: {},
}

export const assert = (val = {}, base = { ...defaults }) =>
  deepMerge(deepMerge(defaults, base), val)

const filterField =
  field =>
  ([f]) =>
    f !== field

const setLimit = value => set('limit', value)

const setOffset = value => set('offset', value)

const setOrderBy =
  (field, direction, options = {}) =>
  query => {
    const { lifo, maxEntries } = {
      lifo: true,
      maxEntries: 2,
      ...options,
    }

    return produce(query, draft => {
      draft.orderBy = [
        ...(draft?.orderBy || []).filter(filterField(field)),
        [field, direction],
      ]

      if (lifo) draft.orderBy = draft.orderBy.reverse()

      if (maxEntries) draft.orderBy = draft.orderBy.slice(0, maxEntries)
    })
  }

const removeOrderBy = field => query =>
  set(
    'orderBy',
    query.orderBy.filter(([f]) => !isEmptyString(field) && f !== field),
  )(query)

const removeModifier = field => query =>
  produce(query, draft => {
    draft.modifiers = omit([field])(draft.modifiers)
  })

const setModifier = (field, value) => set(`modifiers.${field}`, value)

const getModifier = field => get(`modifiers.${field}`)

const removeWhere = field => query =>
  produce(query, draft => {
    draft.where = omit([field])(draft.where || {})
  })

const setWhere = (field, value, maybe = false) =>
  maybe && isEmptyString(value)
    ? removeWhere(field.split('●')[0])
    : set(`where●${field}`, value, false, false, '●')

const getWhere = field => get(`where●${field}`, { delim: '●' })

export const inspect = query => ({
  get: {
    limit: () => get('limit')(query),

    modifier: field => ({
      is: def => getModifier(field)(query) || def,
    }),

    offset: () => get('offset')(query),
    orderBy: field =>
      isDefined(field)
        ? get('orderBy')(query)
            .filter(([f]) => f === field)
            .pop()
        : get('orderBy')(query),

    where: field => ({
      contains: def => getWhere(field)(query)?.contains || def,
      containsAll: def => getWhere(field)(query)?.containsAll || def,
      // gt: def => getWhere(field)(query)?.['>'] || def,
      // gte: def => getWhere(field)(query)?.['>='] || def,
      // identity: def => getWhere(field)(query) || def,
      in: def => getWhere(field)(query)?.in || def || [],
      // intersects: def => getWhere(field)(query)?.intersects || def || [],
      is: def => getWhere(field)(query)?.is || def,
      isAfter: def => getWhere(field)(query)?.isAfter || def,
      isBefore: def => getWhere(field)(query)?.isBefore || def,
      // isectNotEmpty: def => getWhere(field)(query)?.isectNotEmpty || def || [],
      // keywords: def => getWhere(field)(query)?.keywords || def,
      // likei: def => getWhere(field)(query)?.likei || def,
      // lt: def => getWhere(field)(query)?.['<'] || def,
      // lte: def => getWhere(field)(query)?.['<='] || def,
      notIn: def => getWhere(field)(query)?.notIn || def || [],
      startsWith: def => getWhere(field)(query)?.startsWith || def,
    }),
  },
})

const setters = {
  limit: value => setLimit(value),

  modifier: field => ({
    is: value => setModifier(field, value),
  }),

  offset: value => setOffset(value),
  orderBy: (field, direction, options) => setOrderBy(field, direction, options),

  where: (field, maybe = false) => ({
    // the '●' characters here are used because some json data fields have
    // periods in the field names, and we don't want those broken out as levels
    contains: value => setWhere(`${field}●contains`, value, maybe),
    containsAll: value => setWhere(`${field}●containsAll`, value, maybe),
    // gt: value => setWhere(`${field}.>`, value),
    // gte: value => setWhere(`${field}.>=`, value),
    // identity: value => setWhere(field, value),
    in: value => setWhere(`${field}●in`, value),
    // intersects: value => setWhere(`${field}.intersects`, value),
    is: value => setWhere(`${field}●is`, value),
    isAfter: value => setWhere(`${field}●isAfter`, value),
    isBefore: value => setWhere(`${field}●isBefore`, value),
    // isectNotEmpty: value => setWhere(`${field}.isectNotEmpty`, value),
    // keywords: value => setWhere(`${field}.keywords`, value),
    // likei: value => setWhere(`${field}.likei`, value),
    // lt: value => setWhere(`${field}.<`, value),
    // lte: value => setWhere(`${field}.<=`, value),
    notIn: value => setWhere(`${field}●notIn`, value),
    startsWith: value => setWhere(`${field}●startsWith`, value),
  }),
}

setters.whereMaybe = curryRight(setters.where, true)

export const alter = {
  set: setters,

  toggle: {
    orderBy: field => query => {
      const [, current] = inspect(query).get.orderBy(field)
      return setters.orderBy(
        field,
        toggleBetween(current, 'asc', 'desc'),
      )(query)
    },

    where: field => {
      const getters = query => inspect(query).get
      return {
        //     intersects: value => query => setters
        //       .where(field)
        //       .intersects(toggleItem(getters(query).where(field).intersects())(value))(query),
        //     isectNotEmpty: value => query => setters
        //       .where(field)
        //       .isectNotEmpty(toggleItem(getters(query).where(field).isectNotEmpty())(value))(query),
        notIn: value => query =>
          setters
            .where(field)
            .notIn(toggleItem(getters(query).where(field).notIn())(value))(
            query,
          ),
      }
    },
  },

  remove: {
    modifier: field => removeModifier(field),
    orderBy: field => removeOrderBy(field),
    where: field => removeWhere(field),
  },
}

// Common variants and helpers

// export const getTagIds = query => inspect(query).get.where('tagIds').intersects()
// export const toggleTagId = tagId => alter.toggle.where('tagIds').intersects(tagId)

// export const getKeywords = query => inspect(query).get.where('searchKeywords').keywords()
// export const setKeywords = value => alter.set.where('searchKeywords').keywords(value)

// export const toggleOrderByDirection = field => alter.toggle.orderBy(field)

/**
 * This handles a common use-case when dealing with hypotheticals returned from queries.
 * It matches up hypothetical counts to available options, filling in any holes with zero.
 *
 * available: ["Mr. J", "Mr. Jennings", "Mr. Jennings Twin"]
 *
 * actual (aka hypotheticals): {"Mr. J": 7}
 *
 * output: {"Mr. J": 7, "Mr. Jennings": 0, "Mr. Jennings Twin": 0}
 */
// export const optionProjection = (available, actual) => {
//   const baseline = reduce((acc, name) => set(name, 0, true)(acc), {})(available)
//   return merge(actual)(baseline)
// }
