import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { getDecoratedUser } from 'selectors/users'

const useCurrentUser = () => {
  const mapState = useCallback(getDecoratedUser, [])

  return useSelector(mapState)
}

export default useCurrentUser
