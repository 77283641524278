const overrides = ({ mixins: { em, rem }, palette }) => ({
  MuiInput: {
    styleOverrides: {
      root: {
        '&::before': {
          borderColor: palette.grey[4],
        },
        '.MuiInputAdornment-root': {
          paddingBottom: 8,
        },

        '&.Mui-disabled': {
          color: palette.grey[3],
          '&::before': {
            borderBottomStyle: 'dashed',
            borderColor: palette.grey[5],
          },

          '.MuiInputAdornment-root': {
            color: palette.grey[3],
          },
        },

        '&.Mui-error::after': {
          borderBottomWidth: 1,
        },

        '&:not(.Mui-disabled).Mui-error:hover::after, &.Mui-error:focus-within::after':
          {
            borderBottomWidth: 2,
          },
      },

      input: {
        paddingBottom: 10,

        '&::placeholder': {
          color: palette.grey[2],
          fontStyle: 'italic',
          fontSize: rem(1.6),
          lineHeight: '157%',
          letterSpacing: em(-0.02),
          opacity: 1,
        },

        '&.Mui-disabled': {
          WebkitTextFillColor: palette.grey[3],
          color: palette.grey[3],
        },
      },
    },

    variants: [],
  },
})

export default overrides
