import {
  DecoratorBlockNode,
  type SerializedDecoratorBlockNode,
} from '@lexical/react/LexicalDecoratorBlockNode'
import * as lexical from 'lexical'
import { Suspense, lazy } from 'react'

interface ISerializedImageNode extends SerializedDecoratorBlockNode {
  contentId: string
}

const Decorator = lazy(() => import('./Decorator'))

export class ImageNode extends DecoratorBlockNode {
  __contentId: string

  constructor(
    contentId: string,
    format?: lexical.ElementFormatType,
    key?: lexical.NodeKey,
  ) {
    super(format, key)
    this.__contentId = contentId
  }

  static getType() {
    return 'image'
  }

  static clone(node: ImageNode) {
    return new ImageNode(node.__contentId, node.__format, node.__key)
  }

  static importJSON(json: ISerializedImageNode) {
    const node = new ImageNode(json.contentId, json.format)
    return node
  }

  exportJSON(): ISerializedImageNode {
    return {
      ...super.exportJSON(),
      type: this.getType(),
      version: 1,

      contentId: this.__contentId,
    }
  }

  decorate() {
    return (
      <Suspense fallback={null}>
        <Decorator
          contentId={this.__contentId}
          node={this}
          nodeKey={this.getKey()}
        />
      </Suspense>
    )
  }
}

export function $isImageNode(node: lexical.LexicalNode): boolean {
  return node instanceof ImageNode
}

export function $createImageNode(contentId: string): lexical.LexicalNode {
  const node = new ImageNode(contentId)

  return lexical.$applyNodeReplacement(node)
}
