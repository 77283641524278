import compare from 'just-compare'
import { useEffect, useMemo, useRef } from 'react'
import deepClone from 'rfdc/default'

const useDeepCompareMemoize = value => {
  const ref = useRef()

  if (!compare(value, ref.current)) {
    ref.current = deepClone(value)
  }

  return ref.current
}

export const useDeepCompareEffect = (callback, dependencies) =>
  useEffect(callback, dependencies.map(useDeepCompareMemoize))

export const useDeepCompareMemo = (callback, dependencies) =>
  useMemo(callback, dependencies.map(useDeepCompareMemoize))
