import Box from '@mui/material/Box'
/* istanbul ignore file */
import { styled } from '@mui/material/styles'
import { important } from 'polished'
import { featuredContentMaxWidth } from 'styling/theming/base'

const Instructions = styled(Box, { name: 'layout-Instructions' })(
  ({
    theme: {
      mixins: { rem },
      typography,
    },
  }) => ({
    ...typography.variants.instructions,
    letterSpacing: 'normal',
    margin: rem(0, 'auto', 2.4),
    maxWidth: featuredContentMaxWidth,
    textAlign: 'center',
    '.interactive-image': {
      padding: 0,
      '.image-annotations-overlay': important(typography.body1),
    },
    '> p': important(typography.variants.instructions),
  }),
)

export default Instructions
