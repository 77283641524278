import Input from '@mui/material/Input'
import List from '@mui/material/List'
import { styled } from '@mui/material/styles'
import cl from 'classnames'
import { componentShape, numberOrString } from 'core/shapes'
import { includesString } from 'fp/strings'
import { when, whenPresent } from 'fp/utils'
import withDebounce from 'hoc/withDebounce'
import withKeyboardClicks from 'hoc/withKeyboardClicks'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useKeyboardListNavigation } from 'use-keyboard-list-navigation'

const SearchInput = withDebounce()(Input)

const itemShape = PropTypes.shape({
  key: numberOrString,
  label: componentShape.isRequired,
})

export const itemRendererShape = {
  item: itemShape.isRequired,
  selected: PropTypes.bool.isRequired,
}

const Item = styled(withKeyboardClicks('li'), { name: 'ListGrid-Item' })(
  ({
    theme: {
      mixins: { borderS, importantRem, rem, transition },
      palette,
    },
  }) => ({
    ...borderS(palette.grey[4]),
    ...transition('background'),
    alignItems: 'center',
    background: palette.grey[5],
    borderRadius: rem(0.8),
    boxSizing: 'border-box',
    cursor: 'pointer',
    display: 'flex',
    flexFlow: 'column nowrap',
    float: 'left',
    justifyContent: 'space-between',
    margin: importantRem(0.25),
    padding: rem(0.75, 0.25),

    '&.selected': {
      background: palette.selection,
    },

    '&.disabled': {
      fontStyle: 'italic',
      opacity: 0.5,
    },

    '&:hover:not(.disabled)': {
      background: palette.focused,
    },
  }),
)

const DefaultItemRenderer = ({ item: { label } }) => ({ label })
DefaultItemRenderer.propTypes = { ...itemRendererShape }

const ListGrid = props => {
  const {
    ItemRenderer = DefaultItemRenderer,
    ListProps,
    initialIndex,
    itemHeight = 100,
    itemWidth = 150,
    items,
    onProceed,
    onSelect,
    searchable = false,
  } = props
  const listRef = useRef()

  const [selectedIndex, setSelectedIndex] = useState(initialIndex)
  const [searchText, setSearchText] = useState('')

  const visibleItems = useMemo(
    () =>
      searchable
        ? items.filter(({ label }) => includesString(searchText)(label))
        : items,
    [items, searchText, searchable],
  )

  /**
   * TODO:
   * This third-party hook does a decent enough job with the basics, but it's
   * missing some keyboard navigation we'll want and so we'll likely want to
   * fork or replace it.
   *
   * We already have an HOC named `withTabListSupport` which performs a similar
   * function.  We might want to just take the best from both to roll our own
   * hook.
   */
  const { index } = useKeyboardListNavigation({
    defaultValue: selectedIndex ? visibleItems[selectedIndex] : undefined,
    list: visibleItems,
    ref: listRef,
    waitForInteractive: true,
  })

  useEffect(() => {
    listRef.current?.childNodes[index]?.focus()
    when(!visibleItems[index]?.disabled, setSelectedIndex, index)
  }, [index, visibleItems])

  useEffect(() => {
    whenPresent(onSelect, visibleItems[selectedIndex])
  }, [visibleItems, onSelect, selectedIndex])

  const handleSearch = ({ target: { value } }) => {
    setSearchText(value)
  }

  return (
    <div>
      {Boolean(searchable) && (
        <SearchInput
          autoFocus
          fullWidth
          onChange={handleSearch}
          placeholder="Search"
          sx={{ margin: '0 0 1rem' }}
          value={searchText}
        />
      )}

      <List
        ref={listRef}
        {...ListProps}>
        {visibleItems.map((item, idx) => (
          <Item
            className={cl({
              selected: selectedIndex === idx,
              disabled: item.disabled,
            })}
            key={item.key || item.label}
            onClick={() =>
              when(!visibleItems[idx].disabled, setSelectedIndex, idx)
            }
            onDoubleClick={() => whenPresent(onProceed)}
            sx={{ height: itemHeight, width: itemWidth }}>
            <ItemRenderer
              item={item}
              selected={selectedIndex === idx}
            />
          </Item>
        ))}
      </List>
    </div>
  )
}

ListGrid.propTypes = {
  initialIndex: PropTypes.number,
  itemHeight: numberOrString,
  ItemRenderer: PropTypes.func,
  itemWidth: numberOrString,
  items: PropTypes.array.isRequired,
  ListProps: PropTypes.object,
  onProceed: PropTypes.func,
  onSelect: PropTypes.func,
  searchable: PropTypes.bool,
}

export default ListGrid
