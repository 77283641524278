import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'

const Dropdown = forwardRef((props, ref) => {
  const { items, onChange, selectedIdx } = props

  const handleChange = e => {
    const { target } = e
    onChange(e, target.value)
  }

  return (
    <Select
      data-variant="collapsed-tablist"
      fullWidth
      onChange={handleChange}
      ref={ref}
      style={{ marginTop: 0 }}
      tabIndex={0}
      value={items[selectedIdx].value}>
      {items.map(({ disabled, label, value, id }) => (
        <MenuItem
          disabled={disabled}
          id={id}
          key={id}
          value={value}
          variant="collapsed-tablist">
          {label}
        </MenuItem>
      ))}
    </Select>
  )
})

Dropdown.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  selectedIdx: PropTypes.number.isRequired,
}

export default Dropdown
