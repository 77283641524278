import { FormIslandProvider } from 'common/formControls/Form/FormIsland'
import useHasErrors from 'common/formControls/Form/FormIsland/useHasErrors'
import { componentShape } from 'core/shapes'
import CollapsibleSection from './CollapsibleSection'

const Inner = ({ children, ...rest }) => {
  const hasErrors = useHasErrors()

  return (
    <CollapsibleSection {...{ ...rest, hasErrors }}>
      {children}
    </CollapsibleSection>
  )
}

Inner.propTypes = { children: componentShape }

const CollapsibleFormSection = props => (
  <FormIslandProvider>
    <Inner {...props} />
  </FormIslandProvider>
)

export default CollapsibleFormSection
