import { FEATURE_FLAG_GOOGLE_INTEGRATIONS } from 'core/consts'
import { get, set } from 'fp/objects'
import { eitherToPromise, pipe } from 'fp/utils'
import useAbilityChecker from 'hooks/useAbilityChecker'
import useApiFromEffect from 'hooks/useApiFromEffect'
import useCurrentUser from 'hooks/useCurrentUser'
import PropTypes from 'prop-types'
import { createContext, useMemo, useState } from 'react'
import { isDevEnv, isTestEnv } from 'selectors/index'
import init from './authApi'

let initialized = false

const context = createContext()

const getAuthInstance = () => gapi.auth2.getAuthInstance()
const getAuthUser = () => getAuthInstance().currentUser.get()
const getAuthUserIsSignedIn = () => getAuthUser().isSignedIn()
const getAuthUserDetails = () => {
  const profile = getAuthUser().getBasicProfile()
  return {
    id: profile.getId(),
    name: profile.getName(),
    email: profile.getEmail(),
  }
}

const Provider = ({
  children,
  initialStatus = 'INITIALIZING',
  initialTestState,
}) => {
  const {
    isStudent,
    user,
    user: { googleId, preferences },
  } = useCurrentUser()

  const classroomOn = get('googleApps.classroomOn')(preferences)
  const driveOn = get('googleApps.driveOn')(preferences)

  const [state, setState] = useState({
    requireAdditionalScopes: false,
    allScopesGranted: false,
    didSignOut: false,
    errorMessage: null,
    googleDocPreviewHtml: null,
    isWorking: false,
    signinId: null,
    signinEmail: '',
    status: initialStatus,
    wantClassroomOn: classroomOn,
    wantDriveOn: driveOn,
    ...initialTestState,
  })
  const updater = name => newValue => setState(set(name, newValue))
  const callApi = useApiFromEffect()
  const setRequireAdditionalScopes = updater('requireAdditionalScopes')
  const setAllScopesGranted = updater('allScopesGranted')
  const setDidSignOut = updater('didSignOut')
  const setErrorMessage = updater('errorMessage')
  const setGoogleDocPreviewHtml = updater('googleDocPreviewHtml')
  const setIsWorking = updater('isWorking')
  const setStatus = updater('status')
  const setWantClassroomOn = updater('wantClassroomOn')
  const setWantDriveOn = updater('wantDriveOn')

  const has = useAbilityChecker()

  // TODO: replace with real data
  const {
    google: { appScopes, clientId },
  } = {
    google: {
      appScopes: {
        classroomStudent: [
          'https://www.googleapis.com/auth/classroom.rosters.readonly',
          'https://www.googleapis.com/auth/classroom.coursework.me',
        ],
        classroomTeacher: [
          'https://www.googleapis.com/auth/classroom.rosters.readonly',
          'https://www.googleapis.com/auth/classroom.courses.readonly',
          'https://www.googleapis.com/auth/classroom.coursework.students',
        ],
        common: [
          'https://www.googleapis.com/auth/userinfo.profile',
          'https://www.googleapis.com/auth/userinfo.email',
        ],
        drive: ['https://www.googleapis.com/auth/drive'],
      },
      clientId:
        '468576513899-rea200ip850hg7otl0l3mmfj666dea8h.apps.googleusercontent.com',
      scopes: [
        'https://www.googleapis.com/auth/drive',
        'https://www.googleapis.com/auth/userinfo.profile',
        'https://www.googleapis.com/auth/userinfo.email',
      ],
    },
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: TODO: analyze and fix
  const value = useMemo(() => {
    const handleError = ({ message, error, details = '' }) => {
      setIsWorking(false)
      setStatus('ERROR')
      setErrorMessage(message || error)
      /* istanbul ignore next */
      if (!isTestEnv()) {
        if (
          !(
            isDevEnv() &&
            details.includes('whitelist this origin for your project')
          )
        ) {
          // biome-ignore lint/suspicious/noConsole: intended
          console.info(details)
        }
      }
    }

    const updateStatus = () => {
      // biome-ignore lint/complexity/noExcessiveCognitiveComplexity: <explanation>
      setState((prev = {}) => {
        const { allScopesGranted, requireAdditionalScopes } = prev
        const isSignedIn = getAuthUserIsSignedIn()
        const { email, id } = isSignedIn ? getAuthUserDetails() : {}

        let newStatus

        if (!isSignedIn) {
          newStatus = 'SIGNIN'
        } /* istanbul ignore next */ else if (
          id &&
          googleId &&
          id !== googleId
        ) {
          newStatus = 'EMAIL_MISMATCH'
        } else if (googleId && !allScopesGranted && !requireAdditionalScopes) {
          newStatus = 'CHECK_SCOPES'
        } else if (allScopesGranted) {
          newStatus = 'READY'
        } else {
          newStatus = 'ADD_SCOPES'
        }

        setState({
          ...prev,
          signinId: id,
          signinEmail: email,
          status: newStatus,
        })

        setIsWorking(false)
      })
    }

    const signIn = () => {
      const { auth2 } = gapi
      const options = new auth2.SigninOptionsBuilder()

      if (state.didSignOut) {
        options.setPrompt('select_account')
      }

      auth2
        .getAuthInstance()
        .signIn(options)
        .catch(handleError)
        .then(updateStatus)
    }

    const signOut = cb => {
      setDidSignOut(true)

      getAuthInstance().signOut().catch(handleError).then(cb)
    }

    const getNeededScopes = () => {
      if (isStudent) {
        return [
          //
          ...appScopes.common,
          ...(state.wantClassroomOn ? appScopes.classroomStudent : []),
        ]
      }
      return [
        ...appScopes.common,
        ...(state.wantDriveOn ? appScopes.drive : []),
        ...(state.wantClassroomOn ? appScopes.classroomTeacher : []),
      ]
    }

    const checkClassroomStatus = async () => {
      /* istanbul ignore else */
      if (isStudent) {
        const result = await callApi({
          url: `/users/${user.id}`,
          options: {
            method: 'PUT',
            body: { googleCheckWantClassroom: true },
          },
        })
        /* istanbul ignore next */
        result.cata(handleError, ({ teacherWantClassroom }) => {
          setWantDriveOn(true)
          setWantClassroomOn(teacherWantClassroom)
        })
      }
    }

    const authorize = () => {
      setIsWorking(true)

      const googleExpectedUserId = state.signinId

      const updateServer = async response => {
        const result = await callApi({
          url: `/users/${user.id}`,
          options: {
            method: 'PUT',
            body: {
              googleExpectedUserId,
              googleOneTimeCode: response.code,
              preferences: pipe(
                set('googleApps.classroomOn', state.wantClassroomOn),
                set('googleApps.driveOn', state.wantDriveOn),
              )(user.preferences),
            },
          },
        })
        /* istanbul ignore next */
        return result
      }

      /* istanbul ignore next */
      getAuthUser()
        .grantOfflineAccess({ scope: getNeededScopes().join(' ') })
        .then(async response => {
          const r = await updateServer(response)
          return eitherToPromise(r)
        })
        .then(() => {
          setAllScopesGranted(true)
          updateStatus()
        })
        .catch(handleError)
    }

    const deauthorize = cb => {
      setIsWorking(true)

      const updateServer = async () => {
        const result = await callApi({
          url: `/users/${user.id}`,
          options: {
            method: 'PUT',
            body: { googleOneTimeCode: false },
          },
        })
        /* istanbul ignore next */
        return eitherToPromise(result)
      }

      /* istanbul ignore next */
      updateServer()
        .then(() => getAuthInstance().disconnect())
        .then(() => getAuthInstance().signOut())
        .then(updateStatus)
        .then(cb)
        .catch(handleError)
    }

    const checkScopes = async () => {
      setIsWorking(true)

      const result = await callApi({
        url: `/users/${user.id}`,
        options: {
          method: 'PUT',
          body: { googleAuthCheck: true },
        },
      })

      /* istanbul ignore next */
      result
        .map(({ googleScopes: activeScopes }) => {
          const neededScopes = getNeededScopes()
          const hadAllScopes = neededScopes.every(scope =>
            activeScopes.includes(scope),
          )

          setAllScopesGranted(hadAllScopes)

          if (!hadAllScopes) {
            setRequireAdditionalScopes(true)
          }
          return updateStatus()
        })
        .leftMap(handleError)
    }

    const startWorkflow = () =>
      new Promise((resolve, reject) => {
        if (initialized) {
          resolve()
          /* istanbul ignore else */
        } else if (has(FEATURE_FLAG_GOOGLE_INTEGRATIONS)) {
          init(clientId)
            .then(() => {
              initialized = true
              resolve()
            })
            .catch(reject)
        } else {
          reject(
            new Error('Google Integrations disabled by user configuration.'),
          )
        }
      })

    return {
      authorize,
      checkClassroomStatus,
      checkScopes,
      deauthorize,
      handleError,
      setAllScopesGranted,
      setErrorMessage,
      setGoogleDocPreviewHtml,
      setIsWorking,
      setRequireAdditionalScopes,
      setStatus,
      setWantClassroomOn,
      setWantDriveOn,
      signIn,
      signOut,
      startWorkflow,
      updateStatus,
      ...state,
    }
  }, [
    appScopes.classroomStudent,
    appScopes.classroomTeacher,
    appScopes.common,
    appScopes.drive,
    callApi,
    clientId,
    googleId,
    has,
    isStudent,
    setAllScopesGranted,
    setDidSignOut,
    setErrorMessage,
    setGoogleDocPreviewHtml,
    setIsWorking,
    setRequireAdditionalScopes,
    setStatus,
    setWantClassroomOn,
    setWantDriveOn,
    state,
    user.id,
    user.preferences,
  ])

  return <context.Provider value={value}>{children}</context.Provider>
}

Provider.propTypes = {
  children: PropTypes.node.isRequired,
  initialStatus: PropTypes.string,
  initialTestState: PropTypes.object,
}

const withProvider = WrappedComponent => props => (
  <Provider>
    <WrappedComponent {...props} />
  </Provider>
)

export { context, Provider, withProvider }
