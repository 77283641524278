import useEffectOnce from 'hooks/useEffectOnce'
import { useContext } from 'react'
import Account from './Account'
import ErrorComponent from './Error'
import Settings from './Settings'
import SignIn from './SignIn'
import { context, withProvider } from './context'

const AuthWorkflow = withProvider(() => {
  const { checkClassroomStatus, handleError, startWorkflow, updateStatus } =
    useContext(context)

  useEffectOnce(() => {
    startWorkflow()
      .then(checkClassroomStatus)
      .then(updateStatus)
      .catch(handleError)
  }, [])

  return (
    <>
      <ErrorComponent />
      <SignIn />
      <Account />
      <Settings />
    </>
  )
})

export default AuthWorkflow
