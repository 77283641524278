import {
  $applyNodeReplacement,
  $isTextNode,
  type DOMConversion,
  type DOMConversionMap,
  type DOMConversionOutput,
  type LexicalEditor,
  type LexicalNode,
  type NodeKey,
  type SerializedTextNode,
  TextNode,
} from 'lexical'

export class ExtendedTextNode extends TextNode {
  __customStyleType: string

  constructor(text: string, customStyleType: string, key?: NodeKey) {
    super(text, key)
    this.__customStyleType = customStyleType
  }

  static getType(): string {
    return 'extended-text'
  }

  static clone(node: ExtendedTextNode): ExtendedTextNode {
    return new ExtendedTextNode(node.__text, node.__customStyleType, node.__key)
  }

  static importDOM(): DOMConversionMap | null {
    const importers = TextNode.importDOM()

    return {
      ...importers,
      code: () => ({
        conversion: patchStyleConversion(importers?.code),
        priority: 1,
      }),
      em: () => ({
        conversion: patchStyleConversion(importers?.em),
        priority: 1,
      }),
      span: () => ({
        conversion: patchStyleConversion(importers?.span),
        priority: 1,
      }),
      strong: () => ({
        conversion: patchStyleConversion(importers?.strong),
        priority: 1,
      }),
      sub: () => ({
        conversion: patchStyleConversion(importers?.sub),
        priority: 1,
      }),
      sup: () => ({
        conversion: patchStyleConversion(importers?.sup),
        priority: 1,
      }),
    }
  }

  static importJSON(
    serializedNode: SerializedTextNode & { customStyleType: string },
  ): ExtendedTextNode {
    const node = TextNode.importJSON(serializedNode)
    return new ExtendedTextNode(
      node.__text,
      serializedNode.customStyleType,
      node.__key,
    )
  }

  isSimpleText() {
    return this.__type === 'extended-text' && this.__mode === 0
  }

  exportJSON(): SerializedTextNode & { customStyleType: string } {
    // console.log('exportJSON', this.__customStyleType)

    return {
      ...super.exportJSON(),
      type: 'extended-text',
      version: 1,
      customStyleType: this.__customStyleType,
    }
  }

  setCustomStyleType(customStyleType: string): this {
    const writable = this.getWritable()
    writable.__customStyleType = customStyleType
    // console.log(this.__customStyleType)

    return writable
  }

  decorate(editor: LexicalEditor, isReadOnly: boolean): JSX.Element {
    const className = `extended-text ${this.__customStyleType} ${isReadOnly ? 'read-only' : 'editable'}`
    console.log('decorate', className)

    return <span className={className}>{this.getTextContent()}</span>
  }
}

export function $createExtendedTextNode(
  text: string,
  customStyleType = '',
): ExtendedTextNode {
  return $applyNodeReplacement(new ExtendedTextNode(text, customStyleType))
}

export function $isExtendedTextNode(
  node: LexicalNode | null | undefined,
): node is ExtendedTextNode {
  return node instanceof ExtendedTextNode
}

const patchStyleConversion =
  (
    originalDOMConverter?: (node: HTMLElement) => DOMConversion | null,
  ): ((node: HTMLElement) => DOMConversionOutput | null) =>
  node => {
    const original = originalDOMConverter?.(node)

    if (!original) {
      return null
    }
    const originalOutput = original.conversion(node)

    if (!originalOutput) {
      return originalOutput
    }

    const {
      backgroundColor,
      color,
      fontFamily,
      fontSize,
      fontWeight,
      textDecoration,
    } = node.style

    return {
      ...originalOutput,
      forChild: (lexicalNode, parent) => {
        const originalForChild = originalOutput?.forChild ?? (x => x)
        const result = originalForChild(lexicalNode, parent)
        if ($isTextNode(result)) {
          const style = [
            backgroundColor ? `background-color: ${backgroundColor}` : null,
            color ? `color: ${color}` : null,
            fontFamily ? `font-family: ${fontFamily}` : null,
            fontWeight ? `font-weight: ${fontWeight}` : null,
            fontSize ? `font-size: ${fontSize}` : null,
            textDecoration ? `text-decoration: ${textDecoration}` : null,
          ]
            .filter(value => value != null)
            .join('; ')
          if (style.length) {
            return result.setStyle(style)
          }
        }
        return result
      },
    }
  }
