import { styled } from '@mui/material/styles'
import { getImageUrlFromUploadsMap } from 'common/avclub/utils'
import PropTypes from 'prop-types'

const UploadsPreview = styled(
  props => {
    const { altText = 'Preview', className, uploadsMap, onClick } = props

    const url = getImageUrlFromUploadsMap(uploadsMap)

    return url ? (
      <div className={className}>
        <figure>
          <img
            alt={altText}
            src={url}
            onClick={onClick}
          />
        </figure>
      </div>
    ) : null
  },
  { name: 'interactivePlugin-UploadsPreview' },
)({
  maxWidth: 120,
  maxHeight: 120,
  minWidth: 120,
  figure: {
    overflow: 'hidden',
    img: {
      objectFit: 'cover',
      width: '100%',
      transform: 'scale(1)',
      transition: '0.2s ease-in-out',
      cursor: 'pointer',
      '&:hover': {
        transform: 'scale(1.5)',
      },
    },
  },
})

UploadsPreview.propTypes = {
  altText: PropTypes.string,
  onClick: PropTypes.func,
  uploadsMap: PropTypes.object.isRequired,
}

export default UploadsPreview
