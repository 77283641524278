import type { Content } from 'common/@types/custom'
import { isSubsectionLike } from 'core/consts'
import { first } from 'fp/arrays'
import { isDefined, when } from 'fp/utils'
import { newlyAddedChildId } from 'hss/ContentBuilder/consts'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { actions as contentActions } from 'reducers/content'
import {
  getContentByAssetCode,
  getContentById,
  isContentFetching,
  isContentLoaded,
} from 'selectors/content'
import { getCurrentSectionIsTabbed } from 'selectors/contentViewer'
import { getContentViewerParams } from 'selectors/contentViewerParams'
import useIsPinnedContent from './useIsPinnedContent'
import useReduxCallback, { IDLE } from './useReduxCallback'
interface UseContentProps {
  assetCode?: string
  disableFetch?: boolean
  paramName?: string
  // biome-ignore lint/suspicious/noExplicitAny: TODO: I don't know how best to deal with this yet.  explore router types
  queryParams?: Record<string, any>
  refresh?: boolean
  contentType?: string
  contentId?: string
}
type ReduxAction =
  | ReturnType<typeof contentActions.fetchContentByAssetCode>
  | ReturnType<typeof contentActions.fetchContentById>

const useContent = (props: UseContentProps = {}): Content | null => {
  const routeParams = useParams()
  const [needsFetching, setNeedsFetching] = useState(false)
  const [reduxAction, setReduxAction] = useState<ReduxAction | null>(null)
  const matchParams = useSelector(getContentViewerParams())
  const isPinned = useIsPinnedContent()
  const {
    assetCode,
    disableFetch,
    paramName = 'contentId',
    queryParams,
    refresh,
  } = props
  const params = isPinned
    ? routeParams
    : { ...(routeParams || {}), ...(matchParams || {}) }

  const byAssetCode: Content = useSelector(getContentByAssetCode({ assetCode }))

  const contentType = props.contentType || params.contentType
  /**
   * Try to use the TE version of the contentId if it exists.  This is largely to
   * support chapter summaries, but can be used anytime there could be duplicates
   * within the chapter nav tree.
   */
  const availableParam = first(
    [paramName]
      .flat()
      .map(p => params[p])
      .filter(isDefined),
  )

  const contentId: string | undefined = (
    byAssetCode?.id ||
    props.contentId ||
    availableParam
  )?.replace?.('TE-', '')

  const isLoaded = useSelector(
    isContentLoaded({ contentType, contentId, queryParams }),
  )
  const isFetching = useSelector(
    isContentFetching({ contentType, contentId: assetCode || contentId }),
  )

  // biome-ignore lint/correctness/useExhaustiveDependencies(queryParams):
  useEffect(() => {
    setReduxAction(
      isDefined(assetCode)
        ? contentActions.fetchContentByAssetCode({
            assetCode,
            contentType,
            queryParams,
          })
        : contentActions.fetchContentById({
            contentType,
            contentId,
            queryParams,
          }),
    )
    // Purposely excluding queryParams from the dependencies as we'd go into an
    // infinite loop otherwise.
    // SHOULD be safe, but something to keep an eye on if you see weirdness.
  }, [assetCode, contentId, contentType])

  const [startFetch, fetchStatus] = useReduxCallback({
    actionType: reduxAction?.type,
  })

  useEffect(() => {
    setNeedsFetching(
      Boolean(
        isDefined(assetCode || contentId) &&
          fetchStatus === IDLE &&
          !disableFetch &&
          (!isLoaded || refresh) &&
          !isFetching &&
          !String(contentId).startsWith(newlyAddedChildId),
      ),
    )
  }, [
    assetCode,
    contentId,
    disableFetch,
    fetchStatus,
    isFetching,
    isLoaded,
    refresh,
  ])

  useEffect(() => {
    when(needsFetching, startFetch, reduxAction)
  }, [reduxAction, needsFetching, startFetch])
  let type = contentType
  const isTabbedSection = useSelector(getCurrentSectionIsTabbed({ isPinned }))
  if (isSubsectionLike(String(contentType)) && isTabbedSection) {
    // Disable the content type when we're selecting content for a tabbed section.
    // Reason is that these pages can have both subsection and source content types.
    // As such, it's better to look up the content by ID instead of by type.
    type = undefined
  }

  return useSelector(getContentById({ contentType: type, contentId }))
}

export default useContent
