import useToggleState from 'hooks/useToggleState'
import { createContext, useMemo } from 'react'

/**
 * This context keeps track of the open/closed state of dialogs that can be opened
 * via main nav items, including the Contact Form, User Alerts (i.e. Notifications),
 * Interrupt User Alerts (opens automatically upon logging in, if there are new alerts),
 * and Profile User Alerts (accessed via the Bell icon in the top nav).
 */

export const navDialogsContext = createContext()

const NavDialogsProvider = props => {
  const [contactFormDialogIsOpen, toggleContactFormDialogIsOpen] =
    useToggleState()
  const [creditsDialogIsOpen, toggleCreditsDialogIsOpen] = useToggleState(false)
  const [interruptUserAlertsIsOpen, toggleInterruptUserAlertsIsOpen] =
    useToggleState(true)
  const [notificationsIsOpen, toggleNotificationsIsOpen] = useToggleState()
  const [profileUserAlertsIsOpen, toggleProfileUserAlertsIsOpen] =
    useToggleState()

  const value = useMemo(
    () => ({
      contactFormDialogIsOpen,
      creditsDialogIsOpen,
      interruptUserAlertsIsOpen,
      notificationsIsOpen,
      profileUserAlertsIsOpen,

      toggleContactFormDialogIsOpen,
      toggleCreditsDialogIsOpen,
      toggleInterruptUserAlertsIsOpen,
      toggleNotificationsIsOpen,
      toggleProfileUserAlertsIsOpen,
    }),
    [
      contactFormDialogIsOpen,
      creditsDialogIsOpen,
      interruptUserAlertsIsOpen,
      notificationsIsOpen,
      profileUserAlertsIsOpen,

      toggleContactFormDialogIsOpen,
      toggleCreditsDialogIsOpen,
      toggleInterruptUserAlertsIsOpen,
      toggleNotificationsIsOpen,
      toggleProfileUserAlertsIsOpen,
    ],
  )

  return (
    <navDialogsContext.Provider
      value={value}
      {...props}
    />
  )
}

export default NavDialogsProvider
