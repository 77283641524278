import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Dialog from 'common/dialogs/Dialog'
import DebouncedTextField from 'common/formControls/textInputs/DebouncedTextField'
import BusySpinner from 'common/indicators/BusySpinner'
import { isEmptyString } from 'fp/strings'
import useStateWithDynamicDefault from 'hooks/useStateWithDynamicDefault'
import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'

const RequestValueDialog = props => {
  const {
    affirmativeButtonLabel,
    busy,
    errorMessage,
    label,
    onClose,
    onComplete,
    open,
    title,
    value: initialValue = '',
  } = props

  const [value, setValue] = useStateWithDynamicDefault(initialValue)

  const ref = useRef()

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        ref.current?.focus()
        ref.current?.select()
      })
    }
  }, [open])

  const handleComplete = () => {
    onComplete(value.trim())
  }

  const handleChange = ({ target }) => setValue(target.value)

  const handleKeyUp = ({ key }) => {
    /* istanbul ignore next */
    if (key === 'Enter' && !isEmptyString(value)) {
      handleComplete()
    }
  }

  return (
    <Dialog
      disableAutoFocus
      open={open}
      title={title}>
      <DialogContent sx={{ overflow: 'hidden' }}>
        <DebouncedTextField
          disabled={busy}
          label={label}
          name="value"
          onChange={handleChange}
          onKeyUp={handleKeyUp}
          ref={ref}
          value={value}
        />

        {Boolean(busy) && (
          <BusySpinner
            mt={2}
            size={30}
          />
        )}

        {Boolean(errorMessage) && (
          <Alert
            elevation={6}
            id="message-id"
            severity="error"
            sx={{ mt: 2 }}
            variant="filled">
            {errorMessage}
          </Alert>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          color="secondary"
          disabled={busy}
          onClick={onClose}
          variant="secondary">
          Cancel
        </Button>

        <Button
          color="secondary"
          disabled={busy}
          onClick={handleComplete}
          variant="primary">
          {affirmativeButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

RequestValueDialog.propTypes = {
  affirmativeButtonLabel: PropTypes.string.isRequired,
  busy: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  label: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
}

export default RequestValueDialog
