import Box from '@mui/material/Box'
import Icon from '@mui/material/Icon'
import { useTheme } from '@mui/material/styles'
import CloseButton from 'common/dialogs/CloseButton'
import { useContainerQuery } from 'common/layout/ContainerQuery'
import Headline from 'common/text/Headline'
import ScreenReaderText from 'common/text/ScreenReaderText'
import { TOGGLE_STATE_LINE_READER_VISIBLE } from 'core/consts'
import useToggleState from 'hooks/useToggleState'
import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'
import { useDragDropManager } from 'react-dnd'
import { ArrowDownRight } from 'react-feather'
import { rem } from 'styling/theming/base/mixins'
import StyledLineReader from './StyledLineReader'
import useDragDrop from './useDragDrop'

const minWidth = 200
const maxWidth = 1200

const minHeight = 150
const maxHeight = 400

const smallWidth = 300
const normalWidth = 500

const WIDTH_TYPE = 'WIDTH_TYPE'
const HEIGHT_TYPE = 'HEIGHT_TYPE'
const BOTH_TYPE = 'BOTH_TYPE'

const LineReader = ({ initialCoord = { x: 300, y: 300 } }) => {
  const { palette } = useTheme()
  const isSmallScreen = useContainerQuery().down('md')
  const [lineReaderOpen, toggleLineReader] = useToggleState(
    false,
    TOGGLE_STATE_LINE_READER_VISIBLE,
  )
  const lineReaderRef = useRef()
  const widthRef = useRef()
  const heightRef = useRef()
  const bothRef = useRef()

  const dragDropManager = useDragDropManager()
  const monitor = dragDropManager.getMonitor()

  useEffect(() => {
    // biome-ignore lint/complexity/noExcessiveCognitiveComplexity: <explanation>
    const unsubscribe = monitor.subscribeToOffsetChange(() => {
      const itemType = monitor.getItemType()
      if ([WIDTH_TYPE, HEIGHT_TYPE, BOTH_TYPE].includes(itemType)) {
        const offset = monitor.getSourceClientOffset()
        if (offset) {
          const widthOffset =
            itemType === WIDTH_TYPE
              ? widthRef.current.getBoundingClientRect().width + 1
              : bothRef.current.getBoundingClientRect().width + 1
          const newWidth =
            offset.x -
            lineReaderRef.current.getBoundingClientRect().left +
            widthOffset
          const heightOffset =
            itemType === HEIGHT_TYPE
              ? heightRef.current.getBoundingClientRect().height + 1
              : bothRef.current.getBoundingClientRect().height + 1
          const newHeight =
            offset.y -
            lineReaderRef.current.getBoundingClientRect().top +
            heightOffset

          if (
            [WIDTH_TYPE, BOTH_TYPE].includes(itemType) &&
            newWidth >= minWidth &&
            newWidth <= maxWidth
          ) {
            lineReaderRef.current.style.width = `${newWidth}px`
          }

          if (
            [HEIGHT_TYPE, BOTH_TYPE].includes(itemType) &&
            newHeight >= minHeight &&
            newHeight <= maxHeight
          ) {
            lineReaderRef.current.style.height = `${newHeight}px`
          }
        }
      }
    })
    return () => {
      unsubscribe()
    }
  }, [monitor])

  useDragDrop(widthRef, WIDTH_TYPE)
  useDragDrop(heightRef, HEIGHT_TYPE)
  useDragDrop(bothRef, BOTH_TYPE)

  useEffect(() => {
    if (lineReaderOpen) {
      lineReaderRef.current.style.width = `${isSmallScreen ? smallWidth : normalWidth}px`
      lineReaderRef.current.style.height = `${minHeight}px`
    }
  }, [isSmallScreen, lineReaderOpen])

  return (
    Boolean(lineReaderOpen) && (
      <>
        <StyledLineReader
          initialCoord={initialCoord}
          ref={lineReaderRef}>
          <Box
            aria-describedby="line-reader-desc"
            aria-labelledby="line-reader-label">
            <Headline
              Component="h1"
              id="line-reader-label"
              size={6}
              title="Line Reader"
            />

            <Box
              className="right-border"
              ref={widthRef}
            />

            <CloseButton
              color={palette.background.paper}
              onClick={toggleLineReader}
              style={{ color: palette.background.paper, right: '0.5rem' }}
            />

            <Box
              className="bottom-border"
              ref={heightRef}
            />
          </Box>
          <Box
            style={{
              cursor: 'se-resize',
              height: rem(6),
              position: 'absolute',
              width: rem(6),
              right: 0,
              bottom: 0,
            }}>
            <Box
              ref={bothRef}
              style={{
                height: rem(4.5),
                position: 'absolute',
                width: rem(4.5),
                right: 0,
                bottom: 0,
              }}>
              <Icon>
                <ArrowDownRight color={palette.background.paper} />
              </Icon>
            </Box>
          </Box>
        </StyledLineReader>
        <ScreenReaderText id="line-reader-desc">
          Press the Tab key for instructions on using the line reader, a visual
          tool that can be resized and repositioned.
        </ScreenReaderText>
      </>
    )
  )
}

LineReader.propTypes = {
  initialCoord: PropTypes.object,
}

export default LineReader
