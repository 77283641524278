import { Stack, Typography } from '@mui/material'
import ImageSelection from 'common/formControls/textInputs/RichTextEdit2/nodes/ImageNode/ToolbarButton/ImageSelection'
import { isDefined, isUndefined } from 'fp/utils'
import ImageSearch from './ImageSearch'
import { useImageSelectionContext } from './ImageSelectionProvider'

const DialogContentRenderer = () => {
  const { upload, uploadWasMade, searchWasMade } = useImageSelectionContext()

  return (
    <Stack
      alignItems={'center'}
      gap={2}>
      {!uploadWasMade && isUndefined(upload) && <ImageSearch />}

      {!(searchWasMade || uploadWasMade) && (
        <Typography
          color="grey.500"
          variant="tab-title"
          sx={{ my: 2 }}>
          –or–
        </Typography>
      )}

      {(!searchWasMade || isDefined(upload)) && <ImageSelection />}
    </Stack>
  )
}

export default DialogContentRenderer
