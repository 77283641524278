import { Stack } from '@mui/material'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import CloseButton from 'common/dialogs/CloseButton'
import type { Theme } from 'styling/theming/@types/custom'
import DecoratorControl from './DecoratorControl'
import { DecoratorWrapperProps } from '../@types/custom'

const Styled = styled(Box, { name: 'utils-DecoratorWrapper' })(
  ({ theme }: { theme: Theme }) => {
    const {
      mixins: { borderS, rem },
      palette,
    } = theme
    return {
      ...borderS(palette.border[0]),
      position: 'relative',
      borderStyle: 'dashed',
      margin: rem(2, 0),
      padding: 0,
      maxWidth: rem(60),
      th: {
        textAlign: 'left',
        color: palette.grey[1],
        fontWeight: 'normal',
      },
      'th, td': {
        padding: 0,
        verticalAlign: 'middle',
      },
      td: {
        padding: 0,
        position: 'relative',
        '.MuiFormControl-root': {
          marginTop: 0,
        },
      },
      h6: {
        display: 'inline-block',
        margin: rem(1),
        padding: rem(1, 1.5),
        fontFamily: 'Inter',
        fontSize: rem(1.4),
        fontWeight: 'normal',
        textTransform: 'none',
        color: palette.grey.contrastText,
        backgroundColor: palette.grey[1],
      },
      table: {
        tableLayout: 'fixed',
        width: '100%',
        borderSpacing: rem(0, 1),
      },
      'table th': {
        width: '40%',
      },
      footer: { textAlign: 'right' },
    }
  },
) as unknown as typeof Box

const DecoratorWrapper: React.FC<DecoratorWrapperProps> = props => {
  const {
    children,
    content,
    fields,
    node,
    onChange,
    onRemove,
    title,
    titleAddendum,
  } = props

  return (
    <Styled component="section">
      <Box>
        <Box>
          <h6>{title}</h6>
          {titleAddendum}
        </Box>

        <Stack
          direction="row"
          gap={2}
          justifyContent="space-between"
          pb={1}
          px={1.5}>
          <table>
            <tbody>
              {fields.map(field => (
                <DecoratorControl
                  content={content}
                  field={field}
                  key={field.name}
                  node={node}
                  onChange={onChange}
                />
              ))}
            </tbody>
          </table>

          {children}
        </Stack>

        <CloseButton
          dense
          onClick={onRemove}
          style={{ right: '1rem', top: '.5rem' }}
          title="Remove"
        />
      </Box>
    </Styled>
  )
}

export default DecoratorWrapper
