import PropTypes from 'prop-types'
import rangy from 'rangy/lib/rangy-core'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import 'rangy/lib/rangy-textrange'
import {
  ABILITY_STUDENT_INTERFACE,
  INTERACTION_SUBTYPE_TEXT,
} from 'core/consts'
import { componentShape } from 'core/shapes'
import {
  findAncestor,
  getParentContentId,
  isWithinTextControl,
  preventDefault,
} from 'fp/dom'
import { equals, get } from 'fp/objects'
import { isEmptyString } from 'fp/strings'
import { isDefined, matches, when } from 'fp/utils'
import useAbilityChecker from 'hooks/useAbilityChecker'
import useAlert from 'hooks/useAlert'
import useSelectionChangeEvent from 'hooks/useSelectionChangeEvent'
import { contentViewerContext } from 'hss/ContentViewer/ContentViewerProvider'
import { compose } from 'redux'
import AnnotationDialog from './AnnotationDialog'
import useAnnotationColorId from './useAnnotationColorId'
import {
  acquireHashesAndIndexes,
  createRangeForAnnotation,
  findAllTextNodesBetween,
  highlightRange,
  isTeacherAnnotation,
  rangeIntersectsAnnotations,
  removeAllHighlights,
  removeOrphanedHighlights,
} from './utils'
import withStyledAnnotations from './withStyledAnnotations'

const isWithinInteractive = node =>
  isDefined(
    findAncestor(
      node,
      filterNode =>
        filterNode.getAttribute?.('data-contenttype') === 'interactive',
    ),
  )

/**
 * tricky!
 * work out the bounding box for the selection and use that as the anchor
 * for the popper
 */
const getAnchorNode = range => {
  const boundingBox = range.nativeRange.getBoundingClientRect()
  return {
    range,
    clientWidth: boundingBox.width,
    clientHeight: boundingBox.height,
    getBoundingClientRect: () => boundingBox,
  }
}

const Annotatable = ({ children, contextContentId, ...rest }) => {
  const ref = useRef()
  const {
    allowAnnotations,
    annotations,
    annotationsOn,
    // contentWrappingAllowed,
    studentViewingTeacherAnnotations,
  } = useContext(contentViewerContext)
  const has = useAbilityChecker()
  const userIsStudent = has(ABILITY_STUDENT_INTERFACE)

  const disabled = !(allowAnnotations && annotationsOn)
  const [open, setOpen] = useState(false)
  const [anchorNode, setAnchorNode] = useState()
  const [dialogData, setDialogData] = useState()
  const [activeRange, setActiveRange] = useState()
  const [activeAnnotation, setActiveAnnotation] = useState()
  const [colorId, setColorLocally, setColorId] = useAnnotationColorId()
  const addAlert = useAlert()
  // const { height, width } = useComponentSize(ref)

  const onClose = useCallback(keep => {
    setOpen(false)
    setActiveRange(null)
    setActiveAnnotation(null)
    setDialogData(null)
    when(!keep, removeOrphanedHighlights, ref.current)
  }, [])

  useEffect(() => {
    if (open) return
    removeOrphanedHighlights(ref.current)
    removeAllHighlights(ref.current)
    if (disabled) return

    annotations //
      .filter(matches('interactionSubType', INTERACTION_SUBTYPE_TEXT))
      .forEach(annotation => {
        const {
          interactionData: { annotation: annotationText, colorId: color },
        } = annotation
        const range = createRangeForAnnotation(annotation)
        const disablePointer =
          userIsStudent && isTeacherAnnotation(annotation) && !annotationText
        if (range) {
          highlightRange(
            range,
            color,
            { 'data-annotationid': annotation.id, key: annotation.id },
            disablePointer,
            !isEmptyString(annotation.interactionData.annotation),
          )
        }
      })
  }, [annotations, disabled, open, userIsStudent])

  const handleSelection = useCallback(
    selection => {
      if (disabled || (userIsStudent && studentViewingTeacherAnnotations))
        return

      onClose()
      setAnchorNode(undefined)

      if (selection?.isCollapsed || !selection?.rangeCount) return

      const rangyRange = rangy.getSelection().getRangeAt(0)

      if (rangeIntersectsAnnotations(rangyRange)) {
        addAlert({
          message: 'Highlights may not overlap',
          options: { variant: 'warning' },
        })
        return
      }
      const textNodes = findAllTextNodesBetween(
        selection.anchorNode,
        selection.focusNode,
      )

      if (!textNodes.length) return

      const validSelection =
        textNodes.filter(
          node => isWithinTextControl(node) || isWithinInteractive(node),
        ).length === 0

      if (!validSelection) {
        // this is a little too noisy if we do alerts for every invalid selection
        // addAlert({
        //   message: 'Cannot highlight within interactives',
        //   options: { variant: 'warning' },
        // })
        return
      }

      // if we've gotten this far, then the selection is a valid target for an annotation
      // rangyRange.toString() preserves the DOM casing
      // vs the selection.toString() which doesn't (causes issues with headings which are displayed uppercase)
      const selectionData = acquireHashesAndIndexes(
        selection,
        rangyRange.toString(),
        ref.current,
      )

      if (!selectionData) return

      const fellBackToParent =
        !selection.anchorNode.wholeText && !!selection.anchorNode.textContent
      // contentId is the nearest containing block
      const contentId = fellBackToParent
        ? contextContentId
        : getParentContentId(selection.anchorNode)

      setDialogData({
        contentId,
        contextContentId,
        selectionData,
      })

      setAnchorNode(getAnchorNode(rangyRange))
      setActiveRange(rangyRange)
      highlightRange(rangyRange, colorId)
      setOpen(true)
    },
    [
      addAlert,
      colorId,
      contextContentId,
      disabled,
      onClose,
      studentViewingTeacherAnnotations,
      userIsStudent,
    ],
  )

  useSelectionChangeEvent(handleSelection, ref)

  const handleParentClick = useCallback(
    event => {
      const { target } = event
      if (disabled) return

      const annotation = annotations.find(
        compose(equals(target.getAttribute('data-annotationid')), get('id')),
      )
      if (!annotation) return

      // this is to catch vocab/fun facts popper with annotation, TODO: make this work even with annotation?
      const clickableElement = findAncestor(
        target,
        node => node?.tagName === 'A',
      )
      if (clickableElement) {
        preventDefault(event)
      }

      setActiveAnnotation(annotation)
      setColorLocally(annotation.interactionData.colorId)

      if (
        userIsStudent &&
        isTeacherAnnotation(annotation) &&
        !annotation.interactionData.annotation
      )
        return

      const rangyRange = createRangeForAnnotation(annotation)
      const boundingBox = rangyRange.nativeRange.getBoundingClientRect()
      setAnchorNode({
        range: rangyRange,
        clientWidth: boundingBox.width,
        clientHeight: boundingBox.height,
        getBoundingClientRect: () => boundingBox,
      })
      setActiveRange(rangyRange)
      setDialogData({ ...annotation, ...annotation.interactionData })
      setOpen(true)
    },
    [annotations, disabled, setColorLocally, userIsStudent],
  )

  //  when changing colors, we need to re-highlight the current annotation
  useEffect(() => {
    const range = activeAnnotation
      ? createRangeForAnnotation(activeAnnotation)
      : activeRange
    if (range) {
      highlightRange(range, colorId)
    }
  }, [activeAnnotation, activeRange, colorId])

  useEffect(() => {
    const { current } = ref
    current?.addEventListener('click', handleParentClick)

    return () => {
      current?.removeEventListener('click', handleParentClick)
    }
  }, [handleParentClick])

  return (
    <>
      <div
        {...{ ref, ...rest }}
        data-contentid={contextContentId}>
        {children}
      </div>

      {Boolean(colorId && open && dialogData) && (
        <AnnotationDialog
          {...{
            anchorNode,
            onClose,
            open,
            ...dialogData,
            colorId,
            saveColorId: setColorId,
            setColorId: setColorLocally,
          }}
        />
      )}
    </>
  )
}

Annotatable.propTypes = {
  children: componentShape.isRequired,
  // contextContentId is the main content id for the page (usually a subsection)
  contextContentId: PropTypes.string.isRequired,
}

const WithRenderer = withStyledAnnotations(Annotatable)

export default WithRenderer
