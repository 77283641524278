import { componentShape } from 'core/shapes'
import { createElement } from 'react'
import BackButton from './BackButton'

const CancelButton = ({ children = 'Cancel', ...rest }) =>
  createElement(BackButton, { variant: 'tertiary', ...rest }, children)

CancelButton.propTypes = { children: componentShape }

export default CancelButton
