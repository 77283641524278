import { createSelector } from '@comfy/redux-selectors'
import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import { useDispatch, useSelector } from 'react-redux'
import { actions } from 'reducers/errorLog'

const ErrorBanner = () => {
  const dispatch = useDispatch()
  const { currentMessage } = useSelector(createSelector('errorLog'))

  const handleClose = /* istanbul ignore next */ () =>
    dispatch(actions.clearError())

  return (
    <Snackbar
      autoHideDuration={10000}
      ContentProps={{ 'aria-describedby': 'message-id' }}
      onClose={handleClose}
      open={Boolean(currentMessage)}>
      <Alert
        elevation={6}
        id="message-id"
        severity="error"
        variant="filled">
        {currentMessage}
      </Alert>
    </Snackbar>
  )
}

export default ErrorBanner
