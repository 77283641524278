import ConfirmationDialog from 'common/dialogs/ConfirmationDialog'
import { curryRight, identity, when } from 'fp/utils'
import { forwardRef } from 'react'
import { createConfirmation } from 'react-confirm'

const confirm = createConfirmation(ConfirmationDialog, 0)

interface Props {
  DialogProps?: object
  cancelLabel?: string
  confirmLabel?: string
  confirmationMessage?: string
  confirmationTitle?: string
  onClick: (e: React.MouseEvent) => void
}

const withConfirm = <T, P extends object>(
  WrappedComponent: React.ForwardRefExoticComponent<
    React.PropsWithoutRef<P & Props> & React.RefAttributes<T>
  >,
): React.ForwardRefExoticComponent<
  React.PropsWithoutRef<P & Props> & React.RefAttributes<T>
> => {
  const Enhanced = forwardRef<T, P & Props>((props, ref) => {
    const {
      DialogProps,
      cancelLabel = 'Cancel',
      confirmLabel = 'Yes',
      confirmationMessage = 'Are you sure?',
      confirmationTitle = 'Confirm',
      onClick,
      ...rest
    } = props
    const handleClick = (e: React.FormEvent<HTMLInputElement>) => {
      when(e?.preventDefault, () => e.preventDefault())
      confirm({
        primaryText: confirmationTitle,
        secondaryText: confirmationMessage,
        proceed: curryRight(when, onClick, e),
        cancel: identity,
        confirmLabel,
        cancelLabel,
        ...DialogProps,
      })
    }

    return (
      <WrappedComponent
        {...(rest as React.PropsWithoutRef<P & Props>)}
        onClick={handleClick}
        ref={ref}
      />
    )
  })

  return Enhanced
}

export default withConfirm
