import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import Switch from '@mui/material/Switch'
import { propBagsShape, withPropBags } from '../Form/withPropBags'

const HFSwitch = withPropBags([
  'disabled',
  'error',
  'helperText',
  'indeterminate',
  'label',
  'value',
])(props => {
  const { componentProps, controlProps, rest } = props
  const { disabled, error, helperText, label, value } = controlProps

  return (
    <FormControl
      disabled={disabled}
      error={error}
      {...rest}>
      <FormControlLabel
        control={
          <Switch
            checked={Boolean(value)}
            {...componentProps}
            data-color={componentProps.color}
          />
        }
        label={label}
      />
      {Boolean(helperText?.length) && (
        <FormHelperText data-variant="switch">{helperText}</FormHelperText>
      )}
    </FormControl>
  )
})

HFSwitch.propTypes = propBagsShape.isRequired

export default HFSwitch
