import AppBusy from 'common/indicators/AppBusy'
import useAlert from 'hooks/useAlert'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Banner from 'routing/shells/NavShell/Banner'
import { getLocation } from 'selectors/routing'

const SHELL_STATE_LOADING = 'loading'
const SHELL_STATE_ERROR = 'error'
const SHELL_STATE_READY = 'ready'

const AssessmentLegacyShell = () => {
  const addAlert = useAlert()

  const [shellState, setShellState] = useState(SHELL_STATE_LOADING)
  const iframe = useRef()

  const { pathname } = useSelector(getLocation)

  const navigate = useNavigate()

  const sendState = () => {
    iframe.current?.contentWindow?.postMessage({ pathname })
  }

  useEffect(() => {
    const loadTimeout = setTimeout(() => {
      if (shellState === SHELL_STATE_LOADING) {
        setShellState(SHELL_STATE_ERROR)
      }
    }, 15000)
    return () => clearTimeout(loadTimeout)
  }, [shellState])

  useEffect(() => {
    const handler = ev => {
      if (ev.source === iframe.current?.contentWindow) {
        const { data } = ev
        if (data.ready) {
          setShellState(SHELL_STATE_READY)
          sendState()
        }
        if (data.notification) {
          addAlert({
            message: data.notification.message,
            options: { variant: data.notification.variant },
          })
        }
        if (data.navigate) {
          navigate(data.navigate.route)
        }
      }
    }
    window.addEventListener('message', handler)
    return () => {
      window.removeEventListener('message', handler)
    }
  })

  sendState()

  return (
    <>
      {shellState === SHELL_STATE_ERROR && <p>Failed to load</p>}
      {shellState === SHELL_STATE_LOADING && <AppBusy />}
      {shellState !== SHELL_STATE_ERROR && (
        <iframe
          ref={iframe}
          src="/legacy-shell/#!/stub"
          style={{
            width: '100%',
            height: 'calc(100vh - 110px)',
            border: '0',
            opacity: shellState === SHELL_STATE_READY ? '100%' : '0%',
          }}
          title="Assessment"
        />
      )}
    </>
  )
}

const AssessmentLegacyNavShell = () => (
  <>
    <Banner />
    <AssessmentLegacyShell />
  </>
)

export default AssessmentLegacyNavShell
