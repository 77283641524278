import { getCurrentBlock, mergeBlockData } from '@studysync/draft-js-modifiers'
import { inputVariantShape } from 'core/shapes'
import { set } from 'fp/objects'
import withProps from 'hoc/withProps'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import DraftMenuButton from '../toolbar/DraftMenuButton'
import useBlockInsight from '../utils/useBlockInsight'

const Icon = withProps('span', {
  className: 'material-symbols-outlined',
  style: { paddingRight: 10 },
})

const left = 'left'
const none = 'none'
const right = 'right'

const icons = {
  [left]: <Icon>format_image_left</Icon>,
  [none]: <Icon>calendar_view_day</Icon>,
  [right]: <Icon>format_image_right</Icon>,
}

const options = [
  { label: () => <>{icons.left} Left</>, key: left },
  { label: () => <>{icons.none} Normal (default)</>, key: none },
  { label: () => <>{icons.right} Right</>, key: right },
]

const ContentWrappingDropdown = props => {
  const {
    disabled = false,
    editorState,
    features,
    setEditorState,
    variant,
  } = props

  const blockType = useBlockInsight(editorState)

  const currentBlock = getCurrentBlock(editorState)
  const currentFloat = currentBlock.getData().get('float') || 'none'

  const menuItems = useMemo(
    () => options.map(item => set('selected', item.key === currentFloat)(item)),
    [currentFloat],
  )

  const handleChange = ({ key }) => {
    setEditorState(mergeBlockData(editorState, { float: key }), true)
  }

  return blockType.includes('blockquote') ? (
    <DraftMenuButton
      disabled={disabled}
      editorState={editorState}
      featureMenuKey="extended"
      features={features}
      label="Content wrapping"
      onChange={handleChange}
      options={menuItems}
      variant={variant}>
      <Icon>{icons[currentFloat]}</Icon>
    </DraftMenuButton>
  ) : null
}

ContentWrappingDropdown.propTypes = {
  disabled: PropTypes.bool,
  editorState: PropTypes.object.isRequired,
  features: PropTypes.object.isRequired,
  setEditorState: PropTypes.func.isRequired,
  variant: inputVariantShape.isRequired,
}

export default ContentWrappingDropdown
