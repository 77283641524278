import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import ActionLink from 'common/formControls/buttons/ActionLink'
import ExternalLink from 'common/navigation/links/ExternalLink'
import HelpCenterLink from 'common/navigation/links/HelpCenterLink'
import { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { navDialogsContext } from './NavDialogsProvider'

const StyledList = styled(List, { name: 'ListHover' })(
  ({
    theme: {
      breakpoints,
      palette,
      mixins: { transition },
    },
  }) => ({
    li: {
      'a, button': {
        textDecoration: 'none',
        color: palette.text.secondary,
        '&:hover, &:focus': {
          color: 'inherit !important',
        },
      },
      ...transition(),
      backfaceVisibility: 'hidden',
      '&:hover, &:focus': {
        ...transition(),
        transform: 'scale(1.1)',
        color: palette.cobalt[0],
      },
    },
    justifyContent: 'space-around',
    width: '100%',
    [breakpoints.down('sm')]: {
      display: 'none',
    },
  }),
)

const links = [
  {
    text: 'Community',
    href: '//www.imaginelearning.com/community/',
    Renderer: ExternalLink,
  },
  { text: 'Help Center', href: '#', Renderer: HelpCenterLink },
]

const HomeNav = () => {
  const { pathname } = useLocation()
  const { toggleContactFormDialogIsOpen: showContactForm } =
    useContext(navDialogsContext)

  return (
    pathname === '/' && (
      <StyledList
        color="secondary"
        variant="horizontal">
        {links.map(({ text, Renderer, ...rest }) => (
          <ListItem
            color="secondary"
            component="li"
            key={text}>
            <Renderer {...rest}>
              <Typography variant="nav-item-uppercase-semibold">
                {' '}
                {text}
              </Typography>
            </Renderer>
          </ListItem>
        ))}

        <ListItem
          color="secondary"
          component="li">
          <ActionLink
            onClick={() => {
              showContactForm()
            }}>
            <Typography variant="nav-item-uppercase-semibold">
              Contact
            </Typography>
          </ActionLink>
        </ListItem>
      </StyledList>
    )
  )
}

export default HomeNav
