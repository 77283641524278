import type { User } from 'common/@types/custom'
import type { AssetUpload } from 'common/formControls/textInputs/RichTextEdit2/nodes/ImageNode/@types'
import { uploadShape } from 'core/shapes'
import { formatRelativeTime } from 'locale/i18n'
import { useSelector } from 'react-redux'
import { getUserById } from 'selectors/users'

const Provenance = ({ value }: { value: AssetUpload }) => {
  const { cantoFileName, createdById, dateModified, gdriveFileName, url } =
    value || {}

  const origin = cantoFileName ? 'Canto' : gdriveFileName ? 'Google Drive' : ''
  const originFilename = cantoFileName || gdriveFileName

  const user: User = useSelector(getUserById({ userId: createdById }))

  if (!origin) return null

  return (
    <div>
      {user?.firstName} {user?.lastName} uploaded{' '}
      <a
        href={url}
        rel="noreferrer"
        target="_blank">
        {originFilename}
      </a>{' '}
      from {origin}
      {', '}
      {formatRelativeTime(dateModified)}
    </div>
  )
}

Provenance.propTypes = {
  value: uploadShape,
}

export default Provenance
