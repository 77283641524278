import { CodeHighlightNode, CodeNode } from '@lexical/code'
import { AutoLinkNode, LinkNode } from '@lexical/link'
import { ListItemNode, ListNode } from '@lexical/list'
import { TRANSFORMERS } from '@lexical/markdown'
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin'
import { ListPlugin } from '@lexical/react/LexicalListPlugin'
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { TablePlugin } from '@lexical/react/LexicalTablePlugin'
import { HeadingNode, QuoteNode } from '@lexical/rich-text'
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table'
import { isNotEmptyString } from 'fp/strings'
import { CLEAR_HISTORY_COMMAND, type EditorState } from 'lexical'
import { useEffect, useState } from 'react'
import { ExtendedTextNode } from './nodes/ExtendedTextNode'
import { ImageNode } from './nodes/ImageNode'
import { InteractiveNode } from './nodes/InteractiveNode'
// import { ExtendedTextNode } from './nodes/ExtendedTextNode'
import { SourceTitleNode } from './nodes/SourceTitleNode'
import { StandaloneQuestionNode } from './nodes/StandaloneQuestionNode'
import ImagePlugin from './plugins/ImagePlugin'
import InteractivePlugin from './plugins/InteractivePlugin'
import SourceTitlePlugin from './plugins/SourceTitlePlugin'
import StandaloneQuestionPlugin from './plugins/StandaloneQuestionPlugin'
import ToolbarPlugin from './plugins/ToolbarPlugin'
import TreeViewPlugin from './plugins/TreeViewPlugin'
// import { AdvancedHeadingNode } from './nodes/AdvancedHeadingNode'
import theme from './theme'
// import TableCellActionMenuPlugin from './plugins/TableCellActionMenuPlugin'

// const testBlockData = { body: `
//   <p data-typography=\"feature-paragraph drop-cap\">
//     Archaeologists help us better understand people and societies from the past.
//     Archaeologists study artifacts from history to learn more about how people lived.
//     First, they identify and excavate, or dig, historic sites.
//   </p>
//   <div data-variant=\"interactive\" data-contentid=\"9\"> </div>
//   <p>
//     At these sites, they search for human remains, physical structures, or other objects
//     that people have left behind. Then archaeologists analyze their findings to learn
//     more about how people lived. Archaeologists might examine art to find out about a
//     group’s daily life, or look at tools and weapons to figure out what kind of food
//     they ate. Archaeologists might read ancient documents to determine how people set
//     up their governments or look at ruins to learn about their cities.
//   </p>` }

// Lexical React plugins are React components, which makes them
// highly composable. Furthermore, you can lazy load plugins if
// desired, so you don't pay the cost for plugins until you
// actually use them.
const MyCustomAutoFocusPlugin = () => {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    // Focus the editor when the effect fires!
    editor.focus()
  }, [editor])

  return null
}

export const editorConfig = {
  namespace: 'rich-text-editor',
  theme,
  onError(error: Error) {
    throw error
  },
  nodes: [
    ExtendedTextNode,
    // {
    //   replace: TextNode,
    //   with: (node: TextNode) => new ExtendedTextNode(node.__text, ''),
    //   withKlass: ExtendedTextNode,
    // },
    // ExtendedParagraphNode,
    // {
    //   replace: ParagraphNode,
    //   with: (node: ParagraphNode) => new ExtendedParagraphNode(),
    //   withKlass: ExtendedParagraphNode,
    // },

    CodeHighlightNode,
    CodeNode,
    // EmojiNode,
    HeadingNode,
    LinkNode,
    ListItemNode,
    ListNode,
    QuoteNode,

    // AdvancedHeadingNode,
    AutoLinkNode,
    ImageNode,
    InteractiveNode,
    SourceTitleNode,
    StandaloneQuestionNode,

    TableCellNode,
    TableNode,
    TableRowNode,
  ],
}

const Placeholder = () => (
  <div className="editor-placeholder">Enter some rich text...</div>
)

interface IRichTextEditInternalProps {
  displayDebugger: boolean
  initialBodyJSON: object
  onChange: (editorState: EditorState) => void
}

const RichTextEditInternal = ({
  displayDebugger,
  initialBodyJSON,
  onChange,
}: IRichTextEditInternalProps) => {
  const [editor] = useLexicalComposerContext()
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (loaded) return

    // Use a timeout to avoid synchronous updates within react lifecycle method.
    // The ideal solution is to use useLayoutEffect, but that didn't seem to work here.
    // We receive warnings like:
    //    Warning: flushSync was called from inside a lifecycle method.
    //    React cannot flush when React is already rendering.
    //    Consider moving this call to a scheduler task or micro task
    setLoaded(true)
    setTimeout(() => {
      editor.update(() => {
        if (isNotEmptyString(initialBodyJSON)) {
          editor.setEditorState(
            editor.parseEditorState(JSON.stringify(initialBodyJSON)),
          )
          editor.dispatchCommand(CLEAR_HISTORY_COMMAND, undefined)
        }
      })
    }, 0)
  }, [editor, initialBodyJSON, loaded])

  return (
    <div className="lexile-editor">
      <div className="editor-container">
        <ToolbarPlugin />

        <div className="editor-inner">
          <RichTextPlugin
            contentEditable={<ContentEditable className="editor-input" />}
            ErrorBoundary={LexicalErrorBoundary}
            placeholder={<Placeholder />}
          />

          <AutoFocusPlugin />
          <HistoryPlugin />
          <LinkPlugin />
          <ListPlugin />
          <MarkdownShortcutPlugin transformers={TRANSFORMERS} />

          <MyCustomAutoFocusPlugin />

          <OnChangePlugin onChange={onChange} />
          <ImagePlugin />
          <InteractivePlugin />
          <SourceTitlePlugin />
          <StandaloneQuestionPlugin />

          {/* <TableCellResizer /> */}

          <TablePlugin />

          {Boolean(displayDebugger) && <TreeViewPlugin />}
        </div>
      </div>
    </div>
  )
}

const RichTextEdit = (props: IRichTextEditInternalProps) => (
  <LexicalComposer initialConfig={editorConfig}>
    <RichTextEditInternal {...props} />
  </LexicalComposer>
)

export default RichTextEdit
