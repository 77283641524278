const overrides = ({
  mixins: { borderS, important, rem, transition },
  palette,
}) => ({
  MuiAccordion: {
    defaultProps: {
      disableGutters: true,
      square: true,
    },

    styleOverrides: {
      root: {
        backgroundColor: 'inherit',
        color: 'inherit',
      },
    },

    variants: [
      {
        props: { variant: 'single' },
        style: {
          '.MuiAccordionSummary-root, .MuiAccordionSummary-content': {
            cursor: important('initial'),
          },
          '.MuiAccordionSummary-expandIconWrapper': {
            display: 'none',
          },
        },
      },

      {
        props: { variant: 'form-section' },
        style: {
          '&::before': { opacity: 0 },
          '.MuiCollapse-hidden, .MuiCollapse-entered': transition('padding'),
          '.MuiCollapse-hidden': {
            padding: 0,
          },
          '.MuiCollapse-entered': {
            padding: rem(2, 0, 2),
          },
        },
      },

      {
        props: { variant: 'form-section-dense' },
        style: {
          marginTop: important(0),
        },
      },

      {
        props: { variant: 'collapsed-heading' },
        style: {
          '&::before': { opacity: 0 },
          '.MuiCollapse-hidden, .MuiCollapse-entered': transition('padding'),
          '.MuiCollapse-hidden': {
            padding: 0,
          },
          '.MuiCollapse-entered': {
            padding: rem(2, 0, 2),
          },
        },
      },

      {
        props: { variant: 'interactive-grading' },
        style: {
          '&::before': { opacity: 0 },
        },
      },

      {
        props: { variant: 'peer-review' },
        style: {
          '&::before': { opacity: 0 },
          marginTop: rem(1),
          '.MuiCollapse-entered': {
            ...borderS(),
            borderTop: 'none',
          },
          '[data-disabled="true"]': {
            pointerEvents: 'none',
          },
        },
      },

      {
        props: { variant: 'peer-review', 'data-featured': 'true' },
        style: {
          '.MuiCollapse-entered': {
            ...borderS(palette.canary[0]),
            borderTop: 'none',
          },
        },
      },

      {
        props: { 'data-subvariant': 'single-item' },
        style: {
          '&::before': { opacity: 0 },
          marginTop: rem(1),
          '.MuiCollapse-entered': {
            ...borderS(palette.border[1]),
            borderTop: 'none',
          },
        },
      },

      {
        props: { color: 'primary' },
        style: {
          '.MuiCollapse-entered': {
            borderColor: palette.border[0],
          },
        },
      },
    ],
  },
})

export default overrides
