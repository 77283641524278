import PropTypes from 'prop-types'
import { createContext, useMemo, useState } from 'react'

const a11yContext = createContext()

const A11yProvider = ({ children, initialState }) => {
  const [tabModeEnabled, setTabModeEnabled] = useState(false)

  const value = useMemo(
    () => ({
      setTabModeEnabled,
      tabModeEnabled,
      ...initialState,
    }),
    [initialState, tabModeEnabled],
  )

  return <a11yContext.Provider value={value}>{children}</a11yContext.Provider>
}

A11yProvider.propTypes = {
  children: PropTypes.node.isRequired,
  initialState: PropTypes.object,
}

export { a11yContext, A11yProvider }
