import { compressToEncodedURIComponent } from 'lz-string'
import { isObject } from './objects'

const enDash = String.fromCharCode(8211)
const emDash = String.fromCharCode(8212)
const hyphen = String.fromCharCode(45)

/* ---------------------------- URIs ---------------------------------------- */

export const buildUrl = (uri, data = {}, encode = true) => {
  const builtParams = buildUrlParams(data, encode)
  return uri + (builtParams.length ? `?${builtParams}` : '')
}

const buildUrlParams = (data, encode) =>
  Object.entries(data)
    .map(pair => pair.map(cleanUrlParam(encode)).join('='))
    .join('&')

// these unicode characters were causing the API to crash
const cleanSpecialChars = val =>
  String(val).replaceAll(enDash, hyphen).replaceAll(emDash, hyphen)

export const cleanUrlParam = encode => val =>
  isObject(val) || Array.isArray(val)
    ? encode
      ? compressToEncodedURIComponent(JSON.stringify(val))
      : encodeURI(cleanSpecialChars(JSON.stringify(val)))
    : encodeURI(cleanSpecialChars(val))

export const urlRegex =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/

/* ----------------------------  Misc --------------------------------------- */

export const appendScript = config => {
  const script = document.createElement('script')
  script.type = 'text/javascript'
  document.head.appendChild(Object.assign(script, config))
}

export const isMobile = () => navigator?.maxTouchPoints > 2
