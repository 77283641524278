import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import { decrement, increment } from 'fp/numbers'
import PropTypes from 'prop-types'
import { ArrowLeft, ArrowRight } from 'react-feather'

const Navigation = styled(
  props => {
    const { direction, setCurrentSlide } = props
    const handleClick = () => {
      setCurrentSlide(direction === 'prev' ? decrement : increment)
    }
    return (
      <IconButton
        color="secondary"
        data-bodyvariant="body1"
        data-testid={`carousel-3d-${direction}`}
        onClick={handleClick}
        variant="secondary">
        {direction === 'prev' ? <ArrowLeft /> : <ArrowRight />}
      </IconButton>
    )
  },
  { name: '3d-Navigation' },
)(
  ({
    theme: {
      mixins: { rem, sizeR },
      palette,
    },
  }) => ({
    button: {
      color: palette.accent.main,
      opacity: 0.6,
      margin: rem(3),
      '&:hover': { opacity: 1 },
      svg: sizeR(6),
    },
  }),
)

Navigation.propTypes = {
  direction: PropTypes.oneOf(['prev', 'next']).isRequired,
  setCurrentSlide: PropTypes.func.isRequired,
}

export default Navigation
