import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import Headline from 'common/text/Headline'
import { componentShape } from 'core/shapes'
import withProps from 'hoc/withProps'
import PropTypes from 'prop-types'

const Section = styled(Box, { name: 'FormSection' })(
  ({
    theme: {
      mixins: { borderS, em },
      palette,
      typography: { variants },
    },
  }) => ({
    ...borderS(palette.border[1]),
    borderRadius: em(0.5),
    marginTop: em(2),
    padding: em(1.5),
    '.MuiAccordionSummary-content': {
      ...variants['list-header'],
      fontWeight: 400,
    },
  }),
)

const BoxWrapper = withProps(Box, { my: 4, pb: 4 })

const FormSection = ({ children, title, variant }) => {
  const Wrapper = variant === 'contained' ? Section : BoxWrapper
  return (
    <Wrapper>
      <Headline
        size={3}
        title={title}>
        {children}
      </Headline>
    </Wrapper>
  )
}

FormSection.propTypes = {
  children: componentShape.isRequired,
  title: PropTypes.string.isRequired,
  variant: PropTypes.string,
}

export default FormSection
