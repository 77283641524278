import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import {
  renderLibraryCardCell,
  renderSimpleCardCell,
} from 'common/tables/DataTable/Cells'
import { callWith } from 'fp/call'
import { get, notEquals } from 'fp/objects'
import PropTypes from 'prop-types'
import { compose } from 'redux'

const Table = styled('table', { name: 'interactivePlugin-TableCardRenderer' })({
  height: 'fit-content',
  'th, td': {
    verticalAlign: 'top',
    lineHeight: 1.3,
    paddingBottom: 5,
    paddingRight: 5,
  },
  marginBottom: 10,
})

const notCell = name => compose(notEquals(name), get('column.id'))

const TableCardRenderer = props => {
  const {
    forSystemUse,
    onComplete,
    row,
    row: { original },
  } = props
  const cells = row.getVisibleCells()

  const cols = cells.filter(cell =>
    (forSystemUse ? ['uploadsMap'] : ['assetCode', 'id']).every(
      compose(callWith(cell), notCell),
    ),
  )

  return (
    <div style={{ width: '100%' }}>
      <Stack width="100%">
        <Box
          display="flex"
          gap={3}
          justifyContent="space-between"
          width="100%">
          <Table>
            <tbody>
              {cols.map(
                forSystemUse ? renderSimpleCardCell : renderLibraryCardCell,
              )}
            </tbody>
          </Table>
        </Box>
      </Stack>

      <Box
        mt={1}
        textAlign="center">
        <Button
          aria-label={`insert asset ${original.assetCode} into content`}
          onClick={() => onComplete(original)}
          size="small"
          style={{ width: 100 }}
          variant="primary">
          {forSystemUse === 'content-insertion' ? 'Select' : 'Insert'}
        </Button>
      </Box>
    </div>
  )
}

TableCardRenderer.propTypes = {
  forSystemUse: PropTypes.bool,
  onComplete: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,
}

export default TableCardRenderer
