/** ****************************************************************************
 *                                                                             *
 * This convenience HOC is a lot like `withProps`, but it instead pulls prop   *
 * values from a given context provider.                                       *
 *                                                                             *
 * This can be super useful in cases where you have a generic component that   *
 * can exist within multiple contexts, but still needs info FROM that          *
 * containing context.  It wouldn't be possible to code this into the generic  *
 * component, without needing a `useContext` for every possible provider. This *
 * HOC then could abstract away the need to `useContext` in the first place.   *
 *                                                                             *
 * We should probably only use this for that narrow use-case.  If a component  *
 * is designed to work in a single context, then it should probably continue   *
 * to call `useContext` explicitly.                                            *
 *                                                                             *
 **************************************************************************** */

import { pick } from 'fp/objects'
import { useContext } from 'react'

const withContextProps = (WrappedComponent, context, keys) => {
  const Enhanced = props => {
    const providerProps = useContext(context)
    const mergeProps = pick(keys)(providerProps)

    return <WrappedComponent {...{ ...mergeProps, ...props }} />
  }

  return Enhanced
}

export default withContextProps
