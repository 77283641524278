import MuiSlider from '@mui/material/Slider'
import cl from 'classnames'
import PropTypes from 'prop-types'

const Slider = ({
  className,
  markLabel = false,
  readOnly = false,
  thin = false,
  thumb = 'round',
  thumbVisible = 'always',
  ...rest
}) => (
  <MuiSlider
    className={cl(
      {
        thin,
        pill: thumb === 'pill',
        thinPill: thin && thumb === 'pill',
        thumbHover: thumbVisible === 'hover',
        noThumb: thumbVisible === 'never',
        readOnly,
        markLabel,
      },
      className,
    )}
    {...rest}
  />
)

Slider.propTypes = {
  markLabel: PropTypes.bool,
  readOnly: PropTypes.bool,
  thin: PropTypes.bool,
  thumb: PropTypes.oneOf(['round', 'pill']),
  thumbVisible: PropTypes.oneOf(['always', 'hover', 'never']),
}
export default Slider
