import { HANDLED, NOT_HANDLED } from '@studysync/draft-js-modifiers'
import {
  EditorState,
  Modifier,
  RichUtils,
  SelectionState,
  genKey,
} from 'draft-js'
import { last } from 'fp/arrays'
import { isDefined } from 'fp/utils'
import { Map as immutableMap } from 'immutable'
import { toolbarPropTypes } from '../../utils/misc'
import InsertTableButton from './InsertTableButton'
import TableEditor from './TableEditor'
import { buildHtmlForBlockText } from './utils'

const escapeAttrib = s => (isDefined(s) ? String(s).replaceAll('"', '”') : '')

const store = {}

let importingTable = false
let importingTableKey

const blockRendererFn = (
  contentBlock,
  { getEditorState, onChange, readOnly, setPluginHasFocus },
) => {
  if (contentBlock.getType() === 'table') {
    return {
      component: TableEditor,
      editable: true,
      props: {
        getEditorState,
        onChange,
        readOnly,
        setPluginHasFocus,
      },
    }
  }

  return undefined
}

const blockToHTML =
  () =>
  (current, { data, type }, editorState) => {
    if (type === 'table') {
      const contentState = editorState.getCurrentContent()

      const {
        caption: captionPrime,
        tableKey,
        tableShape,
        title: titlePrime,
      } = data

      if (!tableShape) {
        /**
         * A side effect of using the draft-convert package is that we come through
         * here an extra time for each cell block and also for the tbody, but we
         * don't want to output anything at all for those.
         *
         * We have to return 𝘴𝘰𝘮𝘦𝘵𝘩𝘪𝘯𝘨, so we'll return a null character.  The API
         * can't handle those, so we'll later remove all null characters in our
         * exportingPostProcess method.
         */
        return String.fromCharCode(0)
      }

      const tableBlocks = contentState
        .getBlockMap()
        .skipUntil(
          v =>
            v.getType() === 'table' && v.getData().get('tableKey') === tableKey,
        )
        .takeWhile(v => v.getType() === 'table')
        .toList()

      let cellCounter = 0

      const caption = escapeAttrib(captionPrime)
      const title = escapeAttrib(titlePrime)

      return `<table data-caption="${caption || ''}" data-title="${
        title || ''
      }"><tbody>${tableShape
        .map(
          (row, i) =>
            `<tr>${row
              .map((cell, j) => {
                const { alignment: origAlignment, element } = cell
                let cellBlock = tableBlocks.get(cellCounter)

                const alignment =
                  (origAlignment === 'left' && element === 'td') ||
                  (origAlignment === 'center' && element === 'th')
                    ? null
                    : origAlignment

                const [, rowNum, colNum] =
                  cellBlock?.getData().get('tablePosition')?.split('-') ?? []
                if (i !== +rowNum || j !== +colNum) {
                  cellBlock = null
                } else {
                  cellCounter += 1
                }
                return `<${element}${
                  alignment ? ` data-align="${alignment}"` : ''
                }>${buildHtmlForBlockText(
                  '',
                  cellBlock,
                  contentState,
                )}</${element}>`
              })
              .join('')}</tr>`,
        )
        .join('')}</tbody></table>`
    }

    return current
  }

const exportingPostProcess = () => output =>
  output.replaceAll(String.fromCharCode(0), '')

const handleReturn =
  (/* features */) =>
  ({ setEditorState }) =>
  (_, editorState) => {
    const currentBlockType = RichUtils.getCurrentBlockType(editorState)
    if (currentBlockType === 'table') {
      setEditorState(RichUtils.insertSoftNewline(editorState))
      return HANDLED
    }
    return NOT_HANDLED
  }

// biome-ignore lint/complexity/noExcessiveCognitiveComplexity: dead code walking
const htmlToBlock = () => (current, nodeName, node) => {
  let alignment

  if (nodeName === 'table') {
    if (importingTable) return current
    importingTable = true
    importingTableKey = genKey()

    store[importingTableKey] = {
      caption: node.getAttribute('data-caption'),
      cellIdx: 0,
      rowIdx: 0,
      shape: [],
      title: node.getAttribute('data-title'),
    }
  }

  if (nodeName === 'tbody') {
    store[importingTableKey].rowIdx = -1
  }

  if (nodeName === 'tr') {
    store[importingTableKey].rowIdx += 1
    store[importingTableKey].cellIdx = -1
    store[importingTableKey].shape.push([])
  }

  if (nodeName === 'th') {
    store[importingTableKey].cellIdx += 1
    alignment = node.getAttribute('data-align')
    last(store[importingTableKey].shape).push({
      element: 'th',
      ...(alignment ? { alignment } : null),
    })
  }

  if (nodeName === 'td') {
    store[importingTableKey].cellIdx += 1
    alignment = node.getAttribute('data-align')
    last(store[importingTableKey].shape).push({
      element: 'td',
      ...(alignment ? { alignment } : null),
    })
  }

  if (['th', 'td'].includes(nodeName)) {
    return {
      type: 'table',
      data: {
        caption: store[importingTableKey].caption,
        title: store[importingTableKey].title,
        tableKey: importingTableKey,
        tablePosition: `${importingTableKey}-${store[importingTableKey].rowIdx}-${store[importingTableKey].cellIdx}`,
      },
    }
  }

  return current
}

const importingPostProcessTable = tableKey => editorState => {
  const contentState = editorState.getCurrentContent()
  const blocks = contentState.getBlocksAsArray()

  const block = blocks.find(
    b => b.getData().get('tablePosition') === `${tableKey}-0-0`,
  )

  if (block) {
    const key = block.getKey()

    const data = new immutableMap({
      caption: store[tableKey].caption,
      tableKey,
      tablePosition: `${tableKey}-0-0`,
      tableShape: store[tableKey].shape,
      title: store[tableKey].title,
    })

    const selection = SelectionState.createEmpty(key)

    const newContentState = Modifier.setBlockData(
      editorState.getCurrentContent(),
      selection,
      data,
    )

    return EditorState.push(editorState, newContentState, 'change-block-data')
  }

  return editorState
}

const importingPostProcess = () => editorState => {
  if (!importingTable) return editorState

  importingTable = false
  let result = editorState

  for (const tableKey of Object.keys(store)) {
    result = importingPostProcessTable(tableKey)(editorState)
  }

  return result
}

const ToolBarItem = ({ features }) =>
  features.tables ? [InsertTableButton] : []

ToolBarItem.propTypes = toolbarPropTypes

const tablePlugin = {
  blockRendererFn,
  blockToHTML,
  exportingPostProcess,
  handleReturn,
  htmlToBlock,
  importingPostProcess,
  ToolBarItem,
}

export default tablePlugin
