import SplitterLayout from '@studysync/react-splitter-layout'
import PropTypes from 'prop-types'
import { createElement } from 'react'

const SplitPane = props => {
  const {
    children = [],
    customClassName = '',
    enabled = true,
    keyboardStep = 5,
    primaryIndex = 0,
    primaryMinSize = 0,
    secondaryMinSize = 0,
    vertical = false,
    ...rest
  } = props

  return createElement(
    SplitterLayout,
    {
      ...rest,
      customClassName,
      enabled,
      keyboardStep,
      primaryMinSize,
      primaryIndex,
      secondaryMinSize,
      vertical,
    },
    children,
  )
}

SplitPane.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
  customClassName: PropTypes.string,
  enabled: PropTypes.bool,
  keyboardStep: PropTypes.number,
  onDragEnd: PropTypes.func,
  onDragStart: PropTypes.func,
  onSecondaryPaneSizeChange: PropTypes.func,
  primaryIndex: PropTypes.number,
  primaryMinSize: PropTypes.number,
  secondaryInitialSize: PropTypes.number,
  secondaryMaxSize: PropTypes.number,
  secondaryMinSize: PropTypes.number,
  splitterProps: PropTypes.object,
  vertical: PropTypes.bool,
}

export default SplitPane
