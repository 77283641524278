import Icon from '@mui/icons-material/Lock'
import { size } from 'polished'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Headline from 'common/text/Headline'
import { isEmptyString } from 'fp/strings'
import { Link } from 'react-router-dom'

const Route403 = ({ collectionName }) => (
  <Container
    sx={{ my: 10 }}
    variant="centered">
    <Icon style={size('15rem')} />
    <Headline
      mb={1}
      mt={1}
      style={{ fontSize: '8rem' }}
      title="403"
      weight={100}>
      <Headline
        mt={1}
        title="Permission Denied"
      />

      <Box
        component="p"
        mb={0}>
        You do not have sufficient privileges to view this content
      </Box>

      <Box
        component="p"
        margin={0}>
        Go back or return to the <Link to="/">home page</Link>.
      </Box>

      <Typography
        color="textSecondary"
        style={{ opacity: 0.5 }}
        variant="caption">
        {isEmptyString(collectionName) ? null : `collection: ${collectionName}`}
      </Typography>
    </Headline>
  </Container>
)

Route403.propTypes = {
  collectionName: PropTypes.string,
}

export default Route403
