import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { useRef } from 'react'

import { CONTENT_TYPE_CHAPTER } from 'core/consts'
import { Route, Routes } from 'react-router-dom'
import Actions from './Actions'
import Header from './Header'
import Panes from './Panes'

// Nav links use relative paths for navigation.
// If we're looking at a chapter now, then we want those paths to be relative to the chapter, not to `/content-viewer`.
// But if we're looking at something else, e.g. an assignment, then they should be relative to the current route.
const routePaths = [`${CONTENT_TYPE_CHAPTER}/:contentId/*`, '*']

const Nav = () => {
  const actionsRef = useRef()

  return (
    <Stack gap={8}>
      <Box
        sx={{
          color: 'common.white',
          flexGrow: 1,
        }}>
        <Routes>
          {routePaths.map(path => (
            <Route
              element={
                <>
                  <Header />
                  <Panes actionsRef={actionsRef} />
                </>
              }
              key={path}
              path={path}
            />
          ))}
        </Routes>
      </Box>

      <Actions ref={actionsRef} />
    </Stack>
  )
}

export default Nav
