import { EditorBlock } from 'draft-js'
import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'
import PluginInput from '../helpers/PluginInput'

const TableEditor = props => {
  const {
    block,
    blockProps,
    blockProps: { onChange, getEditorState },
  } = props

  const data = block.getData()
  const tableKey = data.get('tableKey')
  const tableShape = data.get('tableShape')
  const position = data.get('tablePosition')
  if (position && !tableShape) {
    // Rendering a cell. Portal an edit block into it's location
    const target = document.querySelector(`[data-position='${position}']`)

    return target ? createPortal(<EditorBlock {...props} />, target) : null
  }

  if (Array.isArray(tableShape)) {
    // Rendering the first cell (aka the table itself)

    return (
      <table id={tableKey}>
        <caption>
          <PluginInput
            block={block}
            blockProps={blockProps}
            component="input"
            dataField="title"
            getEditorState={getEditorState}
            placeholder="Name/Title"
            setEditorState={onChange}
          />
          <PluginInput
            block={block}
            blockProps={blockProps}
            component="input"
            dataField="caption"
            getEditorState={getEditorState}
            placeholder="Caption"
            setEditorState={onChange}
          />
        </caption>

        <tbody>
          {tableShape.map((row, i) => (
            <tr key={i}>
              {row.map(({ alignment, element }, j) => {
                const cellProps = {
                  'data-position': `${tableKey}-${i}-${j}`,
                  style: {
                    textAlign:
                      alignment || (element === 'th' ? 'center' : 'left'),
                  },
                }

                return element === 'th' ? (
                  <th
                    key={`${i}-${j}`}
                    {...cellProps}>
                    {Boolean(i === 0 && j === 0) && <EditorBlock {...props} />}
                  </th>
                ) : (
                  <td
                    key={`${i}-${j}`}
                    {...cellProps}>
                    {Boolean(i === 0 && j === 0) && <EditorBlock {...props} />}
                  </td>
                )
              })}
            </tr>
          ))}
        </tbody>
      </table>
    )
  }

  return <EditorBlock {...props} />
}

TableEditor.propTypes = {
  block: PropTypes.object,
  blockProps: PropTypes.object,
}

export default TableEditor
