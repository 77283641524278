import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import { styled } from '@mui/material/styles'
import Dialog from 'common/dialogs/Dialog'
import BusySpinner from 'common/indicators/BusySpinner'
import Warning from 'common/layout/Warning'
import { uploadTypeShape } from 'core/shapes'
import { isEmptyString } from 'fp/strings'
import { isDefined } from 'fp/utils'
import PropTypes from 'prop-types'
import { isTestEnv } from 'selectors/index'
import AssetPreviewer from '../AssetPreviewer'
import Metadata from './Metadata'

const Preview = styled('div', { name: 'CantoImporter-ImportDialog' })(
  ({
    theme: {
      mixins: { absHeight },
    },
  }) => ({
    textAlign: 'center',
    'audio, img, video': {
      width: 'unset',
      minWidth: 'unset',
      maxWidth: '100%',
      ...absHeight(150),
    },
  }),
)

const ImportDialog = props => {
  const {
    busy,
    disableFieldSelection = false,
    errorMessage,
    metadataAssignments,
    onClose,
    onComplete,
    open,
    rawUpload,
    setMetadataAssignments,
    uploadType,
  } = props

  return (
    <Dialog
      {...{ onClose, open }}
      showCloseButton
      title="Import From Canto"
      variant="maximized-vertically">
      <DialogContent sx={{ overflow: 'hidden' }}>
        {Boolean(busy) && <BusySpinner size={48} />}

        {Boolean(rawUpload) && (
          <Preview>
            <AssetPreviewer
              uploadType={uploadType}
              value={rawUpload}
            />
          </Preview>
        )}

        {Boolean(isDefined(rawUpload) && !disableFieldSelection) && (
          <Metadata
            {...{ metadataAssignments, rawUpload, setMetadataAssignments }}
          />
        )}

        {!isEmptyString(errorMessage) && <Warning>{errorMessage}</Warning>}
      </DialogContent>

      <DialogActions>
        <Button
          color="secondary"
          onClick={onClose}
          variant="secondary">
          Cancel
        </Button>

        <Button
          color="secondary"
          disabled={busy || !isEmptyString(errorMessage)}
          onClick={onComplete}
          variant="primary">
          Import
        </Button>

        {Boolean(isTestEnv()) && (
          <Button onClick={onComplete}>Test Import</Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

ImportDialog.propTypes = {
  busy: PropTypes.bool.isRequired,
  disableFieldSelection: PropTypes.bool,
  errorMessage: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  metadataAssignments: PropTypes.object.isRequired,
  rawUpload: PropTypes.object,
  setMetadataAssignments: PropTypes.func.isRequired,
  uploadType: uploadTypeShape.isRequired,
}

export default ImportDialog
