import Typography from '@mui/material/Typography'
import { flexRender } from '@tanstack/react-table'
import cl from 'classnames'
import { chunk } from 'fp/arrays'
import { get } from 'fp/objects'
import PropTypes from 'prop-types'
import {
  filterCellHeaders,
  filterDetailColumns,
  filterDetailToggle,
  filterNonIntrinsicColumns,
  filterTopLevelColumns,
  isIntrinsic,
} from '../Cells'

const DetailRow = ({ rowClassName, row }) => {
  const allCells = row.getAllCells()
  const topLevelCells = allCells.filter(filterTopLevelColumns)
  const detailCells = allCells.filter(filterDetailColumns)
  const togglerCell = allCells.filter(filterDetailToggle)[0]
  const headerCells = allCells.filter(filterCellHeaders)
  const headerIds = headerCells.map(get('id'))

  const useableCellCount = topLevelCells.filter(
    filterNonIntrinsicColumns,
  ).length

  const rows = chunk(useableCellCount)(detailCells)

  let cellIdx = 0

  return rows.map((_, rowIdx) => {
    const isFirstRow = rowIdx === 0
    const isLastRow = rowIdx === rows.length - 1
    const spanAllRows = false // TODO: driven by media-query
    const cellClassName = isLastRow || spanAllRows ? 'detail-rows-end' : null

    return (
      <tr
        className={cl('detail-row', rowClassName)}
        key={`${row.id}-${rowIdx}`}>
        {topLevelCells.map(tlc => {
          if (isIntrinsic(tlc)) {
            return isFirstRow ? (
              <td
                className={cl('detail-rows-end', cellClassName)}
                headers={[...headerIds, togglerCell.column.id].join(' ')}
                key={`${rowIdx}-${tlc.id}`}
                rowSpan={rows.length}>
                {tlc.column.columnDef.detailCell
                  ? flexRender(
                      tlc.column.columnDef.detailCell,
                      tlc.getContext(),
                    )
                  : null}
              </td>
            ) : null
          }

          const cell = detailCells[cellIdx]
          cellIdx += 1

          return (
            <td
              className={cellClassName}
              headers={[...headerIds, togglerCell.column.id].join(' ')}
              key={`${rowIdx}-${cellIdx}`}>
              {!!cell && (
                <>
                  <Typography
                    component="div"
                    variant="attribute-name">
                    {flexRender(
                      cell.column.columnDef.header,
                      cell.getContext(),
                    )}
                  </Typography>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </>
              )}
            </td>
          )
        })}
      </tr>
    )
  })
}

DetailRow.propTypes = {
  row: PropTypes.object.isRequired,
  rowClassName: PropTypes.string,
}

export default DetailRow
