import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { useTheme } from '@mui/material/styles'
import { annotationShape } from 'core/shapes'
import { set } from 'fp/objects'
import { matches } from 'fp/utils'
import useToggleState from 'hooks/useToggleState'
import Bucket from 'hss/images/controls/video/bucket.svg'
import CheckCircle from 'hss/images/controls/video/check-circle.svg'
import { formatSec } from 'locale/i18n'
import PropTypes from 'prop-types'
import { Circle, Trash } from 'react-feather'

const AnnotationHeader = props => {
  const {
    annotation,
    annotation: { colorId: currentColorId, time },
    handleRemove,
    id,
    update,
  } = props
  const [showColors, toggleShowColors] = useToggleState(false)
  const {
    palette: { annotations: colors },
  } = useTheme()

  const handleColorChange = colorId => {
    update(set('colorId', colorId)(annotation), id)
    toggleShowColors()
  }

  const currentColor = colors.find(matches('colorId', currentColorId))

  return (
    <Box
      display="flex"
      mb={0.5}
      mt={3.5}>
      <Box
        alignSelf="center"
        flexGrow="1"
        textAlign="left">
        {formatSec(time)}
      </Box>
      <Box sx={{ border: '1px solid', borderColor: 'border.1' }}>
        {Boolean(showColors) &&
          colors.map(({ colorId, value, label }) => (
            <Tooltip
              key={colorId}
              title={`Color: ${label}`}>
              <IconButton
                onClick={() => handleColorChange(colorId)}
                style={{ padding: 0 }}>
                {colorId === currentColorId ? (
                  <CheckCircle style={{ color: value, fill: value }} />
                ) : (
                  <Circle style={{ color: value, fill: value }} />
                )}
              </IconButton>
            </Tooltip>
          ))}
        <Tooltip title={`Color: ${currentColor.label}`}>
          <IconButton
            data-testid="show-colors-btn"
            onClick={toggleShowColors}
            style={{ marginLeft: 5, padding: 0 }}>
            <Bucket style={{ color: currentColor.value }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Remove annotation">
          <IconButton
            data-testid="remove-row-btn"
            onClick={handleRemove}
            style={{ padding: 0 }}>
            <Trash />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  )
}

AnnotationHeader.propTypes = {
  annotation: annotationShape.isRequired,
  handleRemove: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  update: PropTypes.func.isRequired,
}

export default AnnotationHeader
