import { CONTENT_STATE_DRAFT, CONTENT_TYPE_SOURCE } from 'core/consts'
import { contentShape } from 'core/shapes'
import PropTypes from 'prop-types'
import Item from './Item'

const Sections = ({ sections }) =>
  sections.map(section => (
    <Item
      data={section.data}
      data-draft={
        section.contentType === CONTENT_STATE_DRAFT ? 'true' : 'false'
      }
      item={section}
      key={section.id}
      nodeId={section.id}
      parentContent={section}>
      {section.children.map(child =>
        child.contentType === CONTENT_TYPE_SOURCE ? (
          <Sections
            key={child.id}
            sections={[child]}
          />
        ) : (
          <Item
            data-draft={
              child.contentType === CONTENT_STATE_DRAFT ? 'true' : 'false'
            }
            item={child}
            key={child.id}
            nodeId={child.id}
            parentContent={section}
          />
        ),
      )}
    </Item>
  ))

Sections.propTypes = {
  sections: PropTypes.arrayOf(contentShape).isRequired,
}

export default Sections
