import Button from '@mui/material/Button'
import { uploadTypeShape } from 'core/shapes'
import { noop } from 'fp/utils'
import useReduxPromise from 'hooks/useReduxPromise'
import useToggleState from 'hooks/useToggleState'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import actionTypes from 'reducers/actionTypes'
import RequestValueDialog from '../RequestValueDialog'
import ImportDialog from './ImportDialog'

const fieldAssignmentsDefaults = {
  caption: 'assetDescription',
  altTextName: 'headline',
  longTextName: 'imageDescription',
}

const CantoImporter = props => {
  const {
    assetCode,
    disableFieldSelection = false,
    disabled: busy = false,
    onCantoFields,
    onComplete,
    previousCantoId,
    setBusy = noop,
    uploadType,
  } = props

  const [cantoId, setCantoId] = useState(previousCantoId || assetCode || '')
  const [importDialogVisible, toggleImportDialogVisible] = useToggleState(false)
  const [requestValueDialogVisible, toggleRequestValueDialogVisible] =
    useToggleState(false)
  const startUpload = useReduxPromise(actionTypes.CANTO_IMPORT_INITIATE)
  const [rawUpload, setRawUpload] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [metadataAssignments, setMetadataAssignments] = useState(
    fieldAssignmentsDefaults,
  )

  useEffect(() => {
    if (requestValueDialogVisible) setErrorMessage(undefined)
  }, [requestValueDialogVisible])

  const handleStart = value => {
    setBusy(true)
    setRawUpload(undefined)
    setMetadataAssignments(fieldAssignmentsDefaults)
    setErrorMessage(undefined)

    startUpload({ cantoId: value, uploadType })
      .then(({ response }) => {
        toggleRequestValueDialogVisible()
        if (onCantoFields) {
          setRawUpload(response)
          toggleImportDialogVisible()
        } else {
          onComplete(response)
        }
        setErrorMessage(undefined)
      })
      .catch(({ error }) => {
        setErrorMessage(error.message)
      })
      .finally(() => {
        setBusy(false)
      })
  }

  const handleCantoIdChange = value => {
    setCantoId(value)
    handleStart(value)
  }

  const handleFieldsComplete = () => {
    toggleImportDialogVisible()
    onCantoFields(rawUpload, metadataAssignments)
    onComplete(rawUpload)
  }

  return (
    <>
      <Button
        onClick={toggleRequestValueDialogVisible}
        size="small"
        sx={{ textTransform: 'none' }}
        variant="secondary">
        Import from Canto...
      </Button>

      <RequestValueDialog
        affirmativeButtonLabel="Continue"
        busy={busy}
        errorMessage={errorMessage}
        label="Canto ID"
        onClose={toggleRequestValueDialogVisible}
        onComplete={handleCantoIdChange}
        open={requestValueDialogVisible}
        title="Import from Canto"
        value={cantoId}
      />

      <ImportDialog
        {...{
          busy,
          disableFieldSelection,
          errorMessage,
          metadataAssignments,
          rawUpload,
          setMetadataAssignments,
          uploadType,
        }}
        onClose={toggleImportDialogVisible}
        onComplete={handleFieldsComplete}
        open={importDialogVisible}
      />
    </>
  )
}

CantoImporter.propTypes = {
  assetCode: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  disableFieldSelection: PropTypes.bool,
  onCantoFields: PropTypes.func,
  onComplete: PropTypes.func.isRequired,
  previousCantoId: PropTypes.string,
  setBusy: PropTypes.func,
  uploadType: uploadTypeShape.isRequired,
}

export default CantoImporter
