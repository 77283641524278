import { componentShape } from 'core/shapes'
import { forwardRef } from 'react'
import HFRadioGroup from './HFRadioGroup'

const HFHorizontalRadioGroup = forwardRef((props, ref) => (
  <HFRadioGroup
    {...props}
    fullWidth
    margin="dense"
    outlined
    ref={ref}
    row
  />
))

HFHorizontalRadioGroup.propTypes = {
  children: componentShape,
}

export default HFHorizontalRadioGroup
