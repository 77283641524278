import Icon from '@mui/icons-material/SentimentVeryDissatisfied'
import { size } from 'polished'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Headline from 'common/text/Headline'
import { isEmptyString } from 'fp/strings'
import { Link } from 'react-router-dom'

const Route404 = ({ collectionName }) => (
  <Container variant="centered">
    <Icon style={size('15rem')} />
    <Headline
      mb={1}
      mt={1}
      style={{ fontSize: '8rem' }}
      title="404"
      weight={100}>
      <Headline
        mt={1}
        title="Page not found"
      />

      <Box
        component="p"
        mb={0}>
        The page you are looking for doesn't exist
      </Box>

      <Box
        component="p"
        margin={0}>
        Go back or return to the <Link to="/">home page</Link>.
      </Box>

      <Typography
        color="textSecondary"
        style={{ opacity: 0.5 }}
        variant="caption">
        {isEmptyString(collectionName) ? null : `collection: ${collectionName}`}
      </Typography>
    </Headline>
  </Container>
)

Route404.propTypes = {
  collectionName: PropTypes.string,
}

export default Route404
