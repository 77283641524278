import { withOptions } from '@comfy/redux-selectors'
import { distill } from 'fp/call'
import { get } from 'fp/objects'
import { isUndefined } from 'fp/utils'
import userAbilityConfig from 'hss/userAbilityConfig'
import { Maybe } from 'monet'
import { matchPath } from 'react-router-dom'
import { createSelector, stateRouter, stateSession } from '.'
import { getDecoratedUser } from './users'

const privilegeChecker =
  ({ user, school }) =>
  ([key, { subPrivilege }]) => {
    const userPrivileges = get('userPrivileges', { fallback: [] })(user)
    const currentDistrictId = get('districtId')(user)
    const currentSchoolId = school?.id

    const privilege = userPrivileges.find(p => {
      const privMatches = p.privilege === key
      const subPrivMatches = !subPrivilege || p.config?.[subPrivilege]
      return privMatches && subPrivMatches
    })

    if (!privilege) return false

    /**
     * TODO:  We currently have no use-cases for either schoolId or districtId.
     *
     * We'll want to redress this when/if the need arises.
     */

    const { districtId, schoolId } = privilege.config || {}

    return (
      /* istanbul ignore next line */ (isUndefined(schoolId) ||
        schoolId === currentSchoolId) &&
      (isUndefined(districtId) || districtId === currentDistrictId)
    )
  }

const createPrivilegesChecker = privilegesConfig => {
  const privileges = get('privileges', { fallback: {} })(privilegesConfig)

  return args =>
    Object.entries(privileges)
      .filter(([, value]) => !!value)
      .some(privilegeChecker(args))
}

const createExclusionChecker = (privilegesConfig, key) => {
  const paths = privilegesConfig[key]

  if (!paths) return false
  // An empty array has differing meanings depending on the key.
  // For "only when", an empty array will exclude all matches
  if (!paths.length) {
    return key === 'onlyWhenRouteMatches'
  }

  return ({ location: { pathname } }) => {
    const result = paths.some(path => !!matchPath(path, pathname))

    return key === 'onlyWhenRouteMatches' ? !result : result
  }
}

const createAbilityFlagChecker = createSelector('createAbilityFlagChecker')(
  getDecoratedUser,
  stateSession,
  stateRouter,
  ({ user = {}, school }, session, { location }) => {
    const { roleId } = user

    const args = {
      location,
      school,
      session,
      user,
    }

    const allowed = abilityFlag => {
      // istanbul ignore next line
      if (isUndefined(abilityFlag)) return false

      // TODO: This is a placeholder for when we have multiple applications
      // We might pull the id from userAbilityConfig, or it may come from session
      // as it did in v3.
      // const { applicationId } = session
      const applicationId = 'hss'

      const lookupKeys = [
        `roles.${roleId}`,
        'privileges',
        `applications.${applicationId}`,
        'custom',
        'default',
      ]

      const flagConfig = userAbilityConfig[abilityFlag]

      return Maybe.fromUndefined(flagConfig)
        .map(fc =>
          lookupKeys
            .map(key =>
              key === 'privileges' ? createPrivilegesChecker(fc) : get(key)(fc),
            )
            .map(check => distill(check, args))
            .some(Boolean),
        )
        .orLazy(() => {
          if (
            !(
              Object.keys(userAbilityConfig).includes(abilityFlag) ||
              String(abilityFlag).startsWith('ff-')
            )
          ) {
            // biome-ignore lint/suspicious/noConsole: intended
            console.warn(
              `UNKNOWN ACCESS FLAG (ignore if feature flag): ${JSON.stringify(abilityFlag)}\n`,
            )
          }
          return false
        })
    }

    const revoked = abilityFlag => {
      // istanbul ignore next line
      if (isUndefined(abilityFlag)) return false

      const flagConfig = userAbilityConfig[abilityFlag]

      return Maybe.fromUndefined(flagConfig?.exclusions)
        .map(fc =>
          Object.keys(fc)
            .map(key => createExclusionChecker(fc, key))
            .map(check => distill(check, args))
            .some(Boolean),
        )
        .orLazy(() => false)
    }

    return flagOrFlags => {
      const flags = [flagOrFlags].flat()

      return (
        !flags.length ||
        (flags.map(allowed).some(Boolean) && !flags.map(revoked).some(Boolean))
      )
    }
  },
)

/** ****************************************************************************
 *                                                                             *
 *                           createAbilityChecker                              *
 *                                                                             *
 * Use this selector when you want to receive a callback function that can     *
 * then be used multiple times.                                                *
 *                                                                             *
 * If you just need a quick, one-time check, then utilize `abilityCheck`       *
 * instead                                                                     *
 *                                                                             *
 ***************************************************************************** */
export const createAbilityChecker = createSelector('createAbilityChecker')(
  createAbilityFlagChecker,
)

/** ****************************************************************************
 *                                                                             *
 *                               abilityCheck                                  *
 *                                                                             *
 * Pass this a single ability or an array of abilities and it will return      *
 * true if the user has access to any of them                                  *
 *                                                                             *
 * If you need to call this multiple times then consider using                 *
 * `createAbilityChecker` instead.                                             *
 *                                                                             *
 ***************************************************************************** */

export const abilityCheck = withOptions((...abilityFlagOrFlags) =>
  createSelector('abilityCheck')(createAbilityChecker, abilityChecker =>
    [...abilityFlagOrFlags].flat().some(abilityChecker),
  ),
)
