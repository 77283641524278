import {
  DecoratorBlockNode,
  type SerializedDecoratorBlockNode,
} from '@lexical/react/LexicalDecoratorBlockNode'
import {
  $applyNodeReplacement,
  type ElementFormatType,
  type LexicalNode,
  type NodeKey,
} from 'lexical'
import { Suspense, lazy } from 'react'

interface ISerializedStandaloneQuestionNode
  extends SerializedDecoratorBlockNode {
  title1: string
  title2: string
  subtitle: string
  alignment: 'left' | 'center' | 'right'
  multiline: boolean
  divider: boolean
}

const Decorator = lazy(() => import('./Decorator'))

export class StandaloneQuestionNode
  extends DecoratorBlockNode
  implements IUpdatable
{
  __alignment: 'left' | 'center' | 'right'

  __divider: boolean

  __multiline: boolean

  __subtitle: string

  __title1: string

  __title2: string

  constructor(format?: ElementFormatType, key?: NodeKey) {
    super(format, key)
    this.__alignment = 'center'
    this.__divider = true
    this.__multiline = false
    this.__subtitle = ''
    this.__title1 = 'Standalone'
    this.__title2 = 'Question'
  }

  static getType() {
    return 'standalone-question'
  }

  static clone(node: StandaloneQuestionNode) {
    const result = new StandaloneQuestionNode(node.__format, node.__key)

    result.__alignment = node.__alignment
    result.__divider = node.__divider
    result.__multiline = node.__multiline
    result.__subtitle = node.__subtitle
    result.__title1 = node.__title1
    result.__title2 = node.__title2

    return result
  }

  static importJSON(json: ISerializedStandaloneQuestionNode) {
    const node = new StandaloneQuestionNode(json.format)
    node.__alignment = json.alignment
    node.__divider = json.divider
    node.__multiline = json.multiline
    node.__subtitle = json.subtitle
    node.__title1 = json.title1
    node.__title2 = json.title2
    return node
  }

  exportJSON(): ISerializedStandaloneQuestionNode {
    return {
      ...super.exportJSON(),
      type: this.getType(),
      version: 1,

      alignment: this.__alignment,
      divider: this.__divider,
      multiline: this.__multiline,
      subtitle: this.__subtitle,
      title1: this.__title1,
      title2: this.__title2,
    }
  }

  decorate() {
    return (
      <Suspense fallback={null}>
        <Decorator
          alignment={this.__alignment}
          divider={this.__divider}
          multiline={this.__multiline}
          node={this}
          nodeKey={this.getKey()}
          subtitle={this.__subtitle}
          title1={this.__title1}
          title2={this.__title2}
        />
      </Suspense>
    )
  }

  updateField({ field, value }: OnChangeArgs) {
    const { name } = field
    const writable = this.getWritable()
    if (name === 'alignment') {
      writable.__alignment = value as 'left' | 'center' | 'right'
    } else if (name === 'divider') {
      writable.__divider = value as boolean
    } else if (name === 'multiline') {
      writable.__multiline = value as boolean
    } else if (name === 'subtitle') {
      writable.__subtitle = value as string
    } else if (name === 'title1') {
      writable.__title1 = value as string
    } else if (name === 'title2') {
      writable.__title2 = value as string
    }
  }
}

export function $isStandaloneQuestionNode(node: LexicalNode): boolean {
  return node instanceof StandaloneQuestionNode
}

export function $createStandaloneQuestionNode(): LexicalNode {
  const node = new StandaloneQuestionNode()

  return $applyNodeReplacement(node)
}
