import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import { insertEntity } from '@studysync/draft-js-modifiers'
import Dialog from 'common/dialogs/Dialog'
import Form from 'common/formControls/Form'
import HFTextField from 'common/formControls/textInputs/HFTextField'
import { urlRegex } from 'fp/internet'
import PropTypes from 'prop-types'

const LinkDialog = ({
  editorState,
  onClose,
  open,
  setEditorState,
  text: defaultText = '',
  url: defaultUrl = '',
}) => {
  const onSubmit = ({ payload: { url, text } }, event) => {
    event.preventDefault()
    onClose()
    setEditorState(insertEntity(editorState, text, 'LINK', { href: url }))
  }

  return (
    <Dialog
      disableAutoFocus
      maxWidth="xs"
      onClose={onClose}
      open={open}
      showCloseButton
      title="Insert Link">
      <Form
        defaultValues={{
          text: defaultText,
          url: defaultUrl,
        }}
        mode="onSubmit"
        name="LinkDialog"
        onSubmit={onSubmit}
        style={{ maxWidth: 'unset' }}
        suppressDirtyNavigationWarning
        suppressRequiredLabel>
        <DialogContent>
          <HFTextField
            InputProps={{ autoFocus: true }}
            label="URL"
            name="url"
            required
            rules={{
              pattern: {
                value: urlRegex,
                message: 'Please enter a valid URL',
              },
            }}
          />

          <HFTextField name="text" />
        </DialogContent>

        <DialogActions>
          <Button
            color="secondary"
            data-testid="input-cancel"
            onClick={onClose}
            variant="secondary">
            Cancel
          </Button>

          <Button
            color="secondary"
            data-testid="input-submit"
            type="submit"
            variant="primary">
            Insert
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  )
}

LinkDialog.propTypes = {
  editorState: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  text: PropTypes.string,
  setEditorState: PropTypes.func.isRequired,
  url: PropTypes.string,
}

export default LinkDialog
