import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import type { IContent } from 'common/@types/cust'
import MediaPreview from 'common/formControls/textInputs/RichTextEdit/plugins/interactivePlugin/MediaPreview'
import type {
  DecoratorField,
  IDecoratorBaseProps,
} from 'common/formControls/textInputs/RichTextEdit2/@types/custom.d.ts'
import { ABILITY_CONTENT_CREATION, CONTENT_TYPE_INTERACTIVE } from 'core/consts'
import withContent from 'hoc/withContent'
import useAbilityCheck from 'hooks/useAbilityCheck'
import type { InteractiveType } from 'hss/ContentBuilder/@types/custom'
import { labels } from 'hss/ContentBuilder/consts'
import Interactive from 'hss/sections/contentBlocks/Interactive'
import InteractiveRenderer from 'hss/sections/contentBlocks/Interactive/InteractiveRenderer'
import { $getNodeByKey, type LexicalNode, type NodeKey } from 'lexical'
import { contentBuilderUrl } from 'routing/consts'
import { $isInteractiveNode } from '.'
import DecoratorWrapper from '../../utils/DecoratorWrapper'

interface IDecoratorProps extends IDecoratorBaseProps {
  content:
    | IContent
    | {
        contentSubType: InteractiveType
        id: string
      }
  node: LexicalNode
  nodeKey: NodeKey
}

const contentType = CONTENT_TYPE_INTERACTIVE

const fields: DecoratorField[] = [
  {
    name: 'content.assetCode',
    type: 'string',
    label: 'Asset code',
    required: true,
  },
  { name: 'content.notes', type: 'string', label: 'Notes', required: false },
]

const Decorator = ({ content, node, nodeKey }: IDecoratorProps) => {
  const [editor] = useLexicalComposerContext()
  const allowedToEdit = useAbilityCheck(ABILITY_CONTENT_CREATION)

  const isEditable = editor.isEditable()

  const openEditWindow = () => {
    window.open(
      `${contentBuilderUrl}/${contentType}/${content.contentSubType}/${content.id}`,
      '_blank',
    )
  }

  const handleRemove = () => {
    if (!isEditable) return

    editor.update(() => {
      const N = $getNodeByKey(nodeKey)
      if (N && $isInteractiveNode(N)) {
        N.remove()
      }
    })
  }

  if (!content.contentSubType) {
    throw new Error('contentSubType is undefined')
  }

  return isEditable ? (
    <DecoratorWrapper
      content={content}
      fields={fields}
      node={node}
      onRemove={handleRemove}
      title={labels[content.contentSubType as keyof typeof labels]}
      titleAddendum={
        allowedToEdit ? (
          <Tooltip title="Edit this interactive in a new tab...">
            <Button
              color="primary"
              onClick={openEditWindow}
              size="small"
              variant="tertiary">
              [edit]
            </Button>
          </Tooltip>
        ) : null
      }>
      <MediaPreview content={content} />
    </DecoratorWrapper>
  ) : (
    <Interactive
      contentid={content.id}
      Renderer={InteractiveRenderer}
    />
  )
}

const Wired = withContent(Decorator, { contentType })

export default Wired
