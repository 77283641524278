import ErrorBoundary from 'common/errorHandling/ErrorBoundary'
import ContainerQuery from 'common/layout/ContainerQuery'
import { Outlet } from 'react-router-dom'
import AccentColorProvider from 'styling/theming/AccentColorProvider'

const MainViewport = () => (
  <ErrorBoundary moduleName="ContentViewer-MainViewport">
    <AccentColorProvider accentColor="teal">
      <ContainerQuery>
        <Outlet />
      </ContainerQuery>
    </AccentColorProvider>
  </ErrorBoundary>
)
export default MainViewport
