/* istanbul ignore file */ // TODO: google integrations are not currently in use

import actionTypes from 'reducers/actionTypes'
import { restEndpoint } from 'reducers/utils'
import { call, takeLatest } from 'redux-saga/effects'
import { dangerouslyCallApi } from './api'

function* handleInitiate(action) {
  yield call(dangerouslyCallApi, {
    action,
    passThrough: { cantoId: action.cantoId },
    url: `${restEndpoint.uploads}?fromGoogle=${action.fileName}&type=${action.uploadType}&isAudioDescription=${Boolean(action.isAudioDescription)}`,
    suppressNotification: true,
  })
}

export default function* uploadSaga() {
  yield takeLatest(actionTypes.GDRIVE_IMPORT_INITIATE, handleInitiate)
}
