/* istanbul ignore file */

/* *****************************************************************************
 *                                                                             *
 * Every effort has been made to keep theses shapes indexed alphabetically.    *
 * In some cases it's just not practical because of shapes composed of other   *
 * shapes that would otherwise be declared out-of-order.                       *
 *                                                                             *
 * To get around this, the unit is divided into two halves.  The first half    *
 * contains shapes that are used as sub-shapes of other, larger shapes. The    *
 * latter half contains all the exports.  Items within both halves are sorted  *
 * alphabetically. In some cases, a shape declared in the top half is then     *
 * subsequently exported in the lower half.                                    *
 *                                                                             *
 * TO BROWSE THIS FILE EFFECTIVELY, you should scroll down to where the        *
 * exports start.                                                              *
 *                                                                             *
 * If your editor has a structure explorer or a 'collapse all' feature, you    *
 * will probably want to employ it. In VS Code this is called "Fold All" and   *
 * is found within the command palette (CMD+SHIFT+P)                           *
 *                                                                             *
 * ----------------                                                            *
 * NOTES ON SHAPES:                                                            *
 * ----------------                                                            *
 *  X - Please ensure that the properties of each shape are also kept sorted   *
 *      alphabetically.                                                        *
 *  X - Before adding a new shape, ask yourself if it's warranted.  For simple *
 *      shapes that are only used within a single file it makes more sense to  *
 *      declare them in that file instead.                                     *
 *  X - Try to give shapes descriptive names so that others can stumble upon   *
 *      them when searching for a candidate shape.  Likewise, make sure to     *
 *      search for existing shapes that fit your needs before deciding to      *
 *      create a new one, to help reduce unnecessary duplication.              *
 *                                                                             *
 **************************************************************************** */

import {
  CONTENT_RESTRICTION_TYPE_CENSORED,
  CONTENT_RESTRICTION_TYPE_HIDDEN_BY_DEFAULT,
  CONTENT_RESTRICTION_TYPE_NONE,
  CONTENT_STATE_DRAFT,
  CONTENT_STATE_PUBLISHED,
  CONTENT_TYPE_ASSESSMENT,
  CONTENT_TYPE_BLOCK,
  CONTENT_TYPE_CHAPTER,
  CONTENT_TYPE_COURSE,
  CONTENT_TYPE_ECHO,
  CONTENT_TYPE_INTERACTIVE,
  CONTENT_TYPE_PAGE,
  CONTENT_TYPE_PAGESET,
  CONTENT_TYPE_RUBRIC,
  CONTENT_TYPE_SCAFFOLD,
  CONTENT_TYPE_SECTION,
  CONTENT_TYPE_SOURCE,
  CONTENT_TYPE_SUBSECTION,
  CONTENT_TYPE_UNIT,
  CONTENT_TYPE_VOCABULARY,
  INTERACTION_STATE_COMPLETED,
  INTERACTION_STATE_OPEN,
  INTERACTION_SUBTYPE_IMAGE,
  INTERACTION_SUBTYPE_TEXT,
  INTERACTION_SUBTYPE_VIDEO,
  INTERACTION_TYPE_ANNOTATION,
  INTERACTION_TYPE_INTERACTIVE,
  INTERACTION_TYPE_PAGE_VIEW,
  INTERACTIVES,
  SCORING_FEEDBACK_CONTENT,
  SCORING_FEEDBACK_VISIBILITY,
  USE_LEVELED_TEXT_OPTIONS,
} from 'core/consts'
import { BUSY, ERROR, IDLE, SUCCESS } from 'hooks/useReduxCallback'
import {
  arrayOf,
  bool,
  elementType,
  exact,
  func,
  instanceOf,
  node,
  number,
  object,
  oneOf,
  oneOfType,
  shape,
  string,
} from 'prop-types'
import { arrayOfTypeAndLength, emptyObjectOrShape } from './customPropTypes'

const assignmentSettingsShape = shape({
  allowExternalLinks: bool,
  allowLate: bool,
  allowResearchLinks: bool,
  audioVoiceovers: bool,
  includeChapterSummary: bool,
  leveledNarrativeText: oneOf(USE_LEVELED_TEXT_OPTIONS),
  scaffolds: bool,
  scoringFeedbackContent: oneOf(SCORING_FEEDBACK_CONTENT),
  scoringFeedbackVisibility: oneOf(SCORING_FEEDBACK_VISIBILITY),
})

const contentStateShape = oneOf([CONTENT_STATE_DRAFT, CONTENT_STATE_PUBLISHED])

const contentTypeShape = oneOf([
  'detached', // used for detached content (builders, storybook, etc)
  CONTENT_TYPE_ASSESSMENT,
  CONTENT_TYPE_BLOCK,
  CONTENT_TYPE_CHAPTER,
  CONTENT_TYPE_COURSE,
  CONTENT_TYPE_ECHO,
  CONTENT_TYPE_INTERACTIVE,
  CONTENT_TYPE_PAGE,
  CONTENT_TYPE_PAGESET,
  CONTENT_TYPE_RUBRIC,
  CONTENT_TYPE_SCAFFOLD,
  CONTENT_TYPE_SECTION,
  CONTENT_TYPE_SOURCE,
  CONTENT_TYPE_SUBSECTION,
  CONTENT_TYPE_UNIT,
  CONTENT_TYPE_VOCABULARY,
])

const numberOrString = oneOfType([number, string])

const element = oneOfType([func, shape({ current: instanceOf(Element) })])

const entityIdShape = numberOrString

const entityIdsShape = arrayOf(entityIdShape)

const locationShape = shape({
  hash: string.isRequired,
  key: string,
  pathname: string.isRequired,
  search: string.isRequired,
  state: object,
})

// const proficiencyShape = shape({
//   id: numberOrString.isRequired,
//   name: string.isRequired,
//   grouping: string.isRequired,
// })

const scoringShape = shape({
  // ???
})

const standardShape = shape({
  displayOrder: number.isRequired,
  groupId: string.isRequired,
  id: string.isRequired,
  shortCode: string.isRequired,
  statement: string.isRequired,
})

const tagShape = shape({
  id: string.isRequired,
  name: string.isRequired,
  shortName: string.isRequired,
})

/* ************************************************************************** */
/* ************************************************************************** */
/** *************************** EXPORTS START HERE ************************** */
/* ************************************************************************** */
/* ************************************************************************** */
// MARK: - Exports

export const annotationShape = shape({
  colorId: number.isRequired,
  text: string,
  time: number.isRequired,
})

export { assignmentSettingsShape }

export const colorShape = oneOf(['inverted', 'primary', 'secondary'])

export const componentShape = oneOfType([node, elementType])

export const coordShape = shape({
  x: numberOrString.isRequired,
  y: numberOrString.isRequired,
})

const contentRestrictionShape = shape({
  contentId: string.isRequired,
  createdById: string,
  districtId: string,
  id: string.isRequired,
  modifiedById: string,
  type: oneOf([
    CONTENT_RESTRICTION_TYPE_CENSORED,
    CONTENT_RESTRICTION_TYPE_HIDDEN_BY_DEFAULT,
    CONTENT_RESTRICTION_TYPE_NONE,
  ]).isRequired,
})

export const contentShape = shape({
  academicVocabPhraseIds: arrayOf(string),
  academicVocabPhrases: arrayOf(object), // contentShape(s) (can't recurse here)
  applicationStandardIds: arrayOf(string),
  applicationStandards: arrayOf(standardShape),
  assetCode: string,
  assignmentCount: number,
  children: arrayOf(object), // contentShape(s) (can't recurse here)
  childrenCount: number,
  contentRestriction: contentRestrictionShape,
  contentState: contentStateShape.isRequired,
  contentSubType: string,
  contentType: contentTypeShape.isRequired,
  contentVocabPhraseIds: arrayOf(string),
  contentVocabPhrases: arrayOf(object), // contentShape(s) (can't recurse here)
  createdById: string,
  data: object,
  footnoteVocabPhraseIds: arrayOf(string),
  footnoteVocabPhrases: arrayOf(object), // contentShape(s) (can't recurse here)
  id: string,
  instructionStandardIds: arrayOf(string).isRequired,
  instructionStandards: arrayOf(standardShape).isRequired,
  modifiedById: string,
  name: string,
  parent: shape({
    contentType: contentTypeShape.isRequired,
    id: string.isRequired,
    name: string,
    parent: object, // contentShape (can't recurse here)
    teacherEdition: bool,
  }),
  proficiencyIds: arrayOf(string),
  scoring: scoringShape,
  tagIds: arrayOf(string).isRequired,
  tags: arrayOf(tagShape).isRequired,
  teacherEdition: bool,
  'teacherEdition-academicVocabPhraseIds': arrayOf(string),
  'teacherEdition-academicVocabPhrases': arrayOf(object), // contentShape(s) (can't recurse here)
  'teacherEdition-contentVocabPhraseIds': arrayOf(string),
  'teacherEdition-contentVocabPhrases': arrayOf(object), // contentShape(s) (can't recurse here)
  'teacherEdition-footnoteVocabPhraseIds': arrayOf(string),
  'teacherEdition-footnoteVocabPhrases': arrayOf(object), // contentShape(s) (can't recurse here)
  uploadsMap: object, // hashmap of uploadShape(s)
  vocabContent: arrayOf(object).isRequired, // contentShape(s) (can't recurse here)
  vocabContentIds: arrayOf(string).isRequired,
})

export { contentTypeShape }

const dayPairShape = shape({
  endDay: number,
  startDay: number.isRequired,
})

export const directionShape = oneOf(['ltr', 'rtl'])

export const displayShape = oneOf(['block', 'inline', 'initial'])

export { element }

export { entityIdShape }

export const floatTypesShape = oneOf(['left', 'none', 'right'])

export const groupAndSortGroupShape = shape({
  id: entityIdShape,
  heading: string.isRequired,
  totalItems: number,
})

export const historyShape = {
  history: shape({
    action: string.isRequired,
    createHref: func.isRequired,
    go: func.isRequired,
    goBack: func.isRequired,
    goForward: func.isRequired,
    length: number.isRequired,
    location: locationShape.isRequired,
    push: func.isRequired,
    replace: func.isRequired,
  }),
  location: locationShape,
  match: shape({
    isExact: bool.isRequired,
    params: object.isRequired,
    path: string.isRequired,
    url: string.isRequired,
  }),
}

export const hotspotCalloutShape = shape({
  id: entityIdShape.isRequired,
  calloutCoord: string.isRequired,
  description: string.isRequired,
  imageAltText: string,
  imagePosition: oneOf(['left', 'top']),
  label: string.isRequired,
  labelCoord: string.isRequired,
  longTextAlternative: string,
})

export const imageClickerMessageShape = shape({
  coord: string,
  message: string.isRequired,
  style: string,
})

export const imageTitleDragCalloutShape = shape({
  calloutCoord: string.isRequired,
  id: entityIdShape.isRequired,
  label: string.isRequired,
  labelCoord: string.isRequired,
})

export const inputVariantShape = oneOf(['filled', 'outlined', 'standard'])

export const interactionShape = shape({
  contentId: string,
  contextContentId: string,
  dateCreated: string,
  dateDeleted: string,
  dateModified: string,
  id: string,
  interactionData: object,
  interactionType: oneOf([
    INTERACTION_TYPE_ANNOTATION,
    INTERACTION_TYPE_INTERACTIVE,
    INTERACTION_TYPE_PAGE_VIEW,
  ]),
  interactionSubType: oneOf([
    INTERACTION_SUBTYPE_IMAGE,
    INTERACTION_SUBTYPE_TEXT,
    INTERACTION_SUBTYPE_VIDEO,
  ]),
  rawUploadMapping: object,
  score: number,
  scoreData: object, // TODO: what is the shape of 'scoreData'?
  state: oneOf([INTERACTION_STATE_COMPLETED, INTERACTION_STATE_OPEN]),
  userAssignmentId: numberOrString,
  userId: string,
})

export const interactiveTypeShape = oneOf(INTERACTIVES)

export const muiColorShape = oneOf([
  'initial',
  'error',
  'inherit',
  'primary',
  'secondary',
  'textPrimary',
  'textSecondary',
])

const nameDescriptionPairShape = shape({
  description: string.isRequired,
  name: string.isRequired,
})

export const nameLabelPair = {
  name: string,
  label: string,
}

export const nameLabelPairShape = shape(nameLabelPair)

export const nameValuePairShape = arrayOf(
  shape({
    id: oneOfType([number, string]),
    label: string.isRequired,
    value: oneOfType([number, string]).isRequired,
  }),
)

export const navItemChildShape = shape({
  label: string.isRequired,
  rightsCheck: func,
  to: string,
  href: string,
})

export const navItemShape = shape({
  Icon: componentShape.isRequired,
  IconActive: componentShape.isRequired,
  items: arrayOf(navItemChildShape).isRequired,
  label: string.isRequired,
  rightsCheck: func,
  to: string,
  href: string,
})

// export const notificationShape = shape({
//   id: number.isRequired,
//   type: oneOf([
//     'assignment-status',
//     'discussion-activity',
//     'student-activity',
//   ]).isRequired,
//   title: string.isRequired,
//   description: string.isRequired,
// })

export { numberOrString }

const pacingOptionsPairShape = oneOfType([
  dayPairShape,
  nameDescriptionPairShape,
  string,
])

export const pacingOptionsShape = shape({
  a: pacingOptionsPairShape,
  b: pacingOptionsPairShape,
  c: pacingOptionsPairShape,
})

// export const questionTypeShape = oneOf([
//   QUESTION_TYPE_INSTRUCTIONS,
//   QUESTION_TYPE_ANNOTATE,
//   QUESTION_TYPE_AUDIO,
//   QUESTION_TYPE_CHART,
//   QUESTION_TYPE_CHOICE_MATRIX,
//   QUESTION_TYPE_CLOZE,
//   QUESTION_TYPE_EBSR,
//   QUESTION_TYPE_ESSAY,
//   QUESTION_TYPE_GROUP_AND_SORT,
//   QUESTION_TYPE_MULTIPLE_CHOICE,
// ])

export const reduxCallbackStatusShape = oneOf([IDLE, BUSY, SUCCESS, ERROR])

export const refShape = oneOfType([
  func,
  shape({ current: instanceOf(Element) }),
])

export const rubricCriteriaShape = shape({
  options: arrayOf(
    shape({
      description: string.isRequired,
      id: string.isRequired,
      score: oneOfType([string, number]).isRequired,
    }),
  ).isRequired,
  title: string.isRequired,
})

export const sizeShape = oneOf([
  'xxsmall',
  'xsmall',
  'small',
  'medium',
  'large',
  'xlarge',
  'xxlarge',
  'xxxlarge',
])

export const squeryShape = shape({
  limit: number.isRequired,
  modifiers: object.isRequired,
  offset: number.isRequired,
  orderBy: arrayOf(arrayOfTypeAndLength(2, string)).isRequired,
  where: object.isRequired,
})

export { standardShape }

export const textAlignShape = oneOf([
  'center',
  'inherit',
  'justify',
  'left',
  'right',
])

export const uploadTypeShape = oneOf(['image', 'video', 'vtt', 'audio'])

export const uploadShape = oneOfType([
  // TODO: remove post refactor
  string,

  // Complete, uploaded asset
  shape({
    type: uploadTypeShape.isRequired,
    createdById: string,
    id: string.isRequired,
    cantoFileName: string,
    gdriveFileName: string,
    metadata: emptyObjectOrShape({
      fields: shape({
        altText: string,
        ariaText: string,
        ariaTextLink: string,
        assetDescription: string,
        caption: string,
        dcmIngestionId: string,
        description: string,
        headline: string,
        imageDescription: string,
      }),
    }),
    url: string.isRequired,
  }),

  // Uploaded to S3 but not stored within DB yet
  exact({
    id: string.isRequired,
  }),
])

export const uploadVariantShape = oneOf(['full', 'thumb'])

const userAlertModeShape = oneOf(['interrupt', 'profile'])

export const userAlertDataShape = shape({
  alertId: string,
  dateConfirmed: string,
  dateCreated: string,
  dateViewed: string,
  id: string,
  message: string.isRequired,
  mode: userAlertModeShape.isRequired,
  title: string.isRequired,
  type: string,
  userId: string,
})

export const userAssignmentShape = shape({
  assignmentId: string.isRequired,
  id: string.isRequired,
})

export const videoShape = shape({
  ccUrl: string,
  dashUrl: string,
  description: string,
  fallbackUrl: string,
  posterUrl: string,
  tag: entityIdsShape,
  url: string,
})

export const vocabTypeShape = oneOf(['academic', 'content', 'footnote'])

export const vttShape = shape({
  valid: bool.isRequired,
  cues: arrayOf(
    shape({
      start: number.isRequired,
      text: string.isRequired,
    }),
  ).isRequired,
})
