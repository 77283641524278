import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import cl from 'classnames'
import { identity } from 'fp/utils'
import { border, position } from 'polished'
import PropTypes from 'prop-types'

const Button = styled(IconButton, { name: 'toolbar-DraftToolButton' })(
  ({
    theme: {
      mixins: { important, rem, size, transition },
      palette,
    },
  }) => ({
    ...size(47.4),
    ...transition('all', 250),
    fontSize: rem(1.6),
    marginRight: important(0),
    svg: { color: 'inherit' },
    '&:disabled': { color: important(palette.text.disabled) },
    '&:hover': {
      backgroundColor: palette.grey.contrastText,
      color: important(palette.grey[0]),
    },

    '&.active': {
      backgroundColor: palette.grey[0],
      color: important(palette.grey.contrastText),
      '&:hover': {
        backgroundColor: important(palette.grey[1]),
      },
    },

    '&.showDropdownIndicator::after': {
      content: '""',
      ...size(0),
      ...border('bottom', 5, 'solid', 'currentColor'),
      ...border('left', 5, 'solid', 'transparent'),
      ...position('absolute', null, 3, 3, null),
    },
  }),
)

const DraftToolButton = props => {
  const {
    className,
    disabled = false,
    editorState,
    excludeToolButtons = [],
    inlineStyleName,
    label,
    showDropdownIndicator,
    toggleInlineStyle,
    ...rest
  } = props

  const btnClassName = cl({
    [className]: true,
    active:
      editorState?.getCurrentInlineStyle().has(inlineStyleName) ||
      rest['aria-expanded'],
    showDropdownIndicator,
  })

  return excludeToolButtons.includes(label) ||
    excludeToolButtons.includes('*') ? null : (
    <Tooltip title={disabled ? '' : label}>
      <Button
        aria-label={label}
        className={btnClassName}
        disabled={disabled}
        onClick={
          toggleInlineStyle ? toggleInlineStyle(inlineStyleName) : identity
        }
        {...rest}
        size="large"
      />
    </Tooltip>
  )
}

DraftToolButton.propTypes = {
  disabled: PropTypes.bool,
  editorState: PropTypes.object,
  excludeToolButtons: PropTypes.arrayOf(PropTypes.string),
  inlineStyleName: PropTypes.string,
  label: PropTypes.node.isRequired,
  showDropdownIndicator: PropTypes.bool,
  toggleInlineStyle: PropTypes.func,
}

export default DraftToolButton
