import { withOptions } from '@comfy/redux-selectors'
import {
  CONTENT_STATE_DRAFT,
  CONTENT_STATE_PUBLISHED,
  CONTENT_TYPE_BLOCK,
  CONTENT_TYPE_CHAPTER,
  CONTENT_TYPE_COURSE,
  CONTENT_TYPE_ECHO,
  CONTENT_TYPE_INTERACTIVE,
  CONTENT_TYPE_RUBRIC,
  CONTENT_TYPE_SCAFFOLD,
  CONTENT_TYPE_SECTION,
  CONTENT_TYPE_SOURCE,
  CONTENT_TYPE_SUBSECTION,
  CONTENT_TYPE_UNIT,
  ROLE_STUDENT,
  TOGGLE_STATE_PRESENTER_MODE,
} from 'core/consts'
import { find, getAt, includes, map, reduce } from 'fp/arrays'
import { equals, get, hasProperty, merge } from 'fp/objects'
import { curryRight, fallbackTo, identity, matches, not } from 'fp/utils'
import {
  assembleContentHierarchy,
  detailsShouldBeHiddenFromStudent,
  flattenHierarchy,
  isEmptyBlock,
} from 'projections/content'
import { pullListed } from 'projections/index'
import { compose } from 'redux'
import { createSelector, stateContent, stateRouting } from '.'
import { getContextualAssignment } from './assignments'
import {
  collapsePropBagsToArray,
  getCollapsedContentBags,
  getContentBags,
  getContentForType,
} from './collapsedContent'
import { getLocalSetting } from './localSettings'
import { createAbilityChecker } from './userAbility'
import {
  getAssignmentBehindUserAssignment,
  getUserAssignment,
} from './userAssignments'
import { getCurrentUser } from './users'
import { getCurrentRoleId, isStudent, isSysAdmin } from './users'
import { omitReduxMetadata } from './utils'

export const contentIsViewableByUser = withOptions(
  ({ contentId, contentType, id }) =>
    createSelector('contentIsViewableByUser')(
      getContentById({ contentId: contentId || id, contentType }),
      isSysAdmin,
      (content, isAdmin) =>
        content?.contentState === CONTENT_STATE_PUBLISHED || isAdmin,
    ),
)

export const contentStateIsDraft = withOptions(
  ({ contentId, contentType, id }) =>
    createSelector('contentStateIsDraft')(
      getContentById({ contentId: contentId || id, contentType }),
      compose(equals(CONTENT_STATE_DRAFT), get('contentState')),
    ),
)

export const getAllContentAsObject = createSelector('getAllContentAsObject')(
  getContentBags,
  compose(reduce(merge, {}), map(omitReduxMetadata)),
)

export const getContentType = withOptions(({ contentId, id }) =>
  createSelector('getContentType')(
    getAllContentAsObject,
    compose(get('contentType'), curryRight(getAt, contentId || id)),
  ),
)

export const getContentHierarchy = withOptions(options =>
  createSelector('getContentHierarchy')(
    getAllContentAsObject,
    getContextualAssignment,
    getExcludedContentIds,
    stateRouting,
    createAbilityChecker,
    getCurrentRoleId,
    getLocalSetting(TOGGLE_STATE_PRESENTER_MODE),
    assembleContentHierarchy(options),
  ),
)

/**
 * No frills, reverse construction of the ancestry tree.
 * This does not take role into account.  Use only for light-duty checks.
 */
export const getContentAncestry = withOptions(({ contentId, id }) =>
  createSelector('getContentAncestry')(getCollapsedContentBags, allContent => {
    const ancestors = []
    let cursor = allContent.find(matches('id', contentId || id))

    while (cursor) {
      ancestors.push(cursor)
      const { id: cursorId } = cursor
      cursor = allContent.find(item =>
        item.children.some(matches('id', cursorId)),
      )
    }

    return ancestors.reduceRight((parent, child) => ({ ...child, parent }), {})
  }),
)

const getExcludedContentIds = createSelector('getExcludedContentIds')(
  getContextualAssignment,
  getCurrentRoleId,
  getLocalSetting(TOGGLE_STATE_PRESENTER_MODE),
  (assignment, roleId, inPresenterMode) =>
    compose(
      fallbackTo([]),
      roleId === ROLE_STUDENT || inPresenterMode ? identity : () => [],
      get('excludedContentIds'),
    )(assignment),
)

export const getFlattenedChildren = withOptions(options =>
  createSelector('getFlattenedChildren')(
    getContentHierarchy(options),
    flattenHierarchy,
  ),
)

export const getFlattenedChildrenOfTypes = withOptions(
  ({ contentTypes, options }) =>
    withOptions(({ contentId, id }) =>
      createSelector('getFlattenedChildrenOfTypes')(
        getFlattenedChildren({ contentId: contentId || id, options }),
        items =>
          items
            .filter(({ contentType }) => contentTypes.includes(contentType))
            // we only want sources if they are single level, otherwise we want the child subsections
            .filter(compose(not, get('isSectionLike'))),
      ),
    ),
)

export const getContentByAssetCode = withOptions(({ assetCode }) =>
  createSelector('getContentByAssetCode')(
    getContentForType(CONTENT_TYPE_COURSE),
    getContentForType(CONTENT_TYPE_CHAPTER),
    getContentForType(CONTENT_TYPE_ECHO),
    getContentForType(CONTENT_TYPE_RUBRIC),
    getContentForType(CONTENT_TYPE_SECTION),
    getContentForType(CONTENT_TYPE_SOURCE),
    getContentForType(CONTENT_TYPE_SUBSECTION),
    getContentForType(CONTENT_TYPE_UNIT),
    compose(
      find(matches('assetCode', assetCode)),
      arr => (assetCode ? arr : []),
      collapsePropBagsToArray,
      (...propBags) => propBags,
    ),
  ),
)

export const getContentById = withOptions(
  ({ content, contentType, contentId, id }) =>
    createSelector('getContentById')(
      getContentForType(contentType || content?.contentType),
      getAllContentAsObject,
      (contentOfRequestedType, allContent) =>
        compose(
          get(String(content?.id || contentId || id)),
          fallbackTo(allContent),
        )(contentOfRequestedType),
    ),
)

export const getContentByIds = withOptions(({ contentIds }) =>
  createSelector('getContentByIds')(getCollapsedContentBags, available =>
    available.filter(compose(id => contentIds.includes(id), get('id'))),
  ),
)

export const getListedContent = withOptions(({ contentType }) =>
  createSelector('getListedContent')(
    getContentForType(contentType),
    pullListed,
  ),
)

export const hasAlternateBodiesForSelfOrDescendants = withOptions(
  ({ kind, content, contentId }) =>
    createSelector('hasAlternateBodiesForSelfOrDescendants')(
      getFlattenedChildren({ contentId: content?.id || contentId }),
      getContentForType(CONTENT_TYPE_BLOCK),
      (flattened, blocks) =>
        [content, ...flattened]
          .filter(matches('contentType', CONTENT_TYPE_BLOCK))
          .map(({ id }) => blocks[id])
          .some(compose(not, isEmptyBlock, get(kind), get('data'))),
    ),
)

/**
 * NOTE:
 * This is a utility method for selectors further down the page and there's no
 * guarantee that it will return the whole object, just the id and contentType.
 *
 * Invoke the getContentById() selector on the result if that's what you're really
 * after.
 *
 * Do not use this directly as a selector without wrapping it first.
 */

export const isBlock = content =>
  [CONTENT_TYPE_BLOCK, CONTENT_TYPE_SCAFFOLD].includes(content?.contentType)

export const isContentFetching = withOptions(({ contentId }) =>
  createSelector('isContentFetching')(
    stateContent,
    compose(includes(contentId), get('fetching')),
  ),
)

export const isContentLoaded = withOptions(
  ({ contentType, contentId, queryParams = {} }) =>
    createSelector('isContentLoaded')(
      getContentForType(contentType),
      reducer => {
        const entry = get(`loaded.${contentId}`)(reducer)

        /**
         * The item is considered loaded if
         *   a) it exists in the 'loaded' bag AND
         *   b) we either
         *     1) did not specify that we care about childDepth OR
         *     2) the requested childDepth matches or exceeds what was already loaded
         *
         * Currently we only care about childDepth, but you can expand this to check
         * for anything on queryParams. Just be careful if comparing objects (squeries
         * for example)
         *
         */

        return Boolean(
          !!entry &&
            (!hasProperty('childDepth')(queryParams) ||
              entry.childDepth >= queryParams.childDepth),
        )
      },
    ),
)

export const getInteractiveShouldBeHidden = withOptions(
  ({ interactiveId, interaction }) =>
    createSelector('getInteractiveShouldBeHidden')(
      isStudent,
      getUserAssignment,
      getAssignmentBehindUserAssignment,
      getContentById({
        contentId: interactiveId,
        contentType: CONTENT_TYPE_INTERACTIVE,
      }),
      () => interaction,
      detailsShouldBeHiddenFromStudent,
    ),
)

export const createdByCurrentUser = withOptions(content =>
  createSelector('editableByCurrentUser')(
    getContentById({ content }),
    getCurrentUser,
    ({ createdById }, { id }) => createdById === id,
  ),
)
