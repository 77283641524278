import { inputVariantShape, interactiveTypeShape } from 'core/shapes'
import { titleCase } from 'fp/strings'
import PropTypes from 'prop-types'
import { compose } from 'redux'

export const allowedInteractivesShape = PropTypes.oneOfType([
  PropTypes.arrayOf(interactiveTypeShape),
  PropTypes.oneOf(['none']),
])

export const fauxEvent = () => ({ preventDefault: f => f })

export const getWordList = compose(
  cleanedText => cleanedText.match(/\S+/g) || [],
  plainText => plainText.replace(/(?:\r\n|\r|\n)/g, ' ').trim(),
  content => content.getPlainText(''),
  editorState => editorState.getCurrentContent(),
)

export const getCharacterCount = compose(a => a.join(' ').length, getWordList)

export const toolbarPropTypes = {
  allowedInteractives: allowedInteractivesShape,
  changeIndent: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  editorState: PropTypes.object.isRequired,
  excludeToolButtons: PropTypes.arrayOf(PropTypes.string),
  features: PropTypes.object.isRequired,
  setEditorState: PropTypes.func.isRequired,
  toggleBlockType: PropTypes.func.isRequired,
  toggleIndentFirstLine: PropTypes.func.isRequired,
  toggleInlineStyle: PropTypes.func.isRequired,
  toggleLinkDialog: PropTypes.func.isRequired,
  variant: inputVariantShape.isRequired,
}

export const toolbarButtonPropTypes = {
  editorState: PropTypes.object.isRequired,
  features: PropTypes.object.isRequired,
  setEditorState: PropTypes.func.isRequired,
  toggleBlockType: PropTypes.func.isRequired,
}

export const rteNumberOfLines = [
  {
    key: 2,
    minHeight: '115px',
  },
  {
    key: 3,
    minHeight: '165px',
  },
  {
    key: 4,
    minHeight: '215px',
  },
  {
    key: 5,
    minHeight: '265px',
  },
  {
    key: 6,
    minHeight: '315px',
  },
  {
    key: 7,
    minHeight: '365px',
  },
  {
    key: 8,
    minHeight: '415px',
  },
  {
    key: 9,
    minHeight: '465px',
  },
  {
    key: 10,
    minHeight: '515px',
  },
]

export const getMinHeight = (numberOfLines = 7) =>
  rteNumberOfLines.find(({ key }) => key === numberOfLines)?.minHeight ||
  '400px'

export const getMaxHeight = () => '500px'

export const rteToolButtons = [
  'bold',
  'italic',
  'underline',
  'strikethrough',
  'highlight',
  'superscript',
  'subscript',
  'format',
  'lists',
  'link',
  'indentation',
].map(key => ({ key, label: titleCase(key) }))

export const getExcludeToolButtons = toolButtonOptions => {
  if (toolButtonOptions.all) {
    return []
  }

  if (toolButtonOptions.none) {
    return ['*']
  }

  return rteToolButtons
    .filter(({ key }) => !toolButtonOptions[key])
    .map(({ label }) => label)
}
