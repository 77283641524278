import { withOptions } from '@comfy/redux-selectors'
import { get } from 'fp/objects'
import { maybeParseJSON } from 'fp/strings'
import { fallbackTo, isUndefined } from 'fp/utils'
import { compose } from 'redux'
import { createSelector, stateLocalSettings } from '.'
import { getCurrentUser } from './users'

export const getRootSettingsKey = createSelector('getRootSettingsKey')(
  getCurrentUser,
  compose(fallbackTo('global'), get('id')),
)

export const getLocalSetting = withOptions(key =>
  createSelector('getLocalSetting')(
    stateLocalSettings,
    getRootSettingsKey,
    (settings, rootKey) => {
      if (isUndefined(key)) throw new Error('getLocalSetting requires a key')
      const value = get(`${rootKey}.${key}`)(settings)
      return maybeParseJSON(value)
    },
  ),
)
