import Tooltip from '@mui/material/Tooltip'
import { contentTypeShape } from 'core/shapes'
import useContent from 'hooks/useContent'
import PropTypes from 'prop-types'
import ExternalLinkConfirm from './ExternalLinkConfirm'

const ContentDefinedExternalLink = ({ children, contentId, contentType }) => {
  const { data = {} } = useContent({ contentId, contentType }) || {}

  const { href, tooltip } = data

  if (!href) return children

  if (!tooltip)
    return <ExternalLinkConfirm href={href}>{children}</ExternalLinkConfirm>

  return (
    <Tooltip title={tooltip}>
      <ExternalLinkConfirm href={href}>{children}</ExternalLinkConfirm>
    </Tooltip>
  )
}

ContentDefinedExternalLink.propTypes = {
  children: PropTypes.node.isRequired,
  contentId: PropTypes.string.isRequired,
  contentType: contentTypeShape.isRequired,
}

export default ContentDefinedExternalLink
