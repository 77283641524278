import { A11y, EffectFade, Navigation, Pagination } from 'swiper/modules'

/** ****************************************************************************
 *                                                                             *
 * Add configurations here as needed (generally the size of the slide is the   *
 * governing factor).                                                          *
 *                                                                             *
 * For available options, see:                                                 *
 *                                                                             *
 *            https://swiperjs.com/swiper-api#parameters                       *
 *                                                                             *
 **************************************************************************** */

const baseConfiguration = {
  modules: [A11y, Navigation, Pagination],
}

export const paginatedSmallSlides = {
  ...baseConfiguration,
  a11y: true,
  // TODO: we do want this to loop but it seems to break nav/pagination when set to true
  // loop: true,
  loopPreventsSliding: true,
  navigation: {
    centeredOnSlides: true,
    enabled: true,
  },
  pagination: { clickable: true },
  slidesPerGroup: 3,
  slidesPerView: 3,
  spaceBetween: 40,

  xs: { slidesPerGroup: 1, slidesPerView: 1 },
  sm: { slidesPerGroup: 2, slidesPerView: 2 },
  md: { slidesPerGroup: 3, slidesPerView: 3 },
  lg: { slidesPerGroup: 4, slidesPerView: 4 },
  xl: { slidesPerGroup: 5, slidesPerView: 5 },
}

// Recently Visited/Bookshelf carousels on home page
export const paginatedImageSlides = {
  ...baseConfiguration,
  a11y: true,
  navigation: {
    centeredOnSlides: true,
    enabled: true,
  },
  pagination: { clickable: true },

  slidesPerGroup: 2.5,
  slidesPerView: 3,
  spaceBetween: 20,

  xs: { slidesPerGroup: 1, slidesPerView: 1 },
  sm: { slidesPerGroup: 2, slidesPerView: 2 },
  md: { slidesPerGroup: 3, slidesPerView: 3 },
}

// hero slides
export const largeSingleSlides = {
  effect: 'fade',
  loop: true,
  modules: [A11y, Pagination, EffectFade, Navigation],
  navigation: {
    enabled: true,
    centeredOnSlides: true,
    contained: true,
  },
  pagination: {
    className: 'dash-pagination',
  },
  slidesPerGroup: 1,
  slidesPerView: 1,
}

export const largeSingleSlidesNoLoop = {
  ...largeSingleSlides,
  loop: false,
}

// fun facts slides
export const singleSlidesWithNavigation = {
  modules: [A11y, Pagination],
  navigation: {
    enabled: true,
    centeredOnSlides: false,
  },
  pagination: {
    className: 'dash-pagination',
  },
  slidesPerGroup: 1,
  slidesPerView: 1,
}
