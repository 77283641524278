import Icon from '@mui/material/Icon'
import { CONTENT_SUBTYPE_CHAPTER_SUMMARY } from 'core/consts'
import { contentShape } from 'core/shapes'
import { noop } from 'fp/utils'
import { assignmentEditContext } from 'hss/AssignmentEditor/assignmentEditContext'
import ContentRestrictionIndicator from 'hss/views/Curriculum/ContentRestrictionIndicator'
import { useContext } from 'react'
import { EyeOff } from 'react-feather'

const AssignmentExclusionIndicator = ({ item }) => {
  const context = useContext(assignmentEditContext)
  const { includeChapterSummary, isContentIdExcluded = noop } = context || {}
  const isMyContentIdExcluded =
    isContentIdExcluded(item.id) && !item.teacherEdition

  return (
    Boolean(
      isMyContentIdExcluded ||
        (!!context &&
          item.contentSubType === CONTENT_SUBTYPE_CHAPTER_SUMMARY &&
          !includeChapterSummary),
    ) && (
      <Icon
        aria-hidden={false}
        aria-label="(excluded from assignment)"
        role="img"
        title="Excluded from assignment">
        <EyeOff />
      </Icon>
    )
  )
}

AssignmentExclusionIndicator.propTypes = {
  item: contentShape.isRequired,
}

const ItemExclusionIndicator = ({ item }) => {
  const isEditingAssignment = Boolean(useContext(assignmentEditContext))
  return isEditingAssignment ? (
    <AssignmentExclusionIndicator item={item} />
  ) : (
    <ContentRestrictionIndicator content={item} />
  )
}

ItemExclusionIndicator.propTypes = {
  item: contentShape.isRequired,
}

export default ItemExclusionIndicator
