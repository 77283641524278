const overrides = theme => {
  const {
    mixins: { important, importantRem, transition },
    palette,
  } = theme

  return {
    MuiRating: {
      styleOverrides: {
        root: {
          '&.Mui-disabled.Mui-readOnly': {
            opacity: 1,
            svg: {
              fill: palette.grey[3],
              stroke: palette.grey[3],
            },
          },
        },
      },

      variants: [
        {
          props: { variant: 'tab-mode' },
          style: {
            '.MuiRating-iconActive': {
              outlineColor: important(palette.focused),
              outlineStyle: important('solid'),
              outlineOffset: importantRem(0.15),
              outlineWidth: importantRem(0.5),
              zIndex: '9999',
              ...transition('all', 150),
            },
          },
        },

        {
          props: { 'data-subvariant': 'peer-review' },
          style: {
            /**
             * Ideally we'd just add gap=8 to the parent but....
             * https://github.com/mui/material-ui/issues/26380
             *
             * Setting margin on particular child spans seems to work for now.
             * There are two variants to account for here, both are spans.
             */
            'span.MuiRating-decimal, label span': {
              marginLeft: 8,
            },
          },
        },

        {
          props: { readOnly: true },
          style: {
            cursor: 'help',
          },
        },
      ],
    },
  }
}

export default overrides
