import { isDefined } from 'fp/utils'
import { decode } from 'he'

const decorator = (/* features */) => {
  const strategy = (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges(character => {
      const entityKey = character.getEntity()

      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'LINK'
      )
    }, callback)
  }

  const component = ({ children }) => (
    <span className="decorator-link">{children}</span>
  )

  return { strategy, component }
}

const entityToHTML = (/* features */) => (entity, originalText) => {
  const { data, type } = entity

  if (type !== 'LINK') return undefined

  const escapedText = decode(originalText)

  if (isDefined(data.contentId)) {
    return (
      <a
        data-contentid={data.contentId}
        data-contenttype={data.contentType}
        // biome-ignore lint/a11y/useValidAnchor: it's fine
        href="#"
      >
        {escapedText}
      </a>
    )
  }

  // FALLBACK
  return <a href={data.href}>{escapedText}</a>
}

const htmlToEntity = (/* features */) => (nodeName, node, createEntity) => {
  if (nodeName === 'a') {
    let props
    if (isDefined(node.getAttribute('data-assetcode'))) {
      // INTERNAL LINK
      props = {
        assetCode: node.getAttribute('data-assetcode'),
        contentType: node.getAttribute('data-contenttype'),
      }
    } else if (isDefined(node.getAttribute('data-contentid'))) {
      // EXTERNAL LINK
      props = {
        contentId: node.getAttribute('data-contentid'),
        contentType: node.getAttribute('data-contenttype'),
      }
    } else {
      // FALLBACK
      props = { href: node.href }
    }

    return createEntity('LINK', 'MUTABLE', props)
  }
  return undefined
}

const linkPlugin = {
  decorator,
  entityToHTML,
  htmlToEntity,
}

export default linkPlugin
