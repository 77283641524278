import { svgColor, themeColorVariants } from './accentColorUtils'

const applicator = ({
  color,
  getProperty,
  bodyVariant,
  theme: {
    mixins: { borderS },
  },
  variant,
}) => ({
  props: { color, variant, 'data-bodyvariant': bodyVariant },
  style: {
    ...svgColor(getProperty('color')),
    backgroundColor: getProperty('backgroundColor'),
    ...borderS(getProperty('borderColor')),
    transition: '250ms',

    '&:hover': {
      background: getProperty('hoverBackground'),
      borderColor: getProperty('hoverBorderColor'),
      boxShadow:
        getProperty('hoverBoxShadow') || /* istanbul ignore next */ 'none',
      ...svgColor(getProperty('hoverColor')),
    },

    '&:active': {
      boxShadow:
        getProperty('activeBoxShadow') || /* istanbul ignore next */ 'none',
    },

    '&.Mui-disabled': {
      backgroundColor: getProperty('disabledBackground'),
      borderColor: getProperty('disabledBorderColor'),
      boxShadow: 'none',
    },
  },
})

const overrides = theme => ({
  MuiIconButton: {
    defaultProps: {
      color: 'secondary',
      variant: 'tertiary',
    },

    variants: [
      ...['body1', 'body2', 'body3'].map(bodyVariant => [
        ...themeColorVariants(theme, applicator, bodyVariant),

        {
          props: { variant: 'none', 'data-bodyvariant': bodyVariant },
          style: {
            '&:hover': { backgroundColor: 'transparent' },
          },
        },

        {
          props: { variant: 'primary', 'data-bodyvariant': bodyVariant },
          style: {
            borderStyle: 'none',
            '&:hover': { borderStyle: 'none' },
          },
        },

        {
          props: { variant: 'tertiary', 'data-bodyvariant': bodyVariant },
          style: {
            '&:hover': { transition: '250ms' },
          },
        },

        {
          props: { variant: 'borderless', 'data-bodyvariant': bodyVariant },
          style: {
            '&.Mui-disabled path': {
              stroke: theme.palette.grey[4],
            },
          },
        },

        {
          props: {
            'data-subvariant': 'toolbutton',
            'data-bodyvariant': bodyVariant,
          },
          style: {
            borderColor: theme.palette.grey[4],
            borderRadius: 0,
            backgroundColor: 'transparent',
            height: 48,
            width: 48,
            padding: 0,
            '&:hover svg': {
              color: theme.palette.text.primary,
            },
          },
        },

        {
          props: {
            'data-subvariant': 'toolbutton-round',
            'data-bodyvariant': bodyVariant,
          },
          style: {
            borderColor: theme.palette.grey[4],
            backgroundColor: 'transparent',
            height: 48,
            width: 48,
            padding: 0,
            '&:hover': {
              borderColor: 'transparent',
              transition: '250ms',
            },
          },
        },

        {
          props: { size: 'small' },
          style: {
            svg: {
              fill: theme.palette.common.white,
              transition: '250ms',
              '& line': {
                stroke: theme.palette.grey[1],
              },
            },
            '&:hover': {
              backgroundColor: 'unset',
              svg: {
                fill: 'currentColor',
                '& line': {
                  stroke: theme.palette.common.white,
                },
              },
            },
          },
        },
        {
          props: {
            'data-subvariant': 'square',
            'data-bodyvariant': bodyVariant,
          },
          style: {
            padding: 0,
            borderRadius: 0,
            width: 48,
            '&:hover': { transition: '250ms' },
          },
        },
      ]),
    ].flat(),

    styleOverrides: {
      sizeLarge: theme.mixins.size(48),
    },
  },
})

export default overrides
