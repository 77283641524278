import styled from '@emotion/styled'
import { Box } from '@mui/material'
import { getImageUrlFromUploadsMap } from 'common/avclub/utils'
import {
  CONTENT_TYPE_INTERACTIVE,
  INTERACTION_SUBTYPE_IMAGE,
} from 'core/consts'
import useContent from 'hooks/useContent'

export type InlineImageSize = 'sm' | 'md' | 'lg'

export type InlineImageVerticalAlign = 'top' | 'middle' | 'bottom'

interface InlineImageProps extends React.ComponentPropsWithoutRef<'img'> {
  contentId?: string
  size: InlineImageSize
  src?: string
  valign: InlineImageVerticalAlign
}

const InlineImage: React.FC<InlineImageProps> = ({
  className,
  contentId,
  size,
  src,
  valign: verticalAlign,
}) => {
  const img = useContent({
    contentId,
    contentType: CONTENT_TYPE_INTERACTIVE,
  })

  const url =
    img?.contentSubType === INTERACTION_SUBTYPE_IMAGE
      ? getImageUrlFromUploadsMap(img.uploadsMap)
      : src

  return (
    <Box
      component="span"
      className={className}
      mx={0.5}
      sx={{ display: 'inline-block', verticalAlign }}>
      <img
        src={url}
        alt={img?.data?.imageAltText}
        className={size}
      />
    </Box>
  )
}

export default styled(InlineImage)({
  '.sm': {
    maxHeight: '1em',
  },
  '.md': {
    maxHeight: '2em',
  },
  '.lg': {
    maxHeight: '3em',
  },
})
