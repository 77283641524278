const plugin = ({ mixins: { important }, palette }) => ({
  '.splitter-layout': {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100vh',
    overflow: 'hidden',

    '.layout-pane': {
      position: 'relative',
      flex: '0 0 auto',
      overflow: 'auto',

      '&.layout-pane-primary': {
        flex: '1 1 auto',
      },
    },

    '> .layout-splitter': {
      backgroundColor: palette.grey[0],
      flex: '0 0 auto',
      width: 8,
      height: '100%',
      cursor: 'col-resize',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50%',
      backgroundImage:
        'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==")',
    },

    '&.splitter-layout-vertical': {
      flexDirection: 'column',

      '&.layout-changing': {
        cursor: 'row-resize',
      },

      '> .layout-splitter': {
        width: '100%',
        height: 4,
        cursor: 'row-resize',
      },
    },

    '.splitter-layout-secondary-panel-hidden': {
      '> .layout-pane': {
        width: important(0),

        '.layout-pane-primary': {
          width: important('100%'),
        },
        ' > .layout-splitter': {
          display: 'none',
        },
      },
    },
  },
})

export default plugin
