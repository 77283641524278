import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import { renderSimpleCardCell } from 'common/tables/DataTable/Cells'
import { findObj } from 'fp/arrays'
import { get, notEquals } from 'fp/objects'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import UploadsPreview from './UploadsPreview'

const Table = styled('table', { name: 'interactivePlugin-TableCardRenderer' })({
  height: 'fit-content',
  'th, td': {
    verticalAlign: 'top',
    lineHeight: 1.3,
    paddingBottom: 5,
    paddingRight: 5,
  },
  marginBottom: 10,
})

const notCell = name => compose(notEquals(name), get('column.columnDef.header'))

const TableCardRenderer = props => {
  const { onComplete, row } = props
  const { original } = row

  const cells = row.getVisibleCells()

  const cols = cells.filter(notCell('Preview')).filter(notCell('ID'))

  const previewCell = findObj('column.columnDef.header', 'Preview')(cells)

  return (
    <div style={{ width: '100%' }}>
      <Stack width="100%">
        <Box
          display="flex"
          gap={3}
          justifyContent="space-between"
          width="100%">
          <Table>
            <tbody>{cols.map(renderSimpleCardCell)}</tbody>
          </Table>

          <UploadsPreview uploadsMap={previewCell.getValue()} />
        </Box>
      </Stack>

      <Box
        mt={1}
        textAlign="center">
        <Button
          aria-label={`insert asset ${original.assetCode} into content`}
          onClick={() => onComplete(original)}
          size="small"
          style={{ width: 100 }}
          variant="primary">
          Insert
        </Button>
      </Box>
    </div>
  )
}

TableCardRenderer.propTypes = {
  onComplete: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,
}

export default TableCardRenderer
