import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import hss from 'apps/hss/themes/hss'
import hsshc from 'apps/hss/themes/hss-hc'
import useEffectOnce from 'hooks/useEffectOnce'
import useThemeName from 'hooks/useThemeName'
import PropTypes from 'prop-types'
import { createContext, memo, useState } from 'react'
import { isDevEnv } from 'selectors/index'

let loggedHero = false

const themes = {
  hss,
  'hss-hc': hsshc,
  ss: hss, // can be removed once we have the user api
  usa: hsshc, // can be removed once we have the user api
}

/* istanbul ignore next line */
const consoleHero = () => {
  if (loggedHero) return
  loggedHero = true
  const asciiFont = 'font-family:monospace;font-size:8px'
  const black = `color:inherit;${asciiFont}`
  const blue = `color:#1C5BFF;${asciiFont}`
  const bylineFont =
    'font-family:monospace;font-size:13px;line-height: 1.5;padding-top:10px'
  const bolded = `font-weight:bold;text-decoration:underline;${bylineFont}`
  const normal = bylineFont

  // biome-ignore lint/suspicious/noConsole: intended
  console.log(
    `%c
▐████████▌    ██████▄         ██▌      ██      ██████████████     ██████▄       ▄█████▄     ███████
    ██        ██    ██       ██▀█▌      ██    ▐█▌                 ██    ██     ██    ▀▀     ██
    ██        ██   ▄█▀      ▐█▌ ▀█       █▌  ▐█▌      %c▄▄▄▄▄▄▄%c     ██   ▄█▀     ▀███▄▄       ██▄▄▄▄▄
    ██        ██▀▀██        █▌   ██      ▐█▌ ██       %c▀▀▀▀▀▀▀%c     ██▀▀██          ▀▀▀█▄     ██▀▀▀▀▀
    ██        ██   █▄      ██     ██      ▀███                    ██   █▄      ██▄   ██     ██
    ██        ██    ▀█▄   ██       ██      ██         ██████████████    ▀█▄     ▀████▀      ███████

 %cTip%c: Type \`theme\` to inspect it directly within the console...
  `,

    black,
    blue,
    black,
    blue,
    black,
    bolded,
    normal,
  )
}

export const context = createContext()

export const NamedProvider = memo(({ themeName, children, sbAccentColor }) => {
  const [accentColor, setAccentColor] = useState('grey') // app default

  const theme = themes[themeName]({ accentColor: sbAccentColor || accentColor })

  useEffectOnce(() => {
    /* istanbul ignore next line */
    if (isDevEnv()) {
      consoleHero()

      window.top.theme = theme
    }
  })

  return (
    <context.Provider value={{ setAccentColor }}>
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </context.Provider>
  )
})
NamedProvider.propTypes = {
  children: PropTypes.node.isRequired,
  sbAccentColor: PropTypes.string, // only present within Storybook
  themeName: PropTypes.string.isRequired,
}

const ConnectedProvider = ({ children }) => {
  const themeName = useThemeName()

  return <NamedProvider themeName={themeName}>{children}</NamedProvider>
}
ConnectedProvider.propTypes = { children: PropTypes.node.isRequired }

const ThemeProvider = ({ children, override }) =>
  override ? (
    <NamedProvider themeName={override}>{children}</NamedProvider>
  ) : (
    <ConnectedProvider>{children}</ConnectedProvider>
  )

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
  override: PropTypes.string,
}

export default ThemeProvider
