import { TreeView } from '@mui/x-tree-view'
import {
  CONTENT_TYPE_ECHO,
  CONTENT_TYPE_SECTION,
  CONTENT_TYPE_SOURCE,
  CONTENT_TYPE_SUBSECTION,
} from 'core/consts'
import { push } from 'fp/arrays'
import useContent from 'hooks/useContent'
import PropTypes from 'prop-types'
import { forwardRef, useEffect, useId, useState } from 'react'
import { useSelector } from 'react-redux'
import { getContentNav, getParentFromContentId } from 'selectors/contentViewer'
import { getContentViewerParams } from 'selectors/contentViewerParams'
import Sections from './Sections'

const ChapterTree = forwardRef(
  ({ actionsHeight = 0, contentId, maxHeight }, ref) => {
    const sections = useSelector(getContentNav({ contentId }))

    const { echoId, navTreeSubsectionId, sourceId, subsectionId } =
      useSelector(getContentViewerParams()) || {}

    const possibleSubsection = useContent({
      contentType: CONTENT_TYPE_SUBSECTION,
      contentId: subsectionId || sourceId,
      disableFetch: true,
    })

    const possibleSource = useContent({
      contentType: CONTENT_TYPE_SOURCE,
      contentId: sourceId,
      disableFetch: true,
    })

    const possibleEcho = useContent({
      contentId: echoId,
      contentType: CONTENT_TYPE_ECHO,
      disableFetch: true,
    })

    const currentSubsection =
      possibleSubsection || possibleSource || possibleEcho

    const subsectionParent = useSelector(
      getParentFromContentId({
        contentType: CONTENT_TYPE_SUBSECTION,
        contentId: possibleSubsection
          ? navTreeSubsectionId || possibleSubsection?.id
          : possibleSubsection?.id,
      }),
    )

    const sourceParent = useSelector(
      getParentFromContentId({
        contentType: CONTENT_TYPE_SOURCE,
        contentId: possibleSource
          ? navTreeSubsectionId || possibleSource?.id
          : possibleSource?.id,
      }),
    )

    const echoParent = useSelector(
      getParentFromContentId({
        contentType: CONTENT_TYPE_ECHO,
        contentId: possibleEcho?.id,
      }),
    )

    const currentSection = String(navTreeSubsectionId).startsWith('TE-')
      ? sections[0]
      : subsectionParent || sourceParent || echoParent

    const currentParentSection = useSelector(
      getParentFromContentId({
        contentType: CONTENT_TYPE_SECTION,
        contentId: currentSection?.id,
      }),
    )

    const [expanded, setExpanded] = useState([])
    const [selected, setSelected] = useState([])
    const id = useId()

    useEffect(() => {
      if (currentSection?.data?.tabbed) {
        // a section within a section is selected -- expand the grandparent
        if (currentParentSection) {
          setExpanded(push(currentParentSection?.id))
        }
        setSelected([currentSection?.id])
      } else {
        setExpanded(push(currentSection?.id))
        setSelected([navTreeSubsectionId || currentSubsection?.id])
      }
    }, [
      currentParentSection,
      currentSection,
      currentSubsection,
      navTreeSubsectionId,
    ])

    const handleToggle = (_, nodeIds) => {
      setExpanded(nodeIds)
    }

    return sections ? (
      <TreeView
        aria-label="navigation tree"
        data-subvariant="content-viewer"
        expanded={expanded}
        id={id}
        onNodeToggle={handleToggle}
        ref={ref}
        selected={selected}
        style={{
          /**
           * This component is restricted in height in order to cause a scrollbar
           * to appear when needed.
           *
           * A bit of padding is added to the bottom so things feel less cramped.
           * Padding equal to the height of the `actions` area is also added so
           * that the scrollbar appears "behind" the `actions` area.
           *
           * SEE ALSO:
           *
           *    SourceTree  - This is pretty much the same as this component.
           *                  Make sure to apply any required edits to both files
           *
           *    Nav/Actions - This is where the `actions` are defined
           *
           *    SectionTree - This is where the `actions` height is defined
           */
          height: maxHeight,
          maxHeight,
          overflowY: 'auto',
          paddingBottom: 40 + actionsHeight,
        }}
        variant="nav-shell">
        <Sections sections={sections} />
      </TreeView>
    ) : null
  },
)

ChapterTree.propTypes = {
  actionsHeight: PropTypes.number,
  contentId: PropTypes.string.isRequired,
  maxHeight: PropTypes.string.isRequired,
}

export default ChapterTree
