import Button from '@mui/material/Button'
import Popper from 'common/indicators/Popper'
import { contentShape } from 'core/shapes'
import { memo } from 'react'
import VocabularyPopperContent from './VocabularyPopperContent'

const paperStyle = {
  minWidth: 495,
}

const VocabularyItem = memo(
  ({ content: vocabPhrase }) => {
    /**
     * TODO: !!!
     * This was all demo code up until now.
     *
     * It used to take these props (this was before vocab was a content flavour):
     *  { definitions, entry, href }
     *
     * This whole file probably needs a rethink, since the vocab content will have
     * blocks.  It also needs to render the 3 types of vocab differently (academic,
     * content and footnote)
     */
    const { label, name } = vocabPhrase

    return (
      <Popper paperStyle={paperStyle}>
        <Popper.Control>
          <Button
            data-subvariant="vocab"
            variant="mock-link">
            {name || label}
          </Button>
        </Popper.Control>

        <Popper.Content>
          <VocabularyPopperContent vocabPhrase={vocabPhrase} />
        </Popper.Content>
      </Popper>
    )
  },
  (prevProps, nextProps) => prevProps.content === nextProps.content,
)

VocabularyItem.propTypes = {
  content: contentShape.isRequired,
}

export default VocabularyItem
