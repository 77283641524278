/** ****************************************************************************
 *                                                                             *
 * On SSC:                                                                     *
 *                                                                             *
 * You'll find references to an 'ssc' url query param throughout the app.      *
 * This stands for "Split Screen Content" and is a base-64 encoded collection  *
 * of information about whatever is stored in the pinned right-half-side of    *
 * the screen.                                                                 *
 *                                                                             *
 * Search for `ssc` alone to find places where the raw value is handled.       *
 * Search for 'SplitScreenContent' for the higher, surface level stuff.        *
 *                                                                             *
 **************************************************************************** */

import PropTypes from 'prop-types'
import { createContext, useMemo } from 'react'

const pinningContext = createContext()
export const rightSideContext = createContext()

const PinnedRouteProvider = ({ children, testingState }) => (
  <pinningContext.Provider value={testingState}>
    {children}
  </pinningContext.Provider>
)

export const RightSideProvider = props => {
  const value = useMemo(() => ({ isRightSide: true }), [])

  return (
    <rightSideContext.Provider
      value={value}
      {...props}
    />
  )
}

PinnedRouteProvider.propTypes = {
  children: PropTypes.node.isRequired,
  testingState: PropTypes.object,
}

export default PinnedRouteProvider
