const MODIFIER_ALT = 'alt'
const MODIFIER_SHIFT = 'shift'

const bindings = [
  { name: 'Bold', key: 'b' },
  { name: 'Italic', key: 'i' },
  { name: 'Underline', key: 'u' },
  { name: 'Strikethrough', key: 'd' },
  { name: 'Insert Link', label: 'Link', command: 'link', key: 'k' },
  {
    name: 'Normal Text',
    label: 'Format',
    command: 'P',
    key: '0',
    modifier: MODIFIER_ALT,
  },
  {
    name: 'Heading 1',
    label: 'Format',
    command: 'header-one',
    key: '1',
    modifier: MODIFIER_ALT,
  },
  {
    name: 'Heading 2',
    label: 'Format',
    command: 'header-two',
    key: '2',
    modifier: MODIFIER_ALT,
  },
  {
    name: 'Heading 3',
    label: 'Format',
    command: 'header-three',
    key: '3',
    modifier: MODIFIER_ALT,
  },
  {
    name: 'Ordered List',
    label: 'Lists',
    command: 'ordered-list-item',
    key: '7',
    modifier: MODIFIER_SHIFT,
  },
  {
    name: 'Unordered List',
    label: 'Lists',
    command: 'unordered-list-item',
    key: '8',
    modifier: MODIFIER_SHIFT,
  },
  {
    name: 'Quote',
    label: 'Format',
    command: 'blockquote',
    key: '9',
    modifier: MODIFIER_SHIFT,
  },
  { name: 'Indent', label: 'Indentation', command: 'increase', key: ']' },
  { name: 'Outdent', label: 'Indentation', command: 'decrease', key: '[' },
  {
    name: 'Toggle first line indent',
    label: 'Indentation',
    command: 'indentFirstLine',
    key: 'f',
  },
]

export default bindings
