import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import cl from 'classnames'
import { when } from 'fp/utils'
import MinimalPause from 'hss/images/controls/audio/minimal-pause.svg'
import MinimalPlay from 'hss/images/controls/audio/minimal-play.svg'
import RegularPause from 'hss/images/controls/audio/regular-pause.svg'
import RegularPlay from 'hss/images/controls/audio/regular-play.svg'
import Stop from 'hss/images/controls/audio/stop.svg'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import { audioContext } from './AudioContextProvider'

const fullSvgProps = {
  viewBox: '0 0 48 48',
  width: 48,
  height: 48,
  overflow: 'visible',
}

const StyledIconButton = styled(IconButton, { name: 'PlayButton-IconButton' })(
  ({ theme: { palette, shadows } }) => ({
    padding: 0,
    borderStyle: 'none',
    '&:hover': {
      svg: {
        strokeWidth: 2,
      },
    },
    '&.minimal': {
      borderStyle: 'solid',
      width: 48,
      borderRadius: 0,
      '&.playing': {
        backgroundColor: palette.grey[0],
        color: palette.grey.contrastText,
        svg: {
          margin: '0 !important',
          path: {
            fill: palette.grey.contrastText,
            stroke: palette.grey.contrastText,
          },
        },
        '&:hover': {
          backgroundColor: palette.background.paper,
          boxShadow: shadows.borderInsetButton,
          svg: {
            path: {
              fill: palette.grey[1],
              stroke: palette.grey[1],
            },
          },
        },
      },
    },
  }),
)

const PlayButton = ({ buttonOnly = false, minimal = false, className }) => {
  const { loaded, playStatus, setPlayStatus, setPosition } =
    useContext(audioContext)

  const playing = playStatus === 'PLAYING'

  const handleClick = () => {
    setPlayStatus(playing ? 'STOPPED' : 'PLAYING')
    // with no controls, instead of pausing where we are, just stop and reset to beginning
    when(buttonOnly, setPosition, 0)
  }

  const PlayIcon = minimal ? MinimalPlay : RegularPlay

  const PauseIcon = buttonOnly ? Stop : minimal ? MinimalPause : RegularPause

  const progressSize = minimal ? '3rem' : '2rem'

  const svgProps = minimal ? null : fullSvgProps

  return (
    <StyledIconButton
      aria-label={playing ? 'Pause' : 'Play'}
      aria-pressed={playing}
      className={cl(className, { minimal, playing })}
      color="secondary"
      data-bodyvariant="body3"
      disabled={!loaded}
      onClick={handleClick}
      size={minimal ? 'small' : 'medium'}
      value={`playing-${playing}`}>
      {loaded ? (
        playing ? (
          <PauseIcon {...svgProps} />
        ) : (
          <PlayIcon {...svgProps} />
        )
      ) : (
        <CircularProgress size={progressSize} />
      )}
    </StyledIconButton>
  )
}

PlayButton.propTypes = {
  buttonOnly: PropTypes.bool,
  minimal: PropTypes.bool,
}

export default PlayButton
