/* istanbul ignore file */
export const minimumTextFieldHeight = 47

const overrides = ({ mixins: { importantPx }, palette, typography }) => ({
  MuiTextField: {
    defaultProps: {
      size: 'medium',
    },

    styleOverrides: {
      root: {
        '& .MuiSelect-select': {
          paddingRight: importantPx(40),
          '&.MuiOutlinedInput-input': {
            display: 'flex',
          },
        },
      },
    },

    variants: [
      {
        props: { 'data-variant': 'collapsed-tablist' },
        style: {
          ...typography.variants['tab-title'],
          fieldset: {
            borderColor: palette.grey[4],
          },
          '.MuiSelect-select': {
            paddingTop: 8,
            height: '100%',
            paddingBottom: 8,
          },
          '.MuiSelect-icon': {
            top: 7,
            right: 12,
            transform: 'scale(0.75)',
          },
        },
      },

      {
        props: { 'data-variant': 'swatch' },
        style: {
          '.MuiSelect-select': {
            padding: 0,
            height: minimumTextFieldHeight,
          },
        },
      },
    ],
  },
})

export default overrides
