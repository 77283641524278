import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'
import { FEATURE_FLAG_POST_MVP } from 'core/consts'
import useAbilityCheck from 'hooks/useAbilityCheck'
import useNavigation from 'hooks/useNavigation'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { Plus } from 'react-feather'
import { useSelector } from 'react-redux'
import {
  contentViewerUrl,
  getTeacherAssignmentCreateRoute,
} from 'routing/consts'
import { getViewerTopLevelContent } from 'selectors/contentViewer'
import { stateRouting } from 'selectors/index'
import { isStaff, isSysAdmin } from 'selectors/users'

const Wrapper = forwardRef(({ children }, ref) => {
  const { palette } = useTheme()

  return (
    <Stack
      alignItems="stretch"
      gap={2}
      mx={2}
      py={2}
      ref={ref}
      sx={{
        /**
         * Possible TODO:
         * It might be cool if instead of a solid background color we used
         * either a gradient with an alpha transparency or shadows of the same.
         * If we had that, then the hard cutoff where the tree view scrolls
         * behind us would instead have a smooth transition.  Totally not needed
         * but could be a nice-to-have.
         */
        backgroundColor: palette.drawer,
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
      }}>
      {children}
    </Stack>
  )
})
Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

const Actions = forwardRef((_, ref) => {
  const { navigate } = useNavigation()

  const postMVP = useAbilityCheck(FEATURE_FLAG_POST_MVP)
  const admin = useSelector(isSysAdmin)
  const mentor = useSelector(isStaff)
  const { currentLocation } = useSelector(stateRouting)
  const justViewing = currentLocation.pathname.startsWith(contentViewerUrl)

  const { contentType, id: contentId } =
    useSelector(getViewerTopLevelContent) || {}

  const handleAssignClick = () => {
    navigate(getTeacherAssignmentCreateRoute({ contentType, id: contentId }))
  }

  if (admin && postMVP) {
    return (
      <Wrapper ref={ref}>
        <Button startIcon={<Plus />}>Add Subsection</Button>
      </Wrapper>
    )
  }

  if (mentor && contentId && justViewing) {
    return (
      <Wrapper ref={ref}>
        <Button
          onClick={handleAssignClick}
          startIcon={<Plus />}
          variant="primary">
          Assign
        </Button>
      </Wrapper>
    )
  }

  return null
})

export default Actions
