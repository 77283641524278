import { createSelector } from '@comfy/redux-selectors'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'
import { get, mapKeys, omit, pick } from 'fp/objects'
import { fallbackTo } from 'fp/utils'
import Pagination from 'hss/sections/contentBlocks/interactives/FunFacts/Pagination'
import PropTypes from 'prop-types'
import {
  Children,
  cloneElement,
  forwardRef,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { ArrowLeft, ArrowRight } from 'react-feather'
import { compose } from 'redux'
import {
  containedPaddingLeft,
  containedPaddingRight,
} from 'styling/theming/base'
import { px } from 'styling/theming/base/mixins'
import { Swiper, SwiperSlide } from 'swiper/react'
import useResizeObserver from 'use-resize-observer'
import { paginatedSmallSlides } from './configurations'

/** ****************************************************************************
 *                                IMPORTANT                                    *
 *******************************************************************************
 *                                                                             *
 * Most of our designs place the nav buttons (when present) so that they are   *
 * centered vertically on a particular feature of the slides. Oftentimes this  *
 * is a poster image and as long as that takes up the whole height of the      *
 * slide then all is good. However sometimes there may be more card content    *
 * below or above the image.  Long story short, the vertical center of the     *
 * slide is not always the "center" of the slide and we'll usually want to use *
 * the image as the center.                                                    *
 *                                                                             *
 * To accommodate these cases, a ref is passed to the first slide Renderer.    *
 * You should attach this ref to the part of the slide that you want the nav   *
 * buttons to center on -- for example, the poster image.                      *
 *                                                                             *
 **************************************************************************** */

/** ****************************************************************************
 *                              KNOWN ISSUES                                   *
 *******************************************************************************
 *                                                                             *
 *    1) The navigation buttons sometimes stop responding to clicks after the  *
 *       screen is resized.  This appears to be an issue in `swiper` and not   *
 *       due to anything we're doing                                           *
 *                                                                             *
 **************************************************************************** */
const NavButton = forwardRef((props, ref) => (
  <IconButton
    className="carousel-nav"
    color="secondary"
    data-bodyvariant="body1"
    ref={ref}
    size="medium"
    variant="secondary"
    {...props}
  />
))

const Carousel = forwardRef((props, ref) => {
  const {
    children,
    configuration,
    sx,
    /**
     * Don't add `...rest` here.  We're explicitly passing in all settings via
     * the `configuration` object so it can be massaged and transformed.
     */
  } = props

  const backupRef = useRef()
  const buttonPrevRef = useRef(null)
  const buttonNextRef = useRef(null)
  const firstSlideRef = useRef(null)
  const swiperRef = useRef()
  const carouselRef = ref || backupRef

  const { height: slideHeight } = useResizeObserver({ ref: firstSlideRef })

  // When Carousel is not set to loop, we need to disable the nav buttons when at the very beginning or end
  const isNoLoop = configuration?.loop === false
  const [buttonPrevDisabled, setButtonPrevDisabled] = useState(isNoLoop)
  const [buttonNextDisabled, setButtonNextDisabled] = useState(false)

  const slides = Children.toArray(
    // don't try this at home
    Children.map(children, (child, idx) =>
      idx ? child : cloneElement(child, { ref: firstSlideRef }),
    ),
  )

  const {
    breakpoints: { keys, values },
  } = useTheme()

  const assertedConfig = useMemo(
    () =>
      createSelector(
        // base
        omit(keys),

        // breakpoints
        compose(
          mapKeys(key => values[key]),
          pick(keys),
        ),

        // navigation
        compose(
          navigation =>
            navigation.enabled
              ? {
                  ...navigation,
                  prevEl: buttonPrevRef.current,
                  nextEl: buttonNextRef.current,
                }
              : navigation,
          fallbackTo(false),
          get('navigation'),
        ),

        // pagination
        compose(
          pagination =>
            pagination.className
              ? { el: `.${pagination.className}` }
              : pagination,
          fallbackTo(false),
          get('pagination'),
        ),

        (base, breakpoints, navigation, pagination) => ({
          ...base,
          breakpoints,
          navigation,
          pagination,
        }),
      )(configuration || paginatedSmallSlides),

    [configuration, keys, values],
  )

  // biome-ignore lint/complexity/noExcessiveCognitiveComplexity: not horrible
  const updateButtonLocations = useCallback(() => {
    if (assertedConfig.navigation?.centeredOnSlides) {
      const button = buttonPrevRef.current?.getBoundingClientRect() || {}
      const { top } = carouselRef.current?.getBoundingClientRect() || {}

      const { top: slideTop } =
        firstSlideRef.current?.getBoundingClientRect() || { top }
      const buttonTop = slideTop - top + slideHeight / 2 - button.height / 2
      const buttonDx = button.width / 2
      const opacity = swiperRef.current?.isLocked ? 0 : 1 // locked means no slides overflow, so no nav needed
      if (buttonPrevRef.current) {
        buttonPrevRef.current.style.left = px(
          assertedConfig.navigation?.contained
            ? containedPaddingLeft
            : -buttonDx,
        )
        buttonPrevRef.current.style.top = px(buttonTop)
        buttonPrevRef.current.style.position = 'absolute'
        buttonPrevRef.current.style.opacity = opacity
      }
      if (buttonNextRef.current) {
        buttonNextRef.current.style.right = px(
          assertedConfig.navigation?.contained
            ? containedPaddingRight
            : -buttonDx,
        )
        buttonNextRef.current.style.top = px(buttonTop)
        buttonNextRef.current.style.position = 'absolute'
        buttonNextRef.current.style.opacity = opacity
      }
    }
  }, [assertedConfig.navigation, carouselRef, slideHeight])

  const handleNavigation = direction => () => {
    if (direction === 'next') {
      swiperRef?.current?.slideNext()

      if (isNoLoop && swiperRef?.current?.isEnd) {
        setButtonNextDisabled(true)
        setButtonPrevDisabled(false)
      }
    } else {
      swiperRef?.current?.slidePrev()

      if (isNoLoop && swiperRef?.current?.isBeginning) {
        setButtonNextDisabled(false)
        setButtonPrevDisabled(true)
      }
    }
  }

  useLayoutEffect(() => {
    updateButtonLocations()
  }, [updateButtonLocations])
  return (
    <Box sx={{ position: 'relative', ...sx }}>
      <Swiper
        ref={carouselRef}
        wrapperTag="ul"
        {...assertedConfig}
        onBeforeInit={swiper => {
          swiperRef.current = swiper
        }}
        onUnlock={() => {
          updateButtonLocations()
        }}>
        {slides.map((slide, idx) => (
          <SwiperSlide
            key={idx}
            tag="li">
            {slide}
          </SwiperSlide>
        ))}
      </Swiper>
      {Boolean(assertedConfig.navigation) && (
        <Stack direction="row">
          <NavButton
            disabled={buttonPrevDisabled}
            onClick={handleNavigation('prev')}
            ref={buttonPrevRef}>
            <ArrowLeft />
          </NavButton>
          {Boolean(assertedConfig.pagination?.el) && (
            <Pagination className={configuration.pagination.className} />
          )}
          <NavButton
            disabled={buttonNextDisabled}
            onClick={handleNavigation('next')}
            ref={buttonNextRef}>
            <ArrowRight />
          </NavButton>
        </Stack>
      )}
    </Box>
  )
})

Carousel.propTypes = {
  children: PropTypes.node,
  /**
   * Not adding a shape here as there's too many options and variations to
   * account for.  If we ever bring in TypeScript, then there are types
   * available from the 3rd party component.
   *
   * For available options, see:
   * https://swiperjs.com/swiper-api#parameters
   *
   */
  configuration: PropTypes.object,
  sx: PropTypes.object,
}
export default Carousel
