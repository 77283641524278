/* istanbul ignore file */

import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import { isObject } from 'fp/objects'
import withCopyToClipboard from 'hoc/withCopyToClipboard'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Clipboard } from 'react-feather'
import { useWatch } from 'react-hook-form'

const CopyButton = withCopyToClipboard(IconButton)

const DebugViewInternalContent = ({ formField, fieldsToWatch }) => {
  const [currentInternalContent, setCurrentInternalContent] = useState('')

  const watching = useWatch(fieldsToWatch || { name: formField })

  const rteBody = isObject(watching) ? watching[formField] : watching

  useEffect(() => {
    setCurrentInternalContent(rteBody)
  }, [rteBody])

  return (
    <Box
      color="error.main"
      my={2}>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between">
        <div>
          <b>DEBUG - INTERNAL CONTENT</b> (read-only)
        </div>
        <div>
          <Tooltip title="Copy contents to clipboard">
            <CopyButton text={currentInternalContent}>
              <Clipboard />
            </CopyButton>
          </Tooltip>
        </div>
      </Stack>
      <Box
        border="2px solid"
        borderColor="error.main"
        color="error.main"
        component="textarea"
        fontFamily="Consolas, Menlo, Monaco, 'Courier New', monospace"
        fontSize="1.4rem"
        maxHeight={220}
        minHeight={220}
        padding={1}
        readOnly
        style={{ overflowY: 'auto' }}
        value={currentInternalContent}
      />
    </Box>
  )
}

DebugViewInternalContent.propTypes = {
  formField: PropTypes.string.isRequired,
  fieldsToWatch: PropTypes.arrayOf(PropTypes.object),
}
export default DebugViewInternalContent
