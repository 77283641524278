import { assert } from 'core/store/search/squery'
import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import actionTypes from 'reducers/actionTypes'
import { failure, success } from 'sagas/utils'
import { getSquery } from 'selectors/routing'
import useEffectOnce from './useEffectOnce'
import useReduxPromise from './useReduxPromise'

const resolve = ({ type }) =>
  [
    success(actionTypes.ASSIGNMENT_FETCH_LIST),
    success(actionTypes.CONTENT_FETCH_LIST),
    success(actionTypes.DISTRICT_FETCH_LIST),
    success(actionTypes.GROUP_FETCH_LIST),
    success(actionTypes.SCHOOL_FETCH_LIST),
    success(actionTypes.USER_ASSIGNMENT_FETCH_LIST),
    success(actionTypes.USER_FETCH_LIST),
  ].includes(type)

const reject = ({ type }) =>
  [
    failure(actionTypes.ASSIGNMENT_FETCH_LIST),
    failure(actionTypes.CONTENT_FETCH_LIST),
    failure(actionTypes.DISTRICT_FETCH_LIST),
    failure(actionTypes.GROUP_FETCH_LIST),
    failure(actionTypes.SCHOOL_FETCH_LIST),
    failure(actionTypes.USER_ASSIGNMENT_FETCH_LIST),
    failure(actionTypes.USER_FETCH_LIST),
  ].includes(type)

const useRouteSquery = ({
  restEndpoint,
  initialSquery = {},
  searchOnLoad,
  searchUrl,
}) => {
  const [dataset, setDataset] = useState()
  const existingSquery = useSelector(getSquery)
  const call = useReduxPromise(
    actionTypes.ROUTE_SET_SQUERY_ENDPOINT,
    undefined,
    resolve,
    reject,
  )

  const updateUrlAndResultingDataset = useCallback(
    async squery =>
      call({
        restEndpoint,
        squery,
        searchUrl,
      }).then(({ response }) => {
        setDataset(response)
      }),
    [call, restEndpoint, searchUrl],
  )

  useEffectOnce(() => {
    if (searchOnLoad || existingSquery) {
      updateUrlAndResultingDataset(existingSquery || assert(initialSquery))
    }
  })

  const setSquery = useCallback(
    newSquery => {
      updateUrlAndResultingDataset(newSquery)
    },
    [updateUrlAndResultingDataset],
  )

  return [
    existingSquery || assert(initialSquery),
    setSquery,
    dataset,
    setDataset, // this is here only as an escape hatch. Don't use it!
  ]
}

export default useRouteSquery
