import { convertToHTML } from 'draft-convert'
import { callWith } from 'fp/call'
import { get } from 'fp/objects'
import { isUndefined } from 'fp/utils'
import { compose } from 'redux'

// Ignored lines are only for dev environments

const blockToHTML = (editorState, features, plugins) => block => {
  const output = plugins
    .map(get('blockToHTML'))
    .filter(Boolean)
    .map(callWith(features))
    .reduce((acc, f) => f(acc, block, editorState), undefined)

  // istanbul ignore next line
  if (isUndefined(output) && block.type !== 'blockquote') {
    /**
     * Block quotes were previously handled in simpleFormatting, but since they can
     * have an optional variant, they are now handled by extendedFormatting.  This
     * would cause false positives here though for normal block quotes, so we're
     * just going to ignore any "unhandled" block quotes.
     */
    // biome-ignore lint/suspicious/noConsole: intended
    console.warn(
      'UNKNOWN BLOCK ->',
      block.type,
      '(in RichTextEdit/exporting/index.js)',
    )
  }

  return output
}

const entityToHTML = (features, plugins) => (entity, originalText) => {
  const output = plugins
    .map(get('entityToHTML'))
    .filter(Boolean)
    .map(callWith(features))
    .reduce(
      (acc, f) =>
        /* istanbul ignore next line */ f(entity, originalText) || acc,
      undefined,
    )

  // istanbul ignore next line
  if (isUndefined(output)) {
    // biome-ignore lint/suspicious/noConsole: intended
    console.warn(
      'UNKNOWN ENTITY ->',
      entity.type,
      '(in RichTextEdit/exporting/index.js)',
    )
    return originalText
  }
  return output
}

const styleToHTML = (features, plugins) => style => {
  const output = plugins
    .map(get('styleToHTML'))
    .filter(Boolean)
    .map(callWith(features))
    .reduce((acc, f) => f(acc, style), undefined)

  // istanbul ignore next line
  if (isUndefined(output)) {
    // biome-ignore lint/suspicious/noConsole: intended
    console.warn(
      'UNKNOWN STYLE ->',
      style,
      '(in RichTextEdit/exporting/index.js)',
    )
  }
  return output
}

const exportingPostProcess = (features, plugins) => output =>
  plugins
    .map(get('exportingPostProcess'))
    .filter(Boolean)
    .map(callWith(features))
    .reduce((acc, f) => f(acc), output)

const contentToHtml = (editorState, features, plugins) => {
  const handlers = {
    blockToHTML: blockToHTML(editorState, features, plugins),
    entityToHTML: entityToHTML(features, plugins),
    styleToHTML: styleToHTML(features, plugins),
  }

  return compose(
    exportingPostProcess(features, plugins),
    convertToHTML(handlers),
  )(editorState.getCurrentContent())
}

export default contentToHtml
