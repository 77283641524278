import { callWith } from 'fp/call'
import { buildUrl } from 'fp/internet'
import { when } from 'fp/utils'
import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { failure, success } from 'sagas/utils'
import { isTestEnv } from 'selectors/index'
import useApiFromEffect from './useApiFromEffect'
import { useDeepCompareEffect } from './useDeepCompare'

const useDataset = ({ restEndpoint, squery, actionType = undefined }) => {
  const [dataset, setDataset] = useState({ data: [], metadata: {} })
  const callApi = useApiFromEffect()
  const dispatch = useDispatch()

  const fetchRows = useCallback(
    async search => {
      const result = isTestEnv()
        ? callApi({
            url: buildUrl(restEndpoint, { search }, false),
          })
        : /* istanbul ignore next */ await callApi({
            url: buildUrl(restEndpoint, { search }, false),
          })

      when(result.isRightValue, setDataset, result.value)

      when(actionType, dispatch, {
        type: callWith(actionType)(
          result.isRightValue || actionType === 'NoTaR3Al@cT10N'
            ? success
            : failure,
        ),
        payload: result.value,
      })
    },
    [actionType, callApi, dispatch, restEndpoint],
  )

  useDeepCompareEffect(() => {
    fetchRows(squery)
  }, [fetchRows, squery])

  return dataset
}

export default useDataset
