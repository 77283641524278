import { PROFICIENCIES } from 'core/consts'
import { findObj } from 'fp/arrays'
import { get } from 'fp/objects'

export const proficiencyLabels = proficiencyIds =>
  proficiencyIds
    .map(i => findObj('id', i)(PROFICIENCIES))
    .filter(Boolean)
    .map(get('name'))
    .join(' / ')
