import Icon from '@mui/material/Icon'
import { sortBy } from 'fp/arrays'
import { callWith } from 'fp/call'
import { get, hasProperty } from 'fp/objects'
import { isFunction } from 'fp/utils'
import { useMemo } from 'react'
import { MoreHorizontal } from 'react-feather'
import plugins from '.'
import DraftMenuButton from '../toolbar/DraftMenuButton'
import { toolbarPropTypes } from '../utils/misc'
import ContentWrappingDropdown from './ContentWrappingDropdown'
import ChooseInteractiveButton from './interactivePlugin/ChooseInteractiveButton'
import AlignmentDropdown from './tablePlugin/AlignmentDropdown'

const RenderToolButtons = props => {
  const { allowedInteractives = [], features } = props

  const children = useMemo(() => {
    const items = [
      () =>
        features.interactives && allowedInteractives !== 'none'
          ? /**
             * This is here instead of within `interactivePlugin` in order to escape
             * a recursive import cycle
             */
            [ChooseInteractiveButton]
          : [],

      ...plugins.map(get('ToolBarItem')),

      /**
       * Ideally these should be located within their plugins, however that would
       * cause dependency cycles 😒
       */
      ({ features: { tables } }) =>
        tables ? [AlignmentDropdown, ContentWrappingDropdown] : [],
    ]

    return items //
      .filter(Boolean)
      .flatMap(
        callWith({
          allowedInteractives,
          features,
        }),
      )
  }, [allowedInteractives, features])

  const buttons = children.filter(isFunction)

  const menuItems = children
    .filter(item => hasProperty('key')(item) && hasProperty('label')(item))
    .sort(sortBy('label'))

  const handleMenuChange = item => {
    plugins //
      .map(get('handleMenuChange'))
      .filter(Boolean)
      .flatMap(callWith(item, props))
  }

  return (
    <>
      {buttons.map((Button, key) => (
        <Button
          key={key}
          {...props}
        />
      ))}

      {Boolean(menuItems.length) && (
        <DraftMenuButton
          disabled={props.disabled}
          featureMenuKey="extended"
          features={features}
          label="More features..."
          onChange={handleMenuChange}
          options={menuItems}>
          <Icon>
            <MoreHorizontal />
          </Icon>
        </DraftMenuButton>
      )}
    </>
  )
}

RenderToolButtons.propTypes = toolbarPropTypes

export default RenderToolButtons
