import { isMobile } from 'fp/internet'
import { useEffect, useState } from 'react'
import { useDragDropManager } from 'react-dnd'

// for mobile, scroll the window when dragging (HTML5 backend already supports auto-scrolling)
const useDragPreviewScrolling = () => {
  const dragDropManager = useDragDropManager()

  const monitor = dragDropManager.getMonitor()

  const [config, setConfig] = useState({
    scrollAllowed: false,
    isDragging: false,
  })
  const { isDragging, scrollDirection } = config
  const scrollContainer =
    document.querySelector('.layout-pane-primary') || window
  const isTouch = isMobile()

  // subscribe to the dragging event
  useEffect(() => {
    const unsubscribe = monitor.subscribeToOffsetChange(() => {
      const offset = monitor.getSourceClientOffset()?.y

      setConfig({
        scrollDirection:
          offset > window.innerHeight - 50 ? 1 : offset < 50 ? -1 : 0,
        isDragging: monitor.isDragging(),
      })
    })
    return () => {
      unsubscribe()
    }
  }, [monitor])

  useEffect(() => {
    const interval = setInterval(() => {
      if (isDragging && scrollDirection && isTouch) {
        scrollContainer.scrollBy(0, scrollDirection * 1)
      }
    }, 1)

    return () => {
      clearInterval(interval)
    }
  }, [isDragging, isTouch, scrollDirection, scrollContainer])
}

export default useDragPreviewScrolling
