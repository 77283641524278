import { componentShape } from 'core/shapes'
import { dedupe } from 'fp/arrays'
import { createContext, useMemo, useState } from 'react'

const formIslandContext = createContext()

const FormIslandProvider = ({ children }) => {
  const [itemNames, setItemNames] = useState([])

  const value = useMemo(
    () => ({
      itemNames,
      registerName: name => setItemNames(prev => dedupe([...prev, name])),
    }),
    [itemNames],
  )
  return (
    <formIslandContext.Provider value={value}>
      {children}
    </formIslandContext.Provider>
  )
}

FormIslandProvider.propTypes = { children: componentShape.isRequired }

export { formIslandContext, FormIslandProvider }
