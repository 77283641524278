import { removeByIndex, swapByIndex } from 'fp/arrays'
import { assertProp, set } from 'fp/objects'
import { generateId } from 'fp/utils'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { arrayMove } from 'react-movable'
import { compose } from 'redux'

const BasicList = props => {
  const { children, items, nonHookForm, onChange, value: val, ...rest } = props

  const value = nonHookForm ? items : val

  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [userHasAddedItems, setUserHasAddedItems] = useState(false)

  const addItem = item => {
    setUserHasAddedItems(true)
    const itemWithId = assertProp('id', generateId())(item)
    onChange({ target: { value: [...value, itemWithId] } }, 'yes?')
  }

  const insertItem = (item, idx) => {
    compose(
      arr => {
        onChange({ target: { value: arr } })
      },
      arr => arrayMove(arr, arr.length - 1, idx),
      () => [...value, item],
    )(item)
  }

  const moveItem = (i, j) => {
    onChange({ target: { value: arrayMove(value, i, j) } })
  }

  const removeItem = idx => {
    onChange({ target: { value: removeByIndex(idx)(value) } })
  }

  const replaceItem = (idx, item) => {
    onChange({ target: { value: set(idx, item)(value) } })
  }

  const sortItems = sortFunction => {
    onChange({ target: { value: Array.from(value).sort(sortFunction) } })
  }

  const swapItems = (i, j) => {
    onChange({ target: { value: swapByIndex(i, j)(value) } })
  }

  return children?.({
    addItem,
    insertItem,
    items: value,
    moveItem,
    removeItem,
    replaceItem,
    selectedIndex,
    setSelectedIndex,
    sortItems,
    swapItems,
    userHasAddedItems,
    ...rest,
  })
}

BasicList.propTypes = {
  children: PropTypes.func,
  name: PropTypes.string.isRequired,
}

export const listShape = {
  addItem: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  removeItem: PropTypes.func.isRequired,
  replaceItem: PropTypes.func.isRequired,
  sortItems: PropTypes.func.isRequired,
  swapItems: PropTypes.func.isRequired,
}

export default BasicList
