import { filter, push } from 'fp/arrays'
import { notEquals } from 'fp/objects'
import { whenPresent } from 'fp/utils'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions } from 'reducers/notifications'
import { stateNotifications } from 'selectors/index'

export const anchorOrigin = {
  horizontal: 'left',
  vertical: 'bottom',
}

const Alerts = () => {
  const dispatch = useDispatch()
  const [displayed, setDisplayed] = useState([])
  const { alerts } = useSelector(stateNotifications)
  const { closeSnackbar, enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    for (const alert of alerts) {
      const {
        key,
        message,
        options = {
          variant: 'success',
        },
        dismissed = false,
      } = alert

      if (dismissed) {
        closeSnackbar(key)
        continue
      }

      if (displayed.includes(key)) continue

      enqueueSnackbar(message, {
        key,
        ...options,
        onClose: (event, reason, k) =>
          whenPresent(options.onClose, event, reason, k),
        onExited: (_, k) => {
          dispatch(actions.removeAlert({ key: k }))
          setDisplayed(filter(notEquals(k)))
        },
      })

      setDisplayed(push(key))
    }
  }, [alerts, closeSnackbar, dispatch, displayed, enqueueSnackbar])

  return null
}

export default Alerts
