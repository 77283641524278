import { styled } from '@mui/material/styles'
import { element } from 'core/shapes'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { animated, useSpring } from 'react-spring'
import ClickSlide from './ClickSlide'
import TouchSlide from './TouchSlide'

const Container = styled(animated.div, { name: '3d-Slide' })({
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  position: 'absolute',
  top: '50%',
  transformOrigin: '50% 50%',
  userSelect: 'none',
})

const Slide = props => {
  const {
    animationConfig,
    children,
    index,
    offsetRadius,
    ordinalIndex,
    setCurrentSlide,
  } = props
  const offsetFromCenter = index - offsetRadius
  const totalPresentableSlides = 2 * offsetRadius + 1
  const distanceFactor = 1 - Math.abs(offsetFromCenter * 0.1)
  const translateXoffset =
    50 * (Math.abs(offsetFromCenter) / (offsetRadius + 1))

  const zIndex = useMemo(
    () => Math.abs(Math.abs(offsetFromCenter) - offsetRadius),
    [offsetFromCenter, offsetRadius],
  )

  const translateX = useMemo(() => {
    let result = -50

    if (offsetRadius !== 0) {
      if (index === 0) {
        return 0
      }
      if (index === totalPresentableSlides - 1) {
        return -100
      }
    }

    if (offsetFromCenter < 0) {
      result += translateXoffset
    } else if (offsetFromCenter > 0) {
      result -= translateXoffset
    }

    return result
  }, [
    index,
    offsetFromCenter,
    offsetRadius,
    totalPresentableSlides,
    translateXoffset,
  ])

  const Factory = offsetFromCenter === 0 ? TouchSlide : ClickSlide

  const style = useSpring({
    config: animationConfig,
    to: {
      transform: `translateY(-50%) translateX(${translateX}%) scale(${distanceFactor})`,
      left: `${offsetRadius === 0 ? 50 : 50 + (offsetFromCenter * 50) / offsetRadius}%`,
    },
  })

  return (
    <Container style={{ ...style, zIndex }}>
      <Factory
        index={ordinalIndex}
        setCurrentSlide={setCurrentSlide}>
        {children}
      </Factory>
    </Container>
  )
}

Slide.propTypes = {
  animationConfig: PropTypes.object,
  children: element.isRequired,
  index: PropTypes.number.isRequired,
  offsetRadius: PropTypes.number.isRequired,
  ordinalIndex: PropTypes.number.isRequired,
  setCurrentSlide: PropTypes.func.isRequired,
}

export default Slide
