import { SCORING_RESULT_CORRECT } from 'core/consts'
import { map, reduce, sort, sortBy } from 'fp/arrays'
import { get, set } from 'fp/objects'
import { fallbackTo, isDefined, isUndefined } from 'fp/utils'
import { Maybe } from 'monet'
import { compose } from 'redux'

const loadScoreData = scoreData =>
  Maybe.fromUndefined(scoreData)
    .map(
      () => itemId =>
        isUndefined(scoreData[itemId])
          ? null
          : scoreData[itemId] === SCORING_RESULT_CORRECT,
    )
    .orJust(() => null)

export const getScore = ({ manualScoreOverride, score } = {}) =>
  isDefined(manualScoreOverride) ? manualScoreOverride : score

export const aggregateGroupOnlyResponses = (groups, interactions) =>
  compose(
    map(group => ({
      ...group,
      items: compose(
        sort(sortBy('count', 'desc')),
        map(([itemId, item]) => ({ itemId, ...item })),
        Object.entries,
      )(group.items),
    })),
    reduce(
      (groupsResult, interaction) => {
        const isCorrect = compose(
          loadScoreData,
          get('scoreData.result.byItemId'),
        )(interaction)

        return groupsResult.map(group => {
          const responseItemIds = compose(
            fallbackTo([]),
            get(`interactionData.selections.${group.id}`),
          )(interaction)
          const updatedItems = responseItemIds.reduce((items, nextItemId) => {
            const item = get(nextItemId)(items) || { count: 0 }
            return set(nextItemId, {
              count: item.count + 1,
              isCorrect: isCorrect(nextItemId),
            })(items)
          }, group.items)
          return set('items', updatedItems)(group)
        })
      },
      groups.map(set('items', {})),
    ),
  )(interactions)

export const aggregateGroupAndSortResponses = (groups, interactions) =>
  compose(
    map(group => ({
      ...group,
      items: group.items.map(
        compose(
          sort(sortBy('count', 'desc')),
          map(([itemId, item]) => ({ itemId, ...item })),
          Object.entries,
        ),
      ),
    })),
    reduce(
      (groupsResult, interaction) => {
        const isCorrect = compose(
          loadScoreData,
          get('scoreData.result.byItemId'),
        )(interaction)

        return groupsResult.map(group => {
          const responseItemIds = compose(
            fallbackTo([]),
            get(`interactionData.selections.${group.id}`),
          )(interaction)
          const updatedItems = responseItemIds.reduce(
            (items, nextItemId, index) => {
              const responses = items[index]
              const response = get(nextItemId)(responses) || { count: 0 }
              return set(`${index}.${nextItemId}`, {
                count: response.count + 1,
                isCorrect: isCorrect(nextItemId),
              })(items)
            },
            group.items,
          )
          return {
            ...group,
            items: updatedItems,
          }
        })
      },
      groups.map(group => set('items', [])(group)),
    ),
  )(interactions)
