import {
  CONTENT_TYPE_ECHO,
  CONTENT_TYPE_SUBSECTION,
  INTERACTION_TYPE_ANNOTATION,
} from 'core/consts'
import { filter, flatten, map, reduce } from 'fp/arrays'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions } from 'reducers/interactions'
import { compose } from 'redux'
import { getContentNav } from 'selectors/contentViewer'
import { getContentViewerParams } from 'selectors/contentViewerParams'
import { getAnnotationsForAssignment } from 'selectors/interactions'

/* istanbul ignore next */
const getSubsectionContent = content =>
  content
    ? content.contentType === CONTENT_TYPE_SUBSECTION ||
      content.contentType === CONTENT_TYPE_ECHO
      ? [content]
      : compose(flatten, map(getSubsectionContent))(content.children)
    : null

const useAnnotations = ({ sharedAssignmentId, userAssignmentId }) => {
  const dispatch = useDispatch()
  const { contentId } = useSelector(getContentViewerParams()) || {}
  const sections = useSelector(getContentNav({ contentId }))

  useEffect(() => {
    dispatch(
      actions.fetchInteractions({
        interactionType: INTERACTION_TYPE_ANNOTATION,
        userAssignmentId,
      }),
    )
  }, [dispatch, userAssignmentId])

  const annotations = useSelector(
    getAnnotationsForAssignment({
      sharedAssignmentId,
      userAssignmentId,
    }),
  )

  // to get them in the same order as the chapter sections
  /* istanbul ignore next */
  const subsectionsWithAnnotations = compose(
    filter(({ id }) => annotations[id]?.length),
    reduce(
      (acc, { children }) => [
        ...acc,
        ...compose(flatten, map(getSubsectionContent))(children),
      ],
      [],
    ),
  )(sections)

  return { annotations, subsectionsWithAnnotations }
}

export default useAnnotations
