import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import SwatchIcon from 'apps/hss/images/color-swatch.svg'
import PropTypes from 'prop-types'

export const colorSwatchShape = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  colorId: PropTypes.number.isRequired,
})

const Base = styled(Box, { name: 'ColorSwatch-Base' })({
  alignItems: 'center',
  display: 'flex',
})

const Animated = styled(Base, { name: 'ColorSwatch-Animated' })({
  transition: 'all 0.15s ease-in-out',
  '&:hover': {
    transition: 'ease-in-out, 0.15s',
    transform: 'scale(1.1)',
  },
})

const ColorSwatch = props => {
  const {
    disableAnimation = false,
    hideLabel = false,
    swatch: { label, value },
  } = props

  const Component = disableAnimation ? Base : Animated

  return (
    <Component>
      <SwatchIcon
        data-testid="color-swatch"
        style={{ fill: value, width: 18, height: 18 }}
      />
      {hideLabel ? null : (
        <Box
          component="span"
          ml="5px">
          {label}
        </Box>
      )}
    </Component>
  )
}

ColorSwatch.propTypes = {
  disableAnimation: PropTypes.bool,
  hideLabel: PropTypes.bool,
  swatch: colorSwatchShape.isRequired,
}
export default ColorSwatch
