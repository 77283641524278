import { CONTENT_TYPE_INTERACTIVE } from 'core/consts'
import { filter, map, reduce } from 'fp/arrays'
import { get } from 'fp/objects'
import { fallbackTo, pipe } from 'fp/utils'
import { compose } from 'redux'

export const getAssignmentScorableNav = (assignment, chapterNav) => {
  const scoreableContentIdLookup = compose(
    reduce((result, next) => result.set(next, true), new Map()),
    fallbackTo([]),
    get('scoreableContentIds'),
    fallbackTo({}),
  )(assignment)
  const isScorableContentId = scoreableContentIdLookup.has.bind(
    scoreableContentIdLookup,
  )
  const shouldInclude = ({ contentType, id }) =>
    contentType !== CONTENT_TYPE_INTERACTIVE || isScorableContentId(id)

  const scorableOnly = pipe(
    fallbackTo([]),
    filter(shouldInclude),
    map(item => ({
      ...item,
      children: scorableOnly(item.children),
    })),
    filter(
      ({ children, contentType }) =>
        children.length || contentType === CONTENT_TYPE_INTERACTIVE,
    ),
  )

  return scorableOnly(chapterNav)
}
