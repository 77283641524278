import Button from '@mui/material/Button'
import { componentShape } from 'core/shapes'
import { createElement } from 'react'
import { useNavigate } from 'react-router-dom'

const BackButton = ({ children = 'Back', ...rest }) => {
  const navigate = useNavigate()

  const onClick = () => {
    navigate(-1)
  }

  return createElement(Button, { ...rest, onClick }, children)
}

BackButton.propTypes = { children: componentShape }

export default BackButton
