import { isEmpty } from 'fp/arrays'
import { get } from 'fp/objects'
import { useDeepCompareEffect } from 'hooks/useDeepCompare'
import { useContext, useState } from 'react'
import { useFormState } from 'react-hook-form'
import { formIslandContext } from '.'

const deepSearch = errors => name => get(name, true)(errors)

const useHasErrors = () => {
  const { itemNames } = useContext(formIslandContext)
  const { errors } = useFormState({ name: itemNames })
  const [hasErrors, setHasErrors] = useState(false)

  useDeepCompareEffect(() => {
    const searcher = deepSearch(errors)
    // clear any previous errors
    if (isEmpty(Object.keys(errors))) setHasErrors(false)

    /**
     * Give the dom a chance to update.
     * The idea here is to allow components to sense when the validation has
     * happened, even if nothing has changed.  For example if a field already
     * had an error and the user clicks submit again, we want any listening
     * components to be able to detect that, even though the error state might
     * not have changed.
     */
    setTimeout(() => {
      setHasErrors(Boolean(itemNames.find(searcher)))
    })
  }, [errors])

  return hasErrors
}

export default useHasErrors
