import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'

const ExternalLink = forwardRef(({ href, children, ...rest }, ref) => (
  <Typography
    component="a"
    href={href}
    rel="noopener noreferrer"
    target="_blank"
    {...rest}
    ref={ref}>
    {children}
  </Typography>
))

ExternalLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default ExternalLink
