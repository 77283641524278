import DialogActions from '@mui/material/DialogActions'
import Pagination from 'common/tables/DataTable/Pagination'
import { squeryShape } from 'core/shapes'
import PropTypes from 'prop-types'

const DialogPagination = props => (
  <DialogActions
    disableSpacing
    style={{ display: 'block' }}>
    <Pagination
      {...props}
      variant="dialog"
    />
  </DialogActions>
)

DialogPagination.propTypes = {
  dataset: PropTypes.object.isRequired,
  setSquery: PropTypes.func.isRequired,
  squery: squeryShape.isRequired,
}

export default DialogPagination
