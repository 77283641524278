import { equals } from 'fp/objects'
import { not, when } from 'fp/utils'
import { useDeepCompareEffect } from 'hooks/useDeepCompare'
import PropTypes from 'prop-types'
import { createContext, createElement, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import actionTypes from 'reducers/actionTypes'
import { compose } from 'redux'
import { stateRouting } from 'selectors/index'

export const routeTransitionContext = createContext()

/**
 * This component works in conjunction with RouteTransition, useNavigation, and the routing saga.
 * Whenever all registered RouteTransitions' exit animations have completed,
 * this provider lets the routing saga know it's ok to allow the route change to proceed.
 *
 * If we don't space out the events like this, the UI won't always show the immediate feedback of the BusySpinner.
 *
 * This functionality is here in a context provider, rather than in RouteTransition itself,
 * so that useNavigation will know when to tell the routing saga to wait.
 *
 * We should only use one of these at a time.
 */
const RouteTransitionProvider = ({ transitionType, ...rest }) => {
  const dispatch = useDispatch()

  // The first thing <RouteTransition /> should do is add itself to this list via the `register` function below,
  // and the last thing it should do is remove itself from this list via the `unregister` function.
  const registeredTransitionIds = useRef([])

  const destination = useRef()
  const inProgressTransitionIds = useRef([])

  const value = useMemo(() => {
    const handleTransitionExitCompletion = (transitionId, nextDestination) => {
      if (destination.current !== nextDestination) {
        destination.current = nextDestination
        inProgressTransitionIds.current = [...registeredTransitionIds.current]
      }
      inProgressTransitionIds.current.splice(
        inProgressTransitionIds.current.indexOf(transitionId),
        1,
      )
      if (!inProgressTransitionIds.current.length) {
        dispatch({
          type: actionTypes.ROUTE_LOCATION_CHANGE_MAY_PROCEED,
          nextPath: nextDestination,
        })
      }
    }

    return {
      exitHasCompleted: handleTransitionExitCompletion,
      register: id => {
        registeredTransitionIds.current.push(id)
      },
      transitionType,
      unregister: id => {
        registeredTransitionIds.current.splice(
          registeredTransitionIds.current.indexOf(id),
          1,
        )
      },
    }
  }, [dispatch, transitionType])

  /**
   * If no transitions are registered, then:
   *   - exitHasCompleted won't be called,
   *   - we won't ever dispatch ROUTE_LOCATION_CHANGE_MAY_PROCEED,
   *   - and navigation will never complete.
   *
   * That is, unless we watch for that scenario here.
   * If it happens, go ahead and dispatch ROUTE_LOCATION_CHANGE_MAY_PROCEED so the nav will continue.
   */
  const { currentLocation = {}, nextLocation = {} } = useSelector(stateRouting)

  const initialLocation = useRef(nextLocation)
  useDeepCompareEffect(() => {
    const locationHasChanged = compose(
      not,
      equals(initialLocation.current, true),
    )
    if (locationHasChanged(nextLocation)) {
      initialLocation.current = null
      if (!registeredTransitionIds.current.length && nextLocation.pathname) {
        dispatch({
          type: actionTypes.ROUTE_LOCATION_CHANGE_MAY_PROCEED,
          nextPath: nextLocation.pathname,
        })
      }
    }
  }, [dispatch, nextLocation])

  /**
   * No transitions are registered if the user clicks the browser "Back" button.
   * This prevents internal plain text links from working after "Back" is used.
   * Catch that scenario here and dispatch ROUTE_LOCATION_CHANGE_MAY_PROCEED.
   */
  useEffect(() => {
    when(
      !initialLocation && nextLocation?.pathname !== currentLocation?.pathname,
      dispatch,
      {
        type: actionTypes.ROUTE_LOCATION_CHANGE_MAY_PROCEED,
        nextPath: nextLocation?.pathname,
      },
    )
  }, [currentLocation, dispatch, nextLocation])

  return createElement(routeTransitionContext.Provider, { value, ...rest })
}

RouteTransitionProvider.propTypes = {
  transitionType: PropTypes.string,
}

export default RouteTransitionProvider
