import { Button } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import withConfirm from 'hoc/withConfirm'

interface Props extends React.ComponentPropsWithoutRef<typeof Button> {}

const Component = withConfirm(Button)

const RemoveAsset: React.FC<Props> = ({ onClick }) => (
  <Tooltip title="Remove asset">
    <Component
      variant="primary"
      color="primary"
      confirmationMessage={
        <>
          Are you sure you want to remove this asset?
          <br />
          <br />
          The original item will remain in the media library and can be searched
          for should you need to re-use it later.
        </>
      }
      confirmationTitle="Remove?"
      onClick={onClick}
      size="small">
      {/* <Trash /> */}
      Remove
    </Component>
  </Tooltip>
)

export default RemoveAsset
