import {
  COLLABORATIVE_INTERACTIVES,
  MANUAL_SUBMITTABLE_INTERACTIVES,
} from 'core/consts'
import { generateId } from 'fp/utils'
import { backendSocketContext } from 'hss/BackendSocketProvider'
import { useContext, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { ReadyState } from 'react-use-websocket'
import { isTestEnv } from 'selectors/index'
import { getAssignmentBehindUserAssignment } from 'selectors/userAssignments'
import { isStaff, isStudent } from 'selectors/users'
import useEffectOnce from './useEffectOnce'

const useSubscribeToInteractions = ({ interactive }) => {
  const { contentSubType, id: interactiveId } = interactive

  const {
    readyState: actualReadyState,
    subscribeToInteractive,
    unsubscribeFromInteractive,
  } = useContext(backendSocketContext)

  // istanbul ignore next
  const readyState = isTestEnv() ? ReadyState.OPEN : actualReadyState

  const userAssignmentAssignment = useSelector(
    getAssignmentBehindUserAssignment,
  )

  const { assignmentId: assignmentIdFromParams } = useParams()
  const subscriptionId = useRef(null)
  const isCurrentUserStudent = useSelector(isStudent)
  const assignmentId = isCurrentUserStudent
    ? userAssignmentAssignment?.id
    : assignmentIdFromParams

  const isCollaborative = COLLABORATIVE_INTERACTIVES.includes(contentSubType)
  const isSubmittable = MANUAL_SUBMITTABLE_INTERACTIVES.includes(contentSubType)
  const isCurrentUserStaff = useSelector(isStaff)
  const shouldSubscribeToInteractive =
    isCollaborative || (isCurrentUserStaff && isSubmittable)

  const isSocketOpen = readyState === ReadyState.OPEN

  useEffect(() => {
    if (
      !subscriptionId.current &&
      shouldSubscribeToInteractive &&
      isSocketOpen &&
      assignmentId
    ) {
      subscriptionId.current = generateId()
      subscribeToInteractive({
        interactiveId,
        assignmentId,
        id: subscriptionId.current,
      })
    }
  }, [
    assignmentId,
    interactiveId,
    isSocketOpen,
    shouldSubscribeToInteractive,
    subscribeToInteractive,
  ])

  useEffectOnce(() => () => {
    if (subscriptionId.current && isSocketOpen) {
      unsubscribeFromInteractive({ id: subscriptionId.current })
      subscriptionId.current = null
    }
  })
}

export default useSubscribeToInteractions
