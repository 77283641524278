import { CONTENT_TYPE_ECHO, CONTENT_TYPE_INTERACTIVE } from 'core/consts'
import { first, map } from 'fp/arrays'
import { get, mapKeys, pick, set } from 'fp/objects'
import { matches } from 'fp/utils'
import { availableEchoFeatures } from 'hss/ContentBuilder/Curriculum/Echo/utils'
import compare from 'just-compare'
import { Either } from 'monet'
import actionTypes from 'reducers/actionTypes'
import { actions as errorLogActions } from 'reducers/errorLog'
import { restEndpoint } from 'reducers/utils'
import { compose } from 'redux'
import { all, call, put, select, takeLeading } from 'redux-saga/effects'
import { getFeaturesForEcho } from 'selectors/contentViewer'
import { dangerouslyCallApi } from './api'
import { preparePayloadForSave } from './contentSaving'
import { success } from './utils'

function* processFeature(interactive, parentPayload) {
  const { contentSubType: feature } = interactive

  const itemPrefix = `${feature}-`
  const ignorePrefix = `${itemPrefix}data.`

  const pertinentItemsInPayload = Object.entries(parentPayload)
    .filter(([key]) => key.startsWith(itemPrefix))
    .filter(([key]) => !key.startsWith(ignorePrefix))

  const keys = pertinentItemsInPayload
    .map(first)
    .map(key => key.replace(itemPrefix, ''))
    .concat(['contentState'])

  const originalValues = pick(keys)(interactive)
  const newValues = {
    ...mapKeys(key => key.replace(itemPrefix, ''))(
      Object.fromEntries(pertinentItemsInPayload),
    ),
    contentState: parentPayload.contentState,
  }

  const modified = !compare(originalValues, newValues)

  if (!modified) return Either.Right(interactive) // no need to save if nothing's changed 😉

  const payload = {
    ...interactive,
    ...newValues,
  }

  const action = yield call(preparePayloadForSave, {
    payload,
    suppressAlert: true,
  })

  return yield call(dangerouslyCallApi, action)
}

export function* handleEchoSave(action) {
  const {
    payload,
    payload: { children },
  } = action

  /**
   * The basic idea here is to reassemble each feature (interactive) and save them
   * individually, before cleansing the parent echo payload and saving that too.
   */

  const contentIds = children
    .filter(matches('contentType', CONTENT_TYPE_INTERACTIVE))
    .map(get('id'))

  const features = yield select(getFeaturesForEcho({ contentIds }))

  // Save each underlying interactive (feature)
  const monads = yield all(
    Object.values(features).map(interactive =>
      call(processFeature, interactive, payload),
    ),
  )

  const left = monads.find(monad => monad.isLeft())
  if (left) {
    yield put(
      errorLogActions.reportError({
        message: `Something went wrong while saving the changes to a feature.

${String(left.left().error)}`,
      }),
    )

    return
  }

  // Save the echo itself
  const newPayload = Object.fromEntries(
    Object.entries(payload)
      .filter(
        ([key]) =>
          !availableEchoFeatures.some(feature => key.startsWith(feature)),
      )
      .filter(([key]) => !key.startsWith('features.'))
      .map(([key, value]) =>
        key === 'children'
          ? [key, map(pick(['id', 'contentType']))(value)]
          : [key, value],
      ),
  )

  yield put({
    ...action,
    type: actionTypes.CONTENT_SAVE,
    payload: newPayload,
  })
}

/**
 * This brings us full-circle.
 *
 * We initially responded to a call to CONTENT_ECHO_SAVE and issued a call to the
 * normal CONTENT_SAVE above, after we'd finished doing some slight-of-hand on the
 * children.
 *
 * Now we need to spit out CONTENT_ECHO_SAVE_SUCCESS for the benefit of any promise
 * listeners (namely, the curriculum editor form) and also the content reducer.
 */
export function* handleContentSaveSuccess(action) {
  const {
    passThrough: { contentType },
  } = action

  if (contentType === CONTENT_TYPE_ECHO) {
    yield put({
      ...action,
      type: success(actionTypes.CONTENT_ECHO_SAVE),
    })
  }
}

function* createChildFeature(echo, feature) {
  const either = yield call(dangerouslyCallApi, {
    action: { type: actionTypes.CONTENT_SAVE },
    options: {
      method: 'POST',
      body: {
        applicationStandardIds: [],
        assetCode: `${echo.assetCode || echo.id}-${feature}`,
        contentType: CONTENT_TYPE_INTERACTIVE,
        contentSubType: feature,
        contentState: echo.contentState,
        instructionStandardIds: [],
      },
    },
    passThrough: { suppressAlert: true },
    url: restEndpoint.content,
  })

  return either.isRight() ? either.right().id : null
}

export function* provideNewEchoWithFeatureStubs(echo) {
  const children = yield all(
    availableEchoFeatures.map(feature =>
      call(createChildFeature, echo, feature),
    ),
  )
  const features = availableEchoFeatures.reduce(
    (acc, feature, idx) => ({
      ...acc,
      [feature]: {
        contentId: children[idx],
        enabled: true,
      },
    }),
    {},
  )

  const either = yield call(dangerouslyCallApi, {
    action: { type: actionTypes.CONTENT_SAVE },
    options: {
      method: 'PATCH',
      body: compose(
        set(
          'children',
          children.map(id => ({ id })),
        ),
        set('data', { features }),
      )(echo),
    },
    passThrough: { suppressAlert: true },
    url: `${restEndpoint.content}/${echo.id}`,
  })

  return either.isRight()
}

/* istanbul ignore next line */
function* echoSaga() {
  yield takeLeading(success(actionTypes.CONTENT_SAVE), handleContentSaveSuccess)
  yield takeLeading(actionTypes.CONTENT_ECHO_SAVE, handleEchoSave)
}

export default echoSaga
