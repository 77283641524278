import actionTypes from 'reducers/actionTypes'

const legacySessionExtenderMiddleware = store => {
  const handler = ev => {
    if (ev.data?.extendSession) {
      store.dispatch({
        type: actionTypes.SESSION_RESET_TIMEOUT,
      })
    }
  }
  window.addEventListener('message', handler)

  return next => action => next(action)
}

export default legacySessionExtenderMiddleware
