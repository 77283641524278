import { isListItem } from './indentation'

export const blockStyleFn = (/* features */) => contentBlock => {
  const result = []

  if (isListItem(contentBlock)) return result

  if (contentBlock.getData().get('indentfirstline')) {
    result.push('indent-first-line')
  }

  if (contentBlock.getData().get('indent')) {
    result.push(`indent-${contentBlock.getData().get('indent')}`)
  }

  const variants = contentBlock.getData().get('variants')
  if (variants) {
    result.push('tr-typography')
    result.push(variants)
  }

  return result.flat().join(' ')
}

export const styleMap = {
  /**
   * The use of strokeDashoffset here is a somewhat hacky way to get around draftjs
   * not supporting classNames within immutable maps.
   * https://github.com/facebook/draft-js/issues/957
   */
  ANSWER: {
    fontStyle: 'italic',
    strokeDashoffset: '3',
  },

  HIGHLIGHTED: {
    strokeDashoffset: '0',
  },

  PARAGRAPH: {
    marginBottom: '1rem',
  },

  STRIKETHROUGH: {
    textDecoration: 'line-through',
  },

  SUBSCRIPT: {
    strokeDashoffset: '1',
  },

  SUPERSCRIPT: {
    strokeDashoffset: '2',
  },
}
