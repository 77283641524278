import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import PlayIcon from 'hss/images/controls/audio/regular-play.svg'
import PauseIcon from 'hss/images/controls/video/pause-circled.svg'
/* istanbul ignore file */
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { createRoot } from 'react-dom/client'
import videojs from 'video.js'

const PlayButton = ({ vjsComponent: { player_ } }) => {
  const [playing, setPlaying] = useState(true)

  useEffect(() => {
    const handlePlay = () => setPlaying(true)
    const handlePause = () => setPlaying(false)

    player_?.on('play', handlePlay)
    player_?.on('pause', handlePause)
    return () => {
      player_?.off('play', handlePlay)
      player_?.off('pause', handlePause)
    }
  }, [player_])

  if (!player_) return null

  const label = playing ? 'Pause' : 'Play'

  const handleClick = () => {
    if (playing) {
      player_.pause()
    } else {
      player_.play()
    }
  }

  return (
    <Tooltip
      arrow
      title={<Typography fontSize="1.4rem">{label}</Typography>}>
      <IconButton
        onClick={handleClick}
        type="button">
        {playing ? (
          <PauseIcon
            className="pause"
            color="white"
            width="32px"
          />
        ) : (
          <PlayIcon
            className="play"
            color="white"
            width="32px"
          />
        )}
      </IconButton>
    </Tooltip>
  )
}

PlayButton.propTypes = {
  vjsComponent: PropTypes.object.isRequired,
}

const vjsComponent = videojs.getComponent('Component')

class ssPlayButton extends vjsComponent {
  constructor(player, options = {}) {
    super(player, options)

    this.options = options
    this.mount = this.mount.bind(this)

    this.el().className = 'vjs-control'

    this.root = createRoot(this.el())

    player.ready(() => {
      this.mount()
    })
  }

  mount() {
    this.root.render(
      <PlayButton
        vjsComponent={this}
        {...this.options}
      />,
    )
  }
}

vjsComponent.registerComponent('ssPlayButton', ssPlayButton)

export default PlayButton
