import Stack from '@mui/material/Stack'
import cl from 'classnames'
import Slider from 'common/formControls/sliders/Slider'
import { assertRange } from 'fp/numbers'
import { curryRight } from 'fp/utils'
import PropTypes from 'prop-types'
/* istanbul ignore file */
import { useCallback, useEffect, useState } from 'react'
import { createRoot } from 'react-dom/client'
import { compose } from 'redux'
import videojs from 'video.js'
import MuteButton from './MuteButton'

const Volume = ({
  vjsComponent,
  volume: extVolume,
  setVolume: extSetVolume,
}) => {
  const [volume, setVolume] = useState(extVolume)
  const [prevVolume, setPrevVolume] = useState(volume)

  const handleChange = compose(
    setVolume,
    curryRight(assertRange, 0, 1),
    amount => amount / 100.0,
  )

  useEffect(() => {
    vjsComponent.player_?.volume(volume)
    extSetVolume(volume)
  }, [extSetVolume, vjsComponent, volume])

  const onChange = useCallback(
    muted => {
      if (muted) {
        setPrevVolume(volume)
        handleChange(0)
      } else {
        handleChange(prevVolume * 100)
      }
    },
    [handleChange, prevVolume, volume],
  )

  return (
    <Stack
      alignItems="center"
      direction="row">
      <MuteButton
        isMuted={volume === 0}
        onChange={onChange}
        vjsComponent={vjsComponent}
      />

      <Slider
        aria-label="Volume"
        aria-valuemax="100"
        aria-valuemin="0"
        aria-valuenow={Math.trunc(volume * 100)}
        className="vjs-volume-slider"
        onChange={(_, v) => handleChange(v)}
        role="slider"
        sx={{
          borderRadius: 0,
          color: 'common.white',
          height: 5,
          marginLeft: '2rem',
          width: '8rem',
        }}
        value={volume * 100}
      />
    </Stack>
  )
}

Volume.propTypes = {
  setVolume: PropTypes.func.isRequired,
  vjsComponent: PropTypes.object.isRequired,
  volume: PropTypes.number.isRequired,
}

const vjsComponent = videojs.getComponent('Component')

class ssVolume extends vjsComponent {
  constructor(player, options) {
    super(player, options)

    this.options = options
    this.mount = this.mount.bind(this)

    this.el().className = cl('vjs-control', 'ss-volume-control')

    this.root = createRoot(this.el())

    player.ready(() => {
      this.mount()
    })

    this.on('dispose', () => {
      /**
       * Feels like we *should* unmount the control, but react 18 does not like it.
       *
       * Doesn't seem to matter is we skip it (?)
       */
      // this.root.unmount()
    })
  }

  mount() {
    this.root.render(
      <Volume
        vjsComponent={this}
        {...this.options}
      />,
    )
  }
}

vjsComponent.registerComponent('ssVolume', ssVolume)

export default Volume
