import TabContext from '@mui/lab/TabContext'
import Tab from '@mui/material/Tab'
import Dialog from 'common/dialogs/Dialog'
import TabList from 'common/layout/TabList'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { LINKABLE_CONTENT_TYPES } from 'shared/consts'
import { contentStubShape } from './CreateEditExternalLinkDialogContents'
import ExternalLinkTab from './ExternalLinkTab'
import InternalLinkTab from './InternalLinkTab'

const AdvancedLinkDialog = ({ currentLinkStub, onClose, onComplete }) => {
  const [currentTab, setCurrentTab] = useState('internal')
  const editing = !!currentLinkStub

  const handleTabChange = (_, value) => setCurrentTab(value)

  const [internalTabLabel, setInternalTabLabel] = useState('')

  return (
    <Dialog
      disableAutoFocus
      // disable closing via the esc key when showing the form for external links
      disableEscapeKeyDown={currentTab === 'external'}
      maxWidth="sm"
      onClose={onClose}
      open
      showCloseButton
      swatch
      title={editing ? 'Edit Link' : 'Apply Link'}
      TitleProps={{ mb: 3 }}
      variant="maximized-vertically">
      {editing ? (
        <ExternalLinkTab
          currentLinkStub={currentLinkStub}
          onClose={onClose}
          onComplete={onComplete}
        />
      ) : (
        <TabContext value={currentTab}>
          <TabList
            aria-label="Type of link to create"
            contained
            data-contained={false}
            minSize="xs"
            onChange={handleTabChange}
            sx={{ button: { flexGrow: 1 } }}>
            <Tab
              label={internalTabLabel}
              value="internal"
            />

            <Tab
              label="Outside of Traverse"
              value="external"
            />
          </TabList>

          <InternalLinkTab
            {...{
              onClose,
              onComplete,
              setInternalTabLabel,
              contentTypes: LINKABLE_CONTENT_TYPES,
            }}
          />

          <ExternalLinkTab {...{ onClose, onComplete }} />
        </TabContext>
      )}
    </Dialog>
  )
}

AdvancedLinkDialog.propTypes = {
  currentLinkStub: contentStubShape,
  onClose: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
}

export default AdvancedLinkDialog
