import Icon from '@mui/material/Icon'
import Tooltip from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import Link from 'common/navigation/links/Link'
import { contentShape } from 'core/shapes'
import withProps from 'hoc/withProps'
import useLocalSetting from 'hooks/useLocalSetting'
import { labels } from 'hss/ContentBuilder/consts'
import Exit from 'hss/images/nav/exit.svg'
import { SETTING_KEY_CONTENT_VIEWER_EXIT } from 'reducers/localSettings'
import { appUrl } from 'routing/consts'

const ExitFilled = withProps(Exit, { className: 'filled' })

const StyledIcon = styled(Icon, { name: 'icon-Container' })(
  ({
    theme: {
      mixins: { transition },
      palette,
    },
  }) => ({
    svg: {
      fill: 'transparent',
      ...transition(),
      opacity: 1,
      '&.filled': {
        fill: palette.common.white,
        boxShadow: 'none',
        opacity: 0,
      },
    },
    '& svg[data-testid="toc-exit"]': {
      opacity: 1,
      position: 'absolute',
    },
    '& svg.filled': {
      opacity: 0,
    },
    '&:hover': {
      '& svg[data-testid="toc-exit"]': {
        opacity: 0,
      },
      '& svg.filled': {
        opacity: 1,
      },
    },
  }),
)

const ExitButton = ({ content, ...props }) => {
  const [{ to }] = useLocalSetting(SETTING_KEY_CONTENT_VIEWER_EXIT, {
    to: appUrl,
  })
  const { contentType } = content || {}
  const name = `Exit ${labels[contentType] || ''}`

  return content ? (
    <Link
      aria-label={name}
      data-testid="exit-btn"
      to={to}>
      <Tooltip title={name}>
        <StyledIcon
          data-subvariant="exit"
          fontSize="large"
          variant="toc-menu"
          {...props}>
          <Exit data-testid="toc-exit" />
          <ExitFilled className="filled" />
        </StyledIcon>
      </Tooltip>
    </Link>
  ) : null
}

ExitButton.propTypes = {
  content: contentShape,
}

export default ExitButton
