import { withOptions } from '@comfy/redux-selectors'
import { cleanUrlParam } from 'fp/internet'
import { get, set } from 'fp/objects'
import { isEmptyString, maybeParseJSON } from 'fp/strings'
import { identity, isFunction } from 'fp/utils'
import { decompressFromEncodedURIComponent } from 'lz-string'
import { matchPath } from 'react-router-dom'
import { compose } from 'redux'
import { createSelector } from '.'

/**
 * Taken verbatim from history@4.10.1 (it was removed from v5)
 *
 * TODO:
 * This could surely be refactored to use utility methods from react-router-dom.
 */
const parsePath = path => {
  let pathname = path || '/'
  let search = ''
  let hash = ''

  const hashIndex = pathname.indexOf('#')
  // istanbul ignore if
  if (hashIndex !== -1) {
    // not currently possible (came from history)
    hash = pathname.substr(hashIndex)
    pathname = pathname.substr(0, hashIndex)
  }

  const searchIndex = pathname.indexOf('?')
  if (searchIndex !== -1) {
    search = pathname.substr(searchIndex)
    pathname = pathname.substr(0, searchIndex)
  }

  return {
    pathname,
    search: search === '?' ? /* istanbul ignore next line */ '' : search,
    hash: hash === '#' ? /* istanbul ignore next line */ '' : hash,
  }
}

/**
 * Taken verbatim from history@4.10.1 (it was removed from v5)
 *
 * TODO:
 * This could surely be refactored to use utility methods from react-router-dom.
 */

// biome-ignore lint/complexity/noExcessiveCognitiveComplexity: <explanation>
const createLocation = (path, state, _, currentLocation) => {
  let location
  if (typeof path === 'string') {
    // Two-arg form: push(path, state)
    location = parsePath(path)
    location.state = state
  } else {
    // One-arg form: push(location)
    location = { ...path }

    if (location.pathname === undefined) location.pathname = ''

    if (location.search) {
      // istanbul ignore else
      if (location.search.charAt(0) !== '?') {
        location.search = `?${location.search}`
      }
    } else {
      location.search = ''
    }

    if (location.hash) {
      if (location.hash.charAt(0) !== '#') location.hash = `#${location.hash}`
    } else {
      location.hash = ''
    }

    if (state !== undefined && location.state === undefined) {
      location.state = state
    }
  }

  try {
    location.pathname = decodeURI(location.pathname)
  } catch (e) {
    // istanbul ignore next line
    if (e instanceof URIError) {
      throw new URIError(
        `Pathname "${location.pathname}" could not be decoded. This is likely caused by an invalid percent-encoding.`,
      )
    }
    // istanbul ignore next line
    throw e
  }

  // not currently using key
  // if (key) location.key = key

  if (currentLocation) {
    // Resolve incomplete/relative pathname relative to current location.
    if (!location.pathname) {
      location.pathname = currentLocation.pathname
    }
  } else if (!location.pathname) {
    // When there is no prior location and pathname is empty, set it to /

    location.pathname = '/'
  }

  return location
}

const createMatchSelector = (path, isPinned, urlOtherThanCurrent) => {
  let lastPathname = null
  let lastMatch = null

  const locationProp = isPinned ? 'routing.pinnedLocation' : 'router.location'
  const getPathname = get(`${locationProp}.pathname`)

  return state => {
    const pathname = urlOtherThanCurrent || getPathname(state)
    if (pathname === lastPathname) {
      return lastMatch
    }
    lastPathname = pathname

    if (!(path?.path && pathname)) return null

    const match = matchPath(path, pathname)
    lastMatch = match

    return lastMatch
  }
}

export const matchPathSelector = withOptions(
  (path, isPinned, urlOtherThanCurrent = undefined) =>
    createSelector('matchPathSelector')(
      identity,
      createMatchSelector(path, isPinned, urlOtherThanCurrent),
    ),
)

export const getQueryParams = createSelector('getQueryParams')(
  'routing.queryParams',
)

export const getDecodedQueryParams = withOptions((key = 'criteria') =>
  createSelector('getDecodedQueryParams')(
    getQueryParams,
    compose(
      s => (isEmptyString(s) ? undefined : maybeParseJSON(s)),
      decompressFromEncodedURIComponent,
      /**
       * NOT A TYPO!!!
       * It's clear that decompressFromEncodedURIComponent expects DECODED strings,
       * even though the name implies the opposite.
       *
       * Ask me how much I struggled to figure out what was going on, go ahead, ask me...
       * No mention of it in their repo or github issues either.  FUN! 🤬
       */
      decodeURIComponent,
      get(key),
    ),
  ),
)

export const getLocation = createSelector('getLocation')('router.location')
export const getPreviousLocation = createSelector('getPreviousLocation')(
  'routing.previousLocation',
)
export const getPinnedLocation = createSelector('getPinnedLocation')(
  'routing.pinnedLocation',
)

// export const isFirstRouteRender = createSelector('isFirstRouteRender')('routing.isFirstRendering')

const resolveToUrl = (to, currentLocation) =>
  isFunction(to) ? to(currentLocation) : to

export const getDesiredLocation = (to, existingLocation) => {
  const result = createLocation(
    resolveToUrl(to, existingLocation),
    null,
    null,
    existingLocation,
  )

  return String(result?.pathname).startsWith('/http') // Original url must have been external
    ? set('pathname', result.pathname.slice(1))(result)
    : result
}

export const getSquery = createSelector('getSquery')(
  getDecodedQueryParams('squery'),
  get('sq'),
)

export const getRestEndpoint = createSelector('getRestEndpoint')(
  getDecodedQueryParams('squery'),
  get('re'),
)

const persistParam = (paramName, existingSearchParams, desiredLocation) => {
  const toSearchParams = new URLSearchParams(desiredLocation.search)
  toSearchParams.delete(paramName)
  toSearchParams.append(paramName, existingSearchParams.get(paramName))
  return toSearchParams.toString()
}

/**
 * DO NOT CALL DIRECTLY
 *
 * Instead utilize the useNavigation hook
 *
 * This is here to avoid duplication between that hook and the routing saga which
 * also relies on it (but obviously can't use the hook)
 */
export const buildSscAwareUrl = ({ existingLocation, to, isOnRightSide }) => {
  let result
  const desiredLocation = getDesiredLocation(to, existingLocation)
  const existingSearchParams = new URLSearchParams(existingLocation.search)
  const isFeedbackPane = existingLocation.search.match(/feedback/g)

  if (isOnRightSide && !existingSearchParams.has('pane')) {
    existingSearchParams.delete('ssc')
    existingSearchParams.append('ssc', cleanUrlParam(true)(desiredLocation))
    result = `${existingLocation.pathname}?${existingSearchParams.toString()}`
  } else {
    result = resolveToUrl(to, existingLocation)
    const [, hash] = result?.split('#') || []
    if (existingSearchParams.has('ssc')) {
      result = `${desiredLocation.pathname}?${persistParam('ssc', existingSearchParams, desiredLocation)}${hash ? `#${hash}` : ''}`
    } else if (existingSearchParams.has('pane') && !isFeedbackPane) {
      // the teacher feedback pane should not persist when navigating the chapter
      // maintain params for notebook display, better place for this?
      result = `${desiredLocation.pathname}?${persistParam('pane', existingSearchParams, desiredLocation)}${hash ? `#${hash}` : ''}`
    }
  }

  return result
}
