import { inspect } from 'core/store/search/squery'
import { get } from 'fp/objects'
import { noop } from 'fp/utils'
import useDataset from 'hooks/useDataset'
import { useRef } from 'react'
import { restEndpoint } from 'reducers/utils'
import DialogPagination from '../../interactivePlugin/DialogPagination'
import InteractivesTable from '../../interactivePlugin/InteractivesTable'
import TableImageCardRenderer from '../../interactivePlugin/TableImageCardRenderer'
import { useImageSelectionContext } from '../ImageSelectionProvider'
import SearchFilter from './SearchFilter'
import SelectedItem from './SelectedItem'

const ImageSearch = () => {
  const { setSquery, squery, searchWasMade } = useImageSelectionContext()

  const imageSearchQuery = inspect(squery).get.modifier('imageSearch').is()

  const dataset = useDataset({ restEndpoint: restEndpoint.content, squery })
  const datasetSquery = get('metadata.squery')(dataset)
  const datasetImageSearchQuery = inspect(datasetSquery)
    .get.modifier('imageSearch')
    .is()
  const isDatasetReady = datasetImageSearchQuery === imageSearchQuery

  const tableRef = useRef<HTMLDivElement>(null)

  return (
    <>
      <SearchFilter />

      {searchWasMade && isDatasetReady && (
        <>
          <SelectedItem ref={tableRef} />

          <InteractivesTable
            {...{
              CardRenderer: TableImageCardRenderer,
              dataset,
              onComplete: noop, // SelectedItem handles this
              ref: tableRef,
            }}
          />

          <DialogPagination
            {...{
              dataset,
              squery,
              setSquery,
              scrollToResultsRef: tableRef,
              sx: { borderTop: '1px solid', borderColor: 'grey.300' },
            }}
          />
        </>
      )}
    </>
  )
}

export default ImageSearch
