import { INTERACTION_STATE_COMPLETED } from 'core/consts'
import { findObj } from 'fp/arrays'

export const echoResponse = (echo, _, interactions) => {
  // istanbul ignore next line
  const {
    data: { features = [], responseType } = {},
  } = echo || {}

  const feature = features[responseType]
  if (!feature?.enabled) return null

  return findObj('contentId', feature.contentId)(interactions)
}

export const echoRubric = (echo, interactives) => {
  const { responseType } = echo.data
  const responseInteractive = findObj(
    'contentSubType',
    responseType,
  )(Object.values(interactives))

  return responseInteractive?.rubric
}

export const isSubmitted = response =>
  response?.state === INTERACTION_STATE_COMPLETED
