const overrides = ({
  mixins: { borderS, important, importantPx, px, transition, rem },
  palette,
  shadows,
  typography,
  typography: { variants },
}) => {
  const simpleCard = {
    backgroundColor: 'transparent',
    padding: rem(3),
    margin: 0,

    position: 'relative',

    ...borderS(palette.grey[4]),
    boxSizing: 'border-box',
    filter: 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.1))',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  }

  return {
    MuiListItem: {
      variants: [
        {
          props: { variant: 'simple-card' },
          style: simpleCard,
        },

        {
          props: { variant: 'numbered-card' },
          style: {
            ...simpleCard,
            counterIncrement: 'item',
            '&::before': {
              content: 'counter(item, decimal-leading-zero)',
              marginBottom: 18,
              ...typography.h2,
            },
          },
        },

        {
          props: { variant: 'objectives' },
          style: {
            counterIncrement: 'item',
            '&::before': {
              content: 'counter(item, decimal-leading-zero)',
              ...typography.h2,
              position: 'relative',
              top: rem(-0.5),
            },
            margin: rem(1.5, 0, 0),
            display: 'flex',
            flexDirection: 'row',
            columnGap: importantPx(18),
            alignItems: 'center',
          },
        },

        {
          props: { variant: 'unstyled' },
          style: {
            ...variants.paragraph,
            padding: 0,
            marginBottom: px(24),
          },
        },

        {
          props: { variant: 'answer' },
          style: {
            display: 'block',
            width: '100%',
            padding: '1.6rem 1.6rem 1.6rem 0.8rem',
            marginBottom: rem(1),
            marginRight: rem(1),
            boxShadow: shadows[1],
            backgroundColor: palette.common.white,
          },
        },

        {
          props: { 'data-correct': 'yes' },
          style: {
            borderLeft: `8px solid ${palette.success.main}`,
            svg: { stroke: palette.success.dark },
          },
        },

        {
          props: { 'data-correct': 'no' },
          style: {
            borderLeft: '8px solid transparent',
            svg: { stroke: palette.error.main },
          },
        },

        {
          props: { variant: 'nav' },
          style: {
            padding: px(10, 10, 10, 24),
            marginTop: important(0),
            height: important('unset'),
            alignItems: important('flex-start'),
            a: {
              ...variants['nav-item'],
              color: palette.primary.contrastText,
            },
          },
        },

        {
          props: { variant: 'source-header' },
          style: {
            width: 'unset',
            display: 'block',
            padding: 0,
            textAlign: 'center',
            '&:not(:first-of-type)::before': {
              content: '"|"',
              padding: rem(0, 0.8),
            },
          },
        },

        {
          props: { variant: 'sub-nav' },
          style: {
            textTransform: 'uppercase',
            a: {
              color: palette.text.primary,
              ...variants['nav-item-uppercase'],
              textDecoration: 'none',
              ...transition(),
              backfaceVisibility: 'hidden',
              '&:hover': {
                color: palette.cobalt[0],
              },
            },
            '&.Mui-selected': {
              a: variants['nav-item-uppercase-semibold'],
              backgroundColor: 'transparent',
            },
          },
        },
      ],
    },
  }
}

export default overrides
