import Stack from '@mui/material/Stack'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useState } from 'react'

const RateControl = ({ player }) => {
  const [speed, setSpeed] = useState(player.playbackRate())

  useEffect(() => {
    setSpeed(player.playbackRate())
  }, [player])

  const handleChange = useCallback(
    ({ target }) => {
      const { value } = target

      player.playbackRate(Number.parseFloat(value))
      setSpeed(value)
    },
    [player],
  )

  return useMemo(
    () => (
      <Stack
        alignItems="center"
        direction="row"
        gap={2}>
        <span>Speed: </span>
        <select
          data-testid="speed-select"
          name="speed"
          onChange={handleChange}
          value={speed}>
          <option value="0.5">0.5x</option>
          <option value="1">1x</option>
          <option value="1.5">1.5x</option>
          <option value="2">2x</option>
        </select>
      </Stack>
    ),
    [handleChange, speed],
  )
}

RateControl.propTypes = {
  player: PropTypes.object.isRequired,
}

export default RateControl
