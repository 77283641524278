import { filter, reduce } from 'fp/arrays'
import { get } from 'fp/objects'
import { whenPresent } from 'fp/utils'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { compose } from 'redux'
import { CHANGE_HANDLER_SIMPLE } from '../Form/withHookForm'
import { propBagsShape, withPropBags } from '../Form/withPropBags'
import OldMultiSelectReplaceMe from './OldMultiSelectReplaceMe'

const HFOldMultiSelectReplaceMe = withPropBags(
  ['disabled', 'error', 'required'],
  { changeHandlerType: CHANGE_HANDLER_SIMPLE },
)(props => {
  const { componentProps, controlProps, rest } = props
  const { onChange, options, value } = componentProps
  const { beforeChange, getOptionValue = get('id') } = rest

  // The `value` prop is expected to be an array of IDs.
  // The `flatSelectedOptions` array is the subset of options that corresponds to the IDs in `value`.
  // (It's "flat" because whereas the options could be grouped, this list is not.)

  const flatSelectedOptions = useMemo(() => {
    const valueArray = value || []
    return compose(
      filter(
        compose(
          v => valueArray.includes(v), // TODO: Why doesn't `unary(valueArray.includes)` work here?
          getOptionValue,
        ),
      ),
      reduce(
        // Each option will have an `options` prop if grouped.
        (result, option) => result.concat(option.options || option),
        [],
      ),
    )(options)
  }, [getOptionValue, options, value])

  const handleChange = useCallback(
    newSelectedOptions => {
      whenPresent(beforeChange, newSelectedOptions)
      whenPresent(onChange, newSelectedOptions.map(getOptionValue))
    },
    [beforeChange, onChange, getOptionValue],
  )

  return (
    <OldMultiSelectReplaceMe
      formControlProps={controlProps}
      {...{
        ...componentProps,
        ...rest,
        onChange: handleChange,
        value: flatSelectedOptions,
        getOptionValue,
      }}
    />
  )
})

HFOldMultiSelectReplaceMe.propTypes = {
  ...propBagsShape.isRequired,
  beforeChange: PropTypes.func,
  getOptionValue: PropTypes.func,
}

export default HFOldMultiSelectReplaceMe
