import {
  DND_TYPE_CALLOUT,
  DND_TYPE_CALLOUT_SWATCH,
  DND_TYPE_CHART_COMPLETION,
  DND_TYPE_GROUP_AND_SORT_ITEM,
} from 'core/consts'
import { isMobile } from 'fp/internet'
import withProps from 'hoc/withProps'
import { OptionContent as ChartCompletionOption } from 'hss/sections/contentBlocks/interactives/ChartCompletion/DragAndDrop/Option'
import DragItem from 'hss/sections/contentBlocks/interactives/DragItem'
import { useDragLayer } from 'react-dnd'
import useDragPreviewScrolling from './useDragPreviewScrolling'

const layerStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
}

const getItemStyles = (initialOffset, currentOffset) => {
  if (!(initialOffset && currentOffset)) {
    return {
      display: 'none',
    }
  }
  const { x, y } = currentOffset

  const transform = `translate(${x}px, ${y}px)`
  return { transform }
}

const renderItem = (itemType, item) => {
  switch (itemType) {
    case DND_TYPE_CHART_COMPLETION:
      return withProps(ChartCompletionOption, { item })
    case DND_TYPE_GROUP_AND_SORT_ITEM:
      return withProps(DragItem, { children: <p>{item.draggedItem.label}</p> })
    case DND_TYPE_CALLOUT:
    case DND_TYPE_CALLOUT_SWATCH:
      return withProps(DragItem, { children: item.label })
    // ADD ANY TYPES OTHERS HERE
    default:
      return null
  }
}

// for touch back ends, manually create a preview while dragging
const PreviewLayer = () => {
  const { currentOffset, initialOffset, isDragging, item, itemType } =
    useDragLayer(monitor => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    }))

  useDragPreviewScrolling()

  if (!isDragging) {
    return null
  }

  // HTML5 backend takes care of this already, otherwise we'll get double vision
  const isTouch = isMobile()

  if (!isTouch) {
    return null
  }

  const Renderer = renderItem(itemType, item)

  return (
    Renderer && (
      <div style={layerStyles}>
        <div style={getItemStyles(initialOffset, currentOffset)}>
          <Renderer />
        </div>
      </div>
    )
  )
}

export default PreviewLayer
