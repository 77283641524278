import useEffectOnce from 'hooks/useEffectOnce'
import { useDrag, useDrop } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'

const useDragDrop = (ref, type) => {
  const [, drop] = useDrop({ accept: type })
  const [, drag, dragPreview] = useDrag(() => ({ type }), [])
  drag(drop(ref))

  useEffectOnce(() => {
    // this useEffect hides the default preview images
    dragPreview(getEmptyImage(), { captureDraggingState: true })
  })
}

export default useDragDrop
