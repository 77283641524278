import { TreeView } from '@mui/x-tree-view'
import { navItemShape } from 'core/shapes'
import useLocalSetting from 'hooks/useLocalSetting'
import PropTypes from 'prop-types'
import { useId } from 'react'
import TreeEntry from './TreeEntry'

const AsTree = ({ entries }) => {
  const [expanded, setExpanded] = useLocalSetting('NavShell-expanded', [])
  const id = useId()

  const handleToggle = (_, nodeIds) => {
    setExpanded(nodeIds)
  }

  return (
    <TreeView
      aria-label="site navigation tree"
      expanded={expanded}
      id={id}
      onNodeToggle={handleToggle}
      variant="nav-shell">
      {entries.map(entry => (
        <TreeEntry
          data-haschildren={Boolean(entry.items.length)}
          entry={entry}
          key={entry.key}
          nodeId={entry.key}>
          {entry.items.map(childEntry => (
            <TreeEntry
              entry={childEntry}
              key={childEntry.key}
              nodeId={childEntry.key}
            />
          ))}
        </TreeEntry>
      ))}
    </TreeView>
  )
}

AsTree.propTypes = {
  entries: PropTypes.arrayOf(navItemShape).isRequired,
}

export default AsTree
