import withConfirm from 'hoc/withConfirm'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import ExternalLink from './ExternalLink'

const ConfirmLeaveSite = withConfirm(ExternalLink)

const ExternalLinkConfirm = forwardRef(({ children, href, ...rest }, ref) => (
  <ConfirmLeaveSite
    cancelLabel="No"
    confirmationMessage={
      <>
        <p>
          You are about to leave Traverse and visit another website.
          <br />
          The website will open within a new browser tab.
        </p>
        <p>{href}</p>
        <p>Do you wish to continue?</p>
      </>
    }
    href={href}
    variant="feature-paragraph"
    {...rest}
    onClick={() => window.open(href, '_blank')}
    ref={ref}>
    {children}
  </ConfirmLeaveSite>
))

ExternalLinkConfirm.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
}

export default ExternalLinkConfirm
