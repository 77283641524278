import { createSelector } from '@comfy/redux-selectors'
import MenuItem from '@mui/material/MenuItem'
import MenuButton from 'common/menus/MenuButton'
import { filter, join, map } from 'fp/arrays'
import { callOwnWith, distill } from 'fp/call'
import { increment } from 'fp/numbers'
import { get } from 'fp/objects'
import { prefix } from 'fp/strings'
import { isDefined, isFunction, when } from 'fp/utils'
import useNavigation from 'hooks/useNavigation'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { compose } from 'redux'

export const extractActionLabelFromRow = createSelector(
  compose(
    join(' '),
    map(callOwnWith('getValue')()),
    filter(get('column.columnDef.isCellHeader')),
    callOwnWith('getVisibleCells')(),
  ),
  compose(prefix('item '), increment, get('index')),
  (headerLabels, itemIndex) => headerLabels || itemIndex,
)

const RowActions = ({
  actions,
  row,
  row: { original: originalRow },
  table: {
    options: { renderAsCards },
  },
}) => {
  // In some contexts, it may be necessary to include row details in the label of the menu button.
  // In table view, we already have programmatic association with row headers so "Actions" is probably enough.
  // But in card view, we don't have that association, so in order to meet WCAG SC 2.4.6,
  // we should provide a more descriptive label.
  // See also https://www.w3.org/WAI/WCAG21/Understanding/headings-and-labels.html

  const { navigate } = useNavigation()
  const dispatch = useDispatch()

  const handleClick = action => () => {
    when(isFunction(action), action, originalRow)
    when(
      isDefined(action.dispatch),
      dispatch,
      distill(action.dispatch, originalRow),
    )
    when(
      isDefined(action.navigate),
      navigate,
      distill(action.navigate, originalRow),
    )
  }

  return (
    <MenuButton
      label={
        renderAsCards
          ? prefix('Actions for ')(extractActionLabelFromRow(row))
          : 'Actions'
      }>
      {actions.map(({ action, label }) => (
        <MenuItem
          key={label}
          onClick={handleClick(action)}>
          {label}
        </MenuItem>
      ))}
    </MenuButton>
  )
}

RowActions.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  row: PropTypes.object.isRequired,
  table: PropTypes.object.isRequired,
}

export default RowActions
