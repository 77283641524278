import {
  ABILITY_CONTENT_CREATION,
  CONTENT_TYPE_ECHO,
  CONTENT_TYPE_INTERACTIVE,
  CONTENT_TYPE_SOURCE,
  CONTENT_TYPE_VOCABULARY,
  ROLE_STAFF,
  ROLE_STUDENT,
} from 'core/consts'
import { LINKABLE_CONTENT_TYPES } from 'shared/consts'
import { createSelector } from './index'
import { createAbilityChecker } from './userAbility'
import { getCurrentRoleId } from './users'

export const getSearchableLibraryContentTypes = createSelector(
  'getSearchableLibraryContentTypes',
)(getCurrentRoleId, createAbilityChecker, (roleId, has) => {
  if ([ROLE_STAFF, ROLE_STUDENT].includes(roleId)) {
    return [
      // teachers only have access to these content types at the moment
      CONTENT_TYPE_ECHO,
      CONTENT_TYPE_SOURCE,
    ]
  }

  if (has(ABILITY_CONTENT_CREATION)) {
    return [
      ...LINKABLE_CONTENT_TYPES,
      // content creators are allowed to search/view individual interactives
      CONTENT_TYPE_INTERACTIVE,
      CONTENT_TYPE_VOCABULARY,
    ]
  }

  return LINKABLE_CONTENT_TYPES
})
