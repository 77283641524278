import MuiTabList, {
  type TabListProps as MuiTabListProps,
} from '@mui/lab/TabList'
import { useContainerQuery } from 'common/layout/ContainerQuery'
import { forwardRef } from 'react'
import AsDropdown from './AsDropdown'

interface TabListProps extends MuiTabListProps {
  children?: React.ReactNode
  contained?: boolean
  minSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
}

const TabList = forwardRef<HTMLDivElement, TabListProps>((props, ref) => {
  const { children, contained = false, minSize = 'md', ...rest } = props
  const isSmallScreen = useContainerQuery().down(minSize)

  return isSmallScreen ? (
    <AsDropdown {...{ ...props, ref }} />
  ) : (
    <MuiTabList
      data-contained={contained}
      {...{ ...rest, ref }}>
      {children}
    </MuiTabList>
  )
})

export default TabList
