import { differenceInMonths } from 'date-fns'
import { filterKeyedObject } from 'fp/objects'
import { now } from 'locale/dates'
import actionTypes from 'reducers/actionTypes'
import { SETTING_KEY_CONTENT_VIEWER_LAST_VIEWED } from 'reducers/localSettings'
import { call, put, select, takeEvery } from 'redux-saga/effects'
import { stateLocalSettings } from 'selectors/index'
import { getLocalSetting, getRootSettingsKey } from 'selectors/localSettings'
import { success } from './utils'

/**
 * We don't alter local settings directly within the app, but instead use redux
 * events to do so.  This affords us abilities that would otherwise be awkward,
 * such as interacting with settings within selectors or sagas.
 *
 * This saga handles the SET action, which is dispatched by the localSettingsActions.set
 * action creator.  It simply takes the current state reducer and sets it into
 * localStorage.
 */
export function* handleSet() {
  const state = yield select(stateLocalSettings)

  yield call([localStorage, 'setItem'], 'hss', JSON.stringify(state))
}

export function* clearOldContentViews() {
  // we're currently doing this right after each session starts
  const rootKey = yield select(getRootSettingsKey)
  const currentState = yield select(
    getLocalSetting(SETTING_KEY_CONTENT_VIEWER_LAST_VIEWED),
  )

  const nextState = filterKeyedObject(
    currentState || /* istanbul ignore next */ {},
    value => differenceInMonths(now(), value.timestamp) < 6,
  )

  yield put({
    type: actionTypes.LOCAL_SETTINGS_SET,
    rootKey,
    key: SETTING_KEY_CONTENT_VIEWER_LAST_VIEWED,
    value: nextState,
  })
}

/* istanbul ignore next line */
function* localSettingsSaga() {
  yield takeEvery(actionTypes.LOCAL_SETTINGS_SET, handleSet)
  yield takeEvery(success(actionTypes.SESSION_LOGIN), clearOldContentViews)
}

export default localSettingsSaga
