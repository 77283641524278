import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import { styled } from '@mui/material/styles'
import { TERRITORIES } from 'core/consts'
import { map } from 'fp/arrays'
import { get } from 'fp/objects'
import { noop, takeSecond } from 'fp/utils'
import withProps from 'hoc/withProps'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { compose } from 'redux'
import MultiPicker from './MultiPicker'

const Img = withProps(
  styled('img', { name: 'Territories-Selections' })(
    ({ theme: { shadows, spacing } }) => ({
      boxShadow: shadows[1],
      marginRight: spacing(1.5),
      flexShrink: 0,
    }),
  ),
  {
    loading: 'lazy',
    width: '20',
  },
)

export const imgSrc = ({ id }) => `/cdn/hssdev/flags/${id}.png`

const TerritoriesPicker = ({ onChange, value: selectedOptionIds, ...rest }) => {
  const [selectedOptions, setSelectedOptions] = useState([])

  useEffect(() => {
    const newValue = TERRITORIES.filter(item =>
      selectedOptionIds.includes(item.id),
    )
    setSelectedOptions(newValue)
  }, [selectedOptionIds])

  const handleChange = compose(onChange || noop, map(get('id')), takeSecond)

  return (
    <MultiPicker
      autoHighlight
      disableCloseOnSelect
      getOptionLabel={get('name')}
      label="Territories"
      options={TERRITORIES}
      {...rest}
      multiple
      onChange={handleChange}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props
        return (
          <Box
            component="li"
            key={key}
            {...optionProps}>
            <Img
              alt=""
              src={imgSrc(option)}
            />
            {option.name} ({option.id})
          </Box>
        )
      }}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          const { key, ...tagProps } = getTagProps({ index })
          return (
            <Chip
              avatar={
                <Avatar
                  alt=""
                  src={imgSrc(option)}
                  variant="shadow-1"
                />
              }
              key={key}
              label={option.name}
              variant="outlined"
              {...tagProps}
            />
          )
        })
      }
      value={selectedOptions}
    />
  )
}

TerritoriesPicker.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.array,
}

export default TerritoriesPicker
