import { set } from 'fp/objects'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import {
  indentedAmount,
  isFirstLineIndented,
  maxAllowedIndents,
} from '../utils/indentation'
import { fauxEvent } from '../utils/misc'
import DraftMenuButton from './DraftMenuButton'

const options = [
  { label: '< Outdent ¶', key: 'decrease' },
  { label: '> Indent ¶', key: 'increase' },
  { label: '> Indent First Line', key: 'indent-first-line' },
]

const IndentMenuButton = ({
  changeIndent,
  editorState,
  label,
  toggleBlockType,
  toggleIndentFirstLine,
  ...rest
}) => {
  const menuItems = useMemo(() => {
    const firstLineIsIndented = isFirstLineIndented(editorState)
    const indented = indentedAmount(editorState)

    return options.map(item => {
      switch (item.key) {
        case 'increase':
          return set('disabled', indented >= maxAllowedIndents)(item)
        case 'decrease':
          return set('disabled', indented <= 0)(item)
        case 'indent-first-line':
          return set(
            'label',
            `${firstLineIsIndented ? '< Outdent' : '> Indent'} First Line`,
          )(item)
        /* istanbul ignore next */
        default:
          return item
      }
    })
  }, [editorState])

  const handleChange = ({ key }) => {
    switch (key) {
      case 'increase':
      case 'decrease':
        changeIndent(fauxEvent(), key)
        break
      case 'indent-first-line':
        toggleIndentFirstLine(fauxEvent)
        break
      /* istanbul ignore next */
      default:
        toggleBlockType(key)()
    }
  }

  return (
    <DraftMenuButton
      editorState={editorState}
      label={label}
      onChange={handleChange}
      options={menuItems}
      {...rest}
    />
  )
}

IndentMenuButton.propTypes = {
  changeIndent: PropTypes.func.isRequired,
  editorState: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  toggleBlockType: PropTypes.func.isRequired,
  toggleIndentFirstLine: PropTypes.func.isRequired,
}

export default IndentMenuButton
