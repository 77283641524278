import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { findObj } from 'fp/arrays'
import { callWith } from 'fp/call'
import { get } from 'fp/objects'
import { curry } from 'fp/utils'
import { type ElementFormatType, FORMAT_ELEMENT_COMMAND } from 'lexical'
import { AlignCenter, AlignJustify, AlignLeft, AlignRight } from 'react-feather'
import { compose } from 'redux'
import type { ToolMenuItem } from '../@types/custom'
import ToolMenuButton from './ToolbarPlugin/ToolMenuButton'

interface AlignmentMenuItem extends ToolMenuItem {
  key: ElementFormatType
}

const options: AlignmentMenuItem[] = [
  {
    key: 'left',
    Icon: <AlignLeft />,
    label: 'Left Align',
  },
  {
    key: 'center',
    Icon: <AlignCenter />,
    label: 'Center Align',
  },
  {
    key: 'right',
    Icon: <AlignRight />,
    label: 'Right Align',
  },
  {
    key: 'justify',
    Icon: <AlignJustify />,
    label: 'Justify Align',
  },
]

const itemCommand = compose(
  get('command'),
  callWith(options),
  curry(findObj, 2, 'value'),
)

const ToolbarButton = ({
  disabled,
  value,
}: { disabled: boolean; value: ElementFormatType }) => {
  const [editor] = useLexicalComposerContext()

  const handleChange = (item: ToolMenuItem) => {
    editor.dispatchCommand(
      FORMAT_ELEMENT_COMMAND,
      item.key as ElementFormatType,
    )
  }

  return (
    <ToolMenuButton
      dense
      disabled={disabled}
      featureMenuKey="alignment"
      label="Align"
      onChange={item => handleChange(item as unknown as ToolMenuItem)}
      options={options}>
      {options.find(item => item.key === value)?.Icon || <AlignLeft />}
    </ToolMenuButton>
  )
}

export default ToolbarButton
