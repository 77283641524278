import { maxAllowedIndents } from 'common/formControls/textInputs/RichTextEdit/utils/indentation'
import { arraySequence } from 'fp/arrays'
import { set } from 'fp/objects'
import { border, transparentize } from 'polished'
import 'draft-js/dist/Draft.css'
import { acuminTextSizeR } from 'styling/theming/base/mixins'
import { inter } from 'styling/theming/base/typography'

const plugin = ({
  mixins: { borderS, important, importantPx, px, rem, transition },
  palette,
  typography,
}) => {
  const blockStyles = {
    'div.public-DraftStyleDefault-block': {
      margin: rem(0, 0, 2),
    },

    'pre.public-DraftStyleDefault-pre pre': {
      padding: 0,
      '.public-DraftStyleDefault-block': { margin: 0 },
    },

    li: {
      marginTop: 0,
      '.public-DraftStyleDefault-block': {
        margin: 0,
      },
    },

    h1: typography.h3,
    h2: typography.h4,
    h3: typography.h5,
  }

  const indents = {
    ...arraySequence(maxAllowedIndents)
      .map(i => ({ marginLeft: rem(i * 2) }))
      .reduce(
        (acc, item, idx) =>
          set(`& .indent-${idx}, [data-indent="${idx}"]`, item, true)(acc),
        {},
      ),

    '.indent-first-line, [data-indentfirstline="true"]': {
      textIndent: rem(4),
    },
  }

  const content = {
    color: palette.text.primary,
    lineHeight: px(24),

    ...blockStyles,

    ...indents,
  }

  const verticalDecorator = {
    position: 'absolute',
    transform: 'rotate(-90deg)',
    padding: rem(1, 1.5),
    fontWeight: 'normal',
    fontSize: rem(1.4),
    lineHeight: 1,
    color: palette.grey.contrastText,
    backgroundColor: transparentize(0.5, palette.grey[1]),
  }

  return {
    '.standard .DraftEditor-root': {
      ...border('bottom', 1, 'solid', palette.grey[0]),
      '&:hover, &:focus-within': {
        ...transition('all', 150),
        borderBottomWidth: 2,
      },
    },

    '.outlined, .filled': {
      boxShadow: `0 0 0 1px ${palette.grey[0]}`,
      '&.MuiBox-root': {
        '&:hover, &:focus-within': {
          ...transition('all', 150),
          boxShadow: `0 0 0 2px ${palette.grey[0]}`,
        },
      },
    },

    '.filled .DraftEditor-root': {
      backgroundColor: palette.grey.contrastText,
    },

    '.Mui-error': {
      '&.standard .DraftEditor-root': {
        borderBottomColor: palette.error.main,
      },

      '&.outlined .DraftEditor-root, &.filled .DraftEditor-root': {
        boxShadow: `0 0 0 1px ${palette.error.main}`,
        '&:hover, &:focus-within': {
          boxShadow: `0 0 0 2px ${palette.error.main}`,
        },
      },
    },

    '.Mui-error.filled .DraftEditor-root': {
      backgroundColor: palette.error.light,
    },

    '.DraftEditor-root': {
      padding: rem(1, 2, 1, 1),
      ...content,
      cursor: 'text',

      '.DraftEditor-editorContainer': {
        ...typography.variants['feature-paragraph'],

        '.decorator-link': {
          color: important('#3397ff'),
          textDecoration: 'underline',
        },

        'ul, ol': {
          position: 'relative',
          left: 40,
          maxWidth: 'calc(100% - 40px)',
        },

        section: { padding: 0 },

        table: {
          borderCollapse: 'collapse',
          width: '100%',
          margin: px(15, 0),
          tableLayout: 'fixed',

          'th, td': {
            ...borderS(palette.grey[4]),
            'div.public-DraftStyleDefault-block': {
              margin: px(5),
              textAlign: 'inherit',
            },
            verticalAlign: 'baseline',
            fontSize: 'smaller',
            lineHeight: 1.6,
          },

          th: {
            backgroundColor: palette.grey[5],
            padding: importantPx(0, 0, 0, 10),
          },

          td: {
            backgroundColor: palette.common.white,
            verticalAlign: 'top',
            padding: px(0, 8, 12),
          },
        },

        'div.credit': {
          ...border('top', 1, 'solid', palette.border[1]),
          color: palette.text.secondary,
          paddingTop: 16,
          marginBottom: rem(4.8),
        },

        '.plugin-input': {
          borderStyle: 'none',
          backgroundColor: palette.primary.A100,
          lineHeight: 1.4,
          wordWrap: 'break-word',
          cursor: 'text',
          width: '100%',
          margin: 1,
          padding: importantPx(7, 10),
          fontSize: rem(1.4),
          fontFamily: inter,
          fontWeight: 100,
          ...transition('all', 200),
          boxShadow: 'none',
          '&:hover': {
            boxShadow: `0 0 0 2px ${palette.grey[3]}`,
          },
          '&:focus-within': {
            boxShadow: `0 0 0 2px ${palette.grey[1]}`,
          },
        },

        'select.plugin-input': {
          minWidth: '30%',
          paddingRight: 40,
          cursor: 'pointer',

          // The rest of this just replaces the dropdown arrow
          WebkitAppearance: 'none !important',
          MozAppearance: 'none !important',
          backgroundImage:
            'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAUCAMAAACtdX32AAAAdVBMVEUAAAD///8AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAhMdQaAAAAJ3RSTlMAAAECAwQGBwsOFBwkJTg5RUZ4eYCHkJefpaytrsXGy8zW3+Do8vNn0bsyAAAAYElEQVR42tXROwJDQAAA0Ymw1p9kiT+L5P5HVEi3qJn2lcPjtIuzUIJ/rhIGy762N3XaThqMN1ZPALsZPEzG1x8LrFL77DHBnEMxBewz0fJ6LyFHTPL7xhwzWYrJ9z22AqmQBV757MHfAAAAAElFTkSuQmCC)',
          backgroundPosition: '100%',
          backgroundRepeat: 'no-repeat',
        },

        'li:first-of-type': {
          position: 'relative',

          '.numbered-card-decorator::before': {
            ...verticalDecorator,
            content: '"Cards"',
            top: 25,
            left: -98,
          },

          '.source-header-decorator::before': {
            ...verticalDecorator,
            content: '"Source"',
            top: 25,
            left: -102,
          },

          '.unstyled-decorator::before': {
            ...verticalDecorator,
            content: '"Simple"',
            top: 35,
            left: -100,
          },
        },

        '[style*="stroke-dashoffset: 0"]': typography.variants.highlighted,
        '[style*="stroke-dashoffset: 1"]': {
          fontSize: 'smaller',
          verticalAlign: 'sub',
        },
        '[style*="stroke-dashoffset: 2"]': {
          fontSize: 'smaller',
          verticalAlign: 'super',
        },
        '[style*="stroke-dashoffset: 3"]': { color: palette.teacherEdition },

        '.source-title > div': {
          textAlign: 'center',
        },

        blockquote: {
          '&.pullQuote': {
            position: 'relative',
            marginTop: 20,
            boxSizing: 'border-box',

            '&::before': {
              content: '"“"',
              position: 'absolute',
              top: -5,
              left: 10,
              color: palette.accent.main,
              ...acuminTextSizeR(3.2, '95%', 900),
            },
          },
        },
      },
    },

    '.DraftEditor-root:hover, .DraftEditor-root:focus-within': {
      '.public-DraftEditorPlaceholder-root': {
        opacity: 0,
      },
    },

    '.DraftEditor-toolbar-container': {
      display: 'flex',
      flexFlow: 'row wrap',

      '.DraftEditor-toolbar:empty': { display: 'none' },
      '.DraftEditor-toolbar': {
        marginBottom: 6,
        '&:not(:last-of-type)': {
          marginRight: 7,
        },
        display: 'flex',
        flexFlow: 'row wrap',
        gap: 1,
        button: {
          borderRadius: 0,
          color: palette.text.secondary,
          display: 'inline-block',
          '&[aria-expanded="true"]': {
            backgroundColor: palette.grey[3],
            color: palette.secondary.contrastText,
          },
          boxShadow: `0 0 0 1px ${palette.grey[0]}`,
          '&:hover, &:focus-within': {
            backgroundColor: palette.grey.contrastText,
            boxShadow: `inset 0 0 0 2px ${palette.grey[0]}`,
          },
          '&.standard': {
            boxShadow: 'none',
          },
        },
      },

      '.DraftEditor-rendered-content': content,

      // Exporting separately so that our toolbar can use the same styles
      '.DraftEditor-blockStylesOnly': blockStyles,
      '.menu-dense': {
        '.MuiList-root': {
          display: 'flex',
        },
      },

      '.public-DraftEditorPlaceholder-root': {
        color: palette.grey[2],
        position: 'absolute',
        opacity: 1,
        ...transition('opacity'),
      },

      '.draft-statusbar.Mui-error': {
        color: palette.error.main,
      },
    },

    '.DraftEditor-disabled': {
      '.DraftEditor-toolbar-container, .DraftEditor-root, .outlined.MuiBox-root':
        {
          opacity: 0.5,
          pointerEvents: 'none',
        },
      '.outlined.MuiBox-root': {
        borderWidth: 1,
        boxShadow: 'none',
        borderStyle: 'dashed',
      },
    },
  }
}

export default plugin
