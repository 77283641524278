const { useDispatch } = require('react-redux')
const { compose } = require('redux')
const { actions } = require('reducers/notifications')

const useAlert = () => {
  const dispatch = useDispatch()

  return compose(dispatch, actions.addAlert)
}

export default useAlert
