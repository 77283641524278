import { navItemShape } from 'core/shapes'
import { get } from 'fp/objects'

const EntryIcon = ({ entry }) => {
  const { Icon, IconActive } = entry

  const selected = entry.matches || entry.items?.some(get('matches'))

  if (selected) {
    return (
      <div className="icon-container">
        <IconActive />
      </div>
    )
  }

  return (
    <div className="icon-container">
      {Boolean(Icon) && <Icon className="icon-unfilled" />}
      {Boolean(IconActive) && <IconActive className="icon-filled" />}
    </div>
  )
}

EntryIcon.propTypes = {
  entry: navItemShape.isRequired,
}

export default EntryIcon
