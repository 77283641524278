export const radiosAndCheckboxes = ({ palette }, accent) => ({
  svg: { color: accent[0] },
  '&.Mui-disabled': {
    svg: {
      color: accent[3],
    },
    '&.Mui-checked svg rect': {
      fill: accent[3],
    },
  },
  '.Mui-disabled:not(.Mui-checked) svg:not(.indeterminate)': {
    color: palette.grey[3],
  },

  '&.Mui-checked': {
    'svg.checked': { display: 'unset' },
    'svg.checked-hover': { display: 'none' },
    '&.MuiRadio-root rect': { fill: accent[0] },
  },

  '&:not(.Mui-checked)': {
    'svg.unchecked': {
      display: 'unset',
      color: palette.grey[0],
    },
    'svg.unchecked-hover': { display: 'none' },
  },

  'svg.indeterminate': { display: 'unset' },
  'svg.indeterminate-hover': { display: 'none' },

  '&:not(.Mui-disabled):hover': {
    svg: {
      color: accent[0],
      circle: { fill: accent[0] },
      rect: {
        stroke: accent[0],
        fill: accent.contrastText,
      },
    },

    '&.Mui-checked': {
      'svg.checked': { display: 'none' },
      'svg.checked-hover': { display: 'unset' },
    },

    '&:not(.Mui-checked)': {
      'svg.unchecked': { display: 'none' },
      'svg.unchecked-hover': { display: 'unset' },
    },

    'svg.indeterminate': { display: 'none' },
    'svg.indeterminate-hover': { display: 'unset' },
  },
})

export const switches = ({ mixins: { borderS, important } }, accent) => ({
  '.Mui-checked': {
    '.MuiSwitch-thumb': {
      ...borderS(accent[0], 2),
    },

    '&+.MuiSwitch-track': {
      backgroundColor: important(accent[0]),
      borderColor: accent[0],
    },
  },

  '.Mui-disabled.Mui-checked': {
    '.MuiSwitch-thumb': {
      borderColor: accent[4],
    },

    '+.MuiSwitch-track': {
      backgroundColor: important(accent[4]),
      borderColor: accent[4],
    },
  },

  '*:not(.Mui-disabled):hover, *:not(.Mui-disabled):hover .Mui-checked': {
    '+.MuiSwitch-track, .MuiSwitch-thumb': {
      borderColor: accent[0],
    },
  },
})

const overrides = theme => {
  const {
    mixins: { em, rem },
    palette,
  } = theme

  return {
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: rem(1.6),
          fontWeight: 400,
          lineHeight: '157%',
          letterSpacing: em(-0.02),
          '&:not(.Mui-disabled):hover': {
            color: '#000',
          },
        },
        p: { margin: 0 },
      },

      variants: [
        {
          props: {
            color: 'primary',
            'data-controltype': 'checkbox',
          },
          style: radiosAndCheckboxes(theme, palette.cobalt),
        },

        {
          props: {
            color: 'secondary',
            'data-controltype': 'checkbox',
          },
          style: radiosAndCheckboxes(theme, palette.grey),
        },

        {
          props: {
            color: 'primary',
            'data-controltype': 'radio',
          },
          style: radiosAndCheckboxes(theme, palette.cobalt),
        },

        {
          props: {
            color: 'secondary',
            'data-controltype': 'radio',
          },
          style: radiosAndCheckboxes(theme, palette.grey),
        },

        {
          props: { size: 'small' },
          style: {
            transform: 'scale(0.75)',
          },
        },
      ],
    },
  }
}

export default overrides
