import { removeBlock } from '@studysync/draft-js-modifiers'
import { EditorState } from 'draft-js'
import { deepMerge, mapKeys, omit } from 'fp/objects'
import { capitalize } from 'fp/strings'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import CustomBlockWrapper from './helpers/CustomBlockWrapper'
import PluginInput from './helpers/PluginInput'
import { customBlockPropsShape } from './helpers/utils'

const definitionBlockType = 'word-definition'
const fields = [
  'word',
  'form',
  'syllabification',
  'pronunciation',
  'definition1',
  'definition2',
]

const WordDefinitionPlaceholder = ({ block, blockProps }) => {
  const { getEditorState, onChange, readOnly } = blockProps

  const handleRemove = () => {
    const editorState = getEditorState()
    const newContentState = removeBlock(
      editorState.getCurrentContent(),
      block.getKey(),
    )
    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      'remove-block',
    )
    onChange(newEditorState)
  }
  return (
    <CustomBlockWrapper
      block={block}
      blockProps={blockProps}
      onRemove={handleRemove}
      readOnly={readOnly}
      title="Definition">
      <table>
        <tbody>
          {fields.map(field => (
            <tr key={field}>
              <th>{capitalize(field)}</th>
              <td>
                <PluginInput
                  block={block}
                  blockProps={blockProps}
                  component="input"
                  dataField={field}
                  getEditorState={getEditorState}
                  setEditorState={onChange}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </CustomBlockWrapper>
  )
}

WordDefinitionPlaceholder.propTypes = {
  block: PropTypes.object.isRequired,
  blockProps: customBlockPropsShape.isRequired,
}

const blockRendererFn = (
  contentBlock,
  {
    // features,
    getEditorState,
    onChange,
    readOnly,
    setPluginHasFocus,
  },
) => {
  if (
    contentBlock.getType() === 'atomic' &&
    contentBlock.getData().get('type') === definitionBlockType
  ) {
    return {
      component: WordDefinitionPlaceholder,
      editable: false,
      props: {
        getEditorState,
        onChange,
        readOnly,
        setPluginHasFocus,
      },
    }
  }

  return undefined
}

const blockToHTML =
  (/* features */) =>
  (current, { data, type }) => {
    if (type === 'atomic' && data.type === definitionBlockType) {
      const dataValues = compose(
        mapKeys(key => `data-${key}`),
        omit('type'),
      )(data)
      return (
        <div
          {...dataValues}
          data-variant={definitionBlockType}
        />
      )
    }

    return current
  }

const htmlToBlock = (/* features */) => (current, _, node) => {
  if (node?.getAttribute?.('data-variant') === definitionBlockType) {
    return deepMerge(current, {
      type: 'atomic',
      mutability: 'IMMUTABLE',
      data: {
        ...omit('type')(node.dataset),
        type: definitionBlockType,
      },
    })
  }

  return current
}

const wordDefinitionPlugin = {
  blockRendererFn,
  blockToHTML,
  htmlToBlock,
}

export default wordDefinitionPlugin
