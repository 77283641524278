import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import { removeBlock } from '@studysync/draft-js-modifiers'
import BusySpinner from 'common/indicators/BusySpinner'
import { ABILITY_CONTENT_EDITING, CONTENT_TYPE_INTERACTIVE } from 'core/consts'
import { EditorState } from 'draft-js'
import { get, notEquals } from 'fp/objects'
import { curry } from 'fp/utils'
import useAbilityCheck from 'hooks/useAbilityCheck'
import useContent from 'hooks/useContent'
import { labels } from 'hss/ContentBuilder/consts'
import PropTypes from 'prop-types'
import { useFormContext, useWatch } from 'react-hook-form'
import { compose } from 'redux'
import { contentBuilderUrl } from 'routing/consts'
import CustomBlockWrapper from '../helpers/CustomBlockWrapper'
import { customBlockPropsShape } from '../helpers/utils'
import MediaPreview from './MediaPreview'

const adminRows = {
  assetCode: 'Asset code',
  standards: 'Standards',
  tags: 'Tags',
  notes: 'Notes',
}

const nonAdminRows = {
  'data.imageAltText': 'Alt Text',
  'data.sensitive': 'Sensitive',
}

const InteractivePlaceholder = ({ block, blockProps }) => {
  const { getEditorState, onChange, readOnly } = blockProps

  const canEdit = useAbilityCheck(ABILITY_CONTENT_EDITING)
  const rows = canEdit ? adminRows : nonAdminRows

  const children = useWatch({ name: 'children' })
  const { setValue } = useFormContext()

  const contentId = block.getData().get('contentId')

  const content = useContent({
    contentType: CONTENT_TYPE_INTERACTIVE,
    contentId,
  })

  const handleRemove = () => {
    const filterById = compose(curry(notEquals)(contentId), get('id'))

    const editorState = getEditorState()
    const newContentState = removeBlock(
      editorState.getCurrentContent(),
      block.getKey(),
    )
    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      'remove-block',
    )

    setValue('children', children?.filter(filterById))
    onChange(newEditorState)
  }

  const openEditWindow = () => {
    window.open(
      `${contentBuilderUrl}/${CONTENT_TYPE_INTERACTIVE}/${content.contentSubType}/${contentId}`,
      '_blank',
    )
  }

  return content ? (
    <CustomBlockWrapper
      block={block}
      blockProps={blockProps}
      draggable
      onRemove={handleRemove}
      readOnly={readOnly}
      title={labels[content.contentSubType]}
      titleAddendum={
        canEdit && (
          <Tooltip title="Edit this interactive in a new tab...">
            <Button
              color="primary"
              onClick={openEditWindow}
              size="small"
              variant="tertiary">
              [edit]
            </Button>
          </Tooltip>
        )
      }>
      <MediaPreview content={content} />

      <table>
        <tbody>
          {Object.entries(rows).map(([key, value]) => (
            <tr key={key}>
              <th>{value}</th>
              <td>
                {typeof get(key)(content) === 'boolean'
                  ? get(key)(content)
                    ? 'Yes'
                    : 'No'
                  : get(key)(content)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </CustomBlockWrapper>
  ) : (
    <BusySpinner />
  )
}

InteractivePlaceholder.propTypes = {
  block: PropTypes.object.isRequired,
  blockProps: customBlockPropsShape.isRequired,
}

export default InteractivePlaceholder
