import { toKeyedObject } from 'fp/arrays'
import { decrement } from 'fp/numbers'
import { omit, set } from 'fp/objects'
import { capitalize } from 'fp/strings'
import { compose } from 'redux'
import { success } from 'sagas/utils'
import actionTypes from './actionTypes'
import { createReducer } from './utils'

/**
 * User Alerts are app-level persistent messages that are  displayed to teacher or sysadmin users.
 *
 * They are of a TYPE of 'system' (e.g. new feature release msg), 'teacher' (e.g. teacher activity msg)
 * or 'student' (e.g. a student has a late assignment).
 *
 * They are of a MODE of 'interrupt' (for messages that should be shown immediately) and
 * 'profile' (for messages that are available by manually clicking on a Notifications link).
 *
 * 'all' is for when mixed types (i.e. all alert types, both new and already-viewed) are fetched, and will have
 * different metadata than when we fetch by specific type.
 *
 * When we are fetching by a specific type/mode, we only need the 'isNew' alerts.
 */
const ALERT_TYPES = ['all', 'isNewInterrupt', 'isNewProfile']

const userAlertInitialStateTypes = ALERT_TYPES.reduce(
  (acc, type) => ({
    ...acc,
    [type]: { data: {}, fetched: false, metadata: undefined },
  }),
  {},
)

const userAlertReducerInitialState = () => ({ ...userAlertInitialStateTypes })

export const actions = {
  fetchUserAlerts: payload => ({
    type: actionTypes.USER_ALERT_FETCH,
    payload,
  }),
  fetchNewUserAlertsByType: ({ mode }) => ({
    type: actionTypes.USER_ALERT_FETCH_NEW_BY_TYPE,
    mode,
  }),
  confirmUserAlert: ({ id }) => ({
    type: actionTypes.USER_ALERT_CONFIRM,
    id,
  }),
}

const handleFetchAllSuccess = (state, { response: { data, metadata } }) => ({
  ...state,
  all: { data: toKeyedObject(data), fetched: true, metadata },
})

const handleFetchNewByTypeSuccess = (
  state,
  { response: { data, metadata } },
) => {
  const mode = metadata?.squery?.modifiers?.forAlertMode

  return Object.keys(metadata).length && mode
    ? {
        ...state,
        [`isNew${capitalize(mode)}`]: {
          data: toKeyedObject(data),
          fetched: true,
          metadata,
        },
      }
    : state
}

const handleConfirmSuccess = (state, { response, response: { id } }) => {
  const storeKey = `isNew${capitalize(response.mode)}`

  return compose(
    set(`all.data.${id}`, response),
    set('all.fetched', true),
    set(storeKey, {
      data: omit(id)(state[storeKey].data),
      fetched: true,
      metadata: {
        count: decrement(state[storeKey].metadata.count),
        squery: state.isNewProfile.metadata.squery,
      },
    }),
  )(state)
}

const userAlerts = createReducer(userAlertReducerInitialState(), {
  [success(actionTypes.USER_ALERT_FETCH)]: handleFetchAllSuccess,
  [success(actionTypes.USER_ALERT_FETCH_NEW_BY_TYPE)]:
    handleFetchNewByTypeSuccess,
  [success(actionTypes.USER_ALERT_CONFIRM)]: handleConfirmSuccess,
})

export default userAlerts
