import Bowser from 'bowser'
import { useMemo } from 'react'

/**
 * See https://lancedikson.github.io/bowser/docs/index.html for available methods
 */

const useBrowser = () =>
  useMemo(() => Bowser.getParser(window.navigator.userAgent), [])

export default useBrowser
