import useContent from './useContent'

/**
 * Be careful if setting disableFetch to false without also specifying childDepth
 * within queryParams.
 *
 * Without childDepth, fetching the parent will only fetch it and its immediate
 * children, meaning the piece of content you are calling this from would lose
 * its own children after the fetch.
 *
 * I'm pretty certain we'd never want to enable fetching when going after parent
 * content, but I can see scenarios where you might so I'm leaving it as an option
 * (but defaulting to not fetch)
 */

const useParentContent = ({
  contentType,
  contentId,
  disableFetch = true,
  id,
  queryParams,
}) => {
  const { parent } =
    useContent({ contentType, contentId: contentId || id }) || {}
  const { contentType: parentContentType, id: parentContentId } = parent || {}
  const fetchedParent = useContent({
    contentType: parentContentType,
    contentId: parentContentId,
    disableFetch,
    queryParams,
  })

  return fetchedParent
}

export default useParentContent
