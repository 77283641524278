import { proceedIfOk } from 'core/middleware/http'
import { videoShape } from 'core/shapes'
import { when } from 'fp/utils'
import webvtt from 'node-webvtt'
import PropTypes from 'prop-types'
import { createContext, useEffect, useMemo, useRef, useState } from 'react'

export const videoPlayerContext = createContext()

const VideoPlayerProvider = props => {
  const {
    allowAnnotations,
    captionId,
    children,
    contextContentId,
    onInteract,
    previewing = false,
    video,
  } = props
  const playerRef = useRef()
  const [playState, setPlayState] = useState('not ready')
  const [theaterMode, setTheaterMode] = useState(false)
  const [annotationsOn, setAnnotationsOn] = useState(false)
  const [transcriptOpen, setTranscriptOpen] = useState(false)
  const [vtt, setVtt] = useState({ valid: false, cues: [] })
  const [currentTime, setCurrentTime] = useState()
  const [videoJustStarted, setVideoJustStarted] = useState(false)
  const [mouseInMotion, setMouseInMotion] = useState(false)
  const [videoDuration, setVideoDuration] = useState()

  useEffect(() => {
    let mounted = true
    const fetchData = () => {
      fetch(`${video.ccUrl}`, {
        headers: {
          accept: 'application/json, text/plain, */*',
          'cache-control': 'no-cache',
        },
        failSilently: true,
      })
        .then(proceedIfOk)
        .then(response => response.text())
        .then(webvtt.parse)
        .then(result => when(mounted, setVtt, result))
    }
    when(video?.ccUrl, fetchData)

    return () => {
      mounted = false
    }
  }, [video?.ccUrl])

  const value = useMemo(
    () => ({
      allowAnnotations,
      annotationsOn,
      captionId,
      contextContentId,
      currentTime,
      mouseInMotion,
      onInteract,
      playerRef,
      playState,
      previewing,
      setAnnotationsOn,
      setCurrentTime,
      setMouseInMotion,
      setPlayState,
      setTheaterMode,
      setTranscriptOpen,
      setVideoDuration,
      setVideoJustStarted,
      theaterMode,
      transcriptOpen,
      video,
      videoDuration,
      videoJustStarted,
      vtt,
    }),
    [
      allowAnnotations,
      annotationsOn,
      captionId,
      contextContentId,
      currentTime,
      mouseInMotion,
      onInteract,
      playState,
      previewing,
      theaterMode,
      transcriptOpen,
      video,
      videoDuration,
      videoJustStarted,
      vtt,
    ],
  )

  return (
    <videoPlayerContext.Provider value={value}>
      {children}
    </videoPlayerContext.Provider>
  )
}

VideoPlayerProvider.propTypes = {
  allowAnnotations: PropTypes.bool.isRequired,
  captionId: PropTypes.string,
  children: PropTypes.node.isRequired,
  contextContentId: PropTypes.string,
  onInteract: PropTypes.func,
  previewing: PropTypes.bool,
  video: videoShape,
}

export default VideoPlayerProvider
