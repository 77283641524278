import {
  $applyNodeReplacement,
  ElementNode,
  type LexicalNode,
  type SerializedElementNode,
} from 'lexical'

export class SourceTitleNode extends ElementNode {
  // biome-ignore lint/complexity/noUselessConstructor: <explanation>
  constructor(key?: string) {
    super(key)
  }

  static getType(): string {
    return 'source-title'
  }

  static clone(node: SourceTitleNode): SourceTitleNode {
    return new SourceTitleNode(node.__key)
  }

  createDOM(/*config: EditorConfig, editor:LexicalEditor*/): HTMLElement {
    // super.createDOM(config, editor)
    const dom = document.createElement('h1')
    return dom
  }

  updateDOM(/*prevNode: SourceTitleNode, dom: HTMLElement*/): boolean {
    // Returning false tells Lexical that this node does not need its
    // DOM element replacing with a new copy from createDOM.
    return false
  }

  static importJSON(/*json: SerializedElementNode*/) {
    const node = new SourceTitleNode()
    return node
  }

  exportJSON(): SerializedElementNode {
    return {
      ...super.exportJSON(),
      type: this.getType(),
      version: 1,

      // contentId: this.__contentId,
    }
  }
}

export function $isSourceTitleNode(node: LexicalNode): boolean {
  return node instanceof SourceTitleNode
}

export function $createSourceTitleNode(): LexicalNode {
  const node = new SourceTitleNode()

  return $applyNodeReplacement(node)
}
