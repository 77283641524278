import SkipToButton from './SkipToButton'

const SkipToBottomNavigation = () => (
  <SkipToButton
    findTarget={() => document.getElementById('bottom-navigation')}
    placement="right">
    Skip to navigation
  </SkipToButton>
)

export default SkipToBottomNavigation
