import { styled } from '@mui/material/styles'
import { keyframes } from '@mui/styled-engine'
import PageLoader from 'common/indicators/PageLoader'
import { componentShape } from 'core/shapes'
import { get } from 'fp/objects'
import { suffix } from 'fp/strings'
import { useDeepCompareEffect } from 'hooks/useDeepCompare'
import useEffectOnce from 'hooks/useEffectOnce'
import { useContext, useId, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { compose } from 'redux'
import { stateRouting } from 'selectors/index'
import { routeTransitionContext } from './RouteTransitionProvider'

const defaultTransitionType = 'leftBump'

const enter = suffix('Enter')
const exit = suffix('Exit')

const isEnter = transitionStage => String(transitionStage).endsWith('Enter')
const isExit = transitionStage => String(transitionStage).endsWith('Exit')

const leftBumpEnter = keyframes`
  from {
    opacity: 0;
    transform: translate(-20px, 0);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }`

const leftBumpExit = keyframes`
  from {
    opacity: 1;
    transform: translate(0px, 0px);
  }
  to {
    transform: translate(-20px, 0);
    opacity: 0;
  }`

const Wrapper = styled('div', { name: 'TocShell-RouteTransition' })(
  ({ theme: { zIndex } }) => ({
    '.busy': {
      visibility: 'hidden',
      position: 'sticky',
      height: '100%',
      width: 'fit-content',
      marginTop: '-10rem',
      top: '45%',
      zIndex: zIndex.drawer + 2,
    },

    '&.leftBumpEnter .content': {
      animation: `0.15s ${leftBumpEnter}`,
    },

    '&.leftBumpExit': {
      '.content': {
        animation: `0.1s ${leftBumpExit} forwards`,
      },
      '.busy': {
        visibility: 'visible',
      },
    },

    /**
     * Add any additional animation types here.  They must come in pairs and end with
     * 'Enter' and 'Exit'.  They also must be lexically correct identifiers, so use
     * 'myAnimation' and not something like 'my-animation'.
     */

    /**
     * In the event that we have multiple transition types and the user happens to
     * be going from one to another, the exit animation from the current route will
     * get used and then the enter animation from the second will become active, and
     * so on.  In other words the enter and exit routines will always happen in pairs,
     * so don't expect the page transition to use the exit routine of the upcoming
     * route -- it will still use the current exit animation prior to that route
     * loading.
     */
  }),
)

/**
 * This component works in conjunction with RouteTransitionProvider, useNavigation, and the routing saga.
 * When the current location is about to change, it shows a nearly immediate animation
 * and, importantly, a BusySpinner. Then it tells the provider its `exitHasCompleted`.
 * If we don't space out the events like this, the UI won't always show the immediate feedback of the BusySpinner.
 */
const RouteTransition = ({ children }) => {
  const id = useId()

  const {
    exitHasCompleted,
    register,
    transitionType = defaultTransitionType,
    unregister,
  } = useContext(routeTransitionContext)

  useEffectOnce(() => {
    register(id)
    return () => {
      unregister(id)
    }
  })

  const nextLocation = compose(get('nextLocation'), useSelector)(stateRouting)
  const { pathname: nextLocationPathName } = nextLocation || {}
  const nextPath = useRef(nextLocationPathName)
  const waitingForEnterAnimationToStart = useRef(false)
  const [transitionStage, setTransitionStage] = useState(enter(transitionType))

  const contentRef = useRef()

  const onContentAnimationEnd = ({ target }) => {
    if (
      target === contentRef.current &&
      isExit(transitionStage) &&
      !waitingForEnterAnimationToStart.current
    ) {
      setTransitionStage(enter(transitionType))
      exitHasCompleted(id, nextLocationPathName)

      /**
       * After we setTransitionStage, it can sometimes take a while before the enter animation actually starts.
       * During this time, it's possible for the user to start another navigation.
       */
      waitingForEnterAnimationToStart.current = true
    }
  }

  const onContentAnimationStart = ({ target }) => {
    if (target === contentRef.current && isEnter(transitionStage)) {
      waitingForEnterAnimationToStart.current = false
    }
  }

  useDeepCompareEffect(() => {
    if (nextPath.current === nextLocationPathName) {
      // If the next location is at the same path name, perhaps the hash or query changed.
      // (For example, maybe we just switched tabs in an Echo.)
      // In that case, we won't actually do any animation and can just allow the location change to proceed.
      exitHasCompleted(id, nextLocationPathName)
    } else {
      nextPath.current = nextLocationPathName
      if (waitingForEnterAnimationToStart.current) {
        /**
         * But if we get here, then the exit animation has already ended,
         * and then nextPath changed again before the enter animation started.
         * (Perhaps the user rapidly clicked a "next" button more than once.)
         * We need to tell the RouteTransitionProvider that the exit is already completed for this new location too.
         */
        exitHasCompleted(id, nextLocationPathName)
      } else {
        /**
         * Normally when the path has just changed,
         * we're not in the middle of loading content/styles,
         * and we can just kick off the exit animation.
         */
        setTransitionStage(exit(transitionType))
      }
    }
  }, [exitHasCompleted, id, nextLocation, transitionType])

  return (
    <Wrapper className={transitionStage}>
      <div
        className="busy"
        data-testid="route-transition-animation"
        style={{
          left: '45%',
        }}>
        <PageLoader
          segments={11}
          size={64}
        />
      </div>
      <div
        className="content"
        onAnimationEnd={onContentAnimationEnd}
        onAnimationStart={onContentAnimationStart}
        ref={contentRef}>
        {children}
      </div>
    </Wrapper>
  )
}

RouteTransition.propTypes = {
  children: componentShape,
}

export default RouteTransition
