import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import Select from 'common/formControls/selects/Select'
import { useCallback, useContext, useMemo, useState } from 'react'
import { audioContext } from './AudioContextProvider'

const RateControl = () => {
  const { setPlaybackRate } = useContext(audioContext)
  const [speed, setSpeed] = useState('1')

  const handleChange = useCallback(
    ({ target }) => {
      const { value } = target

      setPlaybackRate(Number.parseFloat(value))
      setSpeed(value)
    },
    [setPlaybackRate],
  )

  return useMemo(
    () => (
      <Stack
        alignItems="center"
        direction="row"
        gap={2}>
        <span>Speed: </span>
        <Select
          name="speed"
          onChange={handleChange}
          sx={{ marginTop: 0 }}
          value={speed}>
          <MenuItem value="0.5">0.5x</MenuItem>
          <MenuItem value="1">1x</MenuItem>
          <MenuItem value="1.5">1.5x</MenuItem>
          <MenuItem value="2">2x</MenuItem>
        </Select>
      </Stack>
    ),
    [handleChange, speed],
  )
}

export default RateControl
