import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import List from '@mui/material/List'
import Stack from '@mui/material/Stack'
import { flexRender } from '@tanstack/react-table'
import { componentShape } from 'core/shapes'
import { first } from 'fp/arrays'
import { pick } from 'fp/objects'
import PropTypes from 'prop-types'
import {
  filterActionsMenu,
  filterNonIntrinsicColumns,
  filterRowSelector,
  filterTopLevelColumns,
  filterTopLevelHeaderGroup,
} from './Cells'
import RowSelectCell from './Cells/RowSelectCell'
import ColumnarList from './ColumnarList'

export const tableShape = PropTypes.shape({
  getIsSomeRowsSelected: PropTypes.func.isRequired,
  getToggleAllRowsSelectedHandler: PropTypes.func.isRequired,
  getAllColumns: PropTypes.func.isRequired,
  getHeaderGroups: PropTypes.func.isRequired,
  getIsAllRowsSelected: PropTypes.func.isRequired,
  getRowModel: PropTypes.func.isRequired,
  options: PropTypes.shape({
    enableRowSelection: PropTypes.bool.isRequired,
  }),
})

const CardView = ({
  CardRenderer,
  cardRendererProps,
  style,
  table: {
    getIsAllRowsSelected,
    getIsSomeRowsSelected,
    getToggleAllRowsSelectedHandler,
    getHeaderGroups,
    getRowModel,
    options: { enableRowSelection },
  },
  tableId,
  ...rest
}) => {
  const { rows } = getRowModel()
  const headerGroup = first(getHeaderGroups().filter(filterTopLevelHeaderGroup))
  const selectorHeader = first(
    headerGroup.headers.filter(filterTopLevelColumns).filter(filterRowSelector),
  )

  return (
    <>
      {Boolean(enableRowSelection && !!selectorHeader) && (
        <Box ml={3}>
          {flexRender(RowSelectCell, {
            ...pick(
              'pluralLabel',
              'singleLabel',
            )(selectorHeader?.column.columnDef),
            checked: getIsAllRowsSelected(),
            indeterminate: getIsSomeRowsSelected(),
            isHeader: true,
            isCardView: true,
            onChange: getToggleAllRowsSelectedHandler(),
          })}
        </Box>
      )}

      <List {...rest}>
        {rows.map(row => {
          const selectorCell = first(
            row.getAllCells().filter(filterRowSelector),
          )

          const menuCell = first(row.getAllCells().filter(filterActionsMenu))

          const dataCells = row.getAllCells().filter(filterNonIntrinsicColumns)

          return (
            <li key={row.id}>
              <Card variant="table">
                <Stack
                  alignItems="baseline"
                  direction="row"
                  spacing={2}>
                  {Boolean(enableRowSelection && !!selectorCell) &&
                    flexRender(
                      selectorCell.column.columnDef.cell,
                      selectorCell.getContext(),
                    )}

                  <Box style={{ flex: 1 }}>
                    {CardRenderer ? (
                      <CardRenderer
                        row={row}
                        {...cardRendererProps}
                      />
                    ) : (
                      <ColumnarList
                        cells={dataCells}
                        row={row}
                        tableId={tableId}
                      />
                    )}
                  </Box>

                  {!!menuCell &&
                    flexRender(
                      menuCell.column.columnDef.cell,
                      menuCell.getContext(),
                    )}
                  {/* Menu button's label will include row header values, to meet WCAG SC 2.4.6. */}
                </Stack>
              </Card>
            </li>
          )
        })}
      </List>
    </>
  )
}

CardView.propTypes = {
  CardRenderer: componentShape,
  cardRendererProps: PropTypes.object,
  style: PropTypes.object,
  table: tableShape.isRequired,
  tableId: PropTypes.string.isRequired,
}

export default CardView
