import Button from '@mui/material/Button'
import BusySpinner from 'common/indicators/BusySpinner'
import { componentShape } from 'core/shapes'
import { isDefined, when } from 'fp/utils'
import { BUSY } from 'hooks/useReduxCallback'
import PropTypes from 'prop-types'
import { forwardRef, useCallback, useContext, useEffect, useState } from 'react'
import { Send } from 'react-feather'
import { useFormContext, useFormState } from 'react-hook-form'
import { additionalContext } from '../Form/additionalContext'

const SubmitButton = forwardRef(
  (
    { busyIndicator = false, children = 'Submit', disabled = false, ...rest },
    ref,
  ) => {
    const { actionType, setForcedDirty, status } = useContext(additionalContext)
    const localDisabled = isDefined(actionType) ? status === BUSY : disabled
    const { clearErrors, trigger } = useFormContext()
    const [aboutToValidate, setAboutToValidate] = useState(false)

    const { isSubmitSuccessful } = useFormState()

    useEffect(() => {
      if (aboutToValidate) {
        setAboutToValidate(false)
        trigger()
      }
    }, [aboutToValidate, trigger])

    const handleClick = useCallback(() => {
      clearErrors()
      /**
       * Give the dom a chance to update.
       * The idea here is to allow components to sense when the validation has
       * happened, even if nothing has changed.  For example if a field already
       * had an error and the user clicks submit again, we want any listening
       * components to be able to detect that, even though the error state might
       * not have changed.
       */
      setTimeout(() => setAboutToValidate(true))
    }, [clearErrors])

    useEffect(() => {
      when(isSubmitSuccessful, setForcedDirty, false)
    }, [isSubmitSuccessful, setForcedDirty])

    return (
      <Button
        disabled={localDisabled}
        startIcon={<Send />}
        {...rest}
        onClick={handleClick}
        ref={ref}
        type="submit"
        variant="primary">
        {children}

        {Boolean(busyIndicator && disabled) && (
          <>
            &nbsp; <BusySpinner color="inverted" />
          </>
        )}
      </Button>
    )
  },
)

SubmitButton.propTypes = {
  busyIndicator: PropTypes.bool,
  children: componentShape,
  disabled: PropTypes.bool,
}

export default SubmitButton
