import { floatTypesShape } from 'core/shapes'
import { when, whenPresent } from 'fp/utils'
import withProps from 'hoc/withProps'
import useEffectOnce from 'hooks/useEffectOnce'
import PropTypes from 'prop-types'
import { useContext, useEffect } from 'react'
import AudioProvider, { audioContext } from './AudioContextProvider'
import ButtonPlayerRenderer from './ButtonPlayer'
import MinimalPlayerRenderer from './MinimalPlayer'
import RegularPlayerRenderer from './RegularPlayer'

export const AudioPlayer = props => {
  const {
    Renderer = RegularPlayerRenderer,
    autoplay = false,
    contentWrappingAllowed = false,
    float = 'none',
    onPlay,
    url = null,
    ...rest
  } = props

  const { playStatus, setPlayStatus, updateUrl } = useContext(audioContext)

  useEffect(() => {
    when(playStatus === 'PLAYING', whenPresent, onPlay)
  }, [onPlay, playStatus])

  useEffect(() => {
    updateUrl(url)
  }, [updateUrl, url])

  useEffectOnce(() => {
    if (autoplay) {
      setTimeout(() => {
        setPlayStatus('PLAYING')
      })
    }
  })

  if (!url) return null

  const playerProps = {
    float,
    contentWrappingAllowed,
  }
  return (
    <Renderer
      {...playerProps}
      {...rest}
    />
  )
}

AudioPlayer.propTypes = {
  Renderer: PropTypes.oneOf([
    RegularPlayerRenderer,
    MinimalPlayerRenderer,
    ButtonPlayerRenderer,
  ]),
  autoplay: PropTypes.bool,
  contentWrappingAllowed: PropTypes.bool,
  float: floatTypesShape,
  minimal: PropTypes.bool,
  narrow: PropTypes.bool,
  onPlay: PropTypes.func,
  url: PropTypes.string,
}

const withAudioProvider = WrappedComponent => props => (
  <AudioProvider>
    <WrappedComponent {...props} />
  </AudioProvider>
)

const WiredAudioPlayer = withAudioProvider(AudioPlayer)

// why not just wrap them with AudioPlayer in the Renderer? Causes circular dependency

const RegularPlayer = withProps(WiredAudioPlayer)
const MinimalPlayer = withProps(WiredAudioPlayer, {
  Renderer: MinimalPlayerRenderer,
})
const ButtonPlayer = withProps(WiredAudioPlayer, {
  Renderer: ButtonPlayerRenderer,
})

export { MinimalPlayer, RegularPlayer, ButtonPlayer }

export default WiredAudioPlayer // so this is same as regular player
