import ContainerQuery from 'common/layout/ContainerQuery'
import SplitPane from 'common/layout/SplitPane'
import {
  ABILITY_STUDENT_INTERFACE,
  TOGGLE_STATE_PRESENTER_MODE,
} from 'core/consts'
import { map } from 'fp/arrays'
import { get, set } from 'fp/objects'
import { prefix } from 'fp/strings'
import { isDefined } from 'fp/utils'
import useAbilityCheck from 'hooks/useAbilityCheck'
import routeConfig, {
  subsectionRouteConfig,
} from 'hss/ContentViewer/routeConfig'
import AllAnnotations from 'hss/sections/Assignments/Annotations/AllAnnotations'
import Feedback from 'hss/sections/Assignments/Feedback'
import Notebook from 'hss/sections/Assignments/Notebook'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { matchRoutes, renderMatches } from 'react-router-dom'
import { compose } from 'redux'
import { RightSideProvider } from 'routing/TraverseRoute/Pinning'
import {
  contentViewerUrl,
  studentAssignmentsUrl,
  studentBinderUrl,
} from 'routing/consts'
import { getLocalSetting } from 'selectors/localSettings'
import {
  getDecodedQueryParams,
  getPinnedLocation,
  getQueryParams,
} from 'selectors/routing'
import useIsLargeEnoughForPinning from './useIsLargeEnoughForPinning'

/** ****************************************************************************
 *                                                                             *
 *                               !!!!!!!!!!!!!!!!!!                            *
 *                               !!!   NOTICE   !!!                            *
 *                               !!!!!!!!!!!!!!!!!!                            *
 *                                                                             *
 * DO NOT USE DIRECTLY                                                         *
 *                                                                             *
 * Use <TraverseRoute /> instead                                               *
 *                                                                             *
 ***************************************************************************** */

const panes = {
  annotations: AllAnnotations,
  feedback: Feedback,
  notebook: Notebook,
}

const studentPanes = ['annotations', 'notebook']
const teacherPanes = ['annotations', 'feedback']

const PinnableRoute = ({ element: LeftSideComponent }) => {
  const pinnedLocation = useSelector(getPinnedLocation)
  const userIsStudent = useAbilityCheck(ABILITY_STUDENT_INTERFACE)
  const isLargeEnoughForPinning = useIsLargeEnoughForPinning()
  const rightSideRouterLocation = useSelector(getDecodedQueryParams('ssc'))
  const presenterModeEnabled = useSelector(
    getLocalSetting(TOGGLE_STATE_PRESENTER_MODE),
  )
  const { pane } = useSelector(getQueryParams)

  const paneIsAllowed =
    isDefined(pane) && (userIsStudent || presenterModeEnabled)
      ? studentPanes.includes(pane)
      : teacherPanes.includes(pane)

  const contentViewerRouteConfig = compose(
    map(entry =>
      set('path', prefix(`${contentViewerUrl}/`)(get('path')(entry)))(entry),
    ),
    Object.values,
  )(routeConfig)

  const studentAssignmentRouteConfig = compose(
    map(entry =>
      set(
        'path',
        prefix(`${studentAssignmentsUrl}/:userAssignmentId/`)(
          get('path')(entry),
        ),
      )(entry),
    ),
    Object.values,
  )(subsectionRouteConfig)

  const studentBinderRouteConfig = compose(
    map(entry =>
      set(
        'path',
        prefix(`${studentBinderUrl}/:userAssignmentId/`)(get('path')(entry)),
      )(entry),
    ),
    Object.values,
  )(subsectionRouteConfig)

  const allPinnableRoutes = [
    ...contentViewerRouteConfig,
    ...studentAssignmentRouteConfig,
    ...studentBinderRouteConfig,
  ]

  /** **************************************************************************
   *                                                                           *
   * NOTE:                                                                     *
   *                                                                           *
   * We need access to the route config in order to figure out what component  *
   * goes on the right side of the screen.                                     *
   * Therefore it's not enough to just mark a route as 'pinnable', you also    *
   * need to make sure it gets included in this call to matchRoutes().         *
   *                                                                           *
   * Presently only the content viewer has pinnable routes, so only those are  *
   * loaded here.                                                              *
   *                                                                           *
   * Why then do we need to mark the route as pinnable?  It's because other    *
   * routes need to know if you are coming from or going to a pinnable route.  *
   *                                                                           *
   ************************************************************************ ** */

  const matches = rightSideRouterLocation
    ? matchRoutes(allPinnableRoutes, rightSideRouterLocation)
    : []

  const Pane = panes[pane]
  return (
    <SplitPane>
      <ContainerQuery>{LeftSideComponent}</ContainerQuery>

      {/* Never show split screen options on smaller screens */}
      {Boolean(
        (pinnedLocation || paneIsAllowed) && isLargeEnoughForPinning,
      ) && (
        <RightSideProvider>
          <ContainerQuery>
            {paneIsAllowed ? (
              <Pane />
            ) : (
              Boolean(matches.length) && renderMatches(matches)
            )}
          </ContainerQuery>
        </RightSideProvider>
      )}
    </SplitPane>
  )
}

PinnableRoute.propTypes = {
  element: PropTypes.element.isRequired,
}

export default PinnableRoute
