import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from 'common/dialogs/Dialog'
import PropTypes from 'prop-types'

const SimpleDialog = ({ message, open, title = 'Traverse', onClose }) => (
  <Dialog open={open}>
    <DialogTitle>{title}</DialogTitle>

    <DialogContent>{message}</DialogContent>

    <DialogActions>
      <Button
        color="secondary"
        onClick={onClose}
        variant="primary">
        OK
      </Button>
    </DialogActions>
  </Dialog>
)

SimpleDialog.propTypes = {
  message: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  onClose: PropTypes.func.isRequired,
}

export default SimpleDialog
