import Tooltip from '@mui/material/Tooltip'
import { CONTENT_STATE_DRAFT } from 'core/consts'
import { contentShape } from 'core/shapes'
import withProps from 'hoc/withProps'
import PropTypes from 'prop-types'
import { EyeOff } from 'react-feather'

const Draft = withProps('span', { children: '[DRAFT]', className: 'draft' })

const MaybeDraft = ({
  content: { contentState } = {},
  small = false,
  ...rest
}) =>
  contentState === CONTENT_STATE_DRAFT ? (
    <Tooltip title="Unpublished draft">
      <span>
        {small ? (
          <Draft {...rest}>
            <EyeOff />
          </Draft>
        ) : (
          <Draft {...rest} />
        )}
      </span>
    </Tooltip>
  ) : null

MaybeDraft.propTypes = {
  content: contentShape,
  small: PropTypes.bool,
}

export default MaybeDraft
