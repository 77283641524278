import { styled } from '@mui/material/styles'
import { filterKeyedObject, get, pick } from 'fp/objects'
import { isEmptyString } from 'fp/strings'
import { curryRight, not } from 'fp/utils'
import { border } from 'polished'
import PropTypes from 'prop-types'
import { useEffect, useMemo } from 'react'
import { compose } from 'redux'
import FieldPicker from './FieldPicker'

const notEmptyString = compose(not, isEmptyString)

const cantoFields = [
  'altText',
  'ariaText',
  'ariaTextLink',
  'assetDescription',
  'caption',
  'dcmIngestionId',
  'description',
  'headline',
  'imageDescription',
]

const cantoLabels = {
  altText: 'Alt',
  ariaText: 'Aria',
  ariaTextLink: 'Aria link',
  assetDescription: 'Asset desc.',
  caption: 'Caption',
  dcmIngestionId: 'DCM ID',
  description: 'Desc.',
  headline: 'Headline',
  imageDescription: 'Image desc.',
}

const Metadata = styled(
  props => {
    const {
      className,
      metadataAssignments,
      rawUpload,
      setMetadataAssignments,
    } = props

    const availableFields = useMemo(
      () =>
        compose(
          curryRight(filterKeyedObject, notEmptyString),
          pick(cantoFields),
          get('metadata.fields'),
        )(rawUpload),
      [rawUpload],
    )

    useEffect(() => {
      // clear out any missing defaults, so they are available for reassignment
      const availableKeys = Object.keys(availableFields)
      const filter = curryRight(filterKeyedObject, key =>
        availableKeys.includes(key),
      )
      setMetadataAssignments(filter)
    }, [availableFields, setMetadataAssignments])

    return (
      <div className={className}>
        <table cellSpacing={0}>
          <thead>
            <tr>
              <th>Canto</th>
              <th>Use as</th>
              <th>Text</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(availableFields).map(([fieldName, fieldValue]) => (
              <tr key={fieldName}>
                <th>{cantoLabels[fieldName]}</th>
                <td>
                  <FieldPicker
                    {...{
                      fieldName,
                      metadataAssignments,
                      setMetadataAssignments,
                    }}
                  />
                </td>
                <td>{fieldValue}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  },
  { name: 'CantoImporter-Metadata' },
)(
  ({
    theme: {
      mixins: { rem },
      palette,
    },
  }) => ({
    overflowY: 'auto',
    paddingRight: 0,
    position: 'relative',
    height: 'auto',
    maxHeight: '42vh',
    marginTop: rem(2),
    'thead th': {
      background: palette.grey[4],
    },
    'tbody th, td': border('top', 1, 'solid', palette.grey[3]),
    'th, td': {
      lineHeight: 1.35,
      textAlign: 'left',
      verticalAlign: 'top',
    },
    td: { wordBreak: 'break-all' },
    '.MuiFormControlLabel-label': {
      whiteSpace: 'nowrap',
    },
  }),
)

Metadata.propTypes = {
  metadataAssignments: PropTypes.object.isRequired,
  rawUpload: PropTypes.object.isRequired,
  setMetadataAssignments: PropTypes.func.isRequired,
}

export default Metadata
