import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker'
import withProps from 'hoc/withProps'
import { dateShortFormatString } from 'locale/i18n'
import Base from './_Base'

const DatePicker = withProps(Base, {
  Component: MuiDatePicker,
  format: dateShortFormatString,
})

export default DatePicker
