import { styled } from '@mui/material/styles'
import { useContext } from 'react'
import { additionalContext } from './additionalContext'

const RequiredLabel = styled(
  ({ className }) => {
    const { hasRequiredFields } = useContext(additionalContext)

    return hasRequiredFields ? (
      <div className={className}>denotes required field</div>
    ) : null
  },
  { name: 'Form-RequiredLabel' },
)(
  ({
    theme: {
      mixins: { rem },
      palette,
    },
  }) => ({
    textAlign: 'right',
    '&::before': {
      color: palette.error.dark,
      content: '"* "',
    },
    '&.above': { margin: rem(0, 0, 1) },
    '&.below': { margin: rem(2, 0, 0) },
  }),
)

export default RequiredLabel
