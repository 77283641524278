import { PROFICIENCIES } from 'core/consts'
import { map } from 'fp/arrays'
import { get } from 'fp/objects'
import { noop, takeSecond } from 'fp/utils'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { compose } from 'redux'
import MultiPicker from './MultiPicker'

const assignableProficiencies = PROFICIENCIES.filter(get('assignableToContent'))

const ProficienciesPicker = ({
  onChange,
  value: selectedProficiencyIds,
  ...rest
}) => {
  const [selectedProficiencies, setSelectedProficiencies] = useState([])

  useEffect(() => {
    const newValue = assignableProficiencies.filter(item =>
      selectedProficiencyIds.includes(item.id),
    )
    setSelectedProficiencies(newValue)
  }, [selectedProficiencyIds])

  const handleChange = compose(onChange || noop, map(get('id')), takeSecond)

  return (
    <MultiPicker
      disableCloseOnSelect
      getOptionLabel={get('name')}
      groupBy={get('grouping')}
      label="Proficiency Levels"
      options={assignableProficiencies}
      {...rest}
      multiple
      onChange={handleChange}
      value={selectedProficiencies}
    />
  )
}

ProficienciesPicker.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.array,
}

export default ProficienciesPicker
