import { PROFICIENCIES } from 'core/consts'
import { numberOrString } from 'core/shapes'
import { find } from 'fp/arrays'
import { get } from 'fp/objects'
import { matches, noop, takeSecond } from 'fp/utils'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { compose } from 'redux'
import Autocomplete from './Autocomplete'

export const tableRowMixin = props => {
  const { getValue, row } = props
  const {
    original: { firstName, lastName },
  } = row
  const ariaLabel = `Proficiency, ${lastName}, ${firstName}`

  return {
    inputProps: {
      'aria-label': ariaLabel,
    },
    label: '',
    value: getValue(),
  }
}

const defaultProficiency = PROFICIENCIES[0]
const getProficiency = id =>
  find(matches('id', id))(PROFICIENCIES) || defaultProficiency

const ProficiencySelect = ({
  onChange,
  value: selectedProficiencyId,
  ...rest
}) => {
  const [selectedProficiency, setSelectedProficiency] = useState(
    getProficiency(selectedProficiencyId),
  )

  useEffect(() => {
    const newValue = getProficiency(selectedProficiencyId)
    setSelectedProficiency(newValue)
  }, [selectedProficiencyId])

  const handleChange = compose(
    onChange || noop,
    value => ({ target: { value } }),
    get('id'),
    takeSecond,
  )

  return (
    <Autocomplete
      getOptionLabel={get('name')}
      groupBy={get('grouping')}
      label="Proficiency"
      options={PROFICIENCIES}
      {...rest}
      disableClearable
      fullWidth
      onChange={handleChange}
      value={selectedProficiency}
    />
  )
}

ProficiencySelect.propTypes = {
  onChange: PropTypes.func,
  value: numberOrString,
}

export default ProficiencySelect
