import PropTypes from 'prop-types'
import { forwardRef } from 'react'

const withEnterClicks = WrappedComponent => {
  const Enhanced = forwardRef(
    ({ onClick, disabled = false, spacebar = true, ...rest }, ref) => {
      const handleKeyPress = event => {
        const { key } = event
        /* istanbul ignore next */
        if (key === 'Enter' || (spacebar && key === ' ')) {
          event.preventDefault()
          onClick()
        }
      }

      return (
        <WrappedComponent
          disabled={disabled}
          onClick={onClick}
          onKeyPress={disabled ? null : handleKeyPress}
          role="button"
          style={{ pointerEvents: 'auto' }}
          tabIndex={0}
          {...rest}
          ref={ref}
        />
      )
    },
  )
  Enhanced.propTypes = {
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    spacebar: PropTypes.bool,
  }

  return Enhanced
}

export default withEnterClicks
