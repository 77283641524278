import { composeWithDevToolsDevelopmentOnly } from '@redux-devtools/extension'
import legacySessionExtenderMiddleware from 'core/middleware/legacySessionExtender'
import sessionHelperMiddleware from 'core/middleware/sessionHelper'
import { createBrowserHistory, createMemoryHistory } from 'history'
import { applyMiddleware, combineReducers, createStore } from 'redux'
import { createReduxHistoryContext } from 'redux-first-history'
import createSagaMiddleware from 'redux-saga'
import { sortObject } from '../fp/objects'
import contentPreprocessorMiddleware from '../middleware/contentPreprocessors'
import gnsHelperMiddleware from '../middleware/gnsHelper'
import httpMiddleware from '../middleware/http'
import localSettingsMiddleware from '../middleware/localSettings'
import promiseListener from './promiseListener'
import * as reducers from './reducers/index'
import sagas from './sagas/index'
import { isTestEnv } from './selectors/index'

const sagaMiddleware = createSagaMiddleware()

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({
    history: isTestEnv()
      ? createMemoryHistory({ initialEntries: ['/'] })
      : createBrowserHistory(),
  })

const combinedReducers = combineReducers(
  sortObject({
    router: routerReducer,
    ...reducers,
  }),
)

const buildMiddlewares = () =>
  [
    httpMiddleware,
    contentPreprocessorMiddleware(),
    gnsHelperMiddleware,
    localSettingsMiddleware,
    legacySessionExtenderMiddleware,
    sessionHelperMiddleware,
    promiseListener.middleware,
    sagaMiddleware,
    routerMiddleware,
  ].filter(Boolean)

const storeFactory = (initialState = {}) => {
  const middlewares = buildMiddlewares()

  return createStore(
    combinedReducers,
    initialState,
    composeWithDevToolsDevelopmentOnly(applyMiddleware(...middlewares)),
  )
}

const store = storeFactory()

if (!isTestEnv()) sagaMiddleware.run(sagas)

export const history = createReduxHistory(store)

export default store
