import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'

const ButtonWrapper = styled(Stack, { name: 'Audio-ButtonWrapper' })(
  ({
    theme: {
      palette,
      mixins: { rem },
    },
  }) => ({
    background: palette.background.paper,
    flexDirection: 'row',
    height: 48,
    width: 'min-content',

    svg: {
      margin: rem(1.1),
    },

    '&.show-accent': {
      borderRightColor: palette.accent.dark,
      borderRightWidth: 6,
      borderRightStyle: 'solid',
    },

    '&.with-margin': {
      marginTop: rem(1),
    },
  }),
)
export default ButtonWrapper
