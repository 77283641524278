import AppBusy from 'common/indicators/AppBusy'
import BusySpinner from 'common/indicators/BusySpinner'
import { get, omit } from 'fp/objects'
import { when } from 'fp/utils'
import useReduxPromise from 'hooks/useReduxPromise'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import actionTypes from 'reducers/actionTypes'
import Route404 from 'routing/Route404'
import { getAssignmentById, isAssignmentLoaded } from 'selectors/assignments'
import { getDistrictById, isDistrictLoaded } from 'selectors/districts'
import { getGroupById, isGroupLoaded } from 'selectors/groups'
import { getSchoolById, isSchoolLoaded } from 'selectors/schools'
import {
  getUserAssignmentById,
  isUserAssignmentLoaded,
} from 'selectors/userAssignments'
import { getUserById, isUserLoaded } from 'selectors/users'

const actions = {
  [actionTypes.ASSIGNMENT_FETCH]: {
    isLoadedSelector: isAssignmentLoaded,
    itemSelector: getAssignmentById,
    idParam: 'assignmentId',
  },
  [actionTypes.DISTRICT_FETCH]: {
    isLoadedSelector: isDistrictLoaded,
    itemSelector: getDistrictById,
    idParam: 'districtId',
  },
  [actionTypes.GROUP_FETCH]: {
    isLoadedSelector: isGroupLoaded,
    itemSelector: getGroupById,
    idParam: 'groupId',
  },
  [actionTypes.SCHOOL_FETCH]: {
    isLoadedSelector: isSchoolLoaded,
    itemSelector: getSchoolById,
    idParam: 'schoolId',
  },
  [actionTypes.USER_ASSIGNMENT_FETCH]: {
    isLoadedSelector: isUserAssignmentLoaded,
    itemSelector: getUserAssignmentById,
    idParam: 'userAssignmentId',
  },
  [actionTypes.USER_FETCH]: {
    isLoadedSelector: isUserLoaded,
    itemSelector: getUserById,
    idParam: 'studentId',
    reduxActionIdParam: 'userId',
  },
}

const withFetchedItem = (WrappedComponent, options) => {
  const Enhanced = props => {
    /* istanbul ignore next */
    const { actionType } = options || {}
    const { busy = 'local' } = props
    const [error, setError] = useState(false)
    const call = useReduxPromise(actionType)

    const params = useParams()
    const getParam = propName =>
      get(propName)({ ...props, busy }) || get(propName)(params)

    const { idParam, isLoadedSelector, itemSelector, reduxActionIdParam } =
      actions[actionType]
    const { fetchItem, idParams } = {
      fetchItem: Boolean(getParam(idParam)),
      idParams: { [reduxActionIdParam || idParam]: getParam(idParam) },
    }

    const isLoaded = useSelector(isLoadedSelector(idParams))
    const item = useSelector(itemSelector(idParams))

    useEffect(() => {
      const onError = () => setError(true)

      when(!isLoaded && fetchItem, () => {
        call({ ...idParams, ...omit('actionType')(options) }).catch(onError)
      })
    }, [call, fetchItem, idParams, isLoaded, options])

    if (!fetchItem || item) {
      return (
        <WrappedComponent
          {...omit(idParam)({ ...props, busy })}
          item={item}
        />
      )
    }

    if (error) {
      return <Route404 collectionName="Classroom" />
    }

    if (busy === 'app') return <AppBusy />

    if (busy === 'local') {
      return (
        <BusySpinner
          mt={10}
          segments={11}
          size={64}
        />
      )
    }

    return null
  }

  Enhanced.propTypes = {
    busy: PropTypes.oneOf(['app', 'local', 'silent']),
  }

  return Enhanced
}

export default withFetchedItem
