import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { join, map } from 'fp/arrays'
import { get } from 'fp/objects'
import PropTypes from 'prop-types'
import { ChevronDown, ChevronUp } from 'react-feather'
import { compose } from 'redux'

const ExpanderCell = ({ row }) => {
  const isExpanded = row.getIsExpanded()
  const label = isExpanded ? 'Hide Details' : 'Show Details'

  return (
    <Tooltip
      placement="right"
      title={label}>
      <IconButton
        aria-controls={compose(
          join(' '),
          map(get('id')),
          get('detailRows'),
        )(row)}
        aria-expanded={isExpanded}
        aria-label={label}
        data-bodyvariant="body1"
        onClick={() => row.toggleExpanded(!isExpanded)}
        variant="tertiary">
        {isExpanded ? <ChevronUp /> : <ChevronDown />}
      </IconButton>
    </Tooltip>
  )
}

ExpanderCell.propTypes = {
  row: PropTypes.shape({
    getIsExpanded: PropTypes.func.isRequired,
    toggleExpanded: PropTypes.func.isRequired,
  }).isRequired,
}

export default ExpanderCell
