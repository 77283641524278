import type { Content } from 'common/@types/custom'
import type {
  AssetUploadVariant,
  AssetUploadedProps,
  ImageInteractiveData,
} from 'common/formControls/textInputs/RichTextEdit2/nodes/ImageNode/@types'
import type {
  InlineImageSize,
  InlineImageVerticalAlign,
} from 'common/indicators/InlineImage'
import { assert, alter, inspect } from 'core/store/search/squery'
import { isNotEmptyString } from 'fp/strings'
import { isDefined } from 'fp/utils'
import useReduxPromise from 'hooks/useReduxPromise'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import actionTypes from 'reducers/actionTypes'
import { compose } from 'redux'
import { CONTENT_TYPE_INTERACTIVE, INTERACTIVE_TYPE_IMAGE } from 'shared/consts'

export type ImageDisplayMode = 'block' | 'inline' | 'bare-upload'

export type UploadStep = 'upload' | 'annotate' | 'complete'

export interface ImageDisplayModeCallback {
  interactiveId?: string
  displayMode: ImageDisplayMode
  size: InlineImageSize
  valign: InlineImageVerticalAlign
  upload?: AssetUploadedProps
}

interface ImageSelectionContextValue {
  data: ImageInteractiveData
  displayMode: ImageDisplayMode
  onComplete: (result: ImageDisplayModeCallback) => void
  saveAsset: (packet: {
    payload: {
      data: ImageInteractiveData
      upload: AssetUploadedProps
    }
  }) => void
  searchWasMade: boolean
  selectedSearchResult?: Content
  setData: (data: ImageInteractiveData) => void
  setSelectedSearchResult: (result?: Content) => void
  setSize: (size: InlineImageSize) => void
  setSquery: (squery: ReturnType<typeof assert>) => void
  setUpload: (upload?: AssetUploadedProps) => void
  setUploadStep: (step: UploadStep) => void
  setValign: (valign: InlineImageVerticalAlign) => void
  size: InlineImageSize
  squery: ReturnType<typeof assert>
  upload?: AssetUploadedProps
  uploadStep: UploadStep
  uploadWasMade: boolean
  uploadVariant: AssetUploadVariant
  valign: InlineImageVerticalAlign
}

interface Props {
  children: React.ReactNode
  displayMode: ImageDisplayMode
  onComplete: (result: ImageDisplayModeCallback) => void
  uploadVariant: AssetUploadVariant
  doCreateInteractive?: boolean /** set to true if you want to create an
    interactive from any uploaded asset. The user will be prompted for
    additional attributes as needed.
    Currently only 'image' is supported.  See ImageMenuButton from the
    RTE for a use-case*/
}

const imageSelectionContext = createContext<ImageSelectionContextValue | null>(
  null,
)

const initialSquery = compose(
  alter.set.limit(10),
  alter.set.orderBy('name', 'asc'),
  // alter.set.modifier('imageSearch').is('dog'),
  alter.set
    .where('contentType')
    .is(CONTENT_TYPE_INTERACTIVE),
  alter.set.where('contentSubType').is(INTERACTIVE_TYPE_IMAGE),
)(assert())

const ImageSelectionProvider: React.FC<Props> = ({
  children,
  displayMode,
  onComplete,
  doCreateInteractive,
  uploadVariant = 'image',
}) => {
  const saveAsset = useReduxPromise(actionTypes.AUTHORING_ASSET_SAVE)

  const [searchWasMade, setSearchWasMade] = useState(false)
  const [uploadWasMade, setUploadWasMade] = useState(false)

  const [upload, setUpload] = useState<AssetUploadedProps>()
  const [uploadStep, setUploadStep] = useState<UploadStep>('upload')

  const [squery, setSquery] = useState(initialSquery)

  const [size, setSize] = useState<InlineImageSize>('sm')
  const [valign, setValign] = useState<InlineImageVerticalAlign>('top')

  const [selectedSearchResult, setSelectedSearchResult] = useState<Content>()

  const [data, setData] = useState<ImageInteractiveData>({
    caption: '',
    captionPosition: 'bottom',
    imageAltText: '',
    includeSwatch: false,
    longTextAlternative: '',
    noGutters: false,
    sensitive: false,
  })

  useEffect(() => {
    setSearchWasMade(
      isNotEmptyString(inspect(squery).get.modifier('imageSearch').is('')),
    )
  }, [squery])

  useEffect(() => {
    setUploadWasMade(isDefined(upload) && !searchWasMade)
  }, [searchWasMade, upload])

  useEffect(() => {
    if (!uploadWasMade) {
      setUploadStep('upload')
    }
  }, [uploadWasMade])

  useEffect(() => {
    const createInteractiveFromUpload = async () => {
      await saveAsset({
        payload: {
          data,
          upload,
        },
      }).then(({ payload }) => {
        onComplete({
          interactiveId: payload.id,
          displayMode,
          size,
          valign,
          upload,
        })
      })
    }

    if (uploadStep === 'complete') {
      if (doCreateInteractive) {
        createInteractiveFromUpload()
      } else {
        onComplete({
          displayMode,
          size,
          valign,
          upload,
        })
      }
    }
  }, [
    data,
    doCreateInteractive,
    onComplete,
    displayMode,
    saveAsset,
    size,
    upload,
    uploadStep,
    valign,
  ])

  const value: ImageSelectionContextValue = useMemo(() => {
    return {
      data,
      displayMode,
      onComplete,
      saveAsset,
      searchWasMade,
      selectedSearchResult,
      setData,
      setSelectedSearchResult,
      setSize,
      setSquery,
      setUpload,
      setUploadStep,
      setValign,
      size,
      squery,
      upload,
      uploadStep,
      uploadWasMade,
      uploadVariant,
      valign,
    }
  }, [
    data,
    displayMode,
    onComplete,
    saveAsset,
    searchWasMade,
    selectedSearchResult,
    size,
    squery,
    upload,
    uploadStep,
    uploadWasMade,
    uploadVariant,
    valign,
  ])

  return (
    <imageSelectionContext.Provider value={value}>
      {children}
    </imageSelectionContext.Provider>
  )
}

const useImageSelectionContext = () => {
  const context = useContext(imageSelectionContext)
  if (!context) {
    throw new Error(
      'useImageSelectionContext must be used within an AuthorProvider',
    )
  }
  return context
}

export { useImageSelectionContext, ImageSelectionProvider }
