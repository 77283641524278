import Typography from '@mui/material/Typography'
import { componentShape } from 'core/shapes'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import Headline from './Headline'
import { headlineStyleOffsetContext } from './HeadlineStyleOffset'
import ScreenReaderText from './ScreenReaderText'

const EyebrowHeadline = ({
  component,
  eyebrow,
  title,
  children,
  headlineVariant,
  HeadlineProps,
}) => {
  const { offset: headlineStyleOffset = 1 } =
    useContext(headlineStyleOffsetContext) || {}

  return (
    <Headline
      Component={component}
      title={
        <ScreenReaderText>
          {eyebrow}, {title}
        </ScreenReaderText>
      }>
      <div aria-hidden="true">
        {Boolean(eyebrow) && (
          <Typography
            component="div"
            variant="eyebrow">
            {eyebrow}
          </Typography>
        )}

        <Typography
          component="div"
          variant={headlineVariant || `h${headlineStyleOffset}`}
          {...HeadlineProps}>
          {title}
        </Typography>
      </div>

      {children}
    </Headline>
  )
}

EyebrowHeadline.propTypes = {
  HeadlineProps: PropTypes.object,
  component: componentShape,
  eyebrow: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: componentShape,
  headlineVariant: PropTypes.string,
}

export default EyebrowHeadline
