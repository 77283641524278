import { getAuthValue } from 'sagas/api'

const authChannel = new BroadcastChannel('auth')

export const resolveAuthChannel = () => authChannel

const sessionHelperMiddleware = store => {
  authChannel.onmessage = ({ data: { type, authSelector } }) => {
    if (authSelector && authSelector === getAuthValue('authSelector')) {
      store.dispatch({ type, broadcast: false })
    }
  }

  return next => action => next(action)
}

export default sessionHelperMiddleware
