import { Box, Button, FormHelperText, Stack } from '@mui/material'
import ImageSelectionDialog from 'common/formControls/textInputs/RichTextEdit/plugins/imagePlugin/ImageSelectionDialog'
import type { ImageDisplayModeCallback } from 'common/formControls/textInputs/RichTextEdit/plugins/imagePlugin/ImageSelectionProvider'
import type {
  AssetUpload,
  AssetUploadVariant,
  AssetUploadedProps,
} from 'common/formControls/textInputs/RichTextEdit2/nodes/ImageNode/@types'
import Headline from 'common/text/Headline'
import { omit } from 'fp/objects'
import { isNotEmptyString } from 'fp/strings'
import { useState } from 'react'
import { AssetSelectionWrapper } from '.'
import AssetPreviewer from './AssetPreviewer'
import Provenance from './Provenance'
import RemoveAsset from './RemoveAsset'

export interface AssetSelectorProps
  extends React.ComponentPropsWithoutRef<typeof Button> {
  headlineHelperText?: string
  helperText?: string
  label?: string
  labelProps?: object // TODO: use HeadlineProps once available
  name?: string
  onClear?: () => void
  onComplete: (upload: AssetUploadedProps) => void
  required?: boolean
  uploadType: 'image' // only supporting images at this time
  uploadVariant: AssetUploadVariant
  assetValue?: AssetUpload
}

const AssetSelector: React.FC<AssetSelectorProps> = props => {
  const {
    children,
    headlineHelperText,
    helperText,
    label,
    labelProps,
    name,
    onClear,
    onComplete,
    required,
    uploadType,
    uploadVariant,
    assetValue,
    ...rest
  } = props
  const [imageDialogOpen, setImageDialogOpen] = useState(false)

  const handleInsert = (result: ImageDisplayModeCallback) => {
    setImageDialogOpen(false)

    if (result.upload) onComplete(result.upload)
  }

  const removeButton =
    assetValue && onClear ? <RemoveAsset onClick={onClear} /> : <span />

  return (
    <AssetSelectionWrapper>
      {isNotEmptyString(label) && (
        <Headline
          title={`${label}${required ? ' *' : ''}`}
          mt={4}
          {...labelProps}
        />
      )}

      {Boolean(headlineHelperText) && (
        <FormHelperText
          data-variant="block"
          sx={{ marginLeft: '0 !important' }}>
          {headlineHelperText}
        </FormHelperText>
      )}

      <Stack
        alignItems="flex-start"
        direction="row"
        flexWrap="wrap"
        justifyContent="space-between">
        {typeof assetValue === 'object' && (
          <AssetPreviewer
            uploadType={uploadType}
            value={assetValue}
          />
        )}

        <Stack
          alignItems="flex-start"
          gap={1}>
          <Stack
            alignItems="stretch"
            gap={1}>
            <Button
              onClick={() => setImageDialogOpen(true)}
              size="small"
              sx={{ textTransform: 'none', minWidth: 180 }}
              variant="secondary"
              {...omit('assetCode', 'onCantoFields', 'onUpload')(rest)}>
              {props.children || 'Choose image...'}
            </Button>

            {removeButton}
          </Stack>

          {assetValue && <Provenance value={assetValue} />}
        </Stack>
      </Stack>

      {Boolean(helperText) && (
        <Box
          color="error.main"
          mt={2}>
          {helperText}
        </Box>
      )}

      {Boolean(imageDialogOpen) && (
        <ImageSelectionDialog
          doCreateInteractive={false}
          onClose={() => setImageDialogOpen(false)}
          onComplete={handleInsert}
          displayMode="bare-upload"
          uploadVariant={uploadVariant}
          title="Select an image"
        />
      )}
    </AssetSelectionWrapper>
  )
}

export default AssetSelector
