import { get } from 'fp/objects'

export const allowPeerReviews = assignment => {
  const {
    data: { settings } = {},
  } = assignment || {}

  const reviewsRequested = get('reviewsRequested', { fallback: 0 })(settings)
  return reviewsRequested > 0
}
