import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import DualHeadline from 'common/text/DualHeadline'
import { $getNodeByKey, type LexicalNode } from 'lexical'
import type React from 'react'
import { $isStandaloneQuestionNode } from '.'
import DecoratorWrapper from '../../utils/DecoratorWrapper'

interface IDecoratorProps extends IDecoratorBaseProps {
  alignment: 'left' | 'center' | 'right'
  divider: boolean
  multiline: boolean
  node: LexicalNode
  subtitle: string
  title1: string
  title2: string
}

const fields: DecoratorField[] = [
  { name: 'title1', type: 'string', label: 'Title 1', required: true },
  { name: 'title2', type: 'string', label: 'Title 2', required: true },
  { name: 'divider', type: 'boolean', label: 'Divider', required: true },
  { name: 'multiline', type: 'boolean', label: 'Multiline', required: true },
  {
    name: 'alignment',
    type: 'select',
    label: 'Alignment',
    required: true,
    options: [
      { label: 'Left', value: 'left' },
      { label: 'Center', value: 'center' },
      { label: 'Right', value: 'right' },
    ],
  },
  { name: 'subtitle', type: 'string', label: 'Subtitle', required: false },
]

const Decorator: React.FC<IDecoratorProps> = props => {
  const {
    alignment,
    divider,
    multiline,
    node,
    nodeKey,
    subtitle,
    title1,
    title2,
  } = props
  const [editor] = useLexicalComposerContext()

  const isEditable = editor.isEditable()

  const handleRemove = () => {
    if (!isEditable) return

    editor.update(() => {
      const N = $getNodeByKey(nodeKey)
      if (N && $isStandaloneQuestionNode(N)) {
        N.remove()
      }
    })
  }

  const handleEditField = ({ field, value }: OnChangeArgs) => {
    if (!isEditable) return

    editor.update(() => {
      const N = $getNodeByKey(nodeKey)
      if (N && $isStandaloneQuestionNode(N)) {
        ;(N as unknown as IUpdatable).updateField({ field, value })
      }
    })
  }

  return isEditable ? (
    <DecoratorWrapper
      fields={fields}
      node={node}
      onChange={handleEditField}
      onRemove={handleRemove}
      title="Standalone Question"
    />
  ) : (
    <DualHeadline
      dividerBelow={divider}
      multiline={multiline}
      subtitle={subtitle}
      textAlign={alignment}
      title1={title1}
      title2={title2}
      {...{
        mb: 8,
        mt: 12,
      }}
    />
  )
}

export default Decorator
