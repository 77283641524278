import Subscript from '@mui/icons-material/Subscript'
import Superscript from '@mui/icons-material/Superscript'
import Icon from '@mui/material/Icon'
import { getCurrentBlock } from '@studysync/draft-js-modifiers'
import cl from 'classnames'
import Answer from 'hss/images/controls/draft-toolbar/answer.svg'
import Indent from 'hss/images/controls/draft-toolbar/indent.svg'
import Links from 'hss/images/controls/draft-toolbar/link.svg'
import Marker from 'hss/images/controls/draft-toolbar/marker.svg'
import Strikethrough from 'hss/images/controls/draft-toolbar/strikethrough.svg'
import { Bold, Italic, List, Type, Underline } from 'react-feather'
import { isTestEnv } from 'selectors/index'
import RenderToolButtons from '../plugins/RenderToolButtons'
import { tableBlocksAreInSelection } from '../plugins/tablePlugin/utils'
import { toolbarPropTypes } from '../utils/misc'
import DraftToolButton from './DraftToolButton'
import FormatMenuButton from './FormatMenuButton'
import IndentMenuButton from './IndentMenuButton'
import LinkMenuButton from './LinkMenuButton'
import ListMenuButton from './ListMenuButton'
import SelectAllButton from './SelectAllButton'

const DraftToolbar = props => {
  const {
    changeIndent,
    disabled = false,
    editorState,
    excludeToolButtons = [],
    features,
    setEditorState,
    toggleBlockType,
    toggleIndentFirstLine,
    toggleInlineStyle,
    toggleLinkDialog,
    variant,
  } = props

  const currentBlockType = getCurrentBlock(editorState).get('type')

  const tableCellsAreSelected = tableBlocksAreInSelection(editorState)

  const listMenuEnabled =
    [
      'ordered-list-item',
      'paragraph',
      'unordered-list-item',
      'unstyled',
    ].includes(currentBlockType) && !tableCellsAreSelected

  const indentMenuEnabled =
    ['paragraph', 'unstyled'].includes(currentBlockType) &&
    !tableCellsAreSelected

  const formatMenuEnabled = !tableCellsAreSelected

  // Might need this later
  // const currentBlockType = useMemo(() => RichUtils.getCurrentBlockType(editorState), [editorState])

  return (
    <div className="DraftEditor-toolbar-container">
      <div className={cl('DraftEditor-toolbar', variant)}>
        <DraftToolButton
          disabled={disabled}
          editorState={editorState}
          excludeToolButtons={excludeToolButtons}
          inlineStyleName="BOLD"
          label="Bold"
          toggleInlineStyle={toggleInlineStyle}>
          <Icon>
            <Bold />
          </Icon>
        </DraftToolButton>

        <DraftToolButton
          disabled={disabled}
          editorState={editorState}
          excludeToolButtons={excludeToolButtons}
          inlineStyleName="ITALIC"
          label="Italic"
          toggleInlineStyle={toggleInlineStyle}>
          <Icon>
            <Italic />
          </Icon>
        </DraftToolButton>

        <DraftToolButton
          disabled={disabled}
          editorState={editorState}
          excludeToolButtons={excludeToolButtons}
          inlineStyleName="UNDERLINE"
          label="Underline"
          toggleInlineStyle={toggleInlineStyle}>
          <Icon>
            <Underline />
          </Icon>
        </DraftToolButton>

        <DraftToolButton
          disabled={disabled}
          editorState={editorState}
          excludeToolButtons={excludeToolButtons}
          inlineStyleName="STRIKETHROUGH"
          label="Strikethrough"
          toggleInlineStyle={toggleInlineStyle}>
          <Icon>
            <Strikethrough />
          </Icon>
        </DraftToolButton>

        <DraftToolButton
          disabled={disabled}
          editorState={editorState}
          excludeToolButtons={excludeToolButtons}
          inlineStyleName="HIGHLIGHTED"
          label="Highlight"
          toggleInlineStyle={toggleInlineStyle}>
          <Icon>
            <Marker />
          </Icon>
        </DraftToolButton>
      </div>

      <div className={cl('DraftEditor-toolbar', variant)}>
        <DraftToolButton
          disabled={disabled}
          editorState={editorState}
          excludeToolButtons={excludeToolButtons}
          inlineStyleName="SUPERSCRIPT"
          label="Superscript"
          toggleInlineStyle={toggleInlineStyle}>
          <Icon>
            <Superscript />
          </Icon>
        </DraftToolButton>

        <DraftToolButton
          disabled={disabled}
          editorState={editorState}
          excludeToolButtons={excludeToolButtons}
          inlineStyleName="SUBSCRIPT"
          label="Subscript"
          toggleInlineStyle={toggleInlineStyle}>
          <Icon>
            <Subscript />
          </Icon>
        </DraftToolButton>
        {Boolean(features['typography.answer']) && (
          <DraftToolButton
            disabled={disabled}
            editorState={editorState}
            excludeToolButtons={excludeToolButtons}
            inlineStyleName="ANSWER"
            label="Answer"
            toggleInlineStyle={toggleInlineStyle}>
            <Icon>
              <Answer />
            </Icon>
          </DraftToolButton>
        )}
      </div>

      <div className={cl('DraftEditor-toolbar', variant)}>
        {Boolean(features['typography.format']) && (
          <FormatMenuButton
            disabled={disabled || !formatMenuEnabled}
            editorState={editorState}
            excludeToolButtons={excludeToolButtons}
            featureMenuKey="format"
            features={features}
            label="Format"
            setEditorState={setEditorState}
            toggleBlockType={toggleBlockType}>
            <Icon>
              <Type />
            </Icon>
          </FormatMenuButton>
        )}

        <ListMenuButton
          changeIndent={changeIndent}
          disabled={disabled || !listMenuEnabled}
          editorState={editorState}
          excludeToolButtons={excludeToolButtons}
          featureMenuKey="list"
          features={features}
          label="Lists"
          setEditorState={setEditorState}
          toggleBlockType={toggleBlockType}>
          <Icon>
            <List />
          </Icon>
        </ListMenuButton>

        <IndentMenuButton
          changeIndent={changeIndent}
          disabled={disabled || !indentMenuEnabled}
          editorState={editorState}
          excludeToolButtons={excludeToolButtons}
          featureMenuKey="indent"
          features={features}
          label="Indentation"
          setEditorState={setEditorState}
          toggleBlockType={toggleBlockType}
          toggleIndentFirstLine={toggleIndentFirstLine}>
          <Icon>
            <Indent />
          </Icon>
        </IndentMenuButton>

        <LinkMenuButton
          disabled={disabled}
          editorState={editorState}
          excludeToolButtons={excludeToolButtons}
          featureMenuKey="link"
          features={features}
          label="Link"
          setEditorState={setEditorState}
          toggleLinkDialog={toggleLinkDialog}>
          <Icon>
            <Links />
          </Icon>
        </LinkMenuButton>
      </div>

      <div className={cl('DraftEditor-toolbar', variant)}>
        <RenderToolButtons {...{ disabled, ...props }} />

        {/**
         * A wee-bit hacky.
         *
         * draft.js doesn't have a ton of surface area that RTL can latch onto. In
         * order to test the formatting options, text must first be selected, and
         * there's no way to do that from RTL.  Adding this button here (only in
         * a Jest env) allows us to select the current editor contents from our
         * tests.
         *
         */}
        {Boolean(isTestEnv()) && (
          <SelectAllButton
            disabled={disabled}
            editorState={editorState}
            label="Select All"
            setEditorState={setEditorState}
          />
        )}
      </div>
    </div>
  )
}

DraftToolbar.propTypes = toolbarPropTypes

export default DraftToolbar
