import actionTypes from 'reducers/actionTypes'
import { restEndpoint } from 'reducers/utils'
import { call, takeLatest } from 'redux-saga/effects'
import { dangerouslyCallApi } from './api'

function* handleInitiate(action) {
  yield call(dangerouslyCallApi, {
    action,
    passThrough: { cantoId: action.cantoId },
    url: `${restEndpoint.uploads}?fromCanto=${action.cantoId}&type=${action.uploadType}`,
    suppressNotification: true,
  })
}

/* istanbul ignore next line */
function* cantoSaga() {
  yield takeLatest(actionTypes.CANTO_IMPORT_INITIATE, handleInitiate)
}

export default cantoSaga
