import ButtonWrapper from './ButtonWrapper'
import PlayButton from './PlayButton'

const ButtonPlayer = () => (
  <ButtonWrapper className="with-margin">
    <PlayButton
      buttonOnly
      minimal
    />
  </ButtonWrapper>
)
export default ButtonPlayer
