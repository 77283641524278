import { buildUrl } from 'fp/internet'
import { get, set } from 'fp/objects'
import actionTypes from 'reducers/actionTypes'
import { actions as notificationActions } from 'reducers/notifications'
import { restEndpoint } from 'reducers/utils'
import { call, put, select, takeLeading } from 'redux-saga/effects'
import { dangerouslyCallApi } from './api'
import { success } from './utils'

// Register any reducer->endpoint relationships here, for any (ui) tables that
// support the TABLE_CELL_FIELD_CHANGE action.
const endpoints = {
  users: restEndpoint.users,
}

export function* handleTableCellFieldChange(action) {
  const { id, newValue, performSave, reducer, valueField } = action
  const row = yield select(get(`${reducer}.${id}`))
  const body = set(valueField, newValue)(row)

  if (!performSave) return

  yield call(dangerouslyCallApi, {
    action,
    url: buildUrl(`${endpoints[reducer]}/${id}`, false),
    options: {
      method: 'PATCH',
      body,
    },
  })
}

export function* handleTableCellFieldChangeSuccess() {
  yield put(
    notificationActions.addAlert({
      message: 'Save successful',
    }),
  )
}

/* istanbul ignore next line */
function* globalAppSaga() {
  yield takeLeading(
    actionTypes.TABLE_CELL_FIELD_CHANGE,
    handleTableCellFieldChange,
  )
  yield takeLeading(
    success(actionTypes.TABLE_CELL_FIELD_CHANGE),
    handleTableCellFieldChangeSuccess,
  )
}

export default globalAppSaga
