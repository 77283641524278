import { INTERACTIVE_TYPE_GROUP_AND_SORT } from 'core/consts'
import { filter, map } from 'fp/arrays'
import { omit, set } from 'fp/objects'
import { matches } from 'fp/utils'
import { transformUIData } from 'hss/ContentBuilder/interactives/GroupAndSort/utils'
import { produce } from 'immer'
import actionTypes from 'reducers/actionTypes'
import { compose } from 'redux'
import { success } from 'sagas/utils'

/*
  This will convert the GNS UI structure:
  {
    columns: [
      {
        id: 'GEN-1684428329126-Fj7uU',
        rows: [
          {
            id: 'GEN-1684428329126-H8CLC',
            cellText: 'a1',
          },
          {
            id: 'GEN-1684428329126-fIzVv',
            cellText: 'a2',
          },
        ],
        heading: 'a',
      },
      {
        id: 'GEN-1684428329126-GaKJB',
        rows: [
          {
            id: 'GEN-1684428329126-zFc0Y',
            cellText: 'b1',
          },
          {
            id: 'GEN-1684428329126-bfDji',
            cellText: 'b2',
          },
        ],
        heading: 'b',
      },
    ],
  }

  to/from the GNS API structure:
  {
    groups: [
      {
        "id": "GEN-1684428329126-Fj7uU",
        "heading": "a"
        "totalItems": 2,
      },
      {
        "id": "GEN-1684428329126-GaKJB",
        "heading": "b"
        "totalItems": 2,
      }
    ],
    items: [
      {
        "id": "GEN-1684428329126-H8CLC",
        "groupId": "GEN-1684428329126-Fj7uU",
        "cellText": "a1"
      },
      {
        "id": "GEN-1684428329126-fIzVv",
        "groupId": "GEN-1684428329126-Fj7uU",
        "cellText": "a2"
      },
      {
        "id": "GEN-1684428329126-zFc0Y",
        "groupId": "GEN-1684428329126-GaKJB",
        "cellText": "b1"
      },
      {
        "id": "GEN-1684428329126-bfDji",
        "groupId": "GEN-1684428329126-GaKJB",
        "cellText": "b2"
      }
    ]
  }
*/

const gnsHelperMiddleware = () => next => action => {
  if (
    action.type === actionTypes.CONTENT_SAVE &&
    action.payload?.contentSubType === INTERACTIVE_TYPE_GROUP_AND_SORT
  ) {
    return next(
      produce(action, draft => {
        draft.payload = transformUIData(draft.payload)
      }),
    )
  }

  if (
    [
      success(actionTypes.CONTENT_FETCH),
      success(actionTypes.CONTENT_SAVE),
    ].includes(action.type) &&
    action.response?.contentSubType === INTERACTIVE_TYPE_GROUP_AND_SORT
  ) {
    return next(
      produce(action, draft => {
        draft.response.data = compose(
          omit(['groups', 'items']),
          set(
            'columns',
            draft.response.data.groups.map(g => ({
              id: g.id,
              heading: g.heading,
              rows: compose(
                map(item => ({ id: item.id, cellText: item.cellText })),
                filter(matches('groupId', g.id)),
              )(draft.response.data.items),
            })),
          ),
        )(draft.response.data)
      }),
    )
  }

  return next(action)
}

export default gnsHelperMiddleware
