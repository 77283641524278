import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $insertNodeToNearestRoot, mergeRegister } from '@lexical/utils'
import { COMMAND_PRIORITY_EDITOR, createCommand } from 'lexical'
import { useEffect } from 'react'
import {
  $createStandaloneQuestionNode,
  StandaloneQuestionNode,
} from '../nodes/StandaloneQuestionNode'

export const INSERT_STANDALONE_QUESTION_COMMAND = createCommand(
  'INSERT_STANDALONE_QUESTION_COMMAND',
)

const StandaloneQuestionPlugin = () => {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    if (!editor.hasNodes([StandaloneQuestionNode])) {
      throw new Error(
        'StandaloneQuestionPlugin: StandaloneQuestionNode not registered on editor',
      )
    }

    return mergeRegister(
      editor.registerCommand(
        INSERT_STANDALONE_QUESTION_COMMAND,
        () => {
          const newNode = $createStandaloneQuestionNode()
          $insertNodeToNearestRoot(newNode)

          return true
        },
        COMMAND_PRIORITY_EDITOR,
      ),
    )
  }, [editor])

  return null
}

export default StandaloneQuestionPlugin
