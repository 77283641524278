import PropTypes from 'prop-types'
import { components } from 'react-select'

const Input = ({ selectProps, ...rest }) => (
  <components.Input
    {...rest}
    aria-describedby={selectProps?.inputAriaDescribedby}
  />
)

Input.propTypes = {
  selectProps: PropTypes.object,
}

export default Input
