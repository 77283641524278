import { getCurrentBlock, mergeBlockData } from '@studysync/draft-js-modifiers'
import { RichUtils } from 'draft-js'
import { assertRange } from 'fp/numbers'

export const maxAllowedIndents = 10

export const isListItem = block =>
  ['ordered-list-item', 'unordered-list-item'].includes(block.getType())

export const indentedAmount = editorState => {
  const currentBlock = getCurrentBlock(editorState)
  return currentBlock.getData().get('indent') || 0
}

export const isFirstLineIndented = editorState => {
  const currentBlock = getCurrentBlock(editorState)
  return currentBlock.getData().get('indentfirstline')
}

export const changeIndentForCurrentBlock = (
  editorState,
  indentDirection,
  event,
) => {
  /**
   * It __should__ be safe to mutate this synthetic event since they are so ephemeral.
   * The alternative would be to try to clone the event somehow, which I __don't__
   * think RichUtils would appreciate much. 🤷🏽‍♂️
   */

  event.shiftKey = indentDirection === 'decrease'

  const currentAmount = indentedAmount(editorState)
  const indent = assertRange(
    currentAmount + (indentDirection === 'decrease' ? -1 : 1),
    0,
    maxAllowedIndents,
  )

  return isListItem(getCurrentBlock(editorState))
    ? RichUtils.onTab(event, editorState, 4)
    : mergeBlockData(editorState, { indent })
}
