import Button from '@mui/material/Button'
import { uploadTypeShape } from 'core/shapes'
import useReduxPromise from 'hooks/useReduxPromise'
import useToggleState from 'hooks/useToggleState'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import actionTypes from 'reducers/actionTypes'
import RequestValueDialog from '../RequestValueDialog'

const GDriveImporter = props => {
  const {
    assetCode,
    disabled: busy = false,
    isAudioDescription = false,
    onComplete,
    previousFileName,
    setBusy,
    uploadType,
  } = props

  const [fileName, setFileName] = useState(previousFileName || assetCode || '')
  const [requestValueDialogVisible, toggleRequestValueDialogVisible] =
    useToggleState(false)
  const [errorMessage, setErrorMessage] = useState()
  const startUpload = useReduxPromise(actionTypes.GDRIVE_IMPORT_INITIATE)

  useEffect(() => {
    if (requestValueDialogVisible) setErrorMessage(undefined)
  }, [requestValueDialogVisible])

  const handleStart = value => {
    setBusy(true)
    setErrorMessage(undefined)

    startUpload({ fileName: value, isAudioDescription, uploadType })
      .then(({ response }) => {
        toggleRequestValueDialogVisible()
        onComplete(response)
      })
      .catch(({ error }) => {
        setErrorMessage(error.message)
      })
      .finally(() => setBusy(false))
  }

  const handleFileNameChange = value => {
    setFileName(value)
    handleStart(value)
  }

  return (
    <>
      <Button
        onClick={toggleRequestValueDialogVisible}
        size="small"
        sx={{ textTransform: 'none' }}
        variant="secondary">
        Import from Google Drive...
      </Button>

      <RequestValueDialog
        affirmativeButtonLabel="Import"
        busy={busy}
        errorMessage={errorMessage}
        label="Filename"
        onClose={toggleRequestValueDialogVisible}
        onComplete={handleFileNameChange}
        open={requestValueDialogVisible}
        title="Import from Google Drive"
        value={fileName}
      />
    </>
  )
}

GDriveImporter.propTypes = {
  assetCode: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  isAudioDescription: PropTypes.bool,
  onComplete: PropTypes.func.isRequired,
  previousFileName: PropTypes.string,
  setBusy: PropTypes.func,
  uploadType: uploadTypeShape.isRequired,
}

export default GDriveImporter
