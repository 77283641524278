import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import TextField from 'common/formControls/textInputs/TextField'
import Popper from 'common/indicators/Popper'
import SplitHeadline from 'common/text/SplitHeadline'
import {
  ABILITY_ASSIGNMENT_CREATION,
  INTERACTION_SUBTYPE_TEXT,
  INTERACTION_TYPE_ANNOTATION,
} from 'core/consts'
import { isUndefined } from 'fp/utils'
import withCopyToClipboard from 'hoc/withCopyToClipboard'
import withProps from 'hoc/withProps'
import useAbilityCheck from 'hooks/useAbilityCheck'
import useStateWithDynamicDefault from 'hooks/useStateWithDynamicDefault'
import { contentViewerContext } from 'hss/ContentViewer/ContentViewerProvider'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import { Clipboard, X as Close } from 'react-feather'
import KeyboardEventHandler from 'react-keyboard-event-handler'
import { useDispatch, useSelector } from 'react-redux'
import { actions } from 'reducers/interactions'
import { getContextualAssignment } from 'selectors/assignments'
import { getUserAssignment } from 'selectors/userAssignments'
import { rem } from 'styling/theming/base/mixins'
import ColorPicker from './ColorPicker'

const ActionButton = withProps(Button, {
  color: 'primary',
  sx: {
    marginRight: 'auto',
    padding: rem(1, 0, 0),
    width: '100%',
    textDecorationLine: 'Underline',
    color: 'grey.0',
    '&:hover': {
      color: 'grey.0',
      textDecorationLine: 'none',
      background: 'none',
    },
  },
  variant: 'tertiary',
})

const CopyButton = withCopyToClipboard(IconButton)

const AnnotationDialog = props => {
  const {
    anchorNode,
    annotation: originalAnnotation,
    colorId,
    contentId,
    contextContentId,
    id,
    onClose,
    open,
    saveColorId,
    selectionData,
    selectionData: { selectedText },
    setColorId,
    sharedForAssignmentId,
  } = props

  const dispatch = useDispatch()
  const { displayingLeveledContent, displayingSpanishContent } =
    useContext(contentViewerContext)
  const [annotation, setAnnotation] =
    useStateWithDynamicDefault(originalAnnotation)
  const isAssignmentCreator = useAbilityCheck(ABILITY_ASSIGNMENT_CREATION)

  const { id: userAssignmentId } = useSelector(getUserAssignment) || {}

  // we can't get this from the user assignment because the teacher might just be editing the assignment itself
  const { id: assignmentId } = useSelector(getContextualAssignment) || {}

  const isCreating = isUndefined(id)
  const studentViewingTeacherAnnotation =
    !isAssignmentCreator && sharedForAssignmentId

  const headlineLabel = `${studentViewingTeacherAnnotation ? 'Teacher ' : ''} ${annotation ? 'Note' : 'Highlight'}`

  const handleClickedSave = () => {
    dispatch(
      actions.postInteraction({
        sharedForAssignmentId: isAssignmentCreator ? assignmentId : undefined,
        contentId,
        contextContentId,
        id,
        interactionData: {
          annotation,
          colorId,
          displayingLeveledContent,
          displayingSpanishContent,
          selectionData,
        },
        interactionSubType: INTERACTION_SUBTYPE_TEXT,
        interactionType: INTERACTION_TYPE_ANNOTATION,
        userAssignmentId,
      }),
    )
    saveColorId(colorId)
    onClose(true)
  }

  const handleClickRemove = () => {
    dispatch(
      actions.deleteInteraction({
        interactionId: id,
      }),
    )
    onClose(true)
  }

  const dismiss = () => onClose(false)

  return (
    <Popper
      disableCloseButton
      disablePortal={false}
      placement="top">
      <Popper.Content
        anchorEl={anchorNode}
        onClose={dismiss}
        open={open}
        sx={{ width: '49.5rem' }}>
        <SplitHeadline
          alignItems="center"
          flexWrap="nowrap"
          left={headlineLabel}
          mb={0}
          mt={0}
          right={
            <div style={{ textAlign: 'right', height: 32 }}>
              <Tooltip title="Copy highlighted text to clipboard">
                <CopyButton
                  size="small"
                  sx={{ top: -10, left: 10, height: 48, width: 48 }}
                  text={selectedText}>
                  <Clipboard />
                </CopyButton>
              </Tooltip>

              <IconButton
                onClick={dismiss}
                size="large"
                sx={{ top: -10, left: 10 }}
                title="Close">
                <Close />
              </IconButton>

              <KeyboardEventHandler
                handleEventType="keyup"
                handleFocusableElements
                handleKeys={['esc']}
                onKeyEvent={dismiss}
              />
            </div>
          }
        />

        {studentViewingTeacherAnnotation ? (
          annotation && (
            <TextField
              autoFocus
              disabled
              maxRows={3}
              multiline
              name="note"
              sx={{
                '& .Mui-disabled::before': {
                  borderBottomStyle: 'none!important',
                },
                marginTop: 1,
                minWidth: 300,
              }}
              value={annotation}
              variant="standard"
            />
          )
        ) : (
          <>
            <TextField
              autoFocus
              maxRows={3}
              multiline
              name="note"
              onChange={({ target }) => setAnnotation(target.value)}
              placeholder="Type to add a note..."
              sx={{ marginTop: 1, minWidth: 300 }}
              value={annotation}
              variant="standard"
            />

            <ColorPicker
              onChange={setColorId}
              value={colorId}
            />

            <Grid
              container
              justifyContent="space-between"
              pt={1}
              wrap="nowrap">
              <Grid item>
                <ActionButton onClick={handleClickedSave}>
                  {isCreating ? 'Create' : 'Update'}{' '}
                  {annotation ? 'Note' : 'Highlight'}
                </ActionButton>
              </Grid>

              {!isCreating && (
                <Grid item>
                  <ActionButton onClick={handleClickRemove}>
                    Remove {annotation ? 'Note' : 'Highlight'}
                  </ActionButton>
                </Grid>
              )}
            </Grid>
          </>
        )}
      </Popper.Content>
    </Popper>
  )
}

AnnotationDialog.propTypes = {
  anchorNode: PropTypes.object,

  annotation: PropTypes.string,
  colorId: PropTypes.number.isRequired,

  contentId: PropTypes.string.isRequired,
  contextContentId: PropTypes.string.isRequired,

  selectionData: PropTypes.shape({
    selectedText: PropTypes.string.isRequired,
    anchor: PropTypes.shape({
      parserHash: PropTypes.string,
      nodeIndex: PropTypes.number.isRequired,
      occurrenceIndex: PropTypes.number.isRequired,
      selectedPortion: PropTypes.string.isRequired,
      offset: PropTypes.number.isRequired,
    }),
    focus: PropTypes.shape({
      parserHash: PropTypes.string,
      nodeIndex: PropTypes.number.isRequired,
      occurrenceIndex: PropTypes.number.isRequired,
      selectedPortion: PropTypes.string.isRequired,
      offset: PropTypes.number.isRequired,
    }),
  }).isRequired,

  id: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  saveColorId: PropTypes.func.isRequired,
  setColorId: PropTypes.func.isRequired,
  sharedForAssignmentId: PropTypes.string,
}

export default AnnotationDialog
