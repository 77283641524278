import { withOptions } from '@comfy/redux-selectors'
import {
  CONTENT_TYPE_ASSESSMENT,
  CONTENT_TYPE_BLOCK,
  CONTENT_TYPE_CHAPTER,
  CONTENT_TYPE_COURSE,
  CONTENT_TYPE_ECHO,
  CONTENT_TYPE_INTERACTIVE,
  CONTENT_TYPE_PAGE,
  CONTENT_TYPE_PAGESET,
  CONTENT_TYPE_RUBRIC,
  CONTENT_TYPE_RUBRIC_CRITERIA,
  CONTENT_TYPE_SCAFFOLD,
  CONTENT_TYPE_SECTION,
  CONTENT_TYPE_SOURCE,
  CONTENT_TYPE_SUBSECTION,
  CONTENT_TYPE_UNIT,
} from 'core/consts'
import { flatten, map } from 'fp/arrays'
import { get } from 'fp/objects'
import { fallbackTo } from 'fp/utils'
import { compose } from 'redux'
import { createSelector, stateContent } from '.'
import { omitReduxMetadata } from './utils'

export const collapsePropBagsToArray = compose(
  flatten,
  map(Object.values),
  map(omitReduxMetadata),
  fallbackTo({}),
)

export const getContentForType = withOptions(contentType =>
  createSelector('getContentForType')(stateContent, get(contentType)),
)

export const getContentBags = createSelector('getContentBags')(
  // ******** DO NOT SORT ********
  getContentForType(CONTENT_TYPE_COURSE),
  getContentForType(CONTENT_TYPE_UNIT),
  getContentForType(CONTENT_TYPE_CHAPTER),
  getContentForType(CONTENT_TYPE_SUBSECTION),
  getContentForType(CONTENT_TYPE_SECTION),
  getContentForType(CONTENT_TYPE_ECHO),
  getContentForType(CONTENT_TYPE_PAGESET),
  getContentForType(CONTENT_TYPE_PAGE),
  getContentForType(CONTENT_TYPE_SOURCE),
  getContentForType(CONTENT_TYPE_BLOCK),
  getContentForType(CONTENT_TYPE_SCAFFOLD),
  getContentForType(CONTENT_TYPE_INTERACTIVE),
  getContentForType(CONTENT_TYPE_RUBRIC),
  getContentForType(CONTENT_TYPE_RUBRIC_CRITERIA),
  getContentForType(CONTENT_TYPE_ASSESSMENT),
  (...propBags) => propBags,
)

export const getCollapsedContentBags = createSelector(
  'getCollapsedContentBags',
)(getContentBags, collapsePropBagsToArray)
