import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import Switch from '@mui/material/Switch'
import { styled } from '@mui/material/styles'
import cl from 'classnames'
import { componentShape } from 'core/shapes'
import PropTypes from 'prop-types'
import { useId } from 'react'

const SwitchFormControl = styled(
  props => {
    const {
      checked = false,
      className,
      color = 'primary',
      helperText,
      label,
      onChange,
      summary = false,
      ...rest
    } = props
    const id = useId()
    return (
      <FormControl
        className={cl(className, summary ? 'summary' : null)}
        {...rest}>
        <FormControlLabel
          aria-describedby={id}
          aria-pressed={checked}
          control={
            <Switch
              checked={checked}
              color={color}
              data-color={color}
              onChange={onChange}
            />
          }
          label={label}
        />
        {Boolean(helperText) && (
          <FormHelperText
            data-variant="switch"
            id={id}>
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    )
  },
  {
    name: 'switches-BaseSwitchFormControl',
  },
)(
  ({
    theme: {
      mixins: { rem },
      palette,
    },
  }) => ({
    p: {
      width: 'auto',
      marginRight: 8,
    },
    '&.summary': {
      backgroundColor: palette.common.lightGrey,
      borderRadius: 5,
      padding: rem(2, 3),
    },
  }),
)

SwitchFormControl.propTypes = {
  label: PropTypes.string.isRequired,
  helperText: componentShape,
  checked: PropTypes.bool,
  color: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  summary: PropTypes.bool,
}

export default SwitchFormControl
