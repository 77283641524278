import { get } from 'fp/objects'
import { useFormContext, useFormState } from 'react-hook-form'

const useHookFormError = name => {
  const { clearErrors, setError: setErrors } = useFormContext()
  const { errors } = useFormState()

  const error = get(`${name}.message`)(errors)
  const clearError = () => clearErrors(name)
  const setError = (message, shouldFocus = true, type = 'custom') =>
    setErrors(name, { message, type }, { shouldFocus })

  return { error, clearError, setError }
}

export default useHookFormError
