import { withOptions } from '@comfy/redux-selectors'
import { get, hasProperty } from 'fp/objects'
import { pullListed } from 'projections/index'
import { compose } from 'redux'
import { createSelector, stateGroups } from '.'

export const getListedGroups = createSelector('getListedGroups')(
  stateGroups,
  pullListed,
)

export const getGroupById = withOptions(({ groupId }) =>
  createSelector('getGroupById')(stateGroups, get(String(groupId))),
)

export const getGroupStudents = withOptions(({ groupId }) =>
  createSelector('getGroupStudents')(
    getGroupById({ groupId }),
    get('students'),
  ),
)

export const isGroupLoaded = withOptions(({ groupId }) =>
  createSelector('isGroupLoaded')(
    stateGroups,
    compose(hasProperty(String(groupId)), get('loaded')),
  ),
)
