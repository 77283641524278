import { withOptions } from '@comfy/redux-selectors'
import { get } from 'fp/objects'
import { capitalize } from 'fp/strings'
import { createSelector, stateUserAlerts } from '.'

// Data is stored as toKeyedObject(data); these selectors return the data as an array
export const getAllUserAlerts = createSelector('getAllUserAlerts')(
  stateUserAlerts,
  state => Object.values(state.all.data),
)

// valid values for 'mode' are 'interrupt' or 'profile'
const getNewUserAlertsByType = withOptions(({ mode }) =>
  createSelector('getNewUserAlertsByType')(stateUserAlerts, state =>
    Object.values(state[`isNew${capitalize(mode)}`]?.data),
  ),
)

const getNewUserAlertsCount = withOptions(({ mode }) =>
  createSelector('getNewUserAlertsCount')(
    stateUserAlerts,
    get(`isNew${capitalize(mode)}.metadata.count`),
  ),
)

export const getNewInterruptUserAlerts = getNewUserAlertsByType({
  mode: 'interrupt',
})
export const getNewProfileUserAlerts = getNewUserAlertsByType({
  mode: 'profile',
})

export const getNewInterruptUserAlertsCount = getNewUserAlertsCount({
  mode: 'interrupt',
})
export const getNewProfileUserAlertsCount = getNewUserAlertsCount({
  mode: 'profile',
})

export const getUserAlertsFetchedStatus = withOptions(({ type }) =>
  createSelector('getUserAlertsFetchedStatus')(
    stateUserAlerts,
    get(type === 'all' ? 'all.fetched' : `isNew${capitalize(type)}.fetched`),
  ),
)
