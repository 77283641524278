import { whenPresent } from 'fp/utils'
import useIsFormDirty from 'hooks/useIsFormDirty'
import PropTypes from 'prop-types'
import { useEffect } from 'react'

/**
 * See also <DirtyNavigationWarning /> which is the consumer of this callback
 */

const SuccessCallback = props => {
  const { methods, onSuccess, payload, setResetting, waiting } = props

  const isDirty = useIsFormDirty()

  useEffect(() => {
    if (waiting && !isDirty) {
      setResetting({
        waiting: false,
        payload,
      })

      whenPresent(onSuccess, payload, methods)
    }
  }, [isDirty, methods, onSuccess, payload, setResetting, waiting])

  return null
}

SuccessCallback.propTypes = {
  methods: PropTypes.object.isRequired,
  onSuccess: PropTypes.func,
  payload: PropTypes.object,
  setResetting: PropTypes.func.isRequired,
  waiting: PropTypes.bool.isRequired,
}

export default SuccessCallback
