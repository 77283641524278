import { get } from 'fp/objects'
import { isEmptyString } from 'fp/strings'
import PropTypes from 'prop-types'
import { useFormContext, useWatch } from 'react-hook-form'
import AssetUploader from '.'
import { propBagsShape, withPropBags } from '../../Form/withPropBags'

const HFAssetUploader = withPropBags(['disabled', 'onBlur', 'onFocus'])(
  props => {
    const {
      componentProps: {
        helperText,
        label,
        name,
        required,
        value,
        // TODO: we may still have use of these commented props down the road a bit
        // inputProps,
        // inputRef,
        onChange,
      },
      controlProps: {
        disabled,
        // onBlur,
        // onFocus,
      },
      rest: {
        assetCode,
        assetCodeFieldName = 'assetCode',
        headlineHelperText,
        labelProps,
        uploadType,
        onCantoFields,
        uploadVariant,
        variantLinkage,
      },
    } = props

    const hfAssetCode = useWatch({ name: assetCodeFieldName })

    const { setValue } = useFormContext()

    const linkedFieldNames = (variantLinkage || []).map(get('formField'))

    const [...valuesForGenerated] = useWatch({ name: linkedFieldNames })

    const handleUploadSuccess = response => {
      if (linkedFieldNames.length) {
        variantLinkage.forEach(({ formField, variant }, idx) => {
          const currentValue = valuesForGenerated[idx]
          const nextValue = response.generated?.[variant]
          if (isEmptyString(currentValue || '') && nextValue) {
            setValue(formField, nextValue, {
              shouldDirty: true,
              shouldTouch: true,
            })
          }
        })
      }
    }

    const handleClear = () => {
      setValue(name, null)
    }

    return (
      <AssetUploader
        {...{
          assetCode: assetCode || hfAssetCode,
          disabled,
          headlineHelperText,
          helperText,
          label,
          labelProps,
          name,
          onCantoFields,
          onUpload: onChange,
          onClear: handleClear,
          onUploadSuccess: handleUploadSuccess,
          required,
          uploadVariant,
          uploadType,
          value,
        }}
      />
    )
  },
)

HFAssetUploader.propTypes = {
  ...propBagsShape.isRequired,
  assetCodeFieldName: PropTypes.string,
  headlineHelperText: PropTypes.string,
}

export default HFAssetUploader
