import Button from '@mui/material/Button'
import type {
  AssetUpload,
  AssetUploadType,
  AssetUploadedProps,
} from 'common/formControls/textInputs/RichTextEdit2/nodes/ImageNode/@types'
import AppBusy from 'common/indicators/AppBusy'
import useFileUpload from 'hooks/useFileUpload'
import { BUSY } from 'hooks/useReduxCallback'
import { type ChangeEvent, useRef } from 'react'
import { acceptMap, openFileDialog } from './utils'

interface Props {
  disabled?: boolean
  isAudioDescription?: boolean
  onClear: () => void
  onUploadSuccess: (upload: AssetUploadedProps) => void
  uploadType: AssetUploadType
  ButtonProps?: {
    children?: React.ReactNode
  }
  value?: AssetUpload
}

const FileUploader: React.FC<Props> = ({
  disabled = false,
  isAudioDescription = false,
  onClear,
  onUploadSuccess,
  uploadType,
  ButtonProps,
  value,
}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const handleUploadSuccess = (upload: AssetUploadedProps) => {
    onUploadSuccess(upload)
  }

  const { startUpload, status } = useFileUpload({
    onUploadSuccess: handleUploadSuccess,
  })

  const handleStartUpload = (...payload: unknown[]) => {
    if (typeof startUpload === 'function') {
      return startUpload(...payload)
    }
    return Promise.reject(new Error('startUpload is not a function'))
  }

  const handleChange = (fileList: File[]) => {
    handleStartUpload({ file: fileList[0], isAudioDescription, uploadType })
  }

  const onInputChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (target.files) {
      handleChange(Array.from(target.files))
    }
    if (inputRef.current) inputRef.current.value = ''
  }

  const handleClick = () => {
    openFileDialog(inputRef)
  }

  return (
    <>
      <input
        accept={acceptMap[uploadType]}
        disabled={disabled}
        onChange={onInputChange}
        ref={inputRef}
        style={{ display: 'none' }}
        type="file"
      />

      <Button
        onClick={handleClick}
        size="small"
        sx={{ textTransform: 'none' }}
        variant="secondary"
        {...ButtonProps}>
        {ButtonProps?.children || 'Browse file system...'}
      </Button>

      {value && (
        <Button
          size="small"
          variant="primary"
          onClick={onClear}>
          Reset
        </Button>
      )}

      <AppBusy open={status === BUSY} />
    </>
  )
}

export default FileUploader
