import Box from '@mui/material/Box'
import withContent from 'hoc/withContent'
import withFetchedItem from 'hoc/withFetchedItem'
import withOmittedProps from 'hoc/withOmittedProps'
import { AssignmentEditContextProvider } from 'hss/AssignmentEditor/assignmentEditContext'
import LastViewedContentTracker from 'hss/sections/Assignments/LastViewedContentTracker'
import ProgressTracker from 'hss/sections/Assignments/ProgressTracker'
import { useParams } from 'react-router-dom'
import actionTypes from 'reducers/actionTypes'
import RouteTransitionProvider from 'routing/RouteTransition/RouteTransitionProvider'
import PinnedRouteProvider from 'routing/TraverseRoute/Pinning'
import { appUrl } from 'routing/consts'
import OuterViewport from '../OuterViewport'
import { SidebarProvider } from '../SidebarProvider'
import MainViewport from './MainViewport'
import TocSidebar from './TocSidebar'

const ContentGate = withContent(
  withOmittedProps(Box, 'content', 'contentType', 'editing'),
)
const AssignmentGate = withFetchedItem(Box, {
  actionType: actionTypes.ASSIGNMENT_FETCH,
  includeScoringData: true,
})
const UserAssignmentGate = withFetchedItem(Box, {
  actionType: actionTypes.USER_ASSIGNMENT_FETCH,
})

const TocShell = props => (
  <SidebarProvider variant="toc">
    <ContentGate
      busy="app"
      display="flex"
      queryParams={{
        ...props,
        childDepth: 999,
      }}>
      <RouteTransitionProvider transitionType="leftBump">
        <TocSidebar homeUrl={appUrl} />

        <OuterViewport>
          <PinnedRouteProvider>
            <MainViewport />
          </PinnedRouteProvider>
        </OuterViewport>
      </RouteTransitionProvider>
    </ContentGate>
  </SidebarProvider>
)

const TempTocShellWithParams = () => {
  const { userAssignmentId } = useParams()
  return <TocShell forUserAssignmentId={userAssignmentId} />
}

export const AssignmentEditorTocShell = () => (
  <AssignmentGate>
    <AssignmentEditContextProvider>
      <TocShell />
    </AssignmentEditContextProvider>
  </AssignmentGate>
)

export const AssignmentCreatorTocShell = () => (
  <AssignmentEditContextProvider>
    <TocShell />
  </AssignmentEditContextProvider>
)

export const StudentAssignmentTocShell = () => (
  <UserAssignmentGate>
    <LastViewedContentTracker />
    <ProgressTracker />
    <TempTocShellWithParams />
  </UserAssignmentGate>
)

export default TocShell
