import { transparentize } from 'polished'

const overrides = ({
  mixins: { borderS, px, rem, sizeR, transition },
  palette,
}) => ({
  MuiSlider: {
    styleOverrides: {
      root: {
        '.MuiSlider-thumb': {
          ...transition('all', 100),
          '&:hover, .Mui-focusVisible': {
            boxShadow: 'none',
            backgroundColor: palette.background.default,
            border: '2px solid currentColor',
          },
        },
        '.MuiSlider-mark': {
          width: px(2),
          height: rem(4.8),
        },
        '.MuiSlider-markActive': {
          zIndex: -1,
          backgroundColor: palette.accent[1],
        },
        '.MuiSlider-markLabel': {
          top: rem(4.8),
          '&[data-index="0"]': { transform: 'translateX(0%)' },
          '&:nth-last-of-type(2)': { transform: 'translateX(-100%)' },
        },
        '.MuiSlider-track': {
          borderRadius: 0,
        },
        '.MuiSlider-rail': {
          borderRadius: 0,
          backgroundColor: palette.slider.main,
          opacity: 1,
          ...borderS(palette.slider.border),
          width: '99.8%',
        },
        '&.MuiSlider-marked': {
          marginBottom: rem(8),
        },
        '&.thin': {
          '.MuiSlider-track': { height: rem(0.2) },
          '.MuiSlider-rail': { height: rem(0.3) },
          '.MuiSlider-thumb': sizeR(1),
        },
        '&.pill': {
          '.MuiSlider-thumb': {
            ...sizeR(1.5, 0.7),
            borderRadius: 16,
          },
        },
        '&.thinPill': {
          '.MuiSlider-thumb': {
            ...sizeR(1.5, 0.6),
            marginLeft: 0,
            marginTop: -1,
          },
        },
        '&.thumbHover': {
          '.MuiSlider-thumb': { display: 'none' },
          '&:hover': {
            '.MuiSlider-track': { height: rem(0.8) },
            '.MuiSlider-rail': { height: rem(0.8) },
            '.MuiSlider-thumb': {
              display: 'flex',
              top: -4,
              ...sizeR(1.4),
            },
          },
        },
        '&.noThumb': {
          '.MuiSlider-thumb': { display: 'none' },
        },
        '&.readOnly': { cursor: 'unset' },
      },
    },

    variants: [
      {
        props: { variant: 'gradient' },
        style: {
          '.MuiSlider-track': {
            border: 'none',
            height: rem(1.2),
          },
          '.MuiSlider-rail': {
            height: rem(1.2),
          },
          '.MuiSlider-thumb': {
            '&:hover, &.Mui-focusVisible': {
              boxShadow: `0 0 0 12px ${transparentize(0.7, palette.cobalt[1])}`,
            },
          },
          '&:not(.Mui-disabled)': {
            '.MuiSlider-mark': {
              backgroundColor: palette.grey[3],
            },
            '.MuiSlider-track': {
              background: palette.cobalt.gradient,
            },
            '.MuiSlider-rail': {
              backgroundColor: palette.grey[3],
            },
            '.MuiSlider-thumb': {
              backgroundColor: palette.cobalt[1],
            },
          },
        },
      },
    ],
  },
})

export default overrides
