import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $insertNodeToNearestRoot, mergeRegister } from '@lexical/utils'
import { COMMAND_PRIORITY_EDITOR, createCommand } from 'lexical'
import { useEffect } from 'react'
import {
  $createSourceTitleNode,
  SourceTitleNode,
} from '../nodes/SourceTitleNode'

export const INSERT_SOURCE_TITLE_COMMAND = createCommand(
  'INSERT_SOURCE_TITLE_COMMAND',
)

const SourceTitlePlugin = () => {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    if (!editor.hasNodes([SourceTitleNode])) {
      throw new Error(
        'SourceTitlePlugin: SourceTitleNode not registered on editor',
      )
    }

    return mergeRegister(
      editor.registerCommand(
        INSERT_SOURCE_TITLE_COMMAND,
        () => {
          const newNode = $createSourceTitleNode()
          $insertNodeToNearestRoot(newNode)

          return true
        },
        COMMAND_PRIORITY_EDITOR,
      ),
    )
  }, [editor])

  return null
}

export default SourceTitlePlugin
