/* istanbul ignore file */
import Done from '@mui/icons-material/Done'
import Well from './Well'

const Info = props => (
  <Well
    color="secondary"
    fontSize="large"
    icon={<Done />}
    variant="solid"
    {...props}
  />
)

export default Info
