import DialogContent from '@mui/material/DialogContent'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Select from 'common/formControls/selects/Select'
import SearchInput from 'common/formControls/textInputs/SearchInput'
import { squeryShape } from 'core/shapes'
import { alter, inspect } from 'core/store/search/squery'
import { sortBy } from 'fp/arrays'
import { get } from 'fp/objects'
import { not } from 'fp/utils'
import { labels } from 'hss/ContentBuilder/consts'
import { items } from 'hss/views/Library/AddToLibrary/assetTypes/Interactives'
import PropTypes from 'prop-types'
import { compose } from 'redux'

const contentSubTypes = items
  .filter(compose(not, get('disabled')))
  .sort(sortBy('label'))

const InteractivesFilter = styled(
  props => {
    const { className, setSquery, squery, subContentTypes } = props

    const searchText = inspect(squery).get.modifier('keywordSearch').is('')
    const contentSubType = inspect(squery).get.where('contentSubType').is('')

    const handleSearch = value => {
      setSquery(alter.set.modifier('keywordSearch').is(value))
    }

    const handleSubTypeChange = ({ target }) => {
      const { value } = target
      if (value === 'ANY') {
        setSquery(alter.remove.where('contentSubType'))

        if (subContentTypes.length) {
          setSquery(alter.set.where('contentSubType').in(subContentTypes))
        }
      } else {
        setSquery(alter.set.where('contentSubType').is(value))
      }
    }

    return (
      <DialogContent className={className}>
        <Stack
          direction="row"
          pt={3}
          spacing={2}>
          <SearchInput
            autoFocus
            label="Search Interactives"
            onChange={handleSearch}
            sx={{ minWidth: 220 }}
            value={searchText}
          />

          <Select
            fullWidth={false}
            label="Type"
            name="contentSubType"
            onChange={handleSubTypeChange}
            style={{ minWidth: '12rem' }}
            value={contentSubType}>
            {subContentTypes.length !== 1 && (
              <MenuItem value="ANY">Any</MenuItem>
            )}

            {!subContentTypes.length &&
              contentSubTypes.map(
                ({ label, contentType, contentSubType: itemSubType }) => (
                  <MenuItem
                    key={`${contentType}-${itemSubType}`}
                    value={itemSubType}>
                    {label}
                  </MenuItem>
                ),
              )}

            {subContentTypes.map(itemSubType => (
              <MenuItem
                key={itemSubType}
                value={itemSubType}>
                {labels[itemSubType]}
              </MenuItem>
            ))}
          </Select>
        </Stack>
      </DialogContent>
    )
  },
  { name: 'interactivePlugin-InteractivesFilter' },
)(
  ({
    theme: {
      mixins: { absHeight },
    },
  }) => absHeight(96),
)

InteractivesFilter.propTypes = {
  setSquery: PropTypes.func.isRequired,
  squery: squeryShape.isRequired,
  subContentTypes: PropTypes.array.isRequired,
}

export default InteractivesFilter
