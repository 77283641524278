import withFormControl from 'common/formControls/selects/ConnectedMultiSelect/withFormControl'
import ConnectedTagsPicker from './ConnectedTagsPicker'

const TagsPicker = withFormControl(ConnectedTagsPicker)({
  createType: 'TAG',
  helperText: 'Type a tag name',
  label: 'Tags',
  name: 'tags',
})

export default TagsPicker
