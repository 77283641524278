import { MenuItem, type SelectChangeEvent } from '@mui/material'
import type { IContent } from 'common/@types/common'
import Select from 'common/formControls/selects/Select'
import Checkbox from 'common/formControls/switches/Checkbox'
import TextField from 'common/formControls/textInputs/TextField'
import { get } from 'fp/objects'
import useStateWithDynamicDefault from 'hooks/useStateWithDynamicDefault'
import type { LexicalNode } from 'lexical'
import type { SyntheticEvent } from 'react'

const findValue = (
  node: LexicalNode,
  field: DecoratorField,
  content: IContent | undefined,
): string | undefined => {
  let value

  if (field.name.startsWith('content.')) {
    value = get(field.name)({ content })
  } else {
    // biome-ignore lint/suspicious/noExplicitAny: okay for now
    value = node && (node as Record<string, any>)[`__${field.name}`]
  }

  return value
}

type DecoratorControlProps = {
  content?: IContent
  field: DecoratorField
  node: LexicalNode
  onChange?: (args: OnChangeArgs) => void
}

const BooleanControl: React.FC<DecoratorControlProps> = ({
  field,
  node,
  onChange,
}) => {
  const { label, name } = field

  return (
    <Checkbox
      checked={Boolean(
        node && (node as unknown as Record<string, unknown>)[`__${name}`],
      )}
      description=""
      label={label}
      name={name}
      onChange={(_: SyntheticEvent, value: boolean) =>
        onChange?.({ field, value })
      }
    />
  )
}

const ReadOnlyControl: React.FC<DecoratorControlProps> = ({
  content,
  field,
  node,
}) => {
  const value = findValue(node, field, content)

  return <span>{value || <i>n/a</i>}</span>
}

const SelectControl: React.FC<DecoratorControlProps> = ({
  content,
  field,
  node,
  onChange,
}) => {
  const { name } = field

  return (
    <Select
      hideLabel
      name={name}
      onChange={(event: SelectChangeEvent<unknown>) => {
        const target = event.target as HTMLSelectElement

        onChange?.({
          field,
          value: target.value,
        })
      }}
      value={findValue(node, field, content)}>
      {field.options?.map(({ label, value }) => (
        <MenuItem
          key={value}
          value={value}>
          {label}
        </MenuItem>
      ))}
    </Select>
  )
}

const StringControl: React.FC<DecoratorControlProps> = ({
  content,
  field,
  node,
  onChange,
}) => {
  const [value, setValue] = useStateWithDynamicDefault(
    findValue(node, field, content),
  )

  return (
    <TextField
      onChange={(event: SyntheticEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement

        onChange?.({
          field,
          value: target.value,
        })
        setValue(target.value)
      }}
      value={value}
    />
  )
}

const DecoratorControl: React.FC<DecoratorControlProps> = ({
  content,
  field,
  node,
  onChange,
}) => {
  const { label, name } = field

  let Control: React.FC<DecoratorControlProps>

  switch (field.type) {
    case 'boolean':
      Control = BooleanControl
      break
    case 'select':
      Control = SelectControl
      break
    case 'string':
    default:
      Control = StringControl
  }

  if (!onChange) Control = ReadOnlyControl

  return (
    <tr key={name}>
      <th>{label}</th>
      <td>
        <Control {...{ content, field, onChange, node }} />
      </td>
    </tr>
  )
}

export default DecoratorControl
