import { DefaultDraftBlockRenderMap } from 'draft-js'
import { first } from 'fp/arrays'
import { callWith } from 'fp/call'
import { equals, get } from 'fp/objects'
import { Map as immutableMap } from 'immutable'
import blockBreakoutPlugin from './blockBreakoutPlugin'
import dualHeadlinePlugin from './dualHeadlinePlugin'
import extendedFormattingPlugin from './extendedFormattingPlugin'
import funFactsPlugin from './funFactsPlugin'
import imagePlugin from './imagePlugin'
import indentationPlugin from './indentationPlugin'
import interactivePlugin from './interactivePlugin'
import linkPlugin from './linkPlugin'
import listItemPlugin from './listItemPlugin'
import numberCrunchPlugin from './numberCrunchPlugin'
import simpleFormattingPlugin from './simpleFormattingPlugin'
import tablePlugin from './tablePlugin'
import wordDefinitionPlugin from './wordDefinitionPlugin'

/**
 * Plugins are objects that implement one or more of the following methods
 * (organized by section):
 *
 * IMPORTING:
 *   htmlToBlock
 *   htmlToEntity
 *   htmlToStyle
 *   importingPostProcess
 *   textToEntity
 *
 * EXPORTING:
 *   blockToHtml
 *   entityToHtml
 *   exportingPostProcess
 *   styleToHtml
 *
 * RENDERING:
 *   blockRendererFn
 *   blockRenderMap
 *   decorator
 *   handleMenuChange
 *   ToolBarItem
 */

/**
 * We currently don't have any plugins that implement textToEntity.
 *
 *  Boiler plate example from https://github.com/HubSpot/draft-convert
 *
 *  text.replace(/\@(\w+)/g, (match, name, offset) => {
 *    const entityKey = createEntity('AT-MENTION', 'IMMUTABLE', { name })
 *    result.push({
 *      entity: entityKey,
 *      offset,
 *      length: match.length,
 *      result: match,
 *    })
 *  })
 */

/**
 *                      ----=== ON CUSTOM TAGS ===---
 *
 * There are some quirks between draft-js and draft-convert that you need to be
 * aware of when working with custom tags.  This seriously took me the better
 * part of a week to fully figure out, so I'm hoping to spare anyone else that
 * pain by documenting it all here.
 *
 * First know that in draft, everything MUST be in a "block", and blocks MUST have
 * content.  In our case, the content will most likely always be an "entity".
 * (note: refer to /plugins/dualHeadlinePlugin.js while trying to grok the rest
 * of these comments)
 *
 * When importing a custom html tag, you must produce both a block and an entity.
 * The block will probably be of type 'unstyled' or 'atomic'.  If you're not sure
 * which to use, then use 'atomic'. The complete list of available types is found
 * at https://draftjs.org/docs/api-reference-content-block . Later when it comes
 * to programmatically creating new instances of a tag, you'll find that the utility
 * functions I've created do bias heavily toward atomic blocks.
 *
 * The block you produce should be immutable (e.g. uneditable) and it needs to have
 * a data entry called `meta.customType` which you should set to something unique
 * (the tag name for example).
 *
 * The entity you produce is where you convert any tag attributes into data items.
 * This entity should also be marked immutable and should be given the same value
 * for `meta.customType` as the block.
 *
 * If you have a custom renderer for the tag, define that in `blockRendererFn`,
 * keyed to the value you used for `meta.customType`.  Make sure to pass along
 * getEditorState to be able to extract the data attributes within your component.
 * Also pass onChange if the component needs to be able to alter editorState.
 * There is a component called <CustomBlockWrapper /> that you can use to wrap
 * your custom component, for a consistent look and feel.
 *
 * If you need to create an entry on the editor toolbar, provide a ToolBarItem
 * method in your plugin.  This can return either a DraftToolButton or a simple
 * key/value pair.  In the later case, the key/value pair is used as a menu item
 * within the "more..." toolbar button.  You should also provide `handleMenuChange`
 * so that you can respond to user interactions.
 *
 * Whether you use a DraftToolButton or a menu item, you'll want a way to be able
 * to insert a new instance of your plugin tag into the editor.  Recall that draft
 * wants both a block AND an entity.  The cleanest way to deal with this is to
 * first create an entity (using the same signature used during import) and then
 * create a new block that is mapped to this entity using the utility method
 * `insertNewBlock`.  It's important that this is done as a single atomic action
 * so that only one entry is added to the undo stack.
 *
 * There are two props called `readOnly` and `setPluginHasFocus` that are passed
 * to blockRendererFn, if present.  These come into play only if your custom
 * component can accept user input.  It's important that any content-editable
 * portions of your component use setPluginHasFocus() to inform the editor that
 * they have focus. Internally, the editor will stop accepting user input so long
 * as `pluginHasFocus` is true.  This is done to preserve referential integrity.
 * A good strategy is to toggle it onFocus/blur.  The `readOnly` prop is independent
 * of this and serves to let you know if your component should be readonly too.
 * Don't confuse this with immutability or the `editable` attribute of blocks or
 * entities!
 *
 * If you want the user to make edits within your custom block renderer, then use
 * the provided <PluginInput /> helper component.  This allows for basic fields
 * such as inputs selects, or contentEditable elements. These inputs must not
 * reside within a <form />.  Never use hook-form components here!  Remember that
 * your custom renderer is nested inside the RTE.
 *
 * For a complete example, see the dualHeadlinePlugin file.
 */

const plugins = [
  // leave as the first plugin! it provides the block primitives for the others
  simpleFormattingPlugin,

  blockBreakoutPlugin,
  extendedFormattingPlugin,
  dualHeadlinePlugin,
  funFactsPlugin,
  imagePlugin,
  interactivePlugin,
  linkPlugin,
  listItemPlugin,
  numberCrunchPlugin,
  tablePlugin,
  wordDefinitionPlugin,
  // leave as the last plugin! it can decorate the output of the others
  indentationPlugin,
]

export const blockRendererFn = props => contentBlock => {
  const results = plugins
    .map(get('blockRendererFn'))
    .filter(Boolean)
    .map(callWith(contentBlock, props))
    .filter(Boolean)

  return first(results)
}

export const blockRenderMap = features => {
  const results = plugins
    .map(get('blockRenderMap'))
    .filter(Boolean)
    .map(callWith(features))
    .filter(Boolean)
    .reduce((acc, entry) => ({ ...acc, ...entry }), {})

  return DefaultDraftBlockRenderMap.merge(immutableMap(results))
}

export const handleBeforeInput =
  features => (chars, editorState, eventTimeStamp) => {
    const handled = plugins
      .map(get('handleBeforeInput'))
      .filter(Boolean)
      .map(callWith(features))
      .map(callWith(chars, editorState, eventTimeStamp))
      .some(equals('handled'))

    return handled ? 'handled' : null
  }

export const handleKeyCommand =
  features => methods => (command, editorState, eventTimeStamp) => {
    const handled = plugins
      .map(get('handleKeyCommand'))
      .filter(Boolean)
      .map(callWith(features))
      .map(callWith(methods))
      .map(callWith(command, editorState, eventTimeStamp))
      .some(equals('handled'))

    return handled ? 'handled' : null
  }

export const handleReturn =
  features => methods => (chars, editorState, eventTimeStamp) => {
    const handled = plugins
      .map(get('handleReturn'))
      .filter(Boolean)
      .map(callWith(features))
      .map(callWith(methods))
      .map(callWith(chars, editorState, eventTimeStamp))
      .some(equals('handled'))

    return handled ? 'handled' : null
  }

export default plugins
