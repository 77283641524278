import { renderOption } from 'common/formControls/selects/ConnectedMultiSelect/utils'
import withConnectedMultiSelect from 'common/formControls/selects/ConnectedMultiSelect/withConnectedMultiSelect'
import { METADATA_TYPE_TAG } from 'core/consts'
import { restEndpoint } from 'reducers/utils'

const ConnectedTagsPicker = withConnectedMultiSelect({
  renderOption: renderOption('label'),
  label: 'Add tag',
  labelField: 'name',
  orderBy: 'name',
  placeholder: 'Search for tag...',
  uri: `${restEndpoint.metadata}/${METADATA_TYPE_TAG}`,
  valueField: 'tagIds',
})

export default ConnectedTagsPicker
