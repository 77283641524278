const plugin = ({
  mixins: { important, importantPx },
  palette,
  typography,
}) => ({
  '.recharts-default-tooltip': {
    '.recharts-tooltip-item-list': {
      '.recharts-tooltip-item': {
        ...typography.variants.paragraph,
        color: important(palette.grey[0]),
      },
    },

    'p.recharts-tooltip-label': {
      ...typography.h4,
      marginTop: importantPx(8),
      marginBottom: importantPx(12),
    },
  },

  '.recharts-legend-item-text': {
    ...typography.variants.small,
    color: important(palette.grey[0]),
  },

  '.recharts-legend-wrapper': {
    ...typography.variants.paragraph,
    paddingBottom: 36,
  },

  '.recharts-label': typography.variants.eyebrow,

  '.recharts-surface': {
    overflow: 'visible',
  },
})

export default plugin
