import { insertNewBlock } from '@studysync/draft-js-modifiers'
import {
  EditorState,
  type EditorState as EditorStateType,
  Entity,
  Modifier,
} from 'draft-js'
import { push } from 'fp/arrays'
import useAbilityCheck from 'hooks/useAbilityCheck'
import { useCallback, useMemo, useState } from 'react'
import { Image as Glyph } from 'react-feather'
import { useFormContext, useWatch } from 'react-hook-form'
import { FEATURE_FLAG_INLINE_IMAGE } from 'shared/consts'
import DraftMenuButton from '../../toolbar/DraftMenuButton'
import ImageSelectionDialog from './ImageSelectionDialog'
import type {
  ImageDisplayMode,
  ImageDisplayModeCallback,
} from './ImageSelectionProvider'

const options = [
  { label: 'Full Image Block', key: 'block' },
  { label: 'Inline Image/Glyph', key: 'inline' },
]

type DraftToolbarButtonProps = {
  editorState: EditorStateType
  features: object
  setEditorState: (editorState: EditorStateType, update: boolean) => void
  toggleBlockType: (blockType: string) => void
}

const ImageMenuButton: React.FC<DraftToolbarButtonProps> = props => {
  const { editorState, features, setEditorState } = props

  const { setValue } = useFormContext()
  const children = useWatch({ name: 'children' })

  const [imageDialogOpen, setImageDialogOpen] = useState(false)
  const [imageDisplayMode, setImageDisplayMode] =
    useState<ImageDisplayMode>('block')

  /* istanbul ignore next */
  const handleMenuSelection = ({ key }: { key: ImageDisplayMode }) => {
    switch (key) {
      case 'block':
        setImageDisplayMode('block')
        setImageDialogOpen(true)
        break
      case 'inline':
        setImageDisplayMode('inline')
        setImageDialogOpen(true)
        break
      /* istanbul ignore next */
      default:
    }
  }

  const handleInsert = useCallback(
    ({
      interactiveId,
      displayMode,
      size,
      valign,
    }: ImageDisplayModeCallback) => {
      let newEditorState: EditorStateType

      if (displayMode === 'block') {
        newEditorState = insertNewBlock(editorState, 'atomic', '', {
          type: 'interactive',
          contentId: interactiveId,
          displayMode,
        })
      } else {
        const selection = editorState.getSelection()
        const contentState = editorState.getCurrentContent()

        const entityKey = Entity.create('INLINE-IMAGE', 'IMMUTABLE', {
          contentId: interactiveId,
          size,
          valign,
        })
        const textWithEntity = Modifier.replaceText(
          contentState,
          selection,
          ' ',
          undefined,
          entityKey,
        )

        newEditorState = EditorState.push(
          editorState,
          textWithEntity,
          'insert-characters',
        )
      }

      setEditorState(newEditorState, true)

      setValue('children', push({ id: interactiveId })(children))

      setImageDialogOpen(false)
    },
    [editorState, setEditorState, children, setValue],
  )

  const hasInlineImageFlag = useAbilityCheck(FEATURE_FLAG_INLINE_IMAGE)
  const enabledOptions = useMemo(() => {
    return options.filter(({ key }) => {
      // Remove the inline option if the feature flag is not enabled
      return key === 'block' || hasInlineImageFlag
    })
  }, [hasInlineImageFlag])

  return (
    <>
      <DraftMenuButton
        editorState={editorState}
        features={features}
        featureMenuKey="images"
        label="Images"
        onChange={handleMenuSelection}
        options={enabledOptions}
        setEditorState={setEditorState}>
        <Glyph />
      </DraftMenuButton>

      {Boolean(imageDialogOpen) && (
        <ImageSelectionDialog
          doCreateInteractive
          onClose={() => setImageDialogOpen(false)}
          onComplete={handleInsert}
          displayMode={imageDisplayMode}
          uploadVariant="image"
        />
      )}
    </>
  )
}

export default ImageMenuButton
