import useIsPinnedContent from 'hooks/useIsPinnedContent'
import { useContext } from 'react'
import { useDispatch } from 'react-redux'
import {
  type To,
  resolvePath,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import actionTypes from 'reducers/actionTypes'
import { routeTransitionContext } from 'routing/RouteTransition/RouteTransitionProvider'
import { buildSscAwareUrl, getDesiredLocation } from 'selectors/routing'

const useNavigation = () => {
  const existingLocation = useLocation()
  const isOnRightSide = useIsPinnedContent()
  const routerNavigate = useNavigate()

  const dispatch = useDispatch()
  const isInTransitionContext = Boolean(useContext(routeTransitionContext))

  const hrefFromTo = (to: To) => {
    const desiredLocation = getDesiredLocation(to, existingLocation)

    return desiredLocation?.pathname || /* istanbul ignore next */ ''
  }

  type NavigateOptions = {
    replace?: boolean
    skipTransition?: boolean
  }

  const navigate = (to: To, options: NavigateOptions = {}) => {
    const { replace = false, skipTransition } = options

    const url = buildSscAwareUrl({
      existingLocation,
      to,
      isOnRightSide,
    })

    const nextLocation = resolvePath(to)

    const execute = /* istanbul ignore next */ () => {
      routerNavigate(url, { replace })
    }

    /**
     * We don't do the actual route change here, but instead pass it and the
     * execute() wrapper to the routing saga. We also tell the saga whether or not
     * we're in a context where route change animations are being used, so that
     * it will know whether to wait for animations to finish before proceeding.
     * If we skip this step or try to handle it via middleware, then the actions
     * get batched and the animation will never have time to fire.
     */
    dispatch({
      execute,
      nextLocation,
      waitForTransition: isInTransitionContext && !skipTransition,
      type: actionTypes.ROUTE_LOCATION_WILL_CHANGE,
      isRelative: String(hrefFromTo(to)).startsWith('../'),
    })
  }

  return {
    hrefFromTo,
    location: existingLocation,
    navigate,
  }
}

export default useNavigation
