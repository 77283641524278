import { CONTENT_TYPE_SOURCE, CONTENT_TYPE_SUBSECTION } from 'core/consts'
import { equals, get } from 'fp/objects'
import actionTypes from 'reducers/actionTypes'
import { compose } from 'redux'
import { success } from 'sagas/utils'

const sectionCheck = compose(
  equals(CONTENT_TYPE_SUBSECTION),
  get('children.0.contentType'),
)

const preprocessStructuredSources = item => {
  const processItem = c => {
    // sources are treated as sections if they contain subsections
    if (c.contentType === CONTENT_TYPE_SOURCE) {
      c.isSectionLike = sectionCheck(c)
    }

    c.children = c.children?.map(processItem)

    return c
  }

  return processItem(item)
}

const processor = draft => {
  if (draft.type === success(actionTypes.CONTENT_FETCH)) {
    draft.response = preprocessStructuredSources(draft.response)
  }

  if (draft.type === success(actionTypes.CONTENT_FETCH_ASSORTED)) {
    draft.payload.data = draft.payload.data.map(preprocessStructuredSources)
  }

  if (draft.type === success(actionTypes.CONTENT_FETCH_LIST)) {
    draft.response.metadata = preprocessStructuredSources(
      draft.response.metadata,
    )
  }

  return draft
}

export default processor
