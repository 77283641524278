/* istanbul ignore file */

/**
 * TODO:
 * No easy way to unit test this. Perhaps someday if we add E2E tests...
 */

import { useCallback, useEffect, useState } from 'react'

const useSelectionChangeEvent = (callback, onlyWithinRef) => {
  const [selection, setSelection] = useState()
  const [selectionActive, setSelectionActive] = useState(false)

  const handleSelection = useCallback(() => {
    if (selectionActive) {
      setSelection(document.getSelection())
    }
  }, [selectionActive])

  const handleSelectStart = useCallback(() => {
    setSelectionActive(true)
  }, [])

  const handleSelectionEnd = useCallback(() => {
    if (selectionActive) {
      callback(selection)
    }

    setSelectionActive(false)
  }, [callback, selection, selectionActive])

  useEffect(() => {
    const { current } = onlyWithinRef || {}

    if (current) {
      current.addEventListener('selectstart', handleSelectStart)
      current.addEventListener('touchstart', handleSelectStart)
      current.addEventListener('mouseleave', handleSelectionEnd)
      current.addEventListener('touchend', handleSelectionEnd)
    }

    document.addEventListener('selectionchange', handleSelection)
    document.addEventListener('mouseup', handleSelectionEnd)

    return () => {
      if (current) {
        current.removeEventListener('touchstart', handleSelectStart)
        current.removeEventListener('selectstart', handleSelectStart)
        current.removeEventListener('mouseleave', handleSelectionEnd)
        current.removeEventListener('touchend', handleSelectionEnd)
      }

      document.removeEventListener('selectionchange', handleSelection)
      document.removeEventListener('mouseup', handleSelectionEnd)
    }
  }, [handleSelectStart, handleSelection, handleSelectionEnd, onlyWithinRef])
}

export default useSelectionChangeEvent
