/* istanbul ignore file */

import { useTheme } from '@mui/material/styles'

const useColorSet = name => {
  const { palette } = useTheme()

  return palette[name] || {}
}

export default useColorSet
