import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import ColorSwatch, { colorSwatchShape } from './ColorSwatch'

const menuWidth = 210

const Container = styled(MenuList, { name: 'MenuContents-Container' })(
  ({
    theme: {
      mixins: { absWidth, rem },
    },
  }) => ({
    display: 'flex',
    flexFlow: 'row wrap',
    ...absWidth(menuWidth),
    padding: rem(1, 0),
    svg: {
      marginRight: 0,
    },
    li: {
      flexBasis: '100%',
      ...absWidth(menuWidth / 2),
      boxSizing: 'border-box',
      margin: 0,
      padding: rem(0.5, 2),
      '&:hover, &:focus': { background: 'none' },
    },
  }),
)

const MenuContents = ({ colors, handleClose }) => (
  <Container>
    {colors.map(swatch => (
      <MenuItem
        dense
        key={swatch.colorId}
        onClick={() => handleClose(swatch)}>
        <ColorSwatch swatch={swatch} />
      </MenuItem>
    ))}
  </Container>
)

MenuContents.propTypes = {
  colors: PropTypes.arrayOf(colorSwatchShape).isRequired,
  handleClose: PropTypes.func.isRequired,
}

export default MenuContents
