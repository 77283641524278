import LightbulbOutlined from '@mui/icons-material/LightbulbOutlined'
import Icon from '@mui/material/Icon'
import { INTERACTIVE_TYPE_FUN_FACTS } from 'core/consts'
import { EditorState, Modifier } from 'draft-js'
import { pick, renameKeys } from 'fp/objects'
import { curry } from 'fp/utils'
import { useCallback, useState } from 'react'
import { compose } from 'redux'
import DraftMenuButton from '../../toolbar/DraftMenuButton'
import { toolbarButtonPropTypes } from '../../utils/misc'
import ChooseInteractiveDialog from '../interactivePlugin/ChooseInteractiveDialog'

const options = [{ label: 'Select fun fact to insert...', key: 'fun-fact' }]

const FunFactMenuButton = ({ editorState, features, setEditorState }) => {
  const [open, setOpen] = useState(false)

  const handleChange = () => {
    setOpen(true)
  }

  const handleInsert = useCallback(
    data => {
      const contentState = editorState.getCurrentContent()
      const newEntity = contentState.createEntity(
        'FUNFACT',
        'IMMUTABLE',
        compose(
          curry(renameKeys, 2, { id: 'contentId' }),
          pick('id', 'contentType', 'name'),
        )(data),
      )
      const entityKey = contentState.getLastCreatedEntityKey()
      const stateWithText = Modifier.insertText(
        newEntity,
        editorState.getSelection(),
        data.name,
        null,
        entityKey,
      )

      setEditorState(EditorState.push(editorState, stateWithText))
      setOpen(false)
    },
    [editorState, setEditorState],
  )

  return (
    <>
      <DraftMenuButton
        editorState={editorState}
        featureMenuKey="fun-fact"
        features={features}
        label="Fun Facts"
        onChange={handleChange}
        options={options}>
        <Icon>
          <LightbulbOutlined />
        </Icon>
      </DraftMenuButton>

      {Boolean(open) && (
        <ChooseInteractiveDialog
          dense
          disableAutoFocus
          excludeIds={[]}
          maxWidth="sm"
          onClose={() => {
            setOpen(false)
          }}
          onComplete={handleInsert}
          open
          showCloseButton
          subContentTypes={[INTERACTIVE_TYPE_FUN_FACTS]}
          title="Insert Fun Fact"
          variant="maximized-vertically"
        />
      )}
    </>
  )
}
FunFactMenuButton.propTypes = toolbarButtonPropTypes

export default FunFactMenuButton
