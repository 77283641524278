import { deepMerge } from 'fp/objects'
import rightArrow from 'hss/images/nav/arrow-right-small.svg?url'

const overrides = ({
  breakpoints,
  mixins: { importantPx, transition, rem, resetList },
  palette,
  typography: { variants },
}) => {
  const common = {
    display: 'flex',
    flexFlow: 'row wrap',
    columnGap: importantPx(40),
    alignItems: 'stretch',
    padding: 0,
  }

  const horizontal = {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    verticalAlign: 'middle',
    padding: 0,
    listStyle: 'none',
    li: {
      marginTop: 0,
      paddingLeft: 0,
      position: 'relative',
      display: 'block',
      lineHeight: rem(3.5),
    },
  }

  const horizontalExt = deepMerge(horizontal, {
    display: 'inline-flex',
    flexWrap: 'wrap',
    position: 'relative',
    top: -2,
    li: {
      display: 'inline-flex',
      lineHeight: 'inherit',
      '&:not(:last-of-type)': {
        marginRight: rem(0.5),
      },
      width: 'auto',
    },
  })

  const csv = deepMerge(horizontalExt, {
    li: {
      lineHeight: 'inherit !important',
    },
    'li:not(:last-of-type)::after': {
      content: '", "',
      alignSelf: 'end',
    },
  })

  const sourceHeader = {
    color: palette.grey[1],
    display: 'inline-flex',
    flexFlow: 'row wrap',
    justifyContent: 'center',
    width: '100%',
    marginBottom: rem(1.8),
    ...resetList(false, false),
  }

  return {
    MuiList: {
      variants: [
        {
          props: { variant: 'breadcrumbs' },
          style: {
            ...horizontal,
            flexWrap: 'wrap',
            whiteSpace: 'nowrap',
            'li:not(:first-of-type)::before': {
              content: '""',
              padding: rem(0, 1.5),
              backgroundColor: palette.grey[2],
              maskImage: `url(${rightArrow})`,
              position: 'relative',
              top: -3,
              left: 3,
            },
            a: {
              color: palette.text.secondary,
              textDecoration: 'underline',
              textUnderlineOffset: rem(0.4),
              ...variants['nav-item-uppercase-semibold'],
              ...transition(),
              '&:hover': {
                color: palette.cobalt[0],
              },
            },
          },
        },

        {
          props: { variant: 'csv' },
          style: csv,
        },

        {
          props: { variant: 'csv-right' },
          style: deepMerge(csv, { justifyContent: 'flex-end' }),
        },

        {
          props: { variant: 'column-2' },
          style: {
            columnCount: 2,
            padding: 0,
            [breakpoints.down('md')]: {
              columnCount: 1,
            },
          },
        },

        {
          props: { variant: 'grid-2' },
          style: {
            ...common,
            rowGap: importantPx(32),
            display: 'grid',
            margin: 'auto',

            li: {
              columnGap: 16,
              flex: '0 0 calc(50% - (40px))',

              [breakpoints.only('xs')]: {
                flex: '0 0 100%',
              },
            },

            // These apply when within a container query
            '&[data-containersize="xs"] li': { flex: '0 0 100%' },
            '&[data-containersize="sm"] li': { flex: '0 0 100%' },
          },
        },

        {
          props: { variant: 'grid-3' },
          style: {
            ...common,
            rowGap: importantPx(30),
            justifyContent: 'space-between',

            li: {
              // (column gap x 2) / cols
              flex: '0 0 calc(33.333333% - ((40px * 2) / 3))',

              [breakpoints.down('lg')]: {
                flex: '0 0 calc(50% - (40px))',
              },

              [breakpoints.only('xs')]: {
                flex: '0 0 100%',
              },
            },

            // These apply when within a container query
            '&[data-containersize="xs"] li': { flex: '0 0 100%' },
            '&[data-containersize="sm"] li': { flex: '0 0 calc(50% - (40px))' },
          },
        },

        {
          props: { variant: 'horizontal' },
          style: horizontalExt,
        },

        {
          props: { variant: 'grid-2', component: 'ol' },
          style: { counterReset: 'item' },
        },

        {
          props: { variant: 'grid-3', component: 'ol' },
          style: { counterReset: 'item' },
        },

        {
          props: { variant: 'source-header', component: 'ol' },
          style: sourceHeader,
        },

        {
          props: { variant: 'library-source-header' },
          style: {
            ...sourceHeader,
            justifyContent: 'flex-start',
            marginTop: rem(1),
            paddingLeft: 'var(--containedPaddingLeft)',
          },
        },

        {
          props: { variant: 'standard', component: 'ul' },
          style: {
            listStyle: 'initial',
            listStyleType: 'circle',
            li: { display: 'list-item' },
          },
        },

        {
          props: { variant: 'standard', component: 'ol' },
          style: {
            listStyle: 'decimal',
            li: { display: 'list-item' },
          },
        },
      ],
    },
  }
}

export default overrides
