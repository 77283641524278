import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $insertNodeToNearestRoot, mergeRegister } from '@lexical/utils'
import { COMMAND_PRIORITY_EDITOR, createCommand } from 'lexical'
import { useEffect } from 'react'
import {
  $createInteractiveNode,
  InteractiveNode,
} from '../nodes/InteractiveNode'

export const INSERT_INTERACTIVE_COMMAND = createCommand(
  'INSERT_INTERACTIVE_COMMAND',
)

const InteractivePlugin = () => {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    if (!editor.hasNodes([InteractiveNode])) {
      throw new Error(
        'InteractivePlugin: InteractiveNode not registered on editor',
      )
    }

    return mergeRegister(
      editor.registerCommand(
        INSERT_INTERACTIVE_COMMAND,
        payload => {
          const newNode = $createInteractiveNode(payload)
          $insertNodeToNearestRoot(newNode)

          return true
        },
        COMMAND_PRIORITY_EDITOR,
      ),
    )
  }, [editor])

  return null
}

export default InteractivePlugin
