/* istanbul ignore file */
import { selectAll } from '@studysync/draft-js-modifiers'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import DraftToolButton from './DraftToolButton'

const SelectAllButton = ({ editorState, setEditorState, ...rest }) => {
  const handleClick = () => {
    compose(setEditorState, selectAll)(editorState)
  }

  return (
    <DraftToolButton
      onClick={handleClick}
      {...rest}
    />
  )
}

SelectAllButton.propTypes = {
  editorState: PropTypes.object,
  setEditorState: PropTypes.func.isRequired,
}

export default SelectAllButton
