import ErrorBoundary from 'common/errorHandling/ErrorBoundary'
import { ABILITY_ASSIGNMENT_CREATION } from 'core/consts'
import { EditExisting, EditNew } from 'hss/Assessments/Editor/Edit'
import { Routes } from 'react-router-dom'
import { configToRoutes } from 'routing/TraverseRoute'
import Route404 from '../Route404'

const config = {
  assessmentCreate: {
    abilityFlags: ABILITY_ASSIGNMENT_CREATION,
    element: <EditNew />,
    path: 'creator/:contentId',
  },

  assessmentEdit: {
    abilityFlags: ABILITY_ASSIGNMENT_CREATION,
    element: <EditExisting />,
    path: 'editor/:assignmentId',
  },

  404: {
    path: '*',
    element: <Route404 collectionName="Assessment" />,
  },
}

const routes = configToRoutes(config)

const AssessmentRoutes = () => (
  <ErrorBoundary moduleName="AssessmentRoutes">
    <Routes>{routes}</Routes>
  </ErrorBoundary>
)

export default AssessmentRoutes
