import PropTypes from 'prop-types'
import { useSwipeable } from 'react-swipeable'
import SlideContainer from './SlideContainer'

const TouchSlide = props => {
  const { index, setCurrentSlide, ...rest } = props

  const eventHandlers = useSwipeable({
    onSwiped: ({ dir }) => {
      setCurrentSlide(dir === 'Left' ? index + 1 : index - 1)
    },
    trackMouse: true,
  })

  return (
    <SlideContainer
      onClick={() => {
        setCurrentSlide(index + 1)
      }}
      {...rest}
      {...eventHandlers}
    />
  )
}

TouchSlide.propTypes = {
  index: PropTypes.number.isRequired,
  setCurrentSlide: PropTypes.func.isRequired,
}

export default TouchSlide
