import { withOptions } from '@comfy/redux-selectors'
import { get } from 'fp/objects'
import { fallbackTo } from 'fp/utils'
import { compose } from 'redux'
import { createSelector, stateReactions } from '.'
import { omitReduxMetadata } from './utils'

export const getReactionsForContent = withOptions(({ interactionId }) =>
  createSelector('getReactionsForContent')(
    stateReactions,
    compose(fallbackTo({}), get(interactionId), omitReduxMetadata),
  ),
)
