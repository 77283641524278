import Button from '@mui/material/Button'
import { useContext } from 'react'
import { context } from './context'

const EnableIntegrationsButton = () => {
  const { authorize, isWorking, status, wantClassroomOn, wantDriveOn } =
    useContext(context)

  return status === 'ADD_SCOPES' ? (
    <Button
      disabled={isWorking || !(wantDriveOn || wantClassroomOn)}
      fullWidth
      onClick={authorize}
      variant="secondary">
      Enable Google Integrations
    </Button>
  ) : null
}

export default EnableIntegrationsButton
