import {
  reject,
  resolve,
} from 'common/formControls/selects/AssetUploader/FileUploader/utils'
import type { AssetUploadedProps } from 'common/formControls/textInputs/RichTextEdit2/nodes/ImageNode/@types'
import { whenPresent } from 'fp/utils'
import actionTypes from 'reducers/actionTypes'
import type { Action } from 'redux'
import useReduxCallback from './useReduxCallback'

interface UploadAction extends Action {
  response: AssetUploadedProps
}

interface UseFileUploadProps {
  onUploadSuccess?: (response: AssetUploadedProps) => void
}

// Type guard to check if an action is an UploadAction
const isUploadAction = (action: Action): action is UploadAction => {
  return (action as UploadAction).response !== undefined
}

const useFileUpload = ({ onUploadSuccess }: UseFileUploadProps) => {
  const onSuccess = (action: Action) => {
    // TODO: create success action type ?
    if (isUploadAction(action)) {
      const { response } = action
      whenPresent(onUploadSuccess, response)
    }
  }

  const [startUpload, status] = useReduxCallback({
    actionType: actionTypes.UPLOAD_REQUEST,
    onSuccess,
    reject,
    resolve,
  })

  return { startUpload, status }
}

export default useFileUpload
