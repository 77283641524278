import {
  componentShape,
  hotspotCalloutShape,
  imageClickerMessageShape,
  imageTitleDragCalloutShape,
  uploadShape,
} from 'core/shapes'
import { set } from 'fp/objects'
import { matches } from 'fp/utils'
import useComponentSize from 'hooks/useComponentSize'
import PropTypes from 'prop-types'
import { useCallback, useRef } from 'react'
import { useDrop } from 'react-dnd'
import { compose } from 'redux'
import ImageContainer from './ImageContainer'

const coordFields = {
  'CALLOUT-ARROW': 'calloutCoord',
  'CALLOUT-LABEL': 'labelCoord',
  MESSAGE: 'coord',
}
const Backdrop = props => {
  const {
    ItemRenderer,
    darkBackdropImage,
    dropTargets,
    items,
    replaceItem,
    upload,
  } = props
  const imageRef = useRef()
  const { height, width } = useComponentSize(imageRef)

  const moveBox = useCallback(
    ({ id, type }, x, y) => {
      const matcher = matches('id', id)
      const idx = items.findIndex(matcher)
      const coordField = coordFields[type]
      replaceItem(idx, set(coordField, `${x},${y}`)(items[idx]))
    },
    [items, replaceItem],
  )

  const [, drop] = useDrop(
    () => ({
      accept: dropTargets, // ['CALLOUT-ARROW', 'CALLOUT-LABEL'],
      drop(item, monitor) {
        const delta = monitor.getDifferenceFromInitialOffset()

        const x = compose(
          // Math.round,
          val => val + item.x,
          val => (val / width) * 100.0,
        )(delta.x)

        const y = compose(
          // Math.round,
          val => val + item.y,
          val => (val / height) * 100.0,
        )(delta.y)
        moveBox(item, x, y)
        return undefined
      },
    }),
    [moveBox, height],
  )

  return (
    <ImageContainer ref={imageRef}>
      <img
        alt="The background that you place your callouts onto"
        ref={drop}
        src={upload.url}
      />
      {items.map((item, idx) => (
        <ItemRenderer
          darkBackdropImage={darkBackdropImage}
          idx={idx}
          item={item}
          key={item.id}
          parentHeight={height}
          parentWidth={width}
        />
      ))}
    </ImageContainer>
  )
}

Backdrop.propTypes = {
  ItemRenderer: componentShape.isRequired,
  darkBackdropImage: PropTypes.bool.isRequired,
  dropTargets: PropTypes.arrayOf(PropTypes.string).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([
      hotspotCalloutShape,
      imageClickerMessageShape,
      imageTitleDragCalloutShape,
    ]),
  ).isRequired,
  replaceItem: PropTypes.func.isRequired,
  upload: uploadShape.isRequired,
}

export default Backdrop
