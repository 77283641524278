import { withPropBags } from 'common/formControls/Form/withPropBags'
import type { AssetUploadedProps } from 'common/formControls/textInputs/RichTextEdit2/nodes/ImageNode/@types'
import { get } from 'fp/objects'
import { isEmptyString } from 'fp/strings'
import { useFormContext, useWatch } from 'react-hook-form'
import type { VariantLinkage } from '.'
import AssetSelector, { type AssetSelectorProps } from './AssetSelector'

interface HFAssetSelectorProps extends AssetSelectorProps {
  name: string // required
  variantLinkage?: VariantLinkage[]
}

const HFAssetSelector: React.FC<HFAssetSelectorProps> = props => {
  const {
    componentProps: { helperText, label, name, value, required, onChange },
    controlProps: { disabled },
    rest: {
      assetCode,
      assetCodeFieldName = 'assetCode',
      headlineHelperText,
      labelProps,
      uploadType,
      onClear,
      onCantoFields,
      uploadVariant,
      variantLinkage,
    },
  } = props

  const hfAssetCode = useWatch({ name: assetCodeFieldName })

  const { setValue } = useFormContext()

  const linkedFieldNames = (variantLinkage || []).map(get('formField'))

  const [...valuesForGenerated] = useWatch({ name: linkedFieldNames })

  const handleComplete = (upload: AssetUploadedProps) => {
    if (upload && variantLinkage?.length && linkedFieldNames.length) {
      variantLinkage.forEach(
        ({ formField, variant }: VariantLinkage, i: number) => {
          const currentValue = valuesForGenerated[i]
          const nextValue = upload.generated?.[variant]
          if (isEmptyString(currentValue || '') && nextValue) {
            setValue(formField, nextValue, {
              shouldDirty: true,
              shouldTouch: true,
            })
          }
        },
      )
    } else {
      setValue(name, upload)
    }

    onChange?.({ target: { value: upload } })
  }

  const handleClear = () => {
    setValue(name, null)
    onClear?.()
  }

  return (
    <AssetSelector
      {...{
        assetCode: assetCode || hfAssetCode,
        disabled,
        headlineHelperText,
        helperText,
        label,
        labelProps,
        name,
        onCantoFields,
        onUpload: onChange,
        onClear: handleClear,
        onComplete: handleComplete,
        required,
        uploadVariant,
        uploadType,
        assetValue: value,
      }}
    />
  )
}

const WiredHFAssetSelector = withPropBags(['disabled', 'onBlur', 'onFocus'])(
  HFAssetSelector,
)

export default WiredHFAssetSelector
