import Box from '@mui/material/Box'
import NavLink from 'common/navigation/links/NavLink'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getUserAssignmentIsSubmittable } from 'selectors/userAssignments'
import Progress from './Progress'
import SectionTree from './SectionTree'

const Learn = props => {
  const { userAssignmentId } = useParams()
  const isSubmittable = useSelector(
    getUserAssignmentIsSubmittable({ userAssignmentId }),
  )

  return (
    <>
      <Progress />

      <SectionTree {...props} />

      {Boolean(userAssignmentId && isSubmittable) && (
        <Box
          ml="26px"
          my={4}>
          <NavLink to="submit">Submit Assignment</NavLink>
        </Box>
      )}
    </>
  )
}

export default Learn
