import { useSelector } from 'react-redux'
import { createAbilityChecker } from 'selectors/userAbility'
import type * as shared from 'shared/consts'

type AbilityFlag =
  | typeof shared.ABILITY_ADMINISTRATION_INTERFACE
  | typeof shared.ABILITY_ASSESSMENT_INTERFACE
  | typeof shared.ABILITY_ASSIGNMENT_CREATION
  | typeof shared.ABILITY_CONTENT_CREATION
  | typeof shared.ABILITY_CONTENT_EDITING
  | typeof shared.ABILITY_CONTENT_RESTRICTION
  | typeof shared.ABILITY_CURRICULUM_ACCESS
  | typeof shared.ABILITY_DISTRICT_ADMINISTRATION
  | typeof shared.ABILITY_LIBRARY_ACCESS
  | typeof shared.ABILITY_PREVIEW_AS_STUDENT
  | typeof shared.ABILITY_RECEIVE_USER_ALERTS
  | typeof shared.ABILITY_STUDENT_INTERFACE
  | typeof shared.ABILITY_TEACHER_INTERFACE

type AbilityChecker = (flags: AbilityFlag | AbilityFlag[]) => boolean

const useAbilityChecker = (): AbilityChecker =>
  useSelector(createAbilityChecker)

export type { AbilityChecker }

export default useAbilityChecker
