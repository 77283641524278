import FormControl, { type FormControlProps } from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import InputLabel from '@mui/material/InputLabel'
import MuiSelect, {
  type SelectProps as MuiSelectProps,
} from '@mui/material/Select'
import visuallyHidden from '@mui/utils/visuallyHidden'
import { omit, pick } from 'fp/objects'
import { isEmptyString } from 'fp/strings'
import { type ForwardedRef, forwardRef, useId } from 'react'

const controlFields = ['margin']

interface SelectProps extends Omit<MuiSelectProps, 'label'> {
  controlProps?: Partial<FormControlProps>
  helperText?: string
  hideLabel?: boolean
  id?: string
  label?: string
  name: string
}

const Select = forwardRef(
  (props: SelectProps, inputRef: ForwardedRef<HTMLSelectElement>) => {
    const {
      children,
      controlProps,
      helperText,
      hideLabel = false,
      id,
      label,
      name,
      ...rest
    } = props

    const generatedId = useId()
    const controlId = id || generatedId
    const labelId = `${controlId}-label`

    return (
      <FormControl
        {...{
          ...pick(controlFields)(rest),
          ...controlProps,
        }}>
        <InputLabel
          id={labelId}
          sx={hideLabel ? visuallyHidden : {}}>
          {label || name}
        </InputLabel>

        <MuiSelect
          {...{
            autoWidth: true,
            id: controlId,
            name,
            inputRef,
            labelId,
            ...omit(controlFields)(rest),
          }}>
          {children}
        </MuiSelect>

        {isEmptyString(helperText) ? null : (
          <FormHelperText>{helperText}</FormHelperText>
        )}
      </FormControl>
    )
  },
)

export default Select
