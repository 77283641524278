import Backdrop from '@mui/material/Backdrop'
import { styled } from '@mui/material/styles'
import { createPortal } from 'react-dom'
import { isTestEnv } from 'selectors/index'
import PageLoader from './PageLoader'

const BusyIndicator = styled(
  props => (
    <Backdrop
      data-testid="AppBusy"
      open
      {...props}>
      <div
        style={{
          margin: 'auto',
          display: 'flex',
          height: '100vh',
          width: '100vh',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <PageLoader />
      </div>
    </Backdrop>
  ),
  { name: 'indicators-AppBusy' },
)(({ theme: { palette, zIndex } }) => ({
  color: palette.common.white,
  zIndex: zIndex.drawer + 2,
}))

const AppBusy = props => {
  const target = document.querySelector('#tooltip-aria-portal')

  return isTestEnv() ? (
    <BusyIndicator {...props} />
  ) : (
    createPortal(<BusyIndicator {...props} />, target)
  )
}

export default AppBusy
