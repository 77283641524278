import LinearProgress from '@mui/material/LinearProgress'
import { useSelector } from 'react-redux'
import {
  getPercentageOfContentCompleted,
  getViewerTopLevelContent,
  includeProgress,
} from 'selectors/contentViewer'

const Progress = () => {
  const relevant = useSelector(includeProgress)

  const content = useSelector(getViewerTopLevelContent)
  const value = useSelector(
    getPercentageOfContentCompleted({ contentId: content?.id }),
  )

  return relevant ? (
    <LinearProgress
      data-subvariant="nav"
      value={Math.ceil(value)}
      variant="determinate"
    />
  ) : null
}

export default Progress
