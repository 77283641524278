import PropTypes from 'prop-types'
/* istanbul ignore file */
import { useEffect, useMemo } from 'react'

import { componentShape } from 'core/shapes'
import { Route, useLocation, useNavigate } from 'react-router-dom'
import PermissableRoute from './PermissableRoute'
import PinnableRoute from './PinnableRoute'

const InternalRouteRenderer = ({
  element,
  matchOptions = {},
  pinnable = false,
  pinnableChildren = false,
  protect = true,
  ...rest
}) => {
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    /**
     * If we're going to a non-pinnable route and we have pinned info in the url,
     * then remove that info from the url.  This is to prevent suddenly re-enabling
     * pinned mode the next time the user visits a pinnable route.
     */
    const searchParams = new URLSearchParams(location.search)
    if (!(pinnable || pinnableChildren) && searchParams.has('ssc')) {
      searchParams.delete('ssc')
      navigate(`${location.pathname}?${searchParams.toString()}`, {
        replace: true,
      })
    }
  }, [location.pathname, location.search, navigate, pinnable, pinnableChildren])

  const RouteComponent = useMemo(
    () => (pinnable ? PinnableRoute : protect ? PermissableRoute : null),
    [pinnable, protect],
  )

  if (RouteComponent) {
    return (
      <RouteComponent
        element={element}
        {...matchOptions}
        {...rest}
      />
    )
  }

  return element
}

InternalRouteRenderer.propTypes = {
  element: componentShape,
  matchOptions: PropTypes.object,
  pinnable: PropTypes.bool,
  pinnableChildren: PropTypes.bool,
  protect: PropTypes.bool,
}

const createTraverseRoute = ({
  additionalProps,
  children = [],
  index = false,
  path,
  ...rest
}) => (
  <Route
    key={path || ''}
    {...{
      index,
      path,
    }}
    element={
      <InternalRouteRenderer
        {...additionalProps}
        {...rest}
      />
    }>
    {children.map(childRoute =>
      createTraverseRoute({ additionalProps, ...childRoute }),
    )}
  </Route>
)

/**
 * Always use this in place of useRoutes() or <Route />, *IF* any of the routes
 * in your config either:
 *
 *  a) Are restricted by a feature flag or permissions
 *
 *  b) Could be pinnable
 *
 * Note that this really only applies to leaves in the route tree.  A shell for
 * example can use plain vanilla <Route /> since it will have other route configs
 * as its leaves, perhaps multiple branches deep.  Strictly speaking, only the
 * final, end-of-the-line route need be a <TraverseRoute />.
 *
 * That said, any portions of the screen that are contained outside of a TraverseRoute
 * that the user does not have permission to visit would still be visible. Things
 * like sidebars or banners for example.  In some cases this is desireable.
 *
 */

export const configToRoutes = (config, additionalProps) =>
  Object.values(config)
    .filter(Boolean)
    .map(props => createTraverseRoute({ additionalProps, ...props }))
