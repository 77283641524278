import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import Checkbox from 'common/formControls/switches/Checkbox'
import TextField from 'common/formControls/textInputs/TextField'
import { assertRange } from 'fp/numbers'
import { set } from 'fp/objects'
import PropTypes from 'prop-types'
import { useState } from 'react'

const InsertTableDropdown = ({ onChange }) => {
  const [state, setState] = useState({
    rows: 5,
    cols: 2,
    rowHeaders: true,
    colHeaders: false,
  })

  const handleInsert = () => {
    onChange(state)
  }

  const handleSizeChange =
    key =>
    ({ target }) => {
      setState(set(key, assertRange(target.value, 1, 100)))
    }

  const handleBoolChange =
    key =>
    ({ target }) => {
      setState(set(key, target.checked))
    }

  return (
    <Box
      maxWidth={250}
      padding={2}>
      <TextField
        label="Rows"
        min={1}
        name="rows"
        onChange={handleSizeChange('rows')}
        type="number"
        value={state.rows}
      />

      <TextField
        label="Columns"
        min={1}
        name="cols"
        onChange={handleSizeChange('cols')}
        type="number"
        value={state.cols}
      />

      <FormControl>
        <Checkbox
          checked={state.rowHeaders}
          label="First row is header"
          name="rowHeaders"
          onChange={handleBoolChange('rowHeaders')}
        />
      </FormControl>

      <FormControl>
        <Checkbox
          checked={state.colHeaders}
          label="First column is header"
          name="colHeaders"
          onChange={handleBoolChange('colHeaders')}
        />
      </FormControl>

      <Button
        onClick={handleInsert}
        sx={{ width: '100%', mt: 4 }}
        variant="primary">
        Insert
      </Button>
    </Box>
  )
}

InsertTableDropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
}

export default InsertTableDropdown
