import MenuItem from '@mui/material/MenuItem'
import { LANGUAGES } from 'core/consts'
import Select from './Select'

const LanguagePicker = props => (
  <Select
    name="languageId"
    {...props}>
    {LANGUAGES.map(item => (
      <MenuItem
        key={item.id}
        value={item.id}>
        {item.name}
      </MenuItem>
    ))}
  </Select>
)

export default LanguagePicker
