import Headline from 'common/text/Headline'
import { componentShape } from 'core/shapes'
import { omit } from 'fp/objects'
import { isEmptyString } from 'fp/strings'
import withProps from 'hoc/withProps'
import hash from 'object-hash'
import PropTypes from 'prop-types'
import {
  Children,
  cloneElement,
  isValidElement,
  useEffect,
  useState,
} from 'react'
import { propBagsShape, withPropBags } from '../Form/withPropBags'
import {
  hasCantoDirective,
  unwrapCantoDirective,
} from '../selects/AssetUploader/utils'
import DebouncedRichTextEdit from './RichTextEdit/DebouncedRichTextEdit'

const Wrapper = ({ children, label, labelProps, required = false }) =>
  labelProps?.headline ? (
    <Headline
      {...omit('headline')(labelProps)}
      title={label + (required ? ' *' : '')}>
      {children}
    </Headline>
  ) : (
    Children.map(children, child =>
      isValidElement(child) ? cloneElement(child, { label, required }) : child,
    )
  )

Wrapper.propTypes = {
  children: componentShape.isRequired,
  label: componentShape,
  labelProps: PropTypes.object,
  required: PropTypes.bool,
}

const HFRichTextEdit = withPropBags(
  ['disabled', 'inputRef', 'onBlur', 'onFocus', 'readOnly'],
  {
    preventCantoDirectiveCleaning: true,
  },
)(props => {
  const {
    componentProps,
    controlProps,
    rest: { labelProps, ...rest },
  } = props
  const {
    error,
    helperText,
    label,
    name,
    onChange,
    placeholder,
    required,
    value,
  } = componentProps
  const { disabled } = controlProps

  const [contentKey, setContentKey] = useState(name)

  useEffect(() => {
    if (!isEmptyString(value) && hasCantoDirective(value)) {
      /**
       * We're changing contentKey here solely to respond to imported Canto fields
       * having changed.
       *
       * There is a major downside to changing the contentKey whenever the value
       * changes.  It causes the RTE to encode and decode the content to html
       * which not only is wasteful, it also causes the caret to jump to the bottom.
       *
       * So we'll only do it when we know the change came from the Canto workflow.
       */
      setContentKey(hash({ value }))
    }
  }, [value])

  return (
    <Wrapper {...{ label, labelProps, name, required }}>
      <DebouncedRichTextEdit
        {...{
          disabled,
          error,
          helperText,
          InputProps: controlProps,
          name,
          onChange,
          placeholder,
          value: unwrapCantoDirective(value),
          contentKey,
          ...rest,
        }}
      />
    </Wrapper>
  )
})

HFRichTextEdit.propTypes = propBagsShape.isRequired

export const HFRichTextEditLite = withProps(HFRichTextEdit, {
  excludeToolButtons: [
    'Format',
    'Highlight',
    'Indentation',
    'Link',
    'Lists',
    'Strikethrough',
  ],
})

export default HFRichTextEdit
