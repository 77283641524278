import type {
  InlineImageSize,
  InlineImageVerticalAlign,
} from 'common/indicators/InlineImage'
import type { AssetUpload } from '../@types'
import type {
  ImageDisplayMode,
  UploadStep,
} from 'common/formControls/textInputs/RichTextEdit/plugins/imagePlugin/ImageSelectionProvider'
import { Box, Typography } from '@mui/material'
import InlineImage from 'common/indicators/InlineImage'

interface SampleProps extends React.ComponentPropsWithoutRef<'div'> {
  displayMode: ImageDisplayMode
  size: InlineImageSize
  upload: AssetUpload
  uploadStep: UploadStep
  valign: InlineImageVerticalAlign
}

const Sample: React.FC<SampleProps> = props => {
  const { displayMode, size, upload, uploadStep, valign } = props

  return displayMode === 'block' || uploadStep !== 'annotate' ? (
    <img
      src={upload?.url}
      alt="Selected"
      style={{ maxWidth: '50%', objectFit: 'cover', height: '100%' }}
    />
  ) : (
    <Box
      ml={4}
      maxWidth="40%">
      <Typography
        variant="eyebrow"
        component="p">
        Sample
      </Typography>

      <p>
        <InlineImage {...{ src: upload?.url, size, valign }} />
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Hic, nisi
        repellendus blanditiis quam exercitationem dicta
        <InlineImage {...{ src: upload?.url, size, valign }} /> vitae tempora
        optio architecto cum officia impedit.
      </p>
    </Box>
  )
}

export default Sample
