import { componentShape, entityIdShape } from 'core/shapes'
import { percent } from 'fp/strings'
import PropTypes from 'prop-types'
import { useDrag } from 'react-dnd'

const DraggableItem = ({ coordinates, itemId, type, Renderer, ...rest }) => {
  const [x, y] = coordinates.split(',').map(Number.parseFloat)
  const style = {
    position: 'absolute',
    top: percent(y),
    left: percent(x),
  }

  const [{ isDragging }, boxRef] = useDrag(
    () => ({
      type,
      item: { id: itemId, x, y, type },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [itemId, x, y],
  )

  return isDragging ? (
    <div ref={boxRef} />
  ) : (
    <Renderer
      ref={boxRef}
      style={style}
      {...rest}
    />
  )
}

DraggableItem.propTypes = {
  coordinates: PropTypes.string.isRequired,
  itemId: entityIdShape.isRequired,
  type: PropTypes.string.isRequired,
  Renderer: componentShape.isRequired,
}

export default DraggableItem
