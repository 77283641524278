import Slide from '@mui/material/Slide'
import { forwardRef } from 'react'

const Transition = /* istanbul ignore next */ forwardRef((props, ref) => (
  <Slide
    direction="down"
    ref={ref}
    {...props}
    timeout={{ enter: 300, exit: 300 }}
  />
))

export default Transition
