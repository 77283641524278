import ErrorBoundary from 'common/errorHandling/ErrorBoundary'
import { ABILITY_SELF_CONTENT_AUTHORING } from 'core/consts'
import { mapValues, set } from 'fp/objects'
import BlockEditor from 'hss/ContentAuthor/BlockEditor'
/* istanbul ignore file */
import { Routes } from 'react-router-dom'
import Route404 from 'routing/Route404'
import { configToRoutes } from 'routing/TraverseRoute'

const config = mapValues(set('abilityFlags', [ABILITY_SELF_CONTENT_AUTHORING]))(
  {
    /** ***************************** CONTENT ********************************* */
    // TODO: some items go under library search, the others do not
    create1: {
      path: 'create/:contentType',
      element: <BlockEditor creating />,
    },

    create2: {
      path: 'create/:contentType/type/:contentSubType',
      element: <BlockEditor creating />,
    },

    edit1: {
      path: 'edit/:contentType/:contentId',
      element: <BlockEditor editing />,
    },

    edit2: {
      path: 'edit/:contentType/type/:contentSubType/:contentId',
      element: <BlockEditor editing />,
    },

    404: {
      path: '*',
      element: <Route404 collectionName="ContentAuthor" />,
    },
  },
)

const routes = configToRoutes(config)

const ContentAuthorRoutes = () => (
  <ErrorBoundary moduleName="ContentAuthorRoutes">
    <Routes>{routes}</Routes>
  </ErrorBoundary>
)

export default ContentAuthorRoutes
