const overrides = () => ({
  MuiTabPanel: {
    variants: [
      {
        props: { variant: 'condensed' },
        style: {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    ],
  },
})

export default overrides
