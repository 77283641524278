import { compose } from 'redux'
import { get } from './objects'

const isFunction = f => f instanceof Function

// export const allPass =
//   (...args) =>
//   item =>
//     args.every(fn => fn(item))

export const callOwn = pathToFunc => callOwnWith(pathToFunc)()

export const callOwnWith =
  pathToFunc =>
  (...args) =>
  target =>
    compose(callWith(...args), fn => fn?.bind(target), get(pathToFunc))(target)

export const callWith =
  (...args) =>
  fn =>
    fn?.apply(this, args)

export const distill = (fn, ...args) =>
  isFunction(fn) ? fn.apply(this, args) : fn

// export const somePass =
//   (...args) =>
//   item =>
//     args.some(fn => fn(item))
