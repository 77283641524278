import { buildUrl } from 'fp/internet'
import { isEmptyString } from 'fp/strings'
import actionTypes from 'reducers/actionTypes'
import { actions } from 'reducers/notifications'
import { restEndpoint } from 'reducers/utils'
import { call, put, takeEvery, takeLeading } from 'redux-saga/effects'
import { dangerouslyCallApi } from './api'
import { success } from './utils'

function* handleFetch(action) {
  const { schoolId } = action

  yield call(dangerouslyCallApi, {
    action,
    url: buildUrl(`${restEndpoint.schools}/${schoolId}`),
  })
}

function* handleFetchList(action) {
  const { queryParams } = action
  yield call(dangerouslyCallApi, {
    action,
    url: buildUrl(restEndpoint.schools, queryParams, false),
  })
}

function* handleSave(action) {
  const {
    payload: { id, ...school },
  } = action
  const editing = !isEmptyString(id)

  yield call(dangerouslyCallApi, {
    action,
    options: {
      method: editing ? 'PATCH' : 'POST',
      body: {
        ...school,
        id,
      },
    },
    passThrough: {
      editing,
    },
    url: editing ? `${restEndpoint.schools}/${id}` : restEndpoint.schools,
  })
}

export function* handleSaveSuccess({ passThrough: { editing } }) {
  yield put(
    actions.addAlert({
      message: editing ? 'School updated' : 'School created',
    }),
  )
}

/* istanbul ignore next line */
function* schoolSaga() {
  yield takeEvery(actionTypes.SCHOOL_FETCH, handleFetch)
  yield takeEvery(actionTypes.SCHOOL_FETCH_LIST, handleFetchList)
  yield takeLeading(actionTypes.SCHOOL_SAVE, handleSave)
  yield takeLeading(success(actionTypes.SCHOOL_SAVE), handleSaveSuccess)
}

export default schoolSaga
