import Slide from '@mui/material/Slide'
import PropTypes from 'prop-types'

const withTransitionSlide = WrappedComponent => {
  // For documentation on props, see https://mui.com/material-ui/api/slide/
  const Enhanced = ({
    appear = false,
    containerRef,
    direction = 'right',
    shouldSlideIn = false,
    timeout = 500,
    ...rest
  }) => (
    <Slide
      appear={appear}
      data-testid={`[direction: ${direction}, timeout: ${timeout}]`}
      direction={direction}
      in={shouldSlideIn}
      ref={containerRef?.current}
      timeout={timeout}>
      <div>
        <WrappedComponent {...rest} />
      </div>
    </Slide>
  )

  Enhanced.propTypes = {
    appear: PropTypes.bool,
    containerRef: PropTypes.object.isRequired,
    direction: PropTypes.oneOf(['down', 'left', 'right', 'up']),
    shouldSlideIn: PropTypes.bool,
    timeout: PropTypes.number,
  }

  return Enhanced
}

withTransitionSlide.propTypes = {
  WrappedComponent: PropTypes.element.isRequired,
}

export default withTransitionSlide
