import { a11yContext } from 'core/a11y'
import { useContext } from 'react'
import Instructions from './Instructions'

const KeyboardInstructions = props => {
  const { tabModeEnabled } = useContext(a11yContext) || {}
  return (
    tabModeEnabled && (
      <Instructions
        fontSize={['2.5rem']}
        {...props}
      />
    )
  )
}

export default KeyboardInstructions
