import { Button } from '@mui/material'
import { useImageSelectionContext } from './ImageSelectionProvider'

const DialogActionsRenderer = () => {
  const {
    displayMode,
    searchWasMade,
    setUpload,
    setUploadStep,
    uploadStep,
    uploadWasMade,
  } = useImageSelectionContext()

  const back = uploadWasMade || (searchWasMade && displayMode === 'inline')

  const complete =
    (uploadWasMade &&
      (uploadStep === 'annotate' || displayMode === 'bare-upload')) ||
    (displayMode === 'inline' && uploadStep === 'annotate')

  return (
    <>
      {back && (
        <Button
          onClick={() => setUpload(undefined)}
          size="small"
          sx={{ textTransform: 'none' }}
          variant="secondary">
          Previous Step
        </Button>
      )}

      {uploadWasMade &&
        uploadStep !== 'annotate' &&
        displayMode !== 'bare-upload' && (
          <Button
            size="small"
            variant="primary"
            onClick={() => setUploadStep('annotate')}>
            Continue
          </Button>
        )}

      {complete && (
        <Button
          size="small"
          variant="primary"
          onClick={() => setUploadStep('complete')}>
          Complete
        </Button>
      )}
    </>
  )
}

export default DialogActionsRenderer
