import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { findObj } from 'fp/arrays'
import { callWith } from 'fp/call'
import { get } from 'fp/objects'
import { curry } from 'fp/utils'
import {
  $getSelection,
  $isRangeSelection,
  FORMAT_TEXT_COMMAND,
  type TextFormatType,
} from 'lexical'
import { Type as Icon } from 'react-feather'
import { compose } from 'redux'
import type { ToolMenuItem } from '../@types/custom'
import { $isExtendedTextNode } from '../nodes/ExtendedTextNode'
import { INSERT_SOURCE_TITLE_COMMAND } from './SourceTitlePlugin'
import { INSERT_STANDALONE_QUESTION_COMMAND } from './StandaloneQuestionPlugin'
import ToolMenuButton from './ToolbarPlugin/ToolMenuButton'

const options: ToolMenuItem[] = [
  {
    key: 'paragraph',
    label: 'Body Text',
  },
  {
    key: 'source-title',
    label: 'Source Title',
    command: INSERT_SOURCE_TITLE_COMMAND,
  },
  {
    key: 'heading',
    label: 'Content Heading',
  },
  {
    key: 'sub-heading',
    label: 'Sub-heading',
  },
  {
    key: 'question',
    label: 'Standalone Question',
    command: INSERT_STANDALONE_QUESTION_COMMAND,
  },
  {
    key: 'definition',
    label: 'Definition',
  },
  {
    key: 'drop-cap',
    label: 'Drop cap',
  },
  {
    key: 'block-quote',
    label: 'Block Quote',
  },
  {
    key: 'pull-quote',
    label: 'Pull Quote',
  },
  {
    key: 'credit',
    label: 'Credit',
  },
]

const itemCommand = compose(
  get('command'),
  callWith(options),
  curry(findObj, 2, 'value'),
)

const ToolbarButton = () => {
  const [editor] = useLexicalComposerContext()

  const handleItemClicked = (event: React.MouseEvent<HTMLLIElement>) => {
    const value = event.currentTarget.getAttribute('value')
    if (!value) return

    const command = itemCommand(value)

    if (command) {
      editor.dispatchCommand(command, {})
      return
    }

    editor.update(() => {
      const selection = $getSelection()
      if ($isRangeSelection(selection)) {
        const nodes = selection.getNodes()
        nodes.forEach(node => {
          if (node && $isExtendedTextNode(node)) {
            // console.log('yes')

            node.setCustomStyleType(value)
          }
        })
        // console.log(value, nodes)
        // const format: TextFormatType
        editor.dispatchCommand(FORMAT_TEXT_COMMAND, value as TextFormatType)
      }
    })
  }

  return (
    <ToolMenuButton
      featureMenuKey="text-style"
      label="Text Style"
      options={options}>
      <Icon />
      {/* {entries.map(entry => (
        <MenuItem
          key={entry.value}
          onClick={handleItemClicked}
          value={entry.value}
        >
          {entry.label}
        </MenuItem>
      ))} */}
    </ToolMenuButton>
  )
}

export default ToolbarButton
