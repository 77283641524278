import withHookForm from '../Form/withHookForm'
import BasicList from './BasicList'

const withList = (Component, nonHookForm = false) => {
  const List = props => (
    <BasicList {...{ ...props, nonHookForm }}>
      {listProps => (
        <Component
          {...{
            ...listProps,
            children: props.children,
            nonHookForm,
          }}
        />
      )}
    </BasicList>
  )

  return nonHookForm ? List : withHookForm(List)
}

export default withList
