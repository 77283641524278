import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useContext, useState } from 'react'
import { MoreVertical } from 'react-feather'
import { audioContext } from './AudioContextProvider'
import ButtonWrapper from './ButtonWrapper'
import MuteButton from './MuteButton'
import PlayButton from './PlayButton'
import SkipButton from './SkipButton'

const MinimalPlayer = () => {
  const [anchorEl, setAnchorEl] = useState()
  const { goBackwards, goForwards, muted, setMuted } =
    useContext(audioContext) || {}

  const open = Boolean(anchorEl)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <ButtonWrapper className="show-accent">
      <IconButton
        aria-controls={open ? 'more-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        aria-label="Audio Options"
        color="secondary"
        data-bodyvariant="body3"
        data-subvariant="square"
        id="more-button"
        onClick={handleClick}
        style={{
          left: 1,
        }}
        value={`more-open-${open}`}
        variant="tertiary">
        <MoreVertical />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        id="more-menu"
        MenuListProps={{
          'aria-labelledby': 'more-button',
        }}
        onClose={handleClose}
        open={open}>
        <MenuItem onClick={() => setMuted(!muted)}>
          <MuteButton />
        </MenuItem>
        <MenuItem onClick={goForwards}>
          <SkipButton forwards />
        </MenuItem>
        <MenuItem onClick={goBackwards}>
          <SkipButton />
        </MenuItem>
      </Menu>

      <PlayButton minimal />
    </ButtonWrapper>
  )
}

export default MinimalPlayer
