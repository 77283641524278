import ConfirmationDialog from 'common/dialogs/ConfirmationDialog'
import { additionalContext } from 'common/formControls/Form/additionalContext'
import { scrollIntoView } from 'fp/dom'
import { identity } from 'fp/utils'
import useIsFormDirty from 'hooks/useIsFormDirty'
import useNavigation from 'hooks/useNavigation'
import useReduxPromise from 'hooks/useReduxPromise'
import { Maybe } from 'monet'
import PropTypes from 'prop-types'
import { useCallback, useContext } from 'react'
import { createConfirmation } from 'react-confirm'
import { useFormContext } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { actions as notificationActions } from 'reducers/notifications'
import { isModifiedEvent } from './Link'

const confirm = createConfirmation(ConfirmationDialog, 0)

const SaveFirstLink = ({ to, ...rest }) => {
  const dispatch = useDispatch()
  const isDirty = useIsFormDirty()
  const { hrefFromTo, navigate } = useNavigation()
  const { trigger } = useFormContext()
  const href = hrefFromTo(to)
  const {
    actionType,
    disabled,
    getValues,
    setDisabled,
    setSuppressNextDirtyNavigationWarning,
  } = useContext(additionalContext)

  const onSuccess = useCallback(
    payload => {
      const handleNewIdPassThrough = ({ passThrough, response }) => {
        /**
         * If the url we're traveling to contains a temporary id, try to locate it
         * within the API response and then substitute it back into the url.
         *
         * This only works if the API call in question had the list of original ids
         * passed through.  Also this currently assumes the objects will be in a
         * array named children (this was built specifically for `content`, but could
         * be expanded if you find another use-case).  It also only works on one temp
         * id at a time.
         */
        const [frag] = /_not_yet_committed_(\d+)/.exec(to) || []
        // TODO: see test for notes
        // istanbul ignore next
        return Maybe.fromNull(frag)
          .map(s => {
            const { childIds } = passThrough
            return childIds.indexOf(s)
          })
          .map(idx => response?.children[idx].id)
          .map(id => to.replace(frag, id))
          .orJust(to)
      }

      setSuppressNextDirtyNavigationWarning(true)
      const destination = handleNewIdPassThrough(payload || {})

      /**
       * Not entirely sure why this setTimeout is needed.  Without it, react-router-dom
       * calls navigator.push(), but the location does not actually change (!)
       *
       * Is it a threading thing?  Some optimization that react 18 is doing to batch
       * updates?  No idea, but a setTimeout solves it; even with 0ms.
       */
      setTimeout(() => navigate(destination))
    },
    [navigate, setSuppressNextDirtyNavigationWarning, to],
  )

  const call = useReduxPromise(actionType)

  // TODO: no current way to test within react-confirm
  // istanbul ignore next line
  const saveAndContinue = proceed => {
    if (proceed) {
      setDisabled(true)
      call({ payload: getValues() }).then(onSuccess)
    }
  }

  const handleClick = async event => {
    // istanbul ignore else
    if (
      !event.defaultPrevented && // onClick prevented default
      event.button === 0 && // ignore everything but left clicks
      !isModifiedEvent(event) // ignore clicks with modifier keys
    ) {
      event.preventDefault()

      const isValid = await trigger()

      // istanbul ignore else
      if (isValid) {
        // istanbul ignore else
        if (isDirty) {
          confirm({
            primaryText: 'Save Changes?',
            secondaryText:
              'You have uncommitted changes on this page.<br />Save them and continue?',
            proceed: saveAndContinue,
            cancel: identity,
            confirmLabel: 'Save & continue',
            cancelLabel: 'Cancel',
          })
        } else {
          onSuccess()
        }
      } else {
        dispatch(
          notificationActions.addAlert({
            message:
              'Form validation errors must be addressed before proceeding',
            options: {
              variant: 'error',
            },
          }),
        )

        scrollIntoView(document.getElementById('form-error-rollup'), true)
      }
    }
  }

  return (
    <a
      href={href}
      onClick={handleClick}
      {...rest}
      style={{ pointerEvents: disabled ? 'none' : 'auto' }}
    />
  )
}

SaveFirstLink.propTypes = {
  to: PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.string])
    .isRequired,
}

export default SaveFirstLink
