import cl from 'classnames'
import { componentShape } from 'core/shapes'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { useMatch, useResolvedPath } from 'react-router-dom'
import Link from './Link'

const NavLink = forwardRef(({ children, className, to, ...rest }, ref) => {
  const active = useMatch({ path: useResolvedPath(to).pathname })

  return (
    <Link
      {...{
        'aria-current': active ? 'page' : null,
        className: cl(className, { active }),
        ref,
        to,
        ...rest,
      }}>
      {children}
    </Link>
  )
})

NavLink.propTypes = {
  children: componentShape.isRequired,
  to: PropTypes.string.isRequired,
}

export default NavLink
