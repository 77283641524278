import { TreeItem } from '@mui/x-tree-view'
import { CONTENT_TYPE_SECTION } from 'core/consts'
import { contentShape } from 'core/shapes'
import PropTypes from 'prop-types'
import ItemContent from './ItemContent'

const Item = ({ children, item, nodeId, parentContent, ...rest }) => {
  const { tabbed } = parentContent.data || { tabbed: false }
  const isNestedSection =
    parentContent.id !== item.id &&
    parentContent.contentType === CONTENT_TYPE_SECTION &&
    item.contentType === CONTENT_TYPE_SECTION

  return (
    <TreeItem
      ContentComponent={ItemContent}
      ContentProps={{ isNestedSection, item }}
      data-subvariant="content-viewer"
      nodeId={nodeId}
      variant="nav-shell"
      {...rest}>
      {tabbed ? null : children}
    </TreeItem>
  )
}

Item.propTypes = {
  children: PropTypes.node,
  item: contentShape.isRequired,
  nodeId: PropTypes.string.isRequired,
  parentContent: contentShape.isRequired,
}

export default Item
