import MuiIcon from '@mui/material/Icon'
import { styled } from '@mui/material/styles'
import { componentShape } from 'core/shapes'
import { isString } from 'fp/strings'

const IconCallout = styled(
  props => {
    const { Icon, children, className, ...rest } = props
    const iconName = isString(Icon) ? Icon : null

    return (
      <div
        className={className}
        {...rest}>
        <div>{iconName ? <MuiIcon>{iconName}</MuiIcon> : Icon}</div>
        <div>{children}</div>
      </div>
    )
  },
  { name: 'text-IconCallout' },
)(
  ({
    theme: {
      mixins: { rem },
    },
  }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    'div:first-of-type': {
      lineHeight: 0,
      marginRight: rem(1),
    },
    'div:nth-of-type(2)': {
      lineHeight: 1.3,
    },
  }),
)

IconCallout.propTypes = {
  children: componentShape.isRequired,
  Icon: componentShape.isRequired,
}

export default IconCallout
