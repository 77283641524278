import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import ThemedPortal from 'common/wrappers/ThemedPortal'
import { componentShape } from 'core/shapes'
import { asHtml, isString } from 'fp/strings'
import { whenPresent } from 'fp/utils'
import PropTypes from 'prop-types'
import { confirmable } from 'react-confirm'
import Dialog from './Dialog'

const ThemedDialog = /* istanbul ignore next */ props => (
  <ThemedPortal>
    <Dialog {...props} />
  </ThemedPortal>
)

export const BaseDialog = ({
  cancel = null,
  cancelLabel = 'Cancel',
  confirmIcon,
  confirmLabel = 'Yes',
  dismiss = null,
  dispose, // eat (from confirmable)
  primaryText = 'Confirm Action',
  proceed,
  reject, // eat (from confirmable)
  resolve, // eat (from confirmable)
  secondaryText = '',
  show,
  ...rest
}) => {
  const abortAndClose = () => {
    whenPresent(cancel)
    dismiss()
  }

  return (
    <ThemedDialog
      maxWidth="md"
      onClose={abortAndClose}
      open={show}
      sx={{
        '.MuiDialogActions-root > button': { minWidth: 120 },
        '.MuiDialogContent-root': { textAlign: 'center' },
      }}
      title={primaryText}
      {...rest}>
      {isString(secondaryText) ? (
        <DialogContent {...asHtml(secondaryText)} />
      ) : (
        <DialogContent>{secondaryText}</DialogContent>
      )}

      <DialogActions>
        <Button
          color="secondary"
          onClick={() => {
            proceed(false)
            abortAndClose()
          }}
          variant="secondary">
          {cancelLabel}
        </Button>

        <Button
          color="secondary"
          onClick={() => {
            proceed(true)
            dismiss()
          }}
          startIcon={confirmIcon}
          variant="primary">
          {confirmLabel}
        </Button>
      </DialogActions>
    </ThemedDialog>
  )
}

BaseDialog.propTypes = {
  cancel: PropTypes.func,
  cancelLabel: PropTypes.string,
  confirmIcon: componentShape,
  confirmLabel: PropTypes.string,
  dismiss: PropTypes.func,
  dispose: PropTypes.func,
  primaryText: PropTypes.string,
  proceed: PropTypes.func.isRequired,
  reject: PropTypes.func,
  resolve: PropTypes.func,
  secondaryText: PropTypes.node,
  show: PropTypes.bool.isRequired,
}

const ConfirmationDialog = confirmable(BaseDialog)

export default ConfirmationDialog
