import type {
  AssetUpload,
  AssetUploadType,
} from 'common/formControls/textInputs/RichTextEdit2/nodes/ImageNode/@types'

interface Props extends React.ComponentPropsWithoutRef<'div'> {
  uploadType: AssetUploadType
  value?: AssetUpload
}

const AssetPreviewer: React.FC<Props> = ({ uploadType, value }) =>
  value ? (
    <>
      {uploadType === 'audio' && (
        // biome-ignore lint/a11y/useMediaCaption: right?
        <audio controls>
          <source src={value.url} />
        </audio>
      )}

      {uploadType === 'image' && (
        <div
          className="crop-marks"
          style={{ marginBottom: 32 }}>
          <img
            alt="Preview of loaded asset"
            src={value.url}
          />
        </div>
      )}

      {Boolean(uploadType === 'video' && value.url) && (
        // biome-ignore lint/a11y/useMediaCaption: right?
        <video
          controls
          key={value.url}>
          <source src={value.url} />
        </video>
      )}

      {!['audio', 'image', 'video'].includes(uploadType) && (
        <a
          href={value.url}
          rel="noreferrer"
          target="_blank">
          View file
        </a>
      )}
    </>
  ) : null

export default AssetPreviewer
