import TabPanel from '@mui/lab/TabPanel'
import DialogContent from '@mui/material/DialogContent'
import useContent from 'hooks/useContent'
import PropTypes from 'prop-types'
import CreateEditExternalLinkDialogContents, {
  contentStubShape,
} from './CreateEditExternalLinkDialogContents'

const ExternalLinkTab = ({ currentLinkStub, onClose, onComplete }) => {
  const parentContent = useContent()
  const editing = !!currentLinkStub

  const handleInsert = ({ id, contentType }) => {
    onComplete({ id, contentType })
    onClose()
  }

  return editing ? (
    <DialogContent>
      <CreateEditExternalLinkDialogContents
        {...{
          contentId: currentLinkStub.contentId,
          editing,
          parentContent,
          onCancel: onClose,
          onComplete: handleInsert,
        }}
      />
    </DialogContent>
  ) : (
    <TabPanel
      style={{ paddingTop: 0 }}
      value="external">
      <CreateEditExternalLinkDialogContents
        {...{
          editing,
          parentContent,
          onCancel: onClose,
          onComplete: handleInsert,
        }}
      />
    </TabPanel>
  )
}

ExternalLinkTab.propTypes = {
  currentLinkStub: contentStubShape,
  onClose: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
}

export default ExternalLinkTab
