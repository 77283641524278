import { DialogActions, DialogContent } from '@mui/material'
import Dialog from 'common/dialogs/Dialog'
import type { AssetUploadVariant } from 'common/formControls/textInputs/RichTextEdit2/nodes/ImageNode/@types'
import DialogActionsRenderer from './DialogActionsRenderer'
import DialogContentRenderer from './DialogContentRenderer'
import {
  type ImageDisplayMode,
  type ImageDisplayModeCallback,
  ImageSelectionProvider,
} from './ImageSelectionProvider'

type ImageSelectionDialogProps = {
  doCreateInteractive?: boolean
  displayMode: ImageDisplayMode
  onClose: () => void
  onComplete: (result: ImageDisplayModeCallback) => void
  title?: string
  uploadVariant: AssetUploadVariant
}

const ImageSelectionDialog: React.FC<ImageSelectionDialogProps> = props => {
  const {
    displayMode,
    doCreateInteractive,
    onClose,
    onComplete,
    uploadVariant,
    title = 'Select Image to Insert',
  } = props

  return (
    <Dialog
      disableAutoFocus
      maxWidth="sm"
      onClose={onClose}
      open
      showCloseButton
      swatch
      title={title}
      TitleProps={{ mb: 3 }}
      variant="maximized-vertically">
      <ImageSelectionProvider
        doCreateInteractive={doCreateInteractive}
        displayMode={displayMode}
        onComplete={onComplete}
        uploadVariant={uploadVariant}>
        <DialogContent>
          <DialogContentRenderer />
        </DialogContent>

        <DialogActions>
          <DialogActionsRenderer />
        </DialogActions>
      </ImageSelectionProvider>
    </Dialog>
  )
}

export default ImageSelectionDialog
