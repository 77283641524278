import { BaseDialog } from 'common/dialogs/ConfirmationDialog'
import { noop, when } from 'fp/utils'
import useIsFormDirty from 'hooks/useIsFormDirty'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom'
/* istanbul ignore file */
import ReactRouterPrompt from 'react-router-prompt'
import { stateRouting } from 'selectors/index'
import { additionalContext } from './additionalContext'

const DirtyNavigationWarning = () => {
  const [shouldBlock, setShouldBlock] = useState(false)

  const { currentLocation } = useSelector(stateRouting)

  const {
    dirtyNavigationWarning,
    setSuppressNextDirtyNavigationWarning,
    suppressDirtyNavigationWarning: suppressDirtyNavigationWarningFromContext,
    suppressNextDirtyNavigationWarning,
  } = useContext(additionalContext)

  const isDirty = useIsFormDirty(suppressDirtyNavigationWarningFromContext)
  const suppressDirtyNavigationWarning =
    suppressDirtyNavigationWarningFromContext === true

  const { navigator } = useContext(NavigationContext)

  /**
   * This next bit tries to work out if you're going to the same path or not,
   * minus any query params.  It won't block navigation if that's the case.
   *
   * The code looks a bit strange and that's because it was copied more-or-less
   * wholesale from
   * https://github.dev/sshyam-gupta/react-router-prompt/blob/36e6e4c50f640c1a30f1b02993dd62ae8fb3c6bb/src/hooks/use-blocker.ts#L9
   *
   * We'll probably have to revisit this (well, this whole file really) in a
   * future version of react-router.
   *
   */

  /** **************************************************************************
   *                        START OF COPY PASTA                                *
   *************************************************************************** */

  // biome-ignore lint/suspicious/useAwait: <explanation>
  const blocker = useCallback(async tx => {
    tx.retry()
  }, [])

  useEffect(() => {
    if (!shouldBlock) return

    const unblock = navigator.block(tx => {
      when(
        currentLocation?.pathname === tx.location.pathname,
        setSuppressNextDirtyNavigationWarning,
        true,
      )

      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock()
          tx.retry()
        },
      }

      blocker(autoUnblockingTx)
    })

    return unblock
  }, [
    blocker,
    currentLocation?.pathname,
    navigator,
    setSuppressNextDirtyNavigationWarning,
    shouldBlock,
  ])

  /** **************************************************************************
   *                         END OF COPY PASTA                                 *
   *************************************************************************** */
  // console.log('isDirty', isDirty)

  useEffect(() => {
    const willBlock =
      isDirty &&
      !suppressDirtyNavigationWarning &&
      !suppressNextDirtyNavigationWarning

    setShouldBlock(willBlock)

    if (isDirty && !willBlock && suppressNextDirtyNavigationWarning) {
      // Reset, but wait a beat so that we don't block the current transition

      setTimeout(() => {
        setSuppressNextDirtyNavigationWarning(false)
      }, 200)
    }
  }, [
    isDirty,
    setSuppressNextDirtyNavigationWarning,
    suppressDirtyNavigationWarning,
    suppressNextDirtyNavigationWarning,
  ])

  return (
    <ReactRouterPrompt when={shouldBlock}>
      {({ isActive, onConfirm, onCancel }) => {
        /**
         * react-router-prompt uses some asynchronous callbacks in effects that
         * seem to be mucking around with the timing of things.
         *
         * Sometimes it will enable the navigation blocker even though isActive
         * is false, presumably because they've gotten out of sync. It's 𝙨𝙪𝙥𝙥𝙤𝙨𝙚𝙙
         * to turn off the blocker when the transition react-router passed to it
         * is retried, but it sure seems like sometimes the transition completes
         * 𝙗𝙚𝙛𝙤𝙧𝙚 the retry.
         *
         * There's this telling comment buried inside:
         *
         *       " TODO: Figure out how to re-enable this block if the
         *               transition is cancelled for some reason. "
         *
         * I'm not sure if it's their fault or react-router's fault, though that
         * 𝙢𝙞𝙜𝙝𝙩 be the reason that they've delayed putting <Prompt /> back into
         * the release. At any rate, programmatically "pressing" the leave button
         * seems to solve this, along with the <SuccessCallback /> component which
         * ensures that we're on a fresh render cycle.
         */
        when(!shouldBlock && isActive, onConfirm)

        const leave = () => {
          setShouldBlock(false)
          onConfirm()
        }

        return (
          <BaseDialog
            confirmLabel="Leave"
            dismiss={noop}
            primaryText="Leave current page?"
            proceed={proceed => (proceed ? leave() : onCancel())}
            secondaryText={`<div style="text-align: center">${dirtyNavigationWarning}</div>`}
            show={Boolean(isActive && shouldBlock)}
            showCloseButton={false}
          />
        )
      }}
    </ReactRouterPrompt>
  )
}

export default DirtyNavigationWarning
