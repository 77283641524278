import Button from '@mui/material/Button'
import { useContext } from 'react'
import { context } from './context'

const CheckIntegrationsButton = () => {
  const { checkScopes, isWorking, status } = useContext(context)

  return status === 'CHECK_SCOPES' ? (
    <Button
      disabled={isWorking}
      onClick={checkScopes}
      style={{ width: '100%' }}
      variant="secondary">
      Check Google Integrations
    </Button>
  ) : null
}

export default CheckIntegrationsButton
