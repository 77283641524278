import WarningIcon from '@mui/icons-material/Warning'
import { forwardRef } from 'react'
import Well from './Well'

const Warning = forwardRef((props, ref) => (
  <Well
    color="warning"
    fontSize="large"
    icon={<WarningIcon />}
    {...props}
    ref={ref}
  />
))

export default Warning
