import { Maybe } from 'monet'
import { toOrdinal, toWordsOrdinal } from 'number-to-words'
import { compose } from 'redux'
import { isDefined, isFunction, isUndefined, not, unary, when } from './utils'

const uncapitalizedArticles = [
  'a',
  'an',
  'and',
  'as',
  'at',
  'but',
  'by',
  'for',
  'if',
  'in',
  'nor',
  'of',
  'on',
  'or',
  'the',
  'to',
  // TBD more? this is from The U.S. Government Printing Office Style Manual
]

const suffix = frag => s => String(s).concat(frag)

export const asHtml = __html => ({ dangerouslySetInnerHTML: { __html } })

export const camelCaseToWords = s =>
  isString(s)
    ? String(s)
        .replace(/([A-Z])/g, ' $1')
        .trim()
    : ''

export const capitalize = s =>
  isString(s) ? s.charAt(0).toUpperCase() + s.slice(1) : ''

// This came from https://github.com/sindresorhus/escape-string-regexp.
export const escapeStringRegexp = string => {
  if (typeof string !== 'string') {
    throw new TypeError('Expected a string')
  }

  // Escape characters with special meaning either inside or outside character sets.
  // Use a simple backslash escape when it’s always valid,
  // and a `\xnn` escape when the simpler form would be disallowed by Unicode patterns’ stricter grammar.
  return string.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&').replace(/-/g, '\\x2d')
}

// export const formatByteSize = (bytes, precision = 1) => {
//   if (!Number.isInteger(bytes)) {
//     return ''
//   }
//   const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB']

//   const number = bytes === 0
//     ? 0
//     : Math.floor(Math.log(bytes) / Math.log(1024))

//   return `${(bytes / 1024 ** Math.floor(number)).toFixed(precision)} ${units[number]}`
// }

export const includesString = s1 => {
  const S = String(s1).toUpperCase()
  return s2 => String(s2).toUpperCase().includes(S)
}

export const insert = (index, value) => str =>
  String(str).substr(0, index) + value + String(str).substr(index)

export const intToLetter = val =>
  String.fromCharCode(64 + (toInt(val) % 26 || 1))

export const isEmptyString = value =>
  !isDefined(value) || String(value).trim().length === 0

const isJSON = value => {
  try {
    JSON.parse(value)
  } catch (_) {
    return false
  }
  return true
}

export const isNotJSON = compose(not, isJSON)

export const isNotEmptyString = compose(not, isEmptyString)

export const isString = value => typeof value === 'string'

export const isNotString = compose(not, isString)

export const maybeParseJSON = value =>
  isJSON(value) ? JSON.parse(value) : value

// export const maybeStringifyJSON = (value) => {
//   let result
//   try {
//     result = JSON.stringify(value)
//   } catch {
//     /* istanbul ignore next line */
//     result = null
//   }
//   return result || value
// }

export const ordinalName = value => {
  const num = toInt(value)
  return Number.isNaN(num)
    ? 'NaN'
    : num < 11
      ? toWordsOrdinal(num)
      : toOrdinal(num)
}

// export const ordinalSuffix = (value) => {
//   const num = toInt(value)
//   return Number.isNaN(num)
//     ? 'NaN'
//     : toOrdinal(num)
// }

export const percent = suffix('%')

export const prefix = frag => s => String(frag).concat(s)

export const plural = (label, pluralizedLabel) => count =>
  count === 1
    ? label
    : pluralizedLabel ||
      (['ch', 's', 'sh', 'o', 'x', 'z'].some(ending => label?.endsWith(ending))
        ? `${label}es`
        : `${label}s`)

export const pluralize = (label, pluralizedLabel) => count =>
  `${count} ${plural(label, pluralizedLabel)(count)}`

export const split =
  sep =>
  (s = '') =>
    String(s).split(sep)

export const startsWith = needle => haystack =>
  isUndefined(haystack) ? false : String(haystack).startsWith(needle)

export const strip = s =>
  (isDefined(s) && String(s).replace(/\s+/g, ' ').trim()) || ''

export { suffix }

export const titleCase = s =>
  when(
    isDefined(s),
    frag =>
      frag
        .split(' ')
        .map((word, idx) =>
          uncapitalizedArticles.includes(word) && idx !== 0
            ? word
            : capitalize(word),
        )
        .join(' '),
    s,
  )

export const toCamelCase = str =>
  Maybe.fromNull(str)
    .map(s => String(s).split(/[\s-]/g))
    .map(arr =>
      arr
        .map(String)
        .map(s => s.toLowerCase())
        .map((s, i) => (i > 0 ? capitalize(s) : s))
        .join(''),
    )
    .orUndefined()

export const toInt = unary(Number.parseInt)

export const toKebabCase = str =>
  Maybe.fromNull(str)
    .map(s =>
      String(s)
        .replace(/([a-z])([A-Z])/g, '$1-$2')
        .replace(/\s+/g, '-')
        .toLowerCase(),
    )
    .orUndefined()

export const trim = value => (isFunction(value?.trim) ? value.trim() : '') || ''

export const unwrap = frag => s =>
  String(s).replace(new RegExp(`^${frag}|${frag}$`, 'g'), '')

export const wrap = (pre, suf = pre) => compose(prefix(pre), suffix(suf))
