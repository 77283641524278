import { createSelector } from '@comfy/redux-selectors'
import { flexRender } from '@tanstack/react-table'
import LanguagePicker from 'common/formControls/selects/LanguagePicker'
import ProficiencySelect, {
  tableRowMixin,
} from 'common/formControls/selects/ProficiencySelect'
import Link from 'common/navigation/links/Link'
import { callWith } from 'fp/call'
import { asFloatWithoutTrailingZeros, asPercentageString } from 'fp/numbers'
import { equals, get, set } from 'fp/objects'
import { fallbackTo, not } from 'fp/utils'
import withProps from 'hoc/withProps'
import PosterImage from 'hss/views/Card/PosterImage'
import { formatDateStrShort, formatRelativeTime } from 'locale/i18n'
import PropTypes from 'prop-types'
import { createElement, useMemo } from 'react'
import { compose } from 'redux'
import RowActions from '../RowActions'
import ExpanderCell from '../TableView/ExpanderCell'
import IconLinkCell from './IconLinkCell'
import RowSelectCell from './RowSelectCell'
import withSaving from './withSaving'

export const filterActionsMenu = get('column.columnDef.actionsMenu')
export const filterCellHeaders = get('column.columnDef.isCellHeader')
export const filterDetailToggle = get('column.columnDef.detailsToggle')
export const filterTopLevelHeaderGroup = compose(equals(0), get('depth'))
export const filterDetailColumns = get('column.columnDef.isDetailColumn')
export const filterRowSelector = get('column.columnDef.rowSelector')
export const filterTopLevelColumns = compose(
  not,
  get('column.columnDef.isDetailColumn'),
)
export const hasData = compose(Boolean, get('column.columnDef.data'))
export const isIntrinsic = get('column.columnDef.intrinsic')
export const filterNonIntrinsicColumns = compose(not, isIntrinsic)

/** ****************************************************************************
 *
 *                           Common Cell Renderers
 *
 * These methods can be used as the cell property for any column definitions.
 *
 * The props that each receives is defined here:
 *          https://tanstack.com/table/v8/docs/api/core/cell
 *
 * These props contain everything you'd need to know about the table, the current
 * row and the current cell.  The return value must be valid JSX or a primitive.
 * Do not return a component constructor -- return an instance of the component
 * instead.
 *
 *
 * HOCs and Helpers
 *
 *    withSaving
 *        Pass this the name of the reducer associated with the field being edited
 *        and it will take care of publishing any changes directly to the database.
 *
 *    tableRowMixin
 *        If you need to pass additional information about the current row into
 *        the cell renderer, then you'll want to create a `tableRowMixin` method.
 *        See the `ProficiencySelectTableCell` below for an example.
 *
 **************************************************************************** */

const withSavingProps = {
  disabled: PropTypes.bool,
  getValue: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  row: PropTypes.object.isRequired,
}

export const asDetail = set('isDetailColumn', true)
export const asPrimary = set('isCellHeader', true)

const DateStringTableCell = compose(
  fallbackTo('—'),
  formatDateStrShort,
  callWith(),
  get('cell.renderValue'),
)

export const DetailRowHasImage = (columnDef, urlAccessor, altAccessor) => ({
  ...columnDef,
  detailCell: ({ row }) => (
    <img
      alt={get(altAccessor)(row.original)}
      src={get(urlAccessor)(row.original)}
    />
  ),
  intrinsic: true,
})

export const IconLinkToTableCell = (urlAccessor, Icon, HoverIcon) =>
  withProps(IconLinkCell, { urlAccessor, Icon, HoverIcon })

export const LanguageSelectTableCell = withSaving('users')(
  ({ disabled = false, getValue, onChange, row }) => {
    const props = useMemo(
      () => tableRowMixin({ getValue, row }),
      [getValue, row],
    )
    const controlProps = {
      style: {
        marginTop: 0,
      },
    }

    return (
      <LanguagePicker
        {...{ controlProps, disabled, onChange, ...props, hideLabel: true }}
      />
    )
  },
)
LanguageSelectTableCell.propTypes = withSavingProps

export const LinkToTableCell = urlAccessor =>
  createSelector(
    compose(callWith(), get('cell.renderValue')),
    compose(to => ({ to }), urlAccessor, get('row.original')),
    (children, props) => createElement(Link, props, children),
  )

export const PercentageTableCell = compose(
  fallbackTo('—'),
  asPercentageString,
  callWith(),
  get('cell.renderValue'),
)

export const ProficiencySelectTableCell = withSaving('users')(
  ({ disabled = false, getValue, onChange, row }) => {
    const props = useMemo(
      () => tableRowMixin({ getValue, row }),
      [getValue, row],
    )

    return <ProficiencySelect {...{ disabled, onChange, ...props }} />
  },
)
ProficiencySelectTableCell.propTypes = withSavingProps

const RelativeTimeTableCell = compose(
  formatRelativeTime,
  callWith(),
  get('cell.renderValue'),
)

const ScoreTableCell = compose(
  asFloatWithoutTrailingZeros,
  fallbackTo(0), // the API always returns a score but the test data may not, quick fix for now TODO: fix test data
  callWith(),
  get('cell.renderValue'),
)

export const RowActionsMenu = (getRowActions, options = {}) => ({
  actionsMenu: true,
  header: 'Actions',
  id: 'actions',
  intrinsic: true,
  maxSize: 100,
  size: 100,
  ...options,
  cell: props => {
    const actions = getRowActions(props.row)
    return <RowActions {...{ actions, ...props }} />
  },
})

export const RowDetailsToggle = () => ({
  cell: ExpanderCell,
  detailsToggle: true,
  header: 'Details',
  id: 'details',
  intrinsic: true,
  size: 50,
})

export const RowSelector = props => ({
  id: 'select',
  intrinsic: true,
  rowSelector: true,
  size: 50,
  ...props,
  header: ({ table }) => (
    <RowSelectCell
      {...{
        ...props,
        checked: table.getIsAllRowsSelected(),
        indeterminate: table.getIsSomeRowsSelected(),
        isHeader: true,
        onChange: table.getToggleAllRowsSelectedHandler(),
      }}
    />
  ),
  cell: ({ row }) => (
    <RowSelectCell
      {...{
        ...props,
        checked: row.getIsSelected(),
        indeterminate: row.getIsSomeSelected(),
        isHeader: false,
        onChange: row.getToggleSelectedHandler(),
        row,
      }}
    />
  ),
})

export const renderLibraryCardCell = ({
  id,
  getValue,
  getContext,
  row,
  column: {
    columnDef: { cell, header },
  },
}) =>
  getValue() ? (
    header === 'uploadsMap' ? (
      <tr key={id}>
        <td
          rowSpan={4}
          style={{
            minWidth: 200,
            maxWidth: 200,
          }}>
          <PosterImage
            aspectRatio="16:9"
            content={row.original}
            includeLinkToContent
            viewMode="grid"
          />
        </td>
        <td />
        <td />
      </tr>
    ) : (
      <tr key={id}>
        <td />
        <th>{flexRender(header, getContext())}:</th>
        <td>{flexRender(cell, getContext())}</td>
      </tr>
    )
  ) : null

export const renderSimpleCardCell = ({
  id,
  getValue,
  getContext,
  column: {
    columnDef: { cell, header },
  },
}) =>
  getValue() ? (
    <tr key={id}>
      <th>{flexRender(header, getContext())}:</th>
      <td>{flexRender(cell, getContext())}</td>
    </tr>
  ) : null

export const SimpleCell = ({ header, id, ...rest }) => ({
  accessorFn: get(id),
  header,
  id,
  ...rest,
})

export const SimpleDateCell = compose(
  set('cell', DateStringTableCell),
  SimpleCell,
)

export const SimplePercentageCell = compose(
  set('cell', PercentageTableCell),
  SimpleCell,
)

export const SimpleRelativeTimeCell = compose(
  set('cell', RelativeTimeTableCell),
  SimpleCell,
)

export const SimpleScoreCell = compose(set('cell', ScoreTableCell), SimpleCell)
