// copied from https://github.com/use-hooks/react-hooks-image-size/blob/master/src/index.js

import { useEffect, useState } from 'react'

/**
 * Params
 * @param {string} url - The image url
 */

/**
 * Returns
 * @param {array} size - The image size [width, height]
 */

const useImageSize = url => {
  const [size, setSize] = useState([0, 0])

  useEffect(() => {
    if (!url) return
    const img = document.createElement('img')
    img.addEventListener('load', e => {
      const { naturalHeight, naturalWidth } = e.target
      setSize([naturalWidth, naturalHeight])
    })
    img.src = url
    // biome-ignore lint/correctness/useExhaustiveDependencies(url): TODO:
  }, [url])

  return size
}

export default useImageSize
