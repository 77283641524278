import List from '@mui/material/List'
import type { FC } from 'react'
import { filterNonIntrinsicColumns } from './Cells'
import ColumnarList from './ColumnarList'

interface Cell {
  id: string
}

interface Row {
  id: string
  getAllCells: () => Cell[]
}

interface GridViewProps {
  CardRenderer?: FC<{ row: Row }>
  cardRendererProps?: {
    onComplete: () => void
  }
  table: {
    getRowModel: () => { rows: Row[] }
  }
  tableId: string
}

const GridView: React.FC<GridViewProps> = props => {
  const {
    CardRenderer,
    cardRendererProps,
    table: { getRowModel },
    tableId,
  } = props

  const { rows } = getRowModel()

  return (
    <List
      sx={{
        display: 'flex',
        flexFlow: 'row wrap',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
      {rows.map(row => {
        const dataCells = row //
          .getAllCells()
          .filter(filterNonIntrinsicColumns)

        return (
          <li key={row.id}>
            {CardRenderer ? (
              <CardRenderer
                row={row}
                {...cardRendererProps}
              />
            ) : (
              <ColumnarList
                cells={dataCells}
                row={row}
                tableId={tableId}
              />
            )}
          </li>
        )
      })}
    </List>
  )
}

export default GridView
