import { ChevronDown } from 'react-feather'
import { components } from 'react-select'

const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <ChevronDown />
  </components.DropdownIndicator>
)

export default DropdownIndicator
