import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { get } from 'fp/objects'
import { fallbackTo } from 'fp/utils'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { compose } from 'redux'
import Autocomplete from './Autocomplete'

const MultiPicker = forwardRef((props, ref) => {
  const {
    getOptionLabel,
    renderOption: renderOptionFromProps,
    renderOptionsWithCheckboxes = false,
    ...rest
  } = props

  const getCheckboxLabel = option =>
    compose(fallbackTo(option), getOptionLabel || get('label'))(option)

  const renderOption =
    renderOptionFromProps ||
    (renderOptionsWithCheckboxes
      ? ({ key, ...optionProps }, option, { selected }) => (
          <FormControlLabel
            control={<Checkbox checked={selected} />}
            key={key}
            label={getCheckboxLabel(option)}
            {...optionProps}
          />
        )
      : undefined)

  return (
    <Autocomplete
      disableCloseOnSelect
      multiple
      ref={ref}
      {...rest}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
    />
  )
})

MultiPicker.propTypes = {
  getOptionLabel: PropTypes.func,
  renderOption: PropTypes.func,
  renderOptionsWithCheckboxes: PropTypes.bool,
}

export default MultiPicker
