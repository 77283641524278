const theme = {
  ltr: 'ltr',
  rtl: 'rtl',

  paragraph: 'editor-paragraph',

  placeholder: 'editor-placeholder',

  table: 'editor-table',
  tableAddColumns: 'editor-tableAddColumns',
  tableAddRows: 'editor-tableAddRows',
  tableCell: 'editor-tableCell',
  tableCellActionButton: 'editor-tableCellActionButton',
  tableCellActionButtonContainer: 'editor-tableCellActionButtonContainer',
  tableCellEditing: 'editor-tableCellEditing',
  tableCellHeader: 'editor-tableCellHeader',
  tableCellPrimarySelected: 'editor-tableCellPrimarySelected',
  tableCellResizer: 'editor-tableCellResizer',
  tableCellSelected: 'editor-tableCellSelected',
  tableCellSortedIndicator: 'editor-tableCellSortedIndicator',
  tableResizeRuler: 'editor-tableCellResizeRuler',
  tableSelected: 'editor-tableSelected',
  tableSelection: 'editor-tableSelection',

  text: {
    bold: 'editor-text-bold',
    code: 'editor-text-code',
    hashtag: 'editor-text-hashtag',
    italic: 'editor-text-italic',
    overflowed: 'editor-text-overflowed',
    strikethrough: 'editor-text-strikethrough',
    underline: 'editor-text-underline',
    underlineStrikethrough: 'editor-text-underlineStrikethrough',
  },
}

export default theme
