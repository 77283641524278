import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { CARD_IMAGE_MAX_WIDTH } from 'hss/views/Card/PosterImage'
import { searchContext } from 'hss/views/Search/SearchProvider'
import { useContext } from 'react'
import { importantPx } from 'styling/theming/base/mixins'

const useContentCardVariant = props => {
  const { breakpoints } = useTheme()
  const isLargeWidth = useMediaQuery(breakpoints.up('lg'))
  const { viewMode: contextViewMode = 'list' } = useContext(searchContext) || {}

  const resultViewMode = props?.viewMode || contextViewMode

  const isSquare = isLargeWidth && resultViewMode !== 'grid'
  const aspectRatio = isSquare ? '1 / 1' : '16 / 9'
  const maxImageWidth = isSquare ? importantPx(CARD_IMAGE_MAX_WIDTH) : null

  const variant = isLargeWidth ? 'horizontal' : 'vertical-grid'

  return {
    aspectRatio,
    isLargeWidth,
    isSquare,
    maxImageWidth,
    variant,
  }
}

export default useContentCardVariant
