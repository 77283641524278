import ContainerQuery from 'common/layout/ContainerQuery'
import { Outlet } from 'react-router-dom'
import Banner from './Banner'

const AssessmentNavShell = () => (
  <>
    <Banner />

    <ContainerQuery>
      <Outlet />
    </ContainerQuery>
  </>
)

export default AssessmentNavShell
