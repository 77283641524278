import Checkbox from '@mui/material/Checkbox'
import MenuItem from '@mui/material/MenuItem'
import { sortBy } from 'fp/arrays'
import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import Select from './Select'

const CheckboxMultiSelect = ({
  data,
  handleSelected,
  label = 'Filter by',
  name,
  sortFn = (a, b) => a.localeCompare(b), // Default sort is alphabetical, desc
  ...rest
}) => {
  /**
   * Data obj passed expects a key (id) and a display name
   * We iterate over the ids, but show the displayName strings to the user
   * Structure should be ex: { 123: { displayName: 'Hello World', id: '123' } }
   */

  // MUI Select with `multiple` enabled expects an array of strings in the onChange
  const [selectedItems, setSelectedItems] = useState([])

  const sortedData = useMemo(
    () =>
      Object.values(data).sort(
        sortBy('displayName', 'desc', 'function', sortFn),
      ),
    [data, sortFn],
  )

  return (
    <Select
      label={label}
      multiple
      name={name}
      onChange={event => {
        handleSelected(event)
        setSelectedItems(event.target.value)
      }}
      // Filter items are keyed to their ids; display text should be the displayName instead of id
      renderValue={selectedId =>
        selectedId
          .map(id => data?.[id].displayName)
          .sort(sortFn)
          .join(', ')
      }
      value={selectedItems}
      {...rest}>
      {sortedData.map(item => (
        <MenuItem
          className={item?.hasDivider ? 'with-border-top' : undefined}
          key={item.id}
          value={item.id}
          variant="checkbox-multi-select">
          <Checkbox checked={selectedItems.includes(item.id)} />{' '}
          {item.displayName}
        </MenuItem>
      ))}
    </Select>
  )
}

CheckboxMultiSelect.propTypes = {
  data: PropTypes.objectOf(
    PropTypes.shape({
      displayName: PropTypes.string.isRequired,
      hasDivider: PropTypes.bool,
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  handleSelected: PropTypes.func.isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  sortFn: PropTypes.func,
}

export default CheckboxMultiSelect
