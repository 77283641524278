import type {
  InlineImageSize,
  InlineImageVerticalAlign,
} from 'common/indicators/InlineImage'
import type { ContentBlock, ContentState } from 'draft-js'
import { isDefined } from 'fp/utils'
import DraftDecorator from './DraftDecorator'
import ImageMenuButton from './ImageMenuButton'

const ToolBarItem = ({ features }: { features: { images: boolean } }) =>
  features.images ? [ImageMenuButton] : []

const decorator = (/* features */) => {
  type StrategyCallback = (start: number, end: number) => void

  const strategy = (
    contentBlock: ContentBlock,
    callback: StrategyCallback,
    contentState: ContentState,
  ) => {
    contentBlock.findEntityRanges(character => {
      const entityKey = character.getEntity()

      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'INLINE-IMAGE'
      )
    }, callback)
  }

  const component = DraftDecorator

  return { strategy, component }
}

const entityToHTML =
  (/* features */) =>
  (entity: {
    data: {
      contentId: string
      size: InlineImageSize
      valign: InlineImageVerticalAlign
    }
    type: string
  }) => {
    const { data, type } = entity

    if (type !== 'INLINE-IMAGE') return undefined

    return (
      <span
        data-size={data.size}
        data-contentid={data.contentId}
        data-valign={data.valign}
        data-kind="image">
        {' '}
      </span>
    )
  }

const htmlToEntity =
  (/* features */) =>
  (
    nodeName: string,
    node: HTMLElement,
    createEntity: (
      type: string,
      mutability: string,
      data: object,
    ) => object | undefined,
  ) => {
    if (
      nodeName === 'span' &&
      isDefined(node.getAttribute('data-contentid')) &&
      node.getAttribute('data-kind') === 'image'
    ) {
      const props = {
        contentId: node.getAttribute('data-contentid'),
        size: node.getAttribute('data-size'),
        valign: node.getAttribute('data-valign'),
        kind: 'image',
      }

      return createEntity('INLINE-IMAGE', 'IMMUTABLE', props)
    }
    return undefined
  }

const imagePlugin = {
  ToolBarItem,
  decorator,
  entityToHTML,
  htmlToEntity,
}

export default imagePlugin
