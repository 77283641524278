import type { ButtonProps } from '@mui/material/Button/'
import { get, set } from 'fp/objects'
import { fallbackTo, whenPresent } from 'fp/utils'
import useNavigation from 'hooks/useNavigation'
import { type ComponentType, type SyntheticEvent, forwardRef } from 'react'
import type { To } from 'react-router-dom'
import { compose } from 'redux'

type EnhancedProps = ButtonProps & {
  disabled?: boolean
  onClick?: () => void
  replace?: boolean
  to: To
}

const withNavigate = <P extends EnhancedProps>(
  WrappedComponent: ComponentType<P>,
) => {
  const Enhanced = forwardRef<HTMLButtonElement, EnhancedProps>(
    ({ disabled = false, onClick, replace = false, to, ...rest }, ref) => {
      const { navigate } = useNavigation()

      const handleClick = (event: SyntheticEvent) => {
        event.preventDefault()
        /* istanbul ignore else */
        if (!disabled) {
          whenPresent(onClick)
          navigate(to, { replace })
        }
      }

      // Allow explicitly set pointerEvents style to override the default 'auto'
      const propsPointerEvents = get('style.pointerEvents')(rest)
      const style = compose(
        set('pointerEvents', propsPointerEvents || 'auto'),
        fallbackTo({}),
        get('style'),
      )(rest)

      return (
        <WrappedComponent
          disabled={disabled}
          onClick={handleClick}
          ref={ref}
          role="button"
          {...(rest as P)}
          style={style}
        />
      )
    },
  )

  return Enhanced
}

export default withNavigate
