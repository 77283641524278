import { getCurrentBlock } from '@studysync/draft-js-modifiers'
import { useMemo } from 'react'

const useBlockInsight = editorState => {
  const blockType = useMemo(() => {
    const block = getCurrentBlock(editorState)
    const data = block.getData()
    const currentBlockType = block.get('type')
    const variants = data.get('variants') || []
    return [currentBlockType, ...variants]
      .map(variant => (variant === 'unstyled' ? 'paragraph' : variant))
      .join(', ')
    // biome-ignore lint/correctness/useExhaustiveDependencies: TODO:
  }, [editorState])

  return blockType
}

export default useBlockInsight
