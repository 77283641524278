import { useContext } from 'react'
import { context } from './context'

const ErrorComponent = () => {
  const { errorMessage, status } = useContext(context)

  return status === 'ERROR' ? (
    <>
      <p>Google Integrations did not load successfully.</p>
      <p>
        Please try again later. If this issue persists, clear your browser's
        cache and cookies, ensuring third-party cookies are allowed.
      </p>
      <code>{errorMessage}</code>
    </>
  ) : null
}

export default ErrorComponent
