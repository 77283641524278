const overrides = ({ mixins: { importantPx }, palette }) => ({
  MuiTimeline: {
    styleOverrides: {
      root: {
        alignSelf: 'center',
        flexGrow: 0,
        margin: importantPx(120, 0),
        '.MuiTimelineDot-root': {
          margin: 0,
          zIndex: 2,
          '&.selected': {
            backgroundColor: palette.border[5],
            boxShadow: `0 0 0 4px ${palette.selected}`,
          },
          ':hover': {
            backgroundColor: palette.secondary.dark,
            boxShadow: `0 0 0 4px ${palette.secondary.light}`,
          },
        },
        '.MuiTimelineItem-root': {
          '::before': {
            padding: 0,
          },
          padding: '0 16px',
        },
      },
    },
  },
})

export default overrides
