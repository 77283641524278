import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import { AUTH_PROVIDER_IMPERSONATE } from 'core/consts'
import useSession from 'hooks/useSession'
import { Outlet } from 'react-router-dom'
import { appUrl } from 'routing/consts'
import OuterViewport from '../OuterViewport'
import { SidebarProvider } from '../SidebarProvider'
import NavSidebar from './NavSidebar'

const NavShell = () => {
  const {
    session: { authProvider },
  } = useSession()
  const isImpersonatedSession = authProvider === AUTH_PROVIDER_IMPERSONATE

  return (
    <SidebarProvider variant="nav">
      <Box display="flex">
        <NavSidebar homeUrl={appUrl} />

        <OuterViewport pb={0}>
          {Boolean(isImpersonatedSession) && (
            <Alert severity="warning">
              This is an impersonated session. Use caution: you are acting as if
              you were this user.
            </Alert>
          )}
          <Outlet />
        </OuterViewport>
      </Box>
    </SidebarProvider>
  )
}

export default NavShell
