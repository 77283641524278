import { produce } from 'immer'
import { important as allImportant, transparentize } from 'polished'
import { important } from './mixins'

const plugin = theme =>
  produce(theme, draft => {
    /** **************************************************************************
     *                                                                           *
     *                                 N O T I C E                               *
     *                                                                           *
     * ************************************************************************* *
     *                                                                           *
     * If you've arrived here looking for a way to style interactives, you       *
     * should first try adjusting the configuration data over in:                *
     *                                                                           *
     *                 src/apps/hss/ContentBuilder/consts.js                     *
     *                                                                           *
     * THIS file is meant only as an escape hatch for things that can't be       *
     * easily adjusted over there.                                               *
     *                                                                           *
     ************************************************************************** */

    draft.globals = {
      ...draft.globals,
      '.MuiBox-root .block-full-width': {
        '.feature-paragraph, &.block-partially-contained': {
          paddingLeft: important('var(--containedPaddingLeft)'),
          paddingRight: important('var(--containedPaddingRight)'),
          paddingTop: important('3rem'),
        },
      },

      '*[role="button"] > .detailed-menuitem-label': {
        ...allImportant(draft.typography.variants['toc-title']),
        fontWeight: 'normal',
      },
      '*[role="button"] > .detailed-menuitem-title': { display: 'none' },

      '*[data-draft="true"]': {
        backgroundImage: `linear-gradient(
            135deg,
            ${transparentize(0.8, '#ff0000')} 25%,
            transparent 25%,
            transparent 50%,
            ${transparentize(0.8, '#ff0000')} 50%,
            ${transparentize(0.8, '#ff0000')} 75%,
            transparent 75%,
            transparent 100%
          )`,
        backgroundSize: draft.mixins.px(80, 80),
      },
    }
  })

export default plugin
