/** ****************************************************************************
 *                                                                             *
 * TODO:                                                                       *
 *                                                                             *
 * This hook is highly unreliable and will not give accurate output when the   *
 * screen is resized.  It will also often not even notice that the screen HAS  *
 * been resized.                                                               *
 *                                                                             *
 * We'll most likely be replacing it with `use-resize-observer` after it's     *
 * proven itself a bit (it's being used in carousels right now as the test     *
 * case).                                                                      *
 *                                                                             *
 **************************************************************************** */

import { useCallback, useLayoutEffect, useState } from 'react'

const getSize = el =>
  el
    ? { width: el.offsetWidth, height: el.offsetHeight }
    : { width: 0, height: 0 }

const useComponentSize = ref => {
  const [componentSize, setComponentSize] = useState(getSize(ref?.current))

  const handleResize = useCallback(() => {
    setComponentSize(getSize(ref?.current))
  }, [ref?.current])

  useLayoutEffect(() => {
    // istanbul ignore next
    if (!ref?.current) {
      return
    }

    handleResize()

    if (typeof ResizeObserver === 'function') {
      let resizeObserver = new ResizeObserver(
        /* istanbul ignore next */ () => {
          handleResize()
        },
      )
      resizeObserver.observe(ref.current)

      return () => {
        resizeObserver.disconnect(ref.current)
        resizeObserver = null
      }
    }
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize, ref?.current])

  return componentSize
}

export default useComponentSize
