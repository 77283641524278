import { isDate } from 'date-fns'
import parseISO from 'date-fns/parseISO'
import { callWith } from 'fp/call'
import { isEmptyString } from 'fp/strings'
import { noop } from 'fp/utils'
import { formatDateStrShort, formatTimeStr } from 'locale/i18n'
import { Maybe } from 'monet'
import { compose } from 'redux'
import { CHANGE_HANDLER_SIMPLE } from '../Form/withHookForm'
import { propBagsShape, withPropBags } from '../Form/withPropBags'
import DateTimePicker from './DateTimePicker'

// These match MUI's current defaults.
// Defining these here so we can provide accurate error messages.
// https://github.com/mui/material-ui/blob/7b9d7b5d39b4b0780c5c8762ac6e13b8cabdcaf7/lib/src/DatePicker/components/Calendar.tsx#L65
const defaultMinDateTime = new Date(1900, 0, 1)
const defaultMaxDateTime = new Date(2100, 0, 1)

const errorMessages = {
  maxDate: ({ maxDateTime, maxDate }) =>
    `Value must be no later than ${formatDateStrShort(maxDate || maxDateTime || defaultMaxDateTime)}.`,
  maxTime: ({ maxDateTime, maxTime }) =>
    `Value must be no later than ${formatTimeStr(maxTime || maxDateTime || defaultMaxDateTime)}.`,
  minDate: ({ minDateTime, minDate }) =>
    `Value must be no earlier than ${formatDateStrShort(minDate || minDateTime || defaultMinDateTime)}.`,
  minTime: ({ minDateTime, minTime }) =>
    `Value must be no earlier than ${formatTimeStr(minTime || minDateTime || defaultMinDateTime)}.`,
}

const HFDateTimePicker = withPropBags(
  ['disabled', 'inputRef', 'name', 'onBlur', 'onFocus', 'readOnly'],
  {
    changeHandlerType: CHANGE_HANDLER_SIMPLE,
    enableCustomErrorMessage: true,
  },
)(props => {
  const {
    componentProps,
    controlProps,
    rest: { setCustomErrorMessage, ...rest },
  } = props
  const { onChange, value } = componentProps

  const handleError = errorType => {
    setCustomErrorMessage(
      Maybe.fromFalsy(errorType)
        .map(type => errorMessages[type] || noop)
        .flatMap(compose(Maybe.fromFalsy, callWith(rest)))
        .orNull(),
    )
  }

  return (
    <DateTimePicker
      {...{
        ...componentProps,
        ...controlProps,
        maxDateTime: defaultMaxDateTime,
        minDateTime: defaultMinDateTime,
        onChange,
        onError: handleError,
        value: isEmptyString(value)
          ? undefined
          : isDate(value)
            ? value
            : parseISO(value),
        ...rest,
      }}
    />
  )
})

HFDateTimePicker.propTypes = propBagsShape.isRequired

export default HFDateTimePicker
