import { HFDebouncedTextField } from './HFTextField'

const HFTextArea = props => (
  <HFDebouncedTextField
    maxRows={3}
    multiline
    {...props}
  />
)

export default HFTextArea
