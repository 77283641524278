import { Box } from '@mui/material'
import SearchInput from 'common/formControls/textInputs/SearchInput'
import { alter, inspect } from 'core/store/search/squery'
import useHoverFocus from 'hooks/useHoverFocus'
import { useImageSelectionContext } from '../ImageSelectionProvider'

const SearchFilter = () => {
  const { searchWasMade, setSquery, squery } = useImageSelectionContext()

  const searchText = inspect(squery).get.modifier('imageSearch').is('')

  const handleSearch = (value: string) => {
    setSquery(alter.set.modifier('imageSearch').is(value))
  }

  const [ref, _, isFocused] = useHoverFocus()

  return (
    <Box pt={3}>
      <SearchInput
        label="Search existing images..."
        onChange={handleSearch}
        sx={{
          minWidth: searchWasMade ? 500 : isFocused ? 300 : 220,
          transition: '0.2s ease-in-out',
        }}
        value={searchText}
        inputRef={ref}
      />
    </Box>
  )
}

export default SearchFilter
