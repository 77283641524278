/* istanbul ignore file */

/**
 * TODO:
 *
 * KNOWN ISSUES:
 *
 * - Cursor is not changing to 'pointer' for Safari when hovering over main body.
 *
 * - Video is slow to start in Chrome.  This is only the case when using DASH, it
 *   works fine for MP4 files.
 *
 * - Volume cannot be changed in Safari on iPadOS
 */

import { videoPlayerContext } from 'common/avclub/video/context'
import { TOGGLE_STATE_VIDEO_CC_ENABLED } from 'core/consts'
import { numberOrString } from 'core/shapes'
import { when, whenPresent } from 'fp/utils'
import { useDeepCompareEffect } from 'hooks/useDeepCompare'
import useLocalSetting from 'hooks/useLocalSetting'
import useToggleState from 'hooks/useToggleState'
import { contentViewerContext } from 'hss/ContentViewer/ContentViewerProvider'
import { formatSec } from 'locale/i18n'
import PropTypes from 'prop-types'
import { useContext, useEffect, useId, useMemo, useRef } from 'react'
import { stopAllOtherPlayers } from '../../utils'
import GradientOverlay from './controls/GradientOverlay'
import StateChangeIndicator from './controls/StateChangeIndicator'
import './controls/AnnotationsToggle'
import './controls/BigPlayButton'
import './controls/SettingsButton'
import './controls/TheaterToggle'
import './controls/Volume'
import './controls/PlayButton'
import useEffectOnce from 'hooks/useEffectOnce'
import PlayerWrapper from './PlayerWrapper'
import StyledDuration from './controls/StyledDuration'

const VideoJsWrapper = props => {
  const { setVideosInTheaterMode } = useContext(contentViewerContext) || {}
  const {
    allowAnnotations,
    onInteract,
    playState,
    playerRef,
    previewing,
    setAnnotationsOn,
    setCurrentTime,
    setMouseInMotion,
    setPlayState,
    setTheaterMode,
    setTranscriptOpen,
    setVideoDuration,
    setVideoJustStarted,
    theaterMode,
    videoDuration,
  } = useContext(videoPlayerContext)

  const autoId = useId()

  /**
   * Any child components we create will not have access to external providers, such
   * as Redux.  We have to pass in anything from these providers that they'd need.
   *
   * Here we are establishing the settings needed by the control bar.  These will
   * then be passed to controls within the control bar, such as the Volume component.
   */
  const [captionsOn, toggleCaptionsOn] = useToggleState(
    false,
    TOGGLE_STATE_VIDEO_CC_ENABLED,
  )
  const [volume, setVolume] = useLocalSetting('video-volume', 1.0)

  const {
    autoplay = false,
    className = null,
    errorDisplay = true,
    fluid = true,
    height = null,
    id,
    inactivityTimeout = 0,
    muted = false,
    onTimeUpdate,
    poster = '',
    preload = 'auto',
    src,
    tracks = null,
    width = null,
  } = props

  const mouseMoveTimeoutId = useRef()
  const justStartedTimeoutId = useRef()

  const handleMouseMove = () => {
    if (playerRef.current) {
      playerRef.current.el().classList.add('vjs-mouse-in-motion')
      setMouseInMotion(true)
      clearTimeout(mouseMoveTimeoutId.current)
      mouseMoveTimeoutId.current = setTimeout(() => {
        playerRef.current?.el().classList.remove('vjs-mouse-in-motion')
        setMouseInMotion(false)
      }, 500)
    }
  }

  const handleTouchStart = e => {
    // only run this if they touch the actual video, not any of the controls
    if (playerRef.current && e.srcElement.className === 'vjs-tech') {
      // bring up the control toolbar that normally shows on mouse hover
      handleMouseMove()
      if (playerRef.current.paused()) {
        playerRef.current.play()
      } else {
        playerRef.current.pause()
      }
    }
  }

  useEffectOnce(() => () => {
    if (playerRef.current) {
      playerRef.current
        .el()
        .removeEventListener('mousemove', handleMouseMove, true)
    }
    // we don't care about the dependencies here, this is just used for Unmount
  })

  const onLoadedMetadata = () => {
    setVideoDuration(formatSec(playerRef.current.duration()))
  }

  useEffect(() => {
    const curPlayer = playerRef.current
    curPlayer?.on('play', onInteract)

    return () => {
      curPlayer?.off('play', onInteract)
    }
  }, [playerRef, onInteract])

  const initPlayerEvents = player => {
    player.ready(() => {
      when(playerRef.current, setPlayState, 'ready')
    })

    if (player.readyState() < 1) {
      // wait for loadedmetdata event
      player.one('loadedmetadata', onLoadedMetadata)
    } else {
      // metadata already loaded
      onLoadedMetadata()
    }

    player.on('play', () => {
      if (playerRef.current) {
        playerRef.current
          .el()
          .removeEventListener('mousemove', handleMouseMove, true)
        playerRef.current
          .el()
          .removeEventListener('touchstart', handleTouchStart, true)

        playerRef.current
          .el()
          .addEventListener('mousemove', handleMouseMove, true)
        playerRef.current
          .el()
          .addEventListener('touchstart', handleTouchStart, true)

        stopAllOtherPlayers(playerRef.current)
        setPlayState('playing')
      }
      player.el().classList.add('vjs-just-started')
      player.el().classList.add('vjs-has-started')
      setVideoJustStarted(true)
      clearTimeout(justStartedTimeoutId.current)
      justStartedTimeoutId.current = setTimeout(() => {
        player.el()?.classList.remove('vjs-just-started')
        setVideoJustStarted(false)
      }, 3000)
    })
    player.on('playing', () => {
      /**
       * TODO:
       * MAJOR WEIRDNESS HERE!!
       *
       * Enabling this next line causes the player to not start when clicked.  WHY?!
       * It doesn't matter what the playState is set to.
       * It doesn't matter if the playState is even consumed (!)
       *
       * Maybe something about React setting the state interrupts videojs?
       *
       * Without this line, we can't reset the big button when paused.  It's not
       * the end of the world as the player still works fine, but still.... WHY?!
       *
       * EDIT:
       * The same thing seems to happen if you click on the transcript button and
       * it calls setTranscriptOpen().
       * The change of state causes the player to stop.  WHY?!
       */
      // when(playerRef.current, setPlayState, 'playing')
    })
    player.on('pause', () => {
      when(playerRef.current, setPlayState, 'paused')
    })
    player.on('ended', () => {
      when(playerRef.current, setPlayState, 'ended')
      player.el().classList.remove('vjs-has-started')
      if (player.isFullscreen()) player.exitFullscreen()
    })
    player.on('timeupdate', () => {
      when(
        playerRef.current,
        whenPresent,
        onTimeUpdate,
        playerRef.current.currentTime(),
        playerRef.current.remainingTime(),
      )
      when(playerRef.current, setCurrentTime, playerRef.current.currentTime())
    })
  }

  const options = useMemo(() => {
    const playerOptions = {}
    playerOptions.autoplay = autoplay
    playerOptions.bigPlayButton = false
    playerOptions.controls = true
    playerOptions.errorDisplay = errorDisplay
    playerOptions.fluid = fluid
    playerOptions.inactivityTimeout = inactivityTimeout
    playerOptions.muted = muted
    playerOptions.preload = preload
    playerOptions.tracks = tracks
    playerOptions.html5 = {
      // ref: https://github.com/videojs/video.js/issues/4978
      nativeTextTracks: false,
      vhs: {
        overrideNative: true,
      },
    }

    if (width) playerOptions.width = width
    if (height) playerOptions.height = height
    playerOptions.controlBar = {
      children: [
        'timeDivider',
        'currentTimeDisplay',
        'progressControl',
        'durationDisplay',
        'fullscreenToggle',
      ],
    }
    return playerOptions
  }, [
    autoplay,
    errorDisplay,
    fluid,
    height,
    inactivityTimeout,
    muted,
    preload,
    tracks,
    width,
  ])

  useDeepCompareEffect(() => {
    if (playerRef.current) {
      playerRef.current.pause()
      playerRef.current.src(src)
      setTimeout(() => {
        // This causes the controls to be hidden, if we were playing the old src.
        when(playerRef.current, setPlayState, 'not ready')
      }, 100)
    }
  }, [src])

  const handleMounted = player => {
    if (player) {
      const controlBar = player.getChild('ControlBar')
      // const playButton = player.getChild('BigPlayButton')
      const playButton = player.addChild('ssBigPlayButton', {})

      const gradientOverlay = new GradientOverlay(player)
      player.el().insertBefore(gradientOverlay.el(), playButton.el())

      controlBar.addChild(
        'ssPlayButton',
        {},
        options.controlBar.children.length - 4,
      )
      if (!previewing) {
        // annotations and theater mode only work when viewed in the curriculum
        controlBar.addChild(
          'ssTheaterToggle',
          {
            setTheaterMode,
            setVideosInTheaterMode,
            theaterMode,
          },
          options.controlBar.children.length,
        )

        if (allowAnnotations) {
          controlBar.addChild(
            'ssAnnotationsToggle',
            {
              setAnnotationsOn,
              setTheaterMode,
              setVideosInTheaterMode,
            },
            options.controlBar.children.length,
          )
        }
      }

      controlBar.addChild(
        'ssSettingsButton',
        {
          captionsOn,
          playerRef,
          setTranscriptOpen,
          toggleCaptionsOn,
        },
        options.controlBar.children.length,
      )

      controlBar.addChild(
        'ssVolume',
        {
          setVolume,
          volume,
        },
        options.controlBar.children.length,
      )

      initPlayerEvents(player)
    }
  }

  const validStates = ['playing', 'paused']
  return (
    <>
      <PlayerWrapper
        className={className}
        id={id || autoId.replaceAll(':', '')}
        onMounted={handleMounted}
        options={options}
        poster={poster}
        ref={playerRef}
        src={src}
      />
      {validStates.includes(playState) || !videoDuration ? null : (
        <StyledDuration>{videoDuration}</StyledDuration>
      )}
      <StateChangeIndicator playState={playState} />
    </>
  )
}

VideoJsWrapper.propTypes = {
  autoplay: PropTypes.bool,
  className: PropTypes.string,
  errorDisplay: PropTypes.bool,
  fluid: PropTypes.bool,
  height: numberOrString,
  id: numberOrString,
  inactivityTimeout: PropTypes.number,
  muted: PropTypes.bool,
  onTimeUpdate: PropTypes.func,
  poster: PropTypes.string,
  preload: PropTypes.oneOf(['auto', 'none', 'metadata']),
  src: PropTypes.array.isRequired,
  tracks: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      srclang: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  width: numberOrString,
}

export default VideoJsWrapper
