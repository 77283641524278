import FormHelperText from '@mui/material/FormHelperText'
import { styled } from '@mui/material/styles'
import cl from 'classnames'
import { componentShape } from 'core/shapes'
import { pluralize } from 'fp/strings'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { getCharacterCount, getWordList } from './utils/misc'

const StatusBar = styled(
  props => {
    const {
      characterLimit,
      className,
      editorState,
      error,
      helperText, // will contain error message from hook form, when present
      id,
      showStatus = false,
      showWordCount = false,
      status,
    } = props

    const wordCountString = useMemo(() => {
      const wordList = getWordList(editorState)
      const charLength = getCharacterCount(editorState)
      const words = pluralize('word')(wordList.length)
      const chars = pluralize('character')(charLength)

      return characterLimit
        ? `${pluralize('character')(characterLimit - charLength)} remaining`
        : showWordCount
          ? `${words}, ${chars}`
          : ''
    }, [characterLimit, editorState, showWordCount])

    return (
      <div className={cl(className, 'draft-statusbar', { 'Mui-error': error })}>
        {error && helperText ? (
          <FormHelperText
            error={error}
            id={id}>
            {helperText}
          </FormHelperText>
        ) : (
          <div id={id}>{Boolean(showStatus) && status}</div>
        )}
        <div>{wordCountString}</div>
      </div>
    )
  },
  { name: 'RichTextEdit-StatusBar' },
)(
  ({
    theme: {
      mixins: { rem },
    },
  }) => ({
    fontSize: rem(1.3),
    marginTop: rem(0.5),
    display: 'flex',
    justifyContent: 'space-between',
  }),
)

StatusBar.propTypes = {
  characterLimit: PropTypes.oneOf([150, 300]),
  editorState: PropTypes.object.isRequired,
  error: PropTypes.bool.isRequired,
  helperText: componentShape,
  id: PropTypes.string.isRequired,
  showStatus: PropTypes.bool,
  showWordCount: PropTypes.bool,
  status: componentShape,
}

export default StatusBar
