import { border, padding } from 'polished'

const overrides = ({
  mixins: { absHeight, important, px, rem, size, transition },
  palette,
}) => ({
  MuiTreeItem: {
    variants: [
      {
        props: { variant: 'nav-shell' },
        style: {
          color: palette.navigation.main,
          lineHeight: px(20),

          '.MuiTreeItem-iconContainer': {
            svg: {
              color: palette.navigation.main,
              width: 18,
              paddingTop: 2,
            },
            marginRight: 4,
            width: important('unset'),
          },

          '&[data-haschildren="true"] ul': {
            marginTop: 10,
          },

          '.MuiTreeItem-content': {
            display: 'flex',
            flexFlow: 'row nowrap',
            justifyContent: 'space-between',
            margin: px(32, 0),

            '&.leaf': {
              padding: rem(1.6, 0, 1.6, 4),
              margin: 0,
              ...border('left', 1, 'solid', palette.border.dark),
              '&:first-of-type': {
                marginTop: important(0),
              },
            },

            '.icon-filled': { opacity: 0 },

            '.MuiTreeItem-label': transition(),

            '&.customer-insertion .MuiTreeItem-label': {
              '&::before': {
                content: "'⌅'",
                color: palette.info.main,
                marginRight: 4,
              },
            },

            '&:hover': {
              backgroundColor: 'transparent',
              '.icon-unfilled': { opacity: 0 },
              '.icon-filled': { opacity: 1 },
            },

            '> div': {
              display: 'flex',
              alignItems: 'center',
              gap: 15,
            },

            '&.Mui-selected': {
              backgroundColor: 'transparent',
              color: palette.grey.contrastText,
              '.leaf': {
                fontWeight: 800,
                textDecoration: 'underline',
                textUnderlineOffset: rem(0.4),
                ...transition(),
              },

              '.icon-container::before': {
                content: "''",
                background: palette.grey[0],
                borderRadius: '50%',
                position: 'absolute',
                ...size(40),
                zIndex: -1,
                transform: 'translate(-8px,-8px)',
              },

              '&.Mui-focused': {
                backgroundColor: 'transparent',
              },
            },

            '&:not(.Mui-expanded):hover': {
              color: palette.grey.contrastText,
            },
          },
        },
      },

      {
        props: { 'data-subvariant': 'content-viewer' },
        style: {
          marginTop: 2,

          '.MuiTreeItem-label, svg': {
            color: palette.primary.contrastText,
          },

          '.MuiTreeItem-label.excluded': {
            color: palette.navigation.main,
          },

          '.icon-container': {
            ...absHeight(15),
            svg: {
              color: palette.navigation.icon,
              width: 15,
              height: 15,
              strokeWidth: 2,
              '&[data-complete="true"], &[data-viewed="true"]': {
                color: palette.navigation.toc,
                strokeWidth: 3,
              },
              '&[data-viewed="true"]:not([data-complete="true"])': {
                strokeWidth: 3,
              },
            },
          },

          '.MuiTreeItem-content': {
            minHeight: 40,
            height: 'auto',
            margin: 0,
            '> div': {
              gap: 0,
            },

            '&.leaf': {
              borderStyle: 'none',
              ...padding(0, 12, 0, 20),
            },

            '&.Mui-selected .icon-container::before': {
              background: 'unset',
            },
          },
        },
      },
    ],
  },
})

export default overrides
