import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import { useTheme } from '@mui/material/styles'
import cl from 'classnames'
import { filterAndSortVideoAnnotations } from 'common/avclub/utils'
import { videoPlayerContext } from 'common/avclub/video/context'
import { debounce, matches } from 'fp/utils'
import { contentViewerContext } from 'hss/ContentViewer/ContentViewerProvider'
import AnnotationPin from 'hss/images/controls/video/annotation-pin.svg'
/* istanbul ignore file */
import { useCallback, useContext, useEffect, useState } from 'react'

const AnnotationTicks = () => {
  const [duration, setDuration] = useState()
  const [progressHolderLength, setProgressHolderLength] = useState()
  const {
    annotationsOn,
    mouseInMotion,
    playerRef,
    theaterMode,
    videoJustStarted,
  } = useContext(videoPlayerContext)
  const {
    palette: { annotations: colors },
  } = useTheme()
  const [sortedAnnotations, setSortedAnnotations] = useState([])
  const { annotations } = useContext(contentViewerContext)
  const [videoHasStarted, setVideoHasStarted] = useState(false)
  const [ticksTop, setTicksTop] = useState(0)

  useEffect(() => {
    setSortedAnnotations(filterAndSortVideoAnnotations(annotations))
  }, [annotations])

  const redrawTicks = useCallback(() => {
    if (videoHasStarted) {
      const videoJsWrapper =
        document.getElementsByClassName('video-js-wrapper')[0]
      const progressHolder = document.getElementsByClassName(
        'vjs-progress-holder',
      )[0]
      if (videoJsWrapper && progressHolder) {
        setDuration(playerRef.current?.duration())
        setProgressHolderLength(progressHolder.offsetWidth)
        const progressHolderPos = progressHolder.getBoundingClientRect()
        const progressHolderVerticalMiddle =
          (progressHolderPos.top + progressHolderPos.bottom) / 2
        setTicksTop(
          progressHolderVerticalMiddle -
            videoJsWrapper.getBoundingClientRect().top -
            30,
        )
      }
    }
  }, [playerRef, videoHasStarted])

  const debouncedRedrawTicks = debounce(150, redrawTicks)

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    debouncedRedrawTicks()
    // we need to redrawTicks whenever any of these values change
  }, [
    annotations,
    annotationsOn,
    debouncedRedrawTicks,
    videoHasStarted,
    theaterMode,
  ])

  useEffect(() => {
    const onResize = () => {
      setTimeout(() => {
        debouncedRedrawTicks()
      }, 500)
    }

    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [debouncedRedrawTicks])

  playerRef.current?.on('play', () => {
    setVideoHasStarted(true)
  })

  const getColor = colorId => colors.find(matches('colorId', colorId))

  return (
    Boolean(sortedAnnotations?.length && duration && progressHolderLength) && (
      <Box
        className={cl({
          'vjs-annotation-ticks': true,
          'vjs-just-started': videoJustStarted,
          'vjs-mouse-in-motion': mouseInMotion,
        })}
        sx={{
          position: 'absolute',
          left: '98px',
          top: `${ticksTop}px`,
          zIndex: 2,
        }}>
        {sortedAnnotations?.map(
          ({ id, interactionData: { colorId, text, time } }) => (
            <Box
              display="inline"
              key={id}
              left={(time / duration) * progressHolderLength}
              position="absolute">
              <Tooltip
                style={{ lineHeight: 1 }}
                title={text}>
                <div>
                  <AnnotationPin
                    style={{
                      color: getColor(colorId).value,
                      fill: getColor(colorId).value,
                    }}
                  />
                </div>
              </Tooltip>
            </Box>
          ),
        )}
      </Box>
    )
  )
}

export default AnnotationTicks
